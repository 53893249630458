@import "./components/mixins";
@import "./components/Loading.scss";
@import "./components/cam.scss";

html,
body {
  scroll-behavior: smooth;
}

button:focus,
.btn:focus {
  box-shadow: none !important;
}

.cursorPointer {
  cursor: pointer;
}

:root {
  --main-color: #007aff;
  --red: #ff3b30;
  --main-blue: #5898ec;
  --main-red: #ff3b30;
  --main-green: #7ab57e;
}

.table-draggable {
  tbody tr td:first-child {
    cursor: row-resize;
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
  }
}

.btn-primary[disabled] {
  background-color: #ededed !important;
  border: none;
}

.align_start {
  align-items: flex-start !important;
}

.red {
  color: var(--red);
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #ededed !important;
  border: none;
  pointer-events: none;
}

.card-box {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
  padding: 20px;
  border: 1px solid #3778a0;
  border-radius: 10px;
  img {
    max-width: 378px;
    height: auto;
  }
  p {
    font-size: 16px;
    color: #3778a0;
  }
}

.hasSortTb {
  th {
    padding-right: 20px;
    position: relative;
  }
}

.arrowSort {
  position: absolute;
  height: 66px;
  top: 0;
  margin-left: 25px;
  display: inline-block;

  .arrowUp {
    position: absolute;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 8px solid #7f8fa6;
    width: 0;
    height: 0;
    margin: 6px auto;
    right: 0;
    top: 50%;
    margin-top: -15px;
    cursor: pointer;

    &:hover {
      border-bottom: 8px solid #518ced;
    }
  }

  .arrowDown {
    position: absolute;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 8px solid #7f8fa6;
    width: 0;
    height: 0;
    margin: 6px auto;
    right: 0;
    top: 50%;
    margin-top: -14px;
    cursor: pointer;

    &:hover {
      border-top: 8px solid #518ced;
    }
  }

  &.isActiveUp {
    .arrowUp {
      border-bottom: 8px solid #518ced;
    }
  }

  &.isActiveDown {
    .arrowDown {
      border-top: 8px solid #518ced;
    }
  }
}

.boxLoginQR .img {
  width: 120px;
  height: 120px;
  margin: 20px auto;
}

//input date
input[type="date"] {
  appearance: none;
  background-color: white;
  padding: 0.5rem;
  border: 1px solid black;
  border-radius: 0.25rem;
}

input[type="date"]::before {
  content: attr(value);
  display: inline-block;
  color: #555;
  font-size: 1rem;
  font-family: sans-serif;
}

.dateInput {
  appearance: none;
  background-color: white;
  padding: 0.5rem;
  border: 1px solid black;
  border-radius: 0.25rem;
}

.dateInput::before {
  content: var(--date-value);
  display: inline-block;
  color: #555;
  font-size: 1rem;
  font-family: sans-serif;
}

.react-datepicker-popper {
  z-index: 999 !important;
}

.react-datepicker-wrapper input {
  background: #fff url("../images/calendar.svg") no-repeat right 10px center;
  background-size: 20px;
  // background-color: #fff;
}

.retirementdate {
  flex-wrap: nowrap;

  p {
    white-space: nowrap;
    margin-right: 10px;
  }

  .react-datepicker-wrapper {
    width: 150px !important;

    input {
      padding-right: 40px;
      margin-left: 0 !important;
      // background: transparent !important;
      background: url("../images/calendar.svg") no-repeat right 10px center;
      background-size: 20px;
    }
  }
}

.isHidden {
  display: none !important;
  visibility: hidden !important;
}

table.userRetired {
  .clred {
    display: none;
    visibility: hidden;
  }
}

.filterGroup {
  // display: none;
  z-index: 99;

  &.show {
    display: block;
    z-index: 99;
  }

  .listSelEmployee {
    flex-wrap: wrap;
  }

  .listSelection .selectedEmployee {
    display: flex;
    border: none;
    padding: 0;
    border-radius: 0;
    background: none;
    flex-basis: auto;

    .label {
      cursor: pointer;
      background: #fff;
      border: 2px solid #a9a9a9;
      border-radius: 5px;
      padding: 5px;

      span.authority {
        background: #99a3b1;
        font-weight: bold;
        color: #fff;
        font-size: 14px;
        padding: 2px 8px;
        margin-right: 10px;
      }
    }

    cursor: pointer;

    .form-control {
      text-indent: -9999px;
      display: none;

      &:checked + label {
        background: #f8fbff;
        border: 2px solid #5a90d9;

        span {
          color: #5a90d9;
        }

        .authority {
          background: #5a90d9;
          color: #fff;
        }
      }
    }

    span.name {
      display: block;
      margin-bottom: 5px;
    }
  }
}

//loading icon
@keyframes loadingAni {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.loadingAni div {
  position: absolute;
  animation: loadingAni linear 0.78125s infinite;
  background: #52a8ff;
  width: 6px;
  height: 10px;
  border-radius: 3px / 3.4px;
  transform-origin: 3px 23px;
}

.loadingAni div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.703125s;
  background: #52a8ff;
}

.loadingAni div:nth-child(2) {
  transform: rotate(36deg);
  animation-delay: -0.625s;
  background: #52a8ff;
}

.loadingAni div:nth-child(3) {
  transform: rotate(72deg);
  animation-delay: -0.546875s;
  background: #52a8ff;
}

.loadingAni div:nth-child(4) {
  transform: rotate(108deg);
  animation-delay: -0.46875s;
  background: #52a8ff;
}

.loadingAni div:nth-child(5) {
  transform: rotate(144deg);
  animation-delay: -0.390625s;
  background: #52a8ff;
}

.loadingAni div:nth-child(6) {
  transform: rotate(180deg);
  animation-delay: -0.3125s;
  background: #52a8ff;
}

.loadingAni div:nth-child(7) {
  transform: rotate(216deg);
  animation-delay: -0.234375s;
  background: #52a8ff;
}

.loadingAni div:nth-child(8) {
  transform: rotate(252deg);
  animation-delay: -0.15625s;
  background: #52a8ff;
}

.loadingAni div:nth-child(9) {
  transform: rotate(288deg);
  animation-delay: -0.078125s;
  background: #52a8ff;
}

.loadingAni div:nth-child(10) {
  transform: rotate(324deg);
  animation-delay: 0s;
  background: #52a8ff;
}

.spinnerLoading {
  display: inline-block;
  overflow: hidden;
  transform: scale(0.6);
  width: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
}

.loadingAni {
  width: 50px;
  height: 50px;
  position: relative;
  transform: translateZ(0);
  backface-visibility: hidden;
  transform-origin: 0 0;
  /* see note above */
  padding-left: 20px;
}

.loadingAni div {
  box-sizing: content-box;
}

.loading {
  text-align: center;
}

.divScroll {
  position: relative;

  .spinnerLoading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

td.iconDrag {
  background: url(../images/drag.svg) no-repeat center center;
  background-size: 24px;
  text-indent: -9999px;
  min-width: 50px;
}

.productPage {
  input[type="file"] {
    display: none;
  }
}

.hasSticky {
  position: relative;

  th {
    position: sticky;
    top: 0;
    background: #e7ecf5;
    z-index: 9;
  }
}

#selectModal {
  .listproducts {
    .item {
      cursor: pointer;

      &.-active {
        opacity: 1;
      }
    }

    input {
      text-indent: -9999px;
      display: none;
    }
  }
}

#SelectionProduct .modal-dialog {
  max-width: 800px;
  .modal-body {
    padding: 0;
    min-height: 520px;
  }
  .listultag {
    padding: 0 15px;
  }
  .listproducts {
    padding: 15px;
    padding-bottom: 30px;
    overflow-y: auto;
    max-height: 520px;
  }
}

.implantpart {
  .button {
    position: relative;
    transition: all 0.3s;

    &:hover {
      opacity: 0.7;
    }

    button {
      z-index: 1;
    }

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 0;
    }
  }
}

.blockSearch-input .react-datepicker-wrapper {
  width: 75% !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  font-family: Arial, Helvetica, sans-serif !important;
}

.timeSelectBox {
  position: relative;
  z-index: 99;

  input.form-select {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 0.25rem center;
    background-size: 16px 12px;
    font-size: 16px;
    cursor: pointer;
  }

  .timeSelect {
    display: flex;
    background: #ecf1f6;
    border-radius: 4px;
    position: absolute;
    z-index: 111;
  }

  ul {
    height: 200px;
    overflow: auto;
    overflow-y: scroll;
    width: 68px;
    padding: 5px 0;

    li {
      font-style: normal;
      font-size: 15px;
      padding: 5px;
      cursor: pointer;
      position: relative;

      &.isActive {
        // background: rgba($color: #CDD5E5, $alpha: 0.65);
        // &::after {
        //   position: absolute;
        //   content: '';
        //   background: rgba($color: #CDD5E5, $alpha: 0.65);
        //   width: 40px;
        //   height: 27px;
        //   top: 0;
        //   z-index: 0;
        // }
      }
    }

    &.hourSelect {
      text-align: right;
      z-index: 2;

      li {
        padding-right: 10px;

        &::before {
          position: absolute;
          content: " : ";
          right: 0;
          top: 4px;
          z-index: 9999;
        }

        &::after {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          right: 0;
        }
      }
    }

    &.minSelect {
      text-align: left;
      z-index: 1;
      width: 55px;

      li {
        padding-left: 0;

        &::after {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          left: 0;
        }
      }
    }

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      background: #ecf1f6;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ecf1f6;
      border-radius: 10px;
      border: 1px solid #ecf1f6;
    }
  }
}

// .blockSearch .form-group:first-child input {
//   max-width: 200px;
// }

.endOfBreak {
  background: #5f5f5f;
  height: 100vh;
  width: calc(100% + 30px);
  margin-left: -15px;
  position: fixed;
  top: 0px;
  z-index: 2;
  opacity: 0.95;
}

.btn-container {
  display: grid;
  grid-template-columns: 60% calc(40% - 10px);
  gap: 10px;
  justify-content: space-between;
}

.btn-container a:first-child {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 4;
}

.btn-red {
  background: #ff454e;
  color: #fff;
}

.btn-orange {
  background: #ff8e00;
  color: #fff;
}

.overBlock {
  overflow-x: auto;
}

#instruction {
  .modal-dialog {
    background: #f5f6fa;
    max-width: 1440px;
    width: 94%;

    .modal-content {
      background: #f5f6fa;
    }

    .modal-body {
      padding-bottom: 0;
    }

    .overBlock {
      padding-bottom: 15px;
      min-height: calc(100vh - 260px);
    }

    .txt_error {
      text-align: center;
    }

    .issuedList {
      // min-height: calc(100vh - 280px);
      min-width: 1200px;
      .item {
        align-self: stretch;
      }
      &.issuedListMany {
        .item {
          align-self: stretch;
        }
      }
    }

    .issuedList .item .label {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      label {
        padding: 0 10px;
        height: 32px;
        line-height: 30px;
        margin-left: 5px;
      }
    }
  }

  .btn.btn-gray {
    background: #dcdfe6;
    border-color: #dcdfe6;
    pointer-events: none;
  }

  @media all and (max-width: 1180px) {
    .issuedList .item_name {
      font-size: 15px;
    }
  }
}

.blockCard_Desc-btnadd {
  button {
    margin-left: 5px;

    &:first-child {
      margin-left: 0;
    }
  }
}

.listproducts .item p,
.listproducts .item span {
  left: 0;
}

.listproducts .item p:not(.itemName) {
  position: relative;
  bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.listMaterial li {
  align-self: stretch;
}

.listMaterial li.-active {
  background: #518ced;
  color: #fff;
}

#implantSelection {
  .modal-body {
    overflow-y: auto;
  }

  .issuedList {
    margin-top: 0;
    gap: 8px;
    min-height: 400px;
    overflow-y: scroll;
    display: block;

    // overflow-y: hidden;
    .item {
      margin: 0;
      margin-right: 12px;
      margin-bottom: 12px;
      padding-top: 0;
      // position: static;
      position: relative;
      align-self: unset;
      border-color: #518ced;

      &:hover {
        opacity: 1;
      }

      &:nth-child(5n) {
        margin-right: 0;
        .selectionList {
          right: 0;
          left: inherit;
        }
      }

      .subitem {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 100px;
        cursor: pointer;

        input {
          text-indent: 0;
        }

        img {
          top: 0;
        }

        .itemName {
          position: relative;
          flex: 0 0 auto;
          bottom: 0;
          margin-top: 10px;
        }

        &.hasselectionList {
          &::after {
            position: absolute;
            content: "";
            background: url(../images/polygon.png) no-repeat center center;
            background-size: 72%;
            transform: rotate(180deg);
            width: 59px;
            height: 38px;
            right: 50%;
            margin-right: -30px;
            bottom: -10px;
            z-index: 100;
          }
        }
      }

      &.active {
        .itemName {
          color: #fff;
        }
      }
    }

    .selectionList {
      // width: 90%;
      width: auto;
      left: 0;
      top: 105px;
      position: absolute;
      z-index: 99;

      .titVariation {
        color: #000 !important;
        text-align: left;
      }

      .listVariation {
        border: none;
        display: grid;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 15px;
        grid-template-columns: repeat(4, 1fr);
        gap: 12px;
        li {
          margin: 0;
          &.active {
            color: #fff;
            border-color: #518ced;
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
  .note {
    p.f20 {
      line-height: 1.2;
    }
  }
}

#hideManufacturer {
  border-radius: 5px;
}

.breakline {
  white-space: break-spaces;
}

.businessRegisted {
  .name {
    h4 {
      font-size: 20px;
      padding-bottom: 5px;
    }
  }

  .product {
    border: 1px solid #5f646e;
    border-radius: 4px;
    margin-right: 10px;

    p {
      padding: 3px 5px;
    }
  }
}

.filterDepartment {
  .listSelection {
    flex-wrap: wrap;

    .form-check-custom {
      margin-top: 10px;

      label {
        transition: all 0.3s;
        font-weight: 500 !important;
      }

      &:hover {
        label {
          background: #518ced;
          color: #fff;
          border-color: #518ced;
        }
      }
    }
  }
}

.listproducts {
  .item {
    cursor: pointer;
  }
}

.filterDepartment {
  min-width: 880px;
}

.filterE {
  display: none;
}

.blockImg {
  .image {
    figure {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    img {
      object-fit: cover;
      width: auto;
      height: 500px;
    }
  }
}

.listproducts-process {
  opacity: 1 !important;
}

.chosse-order-container {
  display: flex;
}

.chosse-order-container .blockCard {
  margin-right: 10px;
}

.form-check-input-custom {
  width: 22px;
  height: 22px;
  border-radius: 100% !important;
  margin-top: 0.01em;
  appearance: none;
  position: absolute;
  right: 0px;
}

#orderGroupDetail {
  .modal-dialog {
    width: 700px;
  }

  .blockselectDental {
    border: none;
    background-color: transparent;

    div.infor {
      width: 75%;
    }

    .block .inputbox {
      width: 46%;
    }
  }
}

#importPage {
  .blockselectDental {
    width: 800px;
    margin: 0 auto;
    position: relative;
    top: 10%;

    .block:last-child button {
      flex: none;
    }
  }
}

#scanOrderGroup {
  .scanner-video {
    height: 300px;
  }

  .blockselectDental {
    margin-right: 0;
    border: none;
    background-color: transparent;

    .block .inputbox {
      flex-basis: calc(35% - 30px);
    }

    .block:last-child button {
      flex: none;
    }
  }
}

// .absoluteSide {
//   position: absolute;
//   width: 30%;
//   min-width: 280px;
//   box-shadow: 1px 3px 5px 3px rgba(0, 0, 0, .2);
//   left: 0;
//   top: 0;
//   background: #fff;
//   height: 100vh;
//   overflow-y: scroll;
//   overflow-x: hidden;

//   a.iconclose {
//     width: 30px;
//     height: 30px;
//     display: inline-block;
//     float: right;
//     position: sticky;
//     top: 10px;
//     opacity: 0.6;
//   }
// }

// .process_list {
//   display: flex;
// }

.process_list {
  position: relative;
}

.process_list li {
  max-width: initial !important;
  margin-right: 2px;
  position: absolute;
  height: 40px;
  margin-top: -2px;
  border: 1px solid #cdd5e5;
}

// .boxlist {
//   padding: 30px;
//   margin-top: 20px;

//   ul {
//     display: flex;
//     flex-wrap: wrap;
//     margin-bottom: 25px;

//     li {
//       @include widthbox2(50%, 20px);
//       background-color: #508CED;
//       padding: 12px 10px;
//       border-radius: 7px;
//       text-align: center;
//       margin-bottom: 15px;
//       position: relative;

//       * {
//         color: #fff;
//       }

//       &:nth-child(2n) {
//         margin-right: 0px;
//       }

//       .txtdot {
//         width: 35px;
//         height: 35px;
//         border-radius: 100%;
//         background-color: #E66466;
//         @include flexbox(center, center);
//         position: absolute;
//         top: -10px;
//         left: -10px;
//       }
//     }
//   }

//   .iconclose {
//     float: right;
//   }
// }

// .absoluteSide {
//   z-index: 100;
// }

// .blockBtnNav .blockTime .icon {
//   position: relative;
// }

// .divlogout {
//   width: 180px !important;
//   position: absolute;
//   background: #fff;
//   top: 50px;
//   right: 0px;
//   padding: 10px;
//   height: auto !important;
//   box-shadow: 1px 3px 12px 2px rgba(0, 0, 0, 0.2);
//   z-index: 99999;

//   ul {
//     display: block !important;

//     * {
//       display: block !important;
//     }

//     text-align: left !important;

//     li {
//       margin-left: 10px;
//       display: block !important;
//       margin-bottom: 10px !important;

//       &:last-child {
//         margin-bottom: 0px !important;
//       }

//       a {
//         display: flex !important;
//         justify-content: flex-start !important;
//         padding: 5px 0px;

//         img {
//           margin-right: 7px;
//         }
//       }
//     }
//   }

//   * {
//     height: auto !important;
//     width: auto !important;
//   }
// }

.blockprocess {
  overflow: hidden;
}

.blockprocess li {
  overflow: hidden;
}

.blockico {
  @include flexbox(center, center);
  border: 2px solid var(--main-blue);
  color: var(--main-blue);
  font-size: 14px;
  font-weight: 600;
  height: 48px;
  width: 104px;
  border-radius: 30px;
  cursor: pointer;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;

  /* Safari */
  &_red {
    border: 2px solid var(--main-red);
    color: var(--main-red);
  }

  img {
    margin-right: 10px;
  }
}

.fontArial {
  font-family: Arial, Helvetica, sans-serif !important;
  font-weight: normal !important;
  small {
    font-weight: normal !important;
  }
}

.fz30 {
  font-size: 30px;
}

.main-business {
  .rightside {
    .btn-green {
      background-color: var(--main-green);
    }
  }
}

.blockBusiness_regist {
  .item .process span {
    background-color: var(--main-green);
  }
  &.dflex {
    align-items: stretch;
  }
  .item_head h4 {
    line-height: 1.1;
  }
}

.blockBtnNav .blockBtn li a {
  box-shadow: 1px 2px 5px 2px rgba(0, 0, 0, 0.1);
}

.layoutTop .item_info .ins {
  p {
    &:first-child {
      color: #718093 !important;
    }
  }
}

.btn-red2 {
  background: #e6654e !important;
}

// .blockBtnNav .blockTime .icon * img {
//    min-width: 20px !important;
// }

h1,
h2,
h3,
h4,
h5 {
  font-weight: initial !important;
}

.blockBusiness_regist .item_head h4 {
  margin-top: 4px;
}

.page_regist {
  table.tbstyle4 td {
    font-size: 16px;
    // font-weight: 500;
    // * {
    //   font-weight: 500;
    // }
  }
  table.tbstyle4 td .require {
    font-size: 10px;
    font-weight: normal;
    background: #fc6356;
  }
}

.blockBtnNav .blockBtn li a,
.blockBusiness_regist .item_mark .ico span,
.blockBusiness_regist .item_mark li,
.blockBusiness_regist .item_mark li span,
.btn,
.sidebar_nav li a span,
table thead th,
.blockfilter label,
.content_head .title,
table.tbstyle1 td:first-child,
.listul_l .tab-container > .box .text,
ul.listtabs li,
.listproducts p,
.listproducts span,
.layoutSecond .head .btnBack a,
.blockSearch .form-group label,
.toggle-button-cover label,
.filterGroup .listSelEmployee .buttonName span,
.filterGroup .listSelection .buttonSel span,
.filterGroup .head button {
  font-weight: 500;
}

.filterGroup .listSelection .buttonSel .status span {
  font-size: 12px !important;
}

.filterGroup .listSelection .selectedEmployee .label {
  height: 58px;
}

.filterGroup .listSelection .selectedEmployee span.name {
  margin-bottom: 1px;
}

.filterGroup
  .listSelection
  .selectedEmployee
  .form-control:checked
  + label
  span:last-child,
.filterGroup .listSelection .buttonSel .status span:last-child {
  font-weight: 600 !important;
}

.filterSelection.filterEmployee,
.filterSelection.filterDirector {
  position: relative;
}

.filterGroup .scrollBlock {
  max-height: 440px !important;
}

.filterGroup .listSelEmployee .buttonName span.num {
  font-weight: 600 !important;
}

.filterGroup .scrollBlock {
  // remove scrollbar
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;

  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}

.absoluteSide {
  display: block;
}

.pointer {
  cursor: pointer;
}

#VariationSelection {
  .selectionList {
    .listVariation {
      display: flex;
    }
  }
}

.instruction_tblist .tbhorizontal {
  min-height: 450px;
}

.divlogout {
  button {
    background: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    img {
      margin-right: 7px;
    }
  }
}

.page_creditcard {
  .container {
    max-width: 760px;
    margin: 0 auto;
  }
  .cardimg {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border: 2px solid #ed5555;
    padding: 18px;
    &_e {
      min-width: 220px;
      p {
        margin-top: 10px;
        font-size: 12px;
        text-align: center;
      }
    }
  }
  .blocktable {
    table {
      tr {
        td {
          font-size: 14px;
          border-top: 1px solid #5f5f5f;
          border-bottom: 0;
        }
        &:last-child {
          td {
            border-bottom: 1px solid #5f5f5f;
          }
        }
      }
    }
    .form-control,
    .form-select {
      font-size: 14px;
      &::placeholder {
        font-size: 12px;
      }
    }
  }
}

ul.listtabs.listtabsSetting {
  overflow-y: scroll;
  flex-wrap: nowrap;
  li {
    height: 35px;
    padding: 7px 10px;
  }
}

.overtime {
  background: var(--main-red) !important;
}

#teethsSelection {
  .modal-footer {
    .btn {
      margin-left: 0.25rem;
    }
  }
}

.listproductsHasmodal {
  .item {
    opacity: 1 !important;
    align-self: stretch;
    padding-left: 5px;
    padding-right: 5px;
    p {
      font-size: 12px;
    }
    &:hover {
      #VariationModal {
        opacity: 1;
      }
    }
    @include minquery(1783) {
      &:nth-child(8n),
      &:nth-child(8n - 1),
      &:nth-child(8n - 2) {
        #VariationModal {
          right: -20px;
          left: inherit;
          .selectionList-down {
            &::after {
              left: inherit;
              right: 60px;
            }
          }
        }
      }
    }
    @include minquery(1623) {
      @include query(1782) {
        &:nth-child(7n),
        &:nth-child(7n - 1),
        &:nth-child(7n - 2) {
          #VariationModal {
            right: -20px;
            left: inherit;
            .selectionList-down {
              &::after {
                left: inherit;
                right: 60px;
              }
            }
          }
        }
      }
    }
    @include minquery(1463) {
      @include query(1622) {
        &:nth-child(6n),
        &:nth-child(6n - 1),
        &:nth-child(6n - 2) {
          #VariationModal {
            right: -20px;
            left: inherit;
            .selectionList-down {
              &::after {
                left: inherit;
                right: 60px;
              }
            }
          }
        }
      }
    }
    @include query(1462) {
      &:nth-child(5n - 1),
      &:nth-child(5n) {
        #VariationModal {
          right: -20px;
          left: inherit;
          .selectionList-down {
            &::after {
              left: inherit;
              right: 60px;
            }
          }
        }
      }
    }
    @include query(1240) {
      &:nth-child(5n - 2) {
        #VariationModal {
          right: -80px;
          left: inherit;
          .selectionList-down {
            &::after {
              left: inherit;
              right: 120px;
            }
          }
        }
      }
    }
  }
}

#VariationModal {
  position: absolute;
  // min-width: 650px;
  right: inherit;
  top: inherit;
  left: -100px;
  bottom: 120px;
  overflow: visible;
  .modal-dialog {
    // max-width: 650px;
    max-width: inherit;
    position: relative;
    width: 100%;
    margin: 0;
    display: block;
    overflow: visible;
  }
  &.fade {
    transition: 0s !important;
  }
  &.modal.fade.show {
    // position: absolute;
  }
  .modal-content {
    // display: block;
    // padding: 10px;
    position: relative;
    background: none;
    display: block;
  }
  .selectionList {
    position: relative;
    // max-width: 650px;
    // width: calc(100% - 20px);
  }
  .tit {
    text-align: left;
  }
  .listVariation {
    display: grid !important;
    grid-template-columns: repeat(4, 1fr);
    border-top: 0;
    position: relative;
    z-index: 3;
    gap: 10px;
    // margin-top: 10px;
    // flex-wrap: nowrap;
    @include query(1440) {
      grid-template-columns: repeat(3, 1fr);
    }
    li {
      margin: 0;
      max-width: 144px;
      word-wrap: break-word;
      word-break: break-all;
    }
  }
  .selectionList-down::after {
    left: 145px;
  }
}

.instructionSearchPrevious .blockRight ul.dflex {
  li.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.statusPhoto li.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.list_process {
  .content_inside.hastabs {
    height: auto;
    .tbhorizontal {
      max-height: calc(100vh - 200px);
    }
  }
}
#confirmRetireEmployee .modal-dialog {
  max-width: 355px;
}

.listtabs-fixline {
  border-bottom: 0 !important;
  li {
    border-bottom: 4px solid rgb(81, 140, 237);
    min-height: 44px;
    &.addmore {
      border-bottom: 0;
    }
  }
}

.swipeable-list {
  .blockCard {
    width: inherit;
  }

  .swipeable-list-item__content-left {
    background-color: #ed5555;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    padding-right: 20px;
  }
}

.customTimeLine {
  width: calc(100% + 12px);
  margin-left: -12px;
  .red {
    background: #ed5555;
  }
  .green {
    background: #51c17d;
  }
  .yellow {
    background: #f79f1f;
  }
  .blue {
    background: #518ced;
  }
  .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-vl-first {
    border-width: 1px;
  }
  .react-calendar-timeline {
    font-size: 12px;
    font-family: Arial, Helvetica, sans-serif !important;
    .rct-horizontal-lines {
      .rct-hl-even,
      .rct-hl-odd {
        border-color: #cdd5e5;
        background: #f5f6fa;
      }
    }
    .rct-calendar-header {
      border: none;
      background: #fff;
    }
    .rct-dateHeader {
      border: none;
      font-weight: bold;
      background: #fff;
      text-align: center;
    }
    .rct-sidebar {
      border-right: none;
    }
    .rct-sidebar .rct-sidebar-row {
      background: #fff !important;
      border-right: none;
      padding-right: 0;
      padding-left: 12px;
      @include flexbox(flex-start, center);
      width: 100%;
      .sidebarTimeline {
        width: 100%;
        .subtitle {
          @include flexbox(flex-start, center);
          font-weight: normal;
          span {
            display: inline-block;
            font-weight: normal;
          }
          &.hasNo {
            span {
              font-weight: bold;
              &.noBold {
                font-weight: normal;
                margin-left: 10px;
              }
            }
          }
        }
      }
      .block-group {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .icon-detail {
        margin-right: 8px;
        cursor: pointer;
        width: 30px;
      }
      .title {
        font-size: 14px;
        font-weight: bold;
        line-height: 1.2;
        word-break: break-all;
        word-wrap: break-word;
        span {
          font-size: 12px;
          color: #99a3b1;
          display: block;
        }
      }
      .hasNotice {
        position: relative;
        cursor: pointer;
        &::before {
          position: absolute;
          content: "";
          background: url(../images/ico_excla3.svg) no-repeat center center;
          background-size: 24px;
          width: 40px;
          height: 40px;
          right: 0;
          top: 50%;
          margin-top: -20px;
        }
      }
    }
  }
  .rct-items {
    width: 100%;
    * {
      border: none !important;
      width: 100%;
    }
    .inside {
      width: 100%;
      p {
        color: #fff;
        line-height: 1.2;
        text-align: right;
        word-break: keep-all;
        white-space: nowrap;
        span {
          font-size: 14px;
          font-family: Arial, Helvetica, sans-serif !important;
        }
      }
      p.titleTop {
        text-align: left;
      }
    }
    .rct-item {
      font-size: 12px;
      min-height: 52px;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      padding: 2px 5px;
      border-right: 2px solid #fff !important;
      .titleTop {
        font-weight: bold;
      }
      p {
        font-size: 12px;
      }
    }
  }
}
.list_employee {
  .tbstyle3 {
    th {
      &:nth-child(3) {
        text-align: right;
        padding-right: 40px;
        padding-left: 0;
      }
      &:nth-child(4) {
        padding-left: 20px;
      }
    }
    td {
      &:nth-child(3) {
        padding-right: 20px;
        padding-left: 0;
      }
      &:nth-child(4) {
        padding-left: 20px;
      }
    }
  }
}

.content {
  &.content-zindex0 {
    z-index: 0;
    position: relative;
  }
}

#processConfirm {
  .modal-content {
    height: auto;
    min-height: 60vh;
  }
  .info .number {
    .dflex {
      flex-wrap: nowrap;
      margin-right: 10px;
    }
  }
  .dflex .goal,
  .dflex .total {
    min-width: 84px;
    width: inherit;
  }
  .label {
    border: 2px solid #000;
    padding: 1px 8px 0;
    font-weight: bold;
    display: inline-block;
    margin-top: 10px;
  }
  table {
    thead th label {
      width: 63px;
    }
    td.tdlast {
      min-width: 170px;
    }
  }
  .timeSelectBox {
    z-index: inherit;
    .timeSelect {
      z-index: 999;
    }
  }
}

#allProgress {
  table thead th {
    padding: 0px 0px;
  }
  .modal-content {
    height: auto;
    min-height: 500px;
    padding-bottom: 3rem;
    max-width: 1100px;
    margin: auto;
  }

  .text-bold {
    font-weight: bold;
    color: #000;
  }

  .text-gray {
    color: gray;
    font-size: 12px;
  }
  .timeSelectBox {
    z-index: inherit;
    .timeSelect {
      z-index: 999;
    }
  }
  .d-flex-item {
    display: flex;
    justify-content: center;
    align-items: center ;
  }

  .table-progress {
    p {
      font-size: 14px;
    }
    width: 100%;
    th, td {
      padding: 0px;
      text-align: center;
    }

    th {
      background-color: #f8f8f8;
      font-weight: bold;
    }

    td {
      word-wrap: break-word;
    }

    .item-1 {
      font-weight: 500;
      color: #000;
      font-size: 14px;
    }
    .item-2 {
      font-weight: 500;
      color: #000;
      width: auto;
      max-width: 180px;
      font-size: 14px;
      text-align: left;
      padding-left: 10px;
    }
    .item-3{
      color: #FFF;
      font-weight: 400;
      background-color: #7f8fa6;
      padding: 4px;
      margin-right: 20px;
    }
    .w-123 {
      width: 123px;
    }
    .flex-end {
      display: flex;
      justify-content: flex-end;
    }
    .w-200 {
      width: 200px;
    }

    .w-230 {
      width: 230px;
    }
  }
  
  .custom-header {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: white;
    padding: 15px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .table-body {
    height: auto;
    padding-left: 15px;
    padding-right: 15px;
  }

  .p0 {
    padding: 0!important;
  }

  .header-table {
    font-weight: bold !important;
  }

  .fit-content {
    width: fit-content;
    padding: 0 4px 0 4px;
    border: 2px solid black;
  }

  .display-center {
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: 768px) {
    .modal-content {
      max-width: 90%;
      min-height: 50vh;
    }

    .table-progress {
      font-size: 12px;
      td, th {
        padding: 6px;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .table-progress {
      font-size: 10px;
      td, th {
        padding: 4px;
      }
    }
  }
}


.clientclinic .boxregist .txt {
  min-height: 198px;
}

.swipeable-list {
  padding-bottom: 100px !important;
  padding-top: 15px;
  .swipeable-list-item {
    max-height: 3000px;
    overflow: auto;
    background: none;
    margin-bottom: 12px;
    .swipeable-list-item__content {
      padding: 0 15px !important;
      background: none;
      .blockCard {
        margin-bottom: 0;
      }
    }
  }
}

#tagConfirm {
  .modal-content {
    height: auto;
    // min-height: 60vh;
    max-height: 94vh;
    .modal-body {
      overflow-y: hidden;
    }
  }
  table {
    th,
    td {
      padding-left: 0;
    }
    td {
      vertical-align: top;
      .btn {
        min-width: 90px;
        &.btn-green {
          background: #51c17d url(../images/icon_check_w.svg) no-repeat left 7px
            center;
          padding-left: 27px;
        }
      }
    }
  }
  .tbScroll {
    max-height: calc(100vh - 220px);
    overflow-y: scroll;
    min-height: 365px;
  }
  .tbUnmeasured {
    thead {
      th {
        position: sticky;
        left: 0;
        top: 0;
        z-index: 999;
        background: #fff;
      }
    }
    tbody {
      overflow-y: scroll;
      td {
        vertical-align: middle;
      }
      .timeSelectBox {
        z-index: inherit;
      }
      .timeSelect {
        li {
          background: #ecf1f6;
        }
      }
    }
  }
}

.actualHours {
  .rct-dateHeader {
    span,
    small {
      color: #7f8fa6;
    }
  }
  .sortTimeline {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    span.sort {
      font-weight: bold;
      font-size: 14px;
      flex: 0 0 33%;
      padding-right: 24px;
      text-align: right;
      cursor: pointer;
      position: relative;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .arrowSort {
        height: 22px;
        right: 0;
        top: 10px;
      }
    }
  }
  .rightSidebar {
    width: 100%;
    flex: 0 0 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .time {
      flex: 0 0 33%;
      text-align: right;
      font-size: 12px;
      font-weight: bold;
      padding-right: 10px;
      span {
        font-size: 20px;
        font-weight: normal;
        line-height: 1;
        font-style: normal;
      }
      p {
        font-size: 12px;
        line-height: 1;
      }
    }
    &.rightSidebar3row {
      span {
        font-size: 18px;
      }
      .time {
        &:last-child {
          flex: 0 0 30%;
          max-width: 30%;
          padding-right: 0;
        }
        &:nth-child(2) {
          max-width: 33%;
          flex: 0 0 33%;
          // padding-right: 0;
        }
        &.clred {
          p,
          span {
            color: #ed5555;
          }
          .hastime {
            color: #5f5f5f;
            span {
              color: #5f5f5f;
            }
          }
        }
      }
    }
  }
}
#completionModal .modal-dialog-small {
  max-width: 346px;
}

#completionModal .modal-dialog-small {
  max-width: 346px;
}

.selectionList .listVariation {
  li.disabled {
    pointer-events: none;
    background-color: #555;
    color: #fff;
  }
}

#process,
#taskProcess {
  .blockCard.-active {
    border-color: #5f5f5f;
  }
}

.blockCard {
  .blockCard_Info {
    .name {
      width: 145px;
      word-wrap: break-word;
      padding-right: 10px;
      word-break: break-all;
    }
  }

  .disabled {
    // opacity: 1 !important;
    pointer-events: none;
    opacity: 1;
  }
}

.blockdatetime {
  .dateSelect.align_center {
    align-items: center;
    ul::-webkit-scrollbar-thumb {
      background-color: rgb(221, 227, 233);
      border-radius: 10px;
      border: 1px solid rgb(221, 227, 233);
    }
  }
}
.blockBusiness_regist .item_process p .txtsmall {
  color: #5f5f5f;
}

#implantSelection .issuedList {
  .item.disabled {
    pointer-events: none;
    cursor: none;
    background-color: #555;
    border-color: #555;
    span {
      color: #fff;
    }
  }
}

.tbhistoryLabo_List {
  .item {
    position: relative;
    border: 1px solid #cdd5e5;
    border-radius: 12px;

    span.status {
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 10;
    }

    .info {
      border: none;
      border-bottom-left-radius: none;
      border-bottom-right-radius: none;
    }

    div {
      cursor: pointer;
    }
  }
}

.msg.groupMsg {
  z-index: 1000;
}

.blockTeeth-teethSelected .teethTop .triangle {
  &:after {
    top: -8px;
  }

  i::after {
    top: -8px;
  }
}

.blockTeeth-teethSelected .teethTop .round {
  &:after {
    top: -9px;
  }
}

.blockTeeth-teethSelected {
  .tooth.disabled {
    border: 1px solid #ccc;
    color: #888;
    pointer-events: none;
  }
}

.teethSwitch {
  .blockTeeth-teethSelected {
    .tooth.round.disabled {
      border: 1px solid #888;
      color: #888;
      pointer-events: none !important;
    }

    .tooth.triangle.disabled {
      border-left: 1px solid transparent;
      border-right: 1px solid transparent;
      border-bottom: 1px solid;
      border-top: none;
      color: #888;
      pointer-events: none !important;
    }
  }
}

.blockTeeth-teethSelected .teethTop {
  .tooth.remain.itemMid {
    &:after {
      border-top: 1px solid #ccc;
    }

    i::after {
      background-color: #ccc;
    }
  }

  .tooth.remain.itemStart::after {
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
  }

  .tooth.remain.itemEnd::after {
    border-top: 1px solid #ccc;
    border-right: 1px solid #ccc;
  }
}

.blockTeeth-teethSelected .teethBottom {
  .tooth.remain.itemMid {
    &:after {
      border-bottom: 1px solid #ccc;
    }

    i::after {
      background-color: #ccc;
    }
  }

  .tooth.remain.itemStart::after {
    border-bottom: 1px solid #ccc;
    border-left: 1px solid #ccc;
  }

  .tooth.remain.itemEnd::after {
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
  }
}

#setting-individual {
  .content_head {
    .blockdate {
      width: 300px;
    }
  }
}

.blockCard_Desc-number .number {
  .disabled {
    // opacity: 0.5;
    pointer-events: none;
    img {
      opacity: 0.3;
      filter: grayscale(100%);
    }
  }
}

.tbSettingPro {
  max-height: calc(100vh - 100px) !important;
  .arrowSort {
    right: 4px;
    margin-left: 0;
  }
  table {
    width: auto;
    .summary {
      min-width: 85px;
      max-width: 85px;
      background: #fff;
      position: sticky;
      right: 80px;
      z-index: 9;
    }
    .btnDetail {
      max-width: 80px;
      min-width: 80px;
      background: #fff;
      position: sticky;
      right: 0;
      z-index: 9;
    }
    th {
      &.summary,
      &.btnDetail {
        background: #e7ecf5;
        z-index: 10;
      }
    }
    td {
      &.maxWTD {
        max-width: 80px !important;
      }
    }
    th {
      padding-right: 18px;
      &:nth-child(1) {
        min-width: 46px;
        max-width: 46px;
      }
      &:nth-child(2) {
        min-width: 80px;
        max-width: 80px;
      }
      &:nth-child(3) {
        min-width: 230px !important;
        max-width: 230px !important;
        .arrowSort {
          right: inherit;
          margin-left: 25px;
        }
      }
      &.maxWTH {
        max-width: 80px !important;
        word-break: break-all;
        word-wrap: break-word;
      }
      &:nth-child(4) {
        min-width: 50px;
        max-width: 50px;
      }
      &.columnFlex {
        max-width: inherit;
      }
    }
  }
}

#instructionSearch,
#orderHistory {
  .filterSelection {
    .react-datepicker-popper {
      transform: translate(0, 40px) !important;
    }
  }
}

#instructionSearch {
  .modal-content {
    min-height: 90vh;
  }
}

.filterSelection.isActive .txtlable {
  border: 1px solid #518ced;
  color: #518ced;
  filter: sepia(100%) hue-rotate(190deg) saturate(500%);
}

#productSetting .disabled {
  opacity: 0.5;
  pointer-events: none;
}

#orderImage {
  .swipeable-list {
    .swipeable-list-item__content:has(.blockCard:not(.-active)) {
      display: none;
    }
  }
}

// #layoutSetting {
//   .sidebar, .content_head {
//     z-index: 0 !important;
//   }
// }

.task-red {
  border: 3px solid red;
}
.layoutTop .process_list li p {
  color: white;
  height: 25px;
  overflow: hidden;
  text-align: left;
  font-size: 12px;
}

.layoutTop .process_list li span {
  font-size: 12px;
}
.listPhotoFilter .txtresult .filterPhotos {
  .txtsort {
    background: url(../images/icon_sort.svg) no-repeat left center;
    padding-left: 30px;
    background-size: 20px;
    font-size: 12px;
    margin-left: 15px;
    cursor: pointer;

    &.isActiveDown {
      background: url(../images/icon_sort-down.svg) no-repeat left center;
      background-size: 20px;
    }

    &.isActiveUp {
      background: url(../images/icon_sort-up.svg) no-repeat left center;
      background-size: 20px;
    }
  }
  .list {
    width: 286px;
    padding: 10px 0;
    li {
      height: auto;
      padding: 5px 10px;
      font-size: 14px;
      padding-right: 30px;
      background-position: top 8px right 8px;
    }
  }
}

.layoutTop .process_list li span {
  font-size: 12px;
}
#orderImage {
  .swipeable-list {
    .blockCard:not(.-active) {
      display: none;
    }
  }
}

// #layoutSetting {
//   .sidebar, .content_head {
//     z-index: 0 !important;
//   }
// }
.btn-breakdown {
  background-color: #6b93be;
  border-color: #6b93be;
}

.btn-breakdown:hover {
  background-color: #4e6b8b;
  border-color: #4e6b8b;
}

.react-datepicker-popper {
  .react-datepicker {
    // -webkit-backdrop-filter: blur(20);
    // backdrop-filter: blur(20);
    // background-color: rgba(245, 245, 245, 0.9);
    border: none;
    background: url(../images/bgoverlay.png);
    background-size: cover;
    // @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    //   -webkit-backdrop-filter: blur(20);
    //   backdrop-filter: blur(20);
    // }

    // /* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
    // @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    //   background-color: rgba(245, 245, 245, 0.9);
    // }
  }
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__navigation {
    width: 40px;
    height: 40px;
    margin: 7px 0 !important;
    .react-datepicker__navigation-icon::before {
      border-color: #518ced;
    }
  }
  .react-datepicker__header {
    background: none;
    border-bottom: none;
    .react-datepicker__current-month {
      font-size: 20px;
      font-family: Arial, Helvetica, sans-serif;
      font-style: normal;
      font-weight: 500;
      // padding: 5px 0;
    }
    .react-datepicker__day-names {
      .react-datepicker__day-name {
        font-size: 16px;
        font-weight: bold;
        width: 40px;
        height: 40px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        color: #a9a9a9;
      }
    }
  }
  .react-datepicker__day {
    font-size: 22px;
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    width: 40px;
    height: 40px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #5f5f5f;
    &.react-datepicker__day--today {
      color: #f65a5a;
      background-color: #f6d8d8;
      border-radius: 100%;
    }

    &.react-datepicker__day--selected,
    &:hover {
      color: #fff;
      border-radius: 100%;
      background: #518ced;
    }
  }
}

.modal-open {
  .react-datepicker-popper {
    .react-datepicker__navigation {
      margin: 5px 0;
    }
    .react-datepicker__header {
      .react-datepicker__current-month {
        padding: 5px 0;
      }
    }
  }
}

.layoutSecond .head_hasDateTime {
  z-index: 98;
}

#VariationModal {
  left: -10px;

  @include query(1440) {
    left: -10px;
  }
  .selectionList-down::after {
    left: 50px;
    @include query(1440) {
      left: 50px;
    }
  }
}

.registnumberCsv .form-group input {
  background-color: #fff !important;
}

.flexnoWrap {
  // EVMRE-1608
  flex-wrap: nowrap;
  p {
    line-height: 1.4;
  }
}

.blockSearch {
  .form-group-ins.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.content_inside.hasbottom .tbhorizontal.tbhorizontal-fixheight {
  max-height: calc(100vh - 210px);
  tfoot {
    th {
      &:nth-child(4) {
        padding-left: 0;
        word-break: keep-all;
      }
    }
  }
}

#instruction {
  .modal-content button.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

#setting-individual {
  .blockdatetime .dateSelect {
    z-index: 10;
  }
}

#dateRetirement {
  .react-datepicker {
    .react-datepicker__header {
      padding: 4px 0 0;
    }
  }
}

#confirmRetireEmployee {
  &.modal.show .modal-dialog {
    transform: translate(0, -15%);
  }
}

@media all and (max-height: 860px) {
  #confirmRetireEmployee {
    &.modal.show .modal-dialog {
      transform: translate(0, 0);
      height: calc(100vh + 10%);
      align-items: flex-start;
      padding-top: 3%;
    }
  }
}

.blockTeeth-teethSelected .teethAlphabet {
  .tooth i:after,
  .round:after,
  .itemStart::after,
  .itemEnd::after,
  .itemMid:after {
    display: none;
  }
}

.listFavorites {
  .listproducts {
    .item .star {
      right: 0;
      left: auto;
    }
  }
}

#process .content_left > .blockfavoriteProcess ul {
  border: none;
  border-radius: none;
  li:first-child {
    border: 1px solid #518ced;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  li:nth-child(2) {
    border: 1px solid #518ced;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  li.disabled {
    pointer-events: none;
    opacity: 0.5;
    border: 1px solid #99a3b1;

    button {
      color: #99a3b1;
    }
  }
}

.listFavorites {
  .listproducts {
    .item .star {
      right: 0;
      left: auto;
    }
  }
}

#individual {
  .tbhorizontal {
    max-height: inherit;
  }
}

.listproducts .item p,
.listproducts .item span {
  word-break: break-all;
}

.modal-content {
  max-height: 94vh;
}

#Selection .listproducts {
  padding-bottom: 0;
}

.img.havenoimg {
  background: #cdd5e4 url(../images/thumb_img3.svg) no-repeat center top 40%;
  background-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-weight: bold;
    color: #616e8e;
    display: block;
    text-align: center;
    position: absolute;
    padding-top: 20px;
    font-size: 14px;
  }
}

#orderGroupDetail {
  table {
    td {
      padding-left: 9px;
    }
  }
}

#orderGroup,
#orderHistoryDetail {
  .layoutSecond .head_right {
    border-left: none;
  }
}

.listproducts-flex.listproducts {
  .item {
    padding: 5px;
    flex-direction: column;
    justify-content: space-between;
    .img {
      height: 60px;
      margin: 5px 0 0;
      width: 100%;
    }
    span {
      position: relative;
      font-size: 12px;
      bottom: inherit;
      @include flexbox(center, center);
      flex: 1;
      // display: -webkit-box;
      // -webkit-line-clamp: 2;
      // -webkit-box-orient: vertical;
      // overflow: hidden;
      // text-overflow: ellipsis;
    }
    img {
      top: inherit;
      max-height: 45px;
      max-width: inherit;
    }
  }
}

// .sidebar_nav li.active {
//   background: #518ced;
// }

.btn-non-image {
  background: #dcdfe6;
  border: 1px solid #518ced;
  color: #fff;
}

.chartContainer {
  overflow-y: scroll;

  .chartContainerBody {
    margin: 0 auto;
  }
}

.chartContainerBody > canvas {
  height: 600px;
}

#confirmModal {
  z-index: 1000;
}

.chartContainer {
  overflow-y: scroll;
}

.text_red {
  color: #ed5555 !important;
}

.btn-xsmall {
  max-height: 32px;
  min-height: 32px;
}

.statusregist {
  display: inline-block;
  padding: 5px 7px;
  word-break: keep-all;
}

@media print, screen {
  #PP-print2 {
    height: 100vh;
    .flex {
      @include flexbox(center, center);
      flex-direction: column;
      height: 100%;
    }
    .name {
      text-align: center;
      margin-bottom: 10px;
    }
    .svgcode {
      margin: 0 auto;
      max-width: 250px;
    }
    svg {
      max-width: 250px;
      margin: 0 auto;
      width: 250px;
    }
  }
}

#zoomInfoPerform .chartResults {
  max-width: 1500px;

  .close {
    width: 32px;
    height: 32px;
  }
  .headPop .item.statistic-col-modal {
    .col {
      flex: 1 0 auto !important;
    }
  }
}
.filterGroup .listSelection .selectedEmployee span.name {
  line-height: 1.2;
}

.tbSituation {
  table {
    th {
      &:nth-child(3) {
        min-width: 90px;
      }
    }
  }
}

table {
  td.POtext {
    white-space: nowrap;
    padding-right: 0;
  }
}
.filterGroup .listSelection .selectedEmployee span.name {
  line-height: 1.2;
}

.tbSituation {
  table {
    th {
      &:nth-child(3) {
        min-width: 90px;
      }
    }
  }
}

.reasonRegist {
  .dflex.dflex_start {
    padding-right: 0;
  }
  .blockReason {
    overflow-x: hidden;
    .listul_l {
      overflow-x: auto;
      padding-bottom: 0;
      .tab-container .box {
        height: 40px;
      }
      .listul_l .tab-container.-active > .line {
        background: none;
        height: 0;
        width: 0;
        position: relative;
      }
      .line {
        min-width: calc(100% - 150px);
        display: block;
        position: relative;
        content: "";
        width: 100%;
        height: 4px;
        left: 0;
        bottom: 0;
        background-color: currentColor;
        transition: all 0.3s;
      }
    }
    label.btn {
      min-height: 36px;
      span {
        color: #ed5555;
      }
      &:hover,
      &.active {
        span {
          background: none;
          color: #fff;
        }
      }
    }
    .listproducts {
      gap: 10px;
    }
    .p15 {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }
  }
}

.reasonRegist .blockCard {
  padding-bottom: 12px;
}

.brigdeListitem {
  .item {
    margin: 5px 0;
    padding: 5px 0;
    border-bottom: 1px solid #cdd5e5;
  }
}

table {
  td.POtext {
    white-space: nowrap;
    padding-right: 0;
  }
}

.statisticRow {
  &:hover {
    background-color: #e7ecf5;
  }
}

.page_creditcard .blocktable table {
  display: table;
  border-collapse: separate;
  border-spacing: 0px;
}

.weekPoints .tbstyle3 td:first-child span {
  font-weight: normal;
  color: #5e6d8e;
}

.txtDayColor {
  color: #5e6d8e;
}

.weekPoints .tbstyle3 td .txtCase small {
  color: #99a3b1;
}

.weekPoints .tbstyle3 td .txtNum span {
  small {
    font-size: 13px;
  }
}

.performanceDetails {
  .chartResults .blockChart_info {
    .item {
      padding-left: 80px;
      position: relative;
      // gap: 8px;
      .col {
        max-width: inherit;
        margin-left: 0;
        min-width: calc((100% - 80px) / 7);
        padding-left: 2%;
        flex: 0 1 auto;
        &:first-child {
          min-width: 80px;
          max-width: 80px;
          justify-content: center;
          padding-left: 0;
          position: absolute;
          left: 0;
          top: 0;
          margin-left: 0 !important;
        }
      }
      &.statistic-col-fullwidth {
        padding-left: 10px;
        padding-right: 10px;
        .col {
          flex: 1 0 auto !important;
          &:first-child {
            position: relative;
          }
        }
      }
    }
  }
}

.fixlineheight.txtsort {
  line-height: 1.4;
}

.chartResults-fixScroll {
  padding-top: 0;
  .blockChart_info {
    .item {
      padding-left: 120px;
      position: relative;
      justify-content: space-between;
      @include query(960) {
        justify-content: flex-start;
      }
      .col {
        max-width: inherit;
        margin-left: 0;
        min-width: calc((100% - 120px) / 7);
        padding-left: 2%;
        flex: 0 1 auto;
        &:first-child {
          min-width: 120px;
          max-width: 120px;
          justify-content: center;
          padding-left: 0;
          position: absolute;
          left: 0;
          top: 0;
          margin-left: 0 !important;
        }
      }
    }
  }
  .fixed {
    // position: sticky;
    // left: 0;
    // top: 0;
    // background: #fff;
    // z-index: 9;
    // padding-top: 10px;
  }
  .tbsticky {
    th {
      &:nth-child(2) {
        min-width: 110px;
      }
      &:nth-child(3) {
        min-width: 150px;
      }
    }
  }
}

#zoomInfoPerform {
  .headPop {
    .item {
      justify-content: space-between;
      padding-right: 10px;
      gap: 8px;
      .col {
        margin: 0;
        padding: 0;
        min-width: inherit;
        &.colMonth {
          min-width: 70px;
          margin-top: -10px;
        }
        &:nth-child(2) {
          padding-left: 0;
        }
      }
      @include query(1280) {
        justify-content: flex-start;
        margin-left: 0;
        .col {
          margin-left: 0;
        }
      }
      &.statistic-col-modal {
        padding-left: 10px;
        .col {
          &:first-child {
            position: relative;
          }
        }
      }
    }
    .date {
      min-width: 190px;
    }
  }
  .contentPop {
    dl {
      dt {
        // flex: 1 1 calc(100% - 880px);
        max-width: calc(100% - 900px);
        width: calc(100% - 900px);
        min-width: 180px;
      }
      dd {
        max-width: calc(100% - 180px);
        align-items: flex-start;
        @include query(1280) {
          margin-left: 0;
        }
        .colitem {
          min-width: calc((100% - 180px) / 6);
          flex: 1;
          padding-left: 2%;
          margin-left: 0;
          &:first-child {
            padding-left: 0;
          }
          &:last-child {
            flex: 0;
          }
          @include query(1280) {
            &:nth-child(2) {
              min-width: 100px;
            }
            &:nth-child(3) {
              min-width: 160px;
            }
          }
        }
      }
    }
  }
}
#varitation.listPro {
  dl {
    border-top: 1px solid #cdd5e5;
    margin-top: 5px;
    dt {
      line-height: 1.4;
    }
    &:first-child {
      border-top: 0;
    }
  }
  .borderTop {
    border-top: 1px solid #cdd5e5;
  }
}
.filter-block-fixed {
  background-color: #ffffff;
  padding-top: 10px;
  z-index: 999;
  .blockfilter {
    max-width: calc(100vw - 20px);
    // overflow-y: scroll;
  }
}
.blockinfo_hasFilterBlock {
  padding-top: 74px;
  .tbhorizontal {
    max-height: calc(100vh - 130px);
    padding-bottom: 100px;
  }
}
.blockCard_Desc-group {
  .fnwrap {
    flex-wrap: nowrap;
    align-items: center;
  }
  .label {
    min-width: 50px;
  }
  .listDL {
    padding: 10px 0;
    dt {
      line-height: 1.4;
    }
  }
}

.layoutSecond {
  .blockSearch .form-group {
    &:first-child {
      input {
        &:last-child {
          margin-right: 0;
        }
      }
    }
    &:last-child {
      margin-right: 0;
      .form-check-custom {
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

@include query(1280) {
  .layoutSecond {
    .blockSearch .form-group {
      margin-right: 10px;
      &:last-child {
        .dflex {
          justify-content: flex-start;
        }
        .form-check-custom {
          margin-right: 5px;
        }
      }
    }
  }
}

@include query(1180) {
  .blockBtnNav .blockBtn li:first-child a {
    padding: 0.375rem 0.05rem;
    font-size: 17px;
  }
  .blockBtnNav .blockBtn li:nth-child(2) a,
  .blockBtnNav .blockBtn li:nth-child(3) a {
    padding: 0.375rem 0.05rem;
    font-size: 15px;
  }
}

.fixed {
  position: fixed;
}

.filter-block-fixed {
  // background-color: #ffffff;
  // padding-top: 10px;
  z-index: 10;
  .blockfilter {
    .blockfilter_tabs {
      max-width: calc(100vw - 161px) !important;
    }
  }
}

.blockinfo_hasFilterBlock {
  padding-top: 74px;
  .tbhorizontal {
    max-height: calc(100vh - 130px);
  }
}

#weekPointPage {
  @include query(1280) {
    .head_left,
    .head_right {
      flex: 0 1 auto;
    }
    .head_left {
      .btnBack {
        margin-left: 10px;
      }
      .buttonTimeline {
        margin-left: 10px;
      }
    }
    .head_center {
      flex: 1;
    }
  }
}

#clinicPage,
#staffPage {
  @include query(1280) {
    .head_left,
    .head_right {
      flex: 0 0 34%;
    }
    .head_left {
      .btnBack {
        margin-left: 10px;
        @include query(840) {
          a {
            font-size: 12px;
            padding-left: 20px;
          }
        }
      }
      .buttonTimeline {
        margin-left: 5px;
        button,
        a {
          padding-left: 5px;
          padding-right: 5px;
          @include query(840) {
            font-size: 12px;
            margin-left: 0;
          }
        }
      }
    }
    .head_center {
      flex: 1;
    }
  }
}

#staffPointPage,
#departmentPage {
  @include query(1280) {
    .head_left,
    .head_right {
      flex: 1;
    }
    .head_center {
      flex: 0 1 auto;
      button {
        @include query(960) {
          margin-left: 10px;
          margin-right: 10px;
        }
        @include query(840) {
          margin-left: 5px;
          margin-right: 5px;
        }
      }
    }
    .head_right {
      padding-left: 0;
      button {
        &:last-child {
          margin-right: 0 !important;
        }
      }
      .btnDate,
      .btnYear,
      .btnhalfYear,
      .btnMonth,
      .btnWeek {
        @include query(840) {
          min-width: 50px;
        }
        @include query(768) {
          min-width: 48px;
          padding-left: 2px;
          padding-right: 2px;
        }
      }
      .ico-more {
        @include query(840) {
          margin-left: 5px;
        }
      }
    }
    .head_left {
      padding-right: 10px;
      .btnBack {
        margin-left: 10px;
        @include query(840) {
          a {
            font-size: 12px;
            padding-left: 20px;
          }
        }
      }
      .buttonTimeline {
        button,
        a {
          padding-left: 5px;
          padding-right: 5px;
          @include query(840) {
            font-size: 12px;
            margin-left: 0;
          }
        }
      }
    }
  }

  @include query(1600) {
    .head_left,
    .head_right {
      flex: 1;
    }
    .head_center {
      flex: 0 1 auto;
      button {
        @include query(960) {
          margin-left: 10px;
          margin-right: 10px;
        }
        @include query(840) {
          margin-left: 5px;
          margin-right: 5px;
        }
      }
    }
    .head_right {
      padding-left: 0;
      button {
        &:last-child {
          margin-right: 0 !important;
        }
      }
      .btnDate,
      .btnYear,
      .btnhalfYear,
      .btnMonth,
      .btnWeek {
        @include query(840) {
          min-width: 50px;
        }
        @include query(768) {
          min-width: 48px;
          padding-left: 2px;
          padding-right: 2px;
        }
      }
      .ico-more {
        @include query(840) {
          margin-left: 5px;
        }
      }
    }
    .head_left {
      padding-right: 10px;
      .btnBack {
        margin-left: 10px;
        @include query(840) {
          a {
            font-size: 12px;
            padding-left: 20px;
          }
        }
      }
      .buttonTimeline {
        button,
        a {
          padding-left: 1px;
          padding-right: 1px;
          font-size: 14px;
          @include query(840) {
            font-size: 12px;
            margin-left: 0;
          }
        }
      }
    }
    .dflex {
      display: flex;
      flex-wrap: nowrap;
    }
  }

  @media (min-width: 1024px) and (max-width: 1269px) {
    .head {
      height: auto;
    }
    .dflex {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
    }
    .head_left,
    .head_right {
      flex: 1;
    }
    .head_center {
      flex: 0 1 auto;
      button {
        @media (max-width: 960px) {
          margin-left: 10px;
          margin-right: 10px;
        }
        @media (max-width: 840px) {
          margin-left: 5px;
          margin-right: 5px;
        }
      }
    }
    .head_right {
      padding-left: 0;
      button {
        &:last-child {
          margin-right: 0 !important;
        }
      }
      .btnDate,
      .btnYear,
      .btnhalfYear,
      .btnMonth,
      .btnWeek {
        @media (max-width: 840px) {
          min-width: 50px;
        }
        @media (max-width: 768px) {
          min-width: 48px;
          padding-left: 2px;
          padding-right: 2px;
        }
      }
      .ico-more {
        @media (max-width: 840px) {
          margin-left: 5px;
        }
      }
    }
    .head_left {
      padding-right: 10px;
      .btnBack {
        margin-left: 10px;
        @media (max-width: 840px) {
          a {
            font-size: 12px;
            padding-left: 20px;
          }
        }
      }
      .buttonTimeline {
        button,
        a {
          padding-left: 5px;
          padding-right: 5px;
          padding: 0px;
          @media (max-width: 840px) {
            font-size: 12px;
            margin-left: 0;
          }
        }
      }
    }
  }
}

#departmentPage {
  .listPhotoFilter .txtresult p.txtsort {
    margin-left: 7px;
  }
  .listPhotoFilter .txtresult .list {
    top: 34px;
    right: -5px;
  }
  .filter-block-fixed .blockfilter {
    margin-top: 0 !important;
  }
  .fixed.filter-block-fixed {
    width: 100%;
  }
}

#staffPointPage {
  .filter-block-fixed {
    padding-bottom: 10px;
    // background: #fff;
    width: 100%;
  }
  .listPhotoFilter {
    margin-bottom: 0;
  }
  .blockinfo_hasFilterBlock {
    padding-top: 60px;
  }
}

#employeePage {
  @include query(1280) {
    .head_center {
      flex: 1;
      overflow-x: hidden;
      .blockdatetime {
        width: calc(100% + 355px);
        margin-left: -355px;
        z-index: 9;
        @include query(980) {
          margin-left: -300px;
        }
        @include query(840) {
          width: calc(100% + 240px);
          margin-left: -240px;
        }
        @include query(790) {
          margin-left: -200px;
        }
      }
    }
    .head_left {
      flex: 1;
      max-width: 355px;
      @include query(840) {
        max-width: 310px;
      }
      .btnBack {
        margin-left: 10px;
        @include query(840) {
          a {
            font-size: 12px;
            padding-left: 20px;
          }
        }
      }
      .buttonTimeline {
        margin-left: 10px;
        button,
        a {
          padding-left: 5px;
          padding-right: 5px;
          @include query(840) {
            font-size: 12px;
            margin-left: 0;
          }
        }
      }
    }
  }
}

#actualHoursPage {
  @include query(1040) {
    .head_center {
      flex: 1;
      button {
        margin-left: 10px;
        margin-right: 10px;
        @include query(840) {
          margin-left: 5px;
          margin-right: 5px;
        }
      }
    }
    .head_right {
      flex: 0 0 30%;
      a.btn {
        &:last-child {
          margin-right: 0 !important;
        }
      }
      .btnDate,
      .btnYear,
      .btnhalfYear,
      .btnMonth,
      .btnWeek {
        min-width: 50px;
      }
    }
    .head_left {
      flex: 0 0 32%;
      .btnBack {
        margin-left: 10px;
        @include query(840) {
          a {
            font-size: 12px;
            padding-left: 20px;
          }
        }
      }
      .buttonTimeline {
        margin-left: 10px;
        button,
        a {
          padding-left: 5px;
          padding-right: 5px;
          @include query(840) {
            font-size: 12px;
            margin-left: 0;
          }
        }
      }
    }
  }
}

#selectionMetal {
  .listproducts {
    gap: 13px;
    margin-top: 6px;
    .item {
      margin: 0;
      border-radius: 6px;
    }
  }
  .modal-body {
    @media all and (max-height: 720px) {
      min-height: 490px;
    }
    .leftside {
      .dflex_start {
        flex-wrap: nowrap;
        .breakdown {
          min-width: 48px;
        }
        .label {
          label {
            word-break: break-all;
          }
        }
      }
    }
  }
}

.listtabs.listtabs-fixScroll {
  max-width: calc(100vw - 270px);
}

.icoMore {
  min-width: 32px;
  cursor: pointer;
}

.listFavorites {
  .item span {
    font-weight: normal !important;
  }
}

.layoutTop {
  .btn-red2 {
    background: #ff454e !important;
  }
}
.blockTeeth-teethSelected {
  .itemMid.noTeeth i::after {
    height: 0px;
  }

  .teethBottom .itemMid:not(.round)::after {
    bottom: -8px;
  }

  .teethBottom .tooth.remain.itemMid:after,
  .teethBottom .itemMid.triangle::after {
    bottom: -8px;
  }
}

#modalFavorites {
  .listproducts .item.-active {
    background: none !important;

    span {
      color: #518ced;
    }
  }
}

#popupImage {
  .modal-content {
    width: auto;
    margin: 0 auto;
    overflow: hidden;
    .modal-body {
      padding: 0;
      border-radius: 6px;
      overflow: hidden;
      max-width: 90vw;
      max-height: 90vh;
    }
    .close {
      right: 5px;
      top: 5px;
    }
    figure {
      border-radius: 6px;
      overflow: hidden;
      @include flexbox(center, center);
      max-width: 90vw;
      max-height: 90vh;
      img {
        border: 1px solid #5f5f5f;
        border-radius: 6px;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}

.form-controlh48 {
  height: 48px !important;
}

.page_regist {
  .labinformation {
    .tbstyle4 {
      tr {
        &:first-child {
          td {
            border-top: 1px solid #5f5f5f;
          }
        }
      }
      &-fix {
        td {
          &:first-child {
            padding-left: 20px;
          }
        }
      }
    }
    &.registConfirm {
      td {
        font-weight: bold;
        padding: 25px 0;
      }
    }
  }
}

.page_creditcard {
  .tbstyle4 {
    &-fix {
      td {
        &:first-child {
          padding-left: 20px;
        }
      }
    }
  }
}

.blockBtnNav .blockTime .icon {
  justify-content: flex-end;
  .icon_user {
    justify-content: flex-end;
  }
}

.blockSearch {
  .blockSearch-input {
    .form-group {
      @media all and (max-width: 1300px) {
        margin-right: 10px;
        .form-control.w80 {
          margin-right: 0;
        }
      }
    }
  }
}

.login {
  max-width: 460px;
}

#scanModal {
  .modal-body {
    padding: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    overflow: hidden;
  }
}

.modal-header {
  button.close {
    @include flexbox(center, center);
  }
}

.swipeable-list-item {
  .blockCard {
    .blockCard_Head {
      align-items: center;
      .label {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;
        gap: 4px;
      }
      .label:not(.label-square) span {
        height: 28px;
        margin-left: 0;
        margin-bottom: 0;
      }
    }
  }
}

#productSetting {
  .btn-outline-primary.disabled,
  .btn-outline-primary:disabled {
    color: #dcdfe6;
    background-color: transparent;
    border-color: #dcdfe6;
  }
}

#Selection .modal-content:has(.min-height-520) {
  min-height: 520px;
  .listproducts {
    min-height: unset;
  }
}

#implantSelection .modal-content:has(.min-height-520) {
  min-height: 520px;
  .listproducts {
    min-height: unset;
  }
}
// .blockCard_Desc-info .listPro .form-select{
//   padding-left: 5px;
//   padding-right: 5px;
//   font-size: 14px;
// }

#varitation {
  .form-select {
    padding-left: 5px;
    padding-right: 5px;
    font-size: 14px;
    cursor: pointer;
  }
  .form-check {
    margin-bottom: 0;
    .form-check-label {
      font-size: 14px;
    }
  }
  .form-check-input {
    cursor: pointer;
  }
}

#instructionSearchPrevious {
  .modal-dialog {
    max-width: 1180px;
    width: 94vw;
  }
  .modal-body {
    overflow-y: hidden;
  }

  .blockImageCaptured .images {
    width: 100%;
  }
}

.layoutTop .rightside {
  max-width: calc(100% - 365px);
  @media all and (max-width: 1180px) {
    // max-width: calc(100% - 280px);
  }
}

.blockBusiness_regist {
  height: 100%;
  .slick-slider {
    height: 100%;
    .slick-track {
      // width: 100% !important;
      .slick-slide {
        // width: 100% !important;
        // &:first-child {
        //   margin-right: 1%;
        // }
        > div {
          margin-bottom: 2%;
        }
        .sliderdflex {
          display: grid !important;
          grid-template-columns: 49.5% 49.5%;
          gap: 12px;
        }
      }
    }
  }
}

#implantSelection {
  #implantParts {
    .listproducts {
      min-height: 520px;
      .item {
        &:nth-child(5n-3) {
          #VariationSelection {
            left: -155px;
          }
        }
        &:nth-child(5n-2) {
          #VariationSelection {
            left: -255px;
          }
        }
        &:nth-child(5n-1) {
          #VariationSelection {
            left: inherit;
            right: -155px;
          }
        }
        &:nth-child(5n) {
          #VariationSelection {
            left: inherit;
            right: -1px;
          }
        }
      }
    }
  }
}

.slick-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  transform: translate(-50%, 0);

  background: rgba(185, 185, 185, 0.3);
  border-radius: 12px;
  display: inline-flex;
  margin: 0 auto;
  min-height: 24px;
  padding: 5px 10px !important;
  gap: 0 !important;
  max-width: 50% !important;
  width: initial !important;
  bottom: 60px !important;

  li {
    margin: 0 5px;
    width: initial !important;
    height: initial !important;

    button {
      width: initial !important;
      height: initial !important;
      padding: 0px !important;
    }

    button:hover:before {
      color: white !important;
    }

    button:before {
      font-size: 10px !important;
      position: relative !important;
    }
  }

  li.slick-active {
    button:before {
      color: white !important;
    }
  }
}

#implantSelection {
  #implantParts {
    .listproducts {
      min-height: 520px;
      .item {
        &:nth-child(5n-3) {
          #VariationSelection {
            left: -155px;
          }
        }
        &:nth-child(5n-2) {
          #VariationSelection {
            left: -255px;
          }
        }
        &:nth-child(5n-1) {
          #VariationSelection {
            left: inherit;
            right: -155px;
          }
        }
        &:nth-child(5n) {
          #VariationSelection {
            left: inherit;
            right: -1px;
          }
        }
      }
    }
  }
}

.blockImageCaptured .listbtnimg button {
  margin-right: 8px;
}

.divlogout {
  padding: 0;
  border-radius: 5px;
  ul
    li {
      padding: 5px 12px;
      margin-left: 0;
      margin-bottom: 5px;
    }

  .usernameStyle {
    position: relative;
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.ellipseName {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: wrap;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  // overflow: hidden;
  word-break: break-all;
  line-height: 1.2;
}

.mw-200 {
  max-width: 200px;
}
.blockCard_Desc {
  .number {
    width: 114px;
    button.btnminus,
    button.btnadd {
      width: 36px;
    }
  }
  .form-check {
    margin-right: 5px;
    input.form-check-input {
      margin-right: 5px;
    }
    label {
      font-size: 14px;
    }
  }

  #productSwitch {
    .dflex.item {
      justify-content: flex-end;
    }

    #varitation {
      .dflex.item {
        justify-content: start;

        dl.dflex {
          flex-wrap: nowrap;
        }
      }
    }
  }
}
.modal-left {
  margin: 3vh 10px;
  max-width: 68vw !important;
}

.chartResults .blockChart_info .item p.clgray,
.chartResults table.tbstyle3 p.clgray {
  font-size: 15px;
}

.performanceDetails
.blockLeftcol .achieve_body dl dd .clgray {
  font-size: 16px;
}
.modal-background-transparent::after {
  background: transparent !important;
  display: none;
}

.modal-background-transparent {
  right: 25%;
  overflow: hidden !important;
  // bottom: initial !important;
  // top: 6vh !important;
}

.modal-background-transparent .modal-content {
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.16);
  margin: 5px;
}

#weekPointPage {
  .content_inside {
    overflow: hidden;
  }
  .blockinfo {
    width: calc(100% + 12px);
    margin-left: -12px;
    .tbhorizontal {
      max-height: calc(100vh - 178px);
    }
  }
  .tbstyle3 {
    // table-layout: fixed;
    td {
      width: 10%;
      max-width: 160px;
    }
    th,
    td{
      &:first-child {
        // min-width: 280px;
        max-width: 330px;
        width: auto;
      }
    }
  }
}

#RankingView{
  .filter-block-fixed{
    @media all and (max-width: 1180px) {
      overflow-x: scroll;
      padding-right: 20px;
    }
  }
  .blockfilter {
    @media all and (max-width: 1200px) {
      min-width: 1200px;
    }
  }
  .blockinfo{
    padding-top: 0;
    margin-top: 61px;
    height: calc(100vh - 130px);
    position: relative;
    font-size: 14px;
    margin-right: 10px;
    // &::after {
    //   position: absolute;
    //   content: '';
    //   background: #fff;
    //   width: 178px;
    //   height: 49px;
    //   left: 0;
    //   top: 1px;
    //   border-bottom: 1px solid #ccc;
    //   border-left: 1px solid #ccc;
    //   border-right: 1px solid #ccc;
    //   z-index: 99;
    // }
    .firstScreen{
      border: 1px solid #cdd5e5;
      text-align: center;
      padding: 5vw;
      width: 80%;
      position: absolute;
      transform: translate(-50%,-50%);
      left: 50%;
      top: 50%;
      p{
        font-size: 3.7vw;
      }
    }
  }
  .sortRank{
    span.txtlable{
      background: none;
      padding-right: 15px;
      cursor: default;
    }
    .rank1{
      span.txtlable{
        border-color: #D1A621;
        background: #F5E3AB url(../images/rank1.svg) no-repeat left 5px top 8px;
        background-size: 14px;
        padding-left: 25px;
      }
    }
    .rank2{
      span.txtlable{
        border-color: #9C979C;
        background: #E6E4E6 url(../images/rank2.svg) no-repeat left 7px top 8px;
        background-size: 9px;
        padding-left: 22px;
      }
    }
    .rank3{
      span.txtlable{
        border-color: #8F543B;
        color: #fff;
        background: #BE863B url(../images/rank3.svg) no-repeat left 7px top 8px;
        background-size: 9px;
        padding-left: 22px;
      }
    }
    .rankWorst{
      span.txtlable{
        border-color: #999;
        background: #8C8C8C;
        color: #fff;
      }
    }
    .rankBelow{
      span.txtlable{
        color: #CD2020;
      }
    }
  }
  .filter-block-fixed {
    width: 100%;
  }
}

.blockRanks{
  overflow-y: hidden;
  .blockScroll{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    overflow-y: auto;
    height: calc(100vh - 130px);
    border-top: 1px solid #ccc;
  }
  .firstBlock{
    min-width: 180px;
    max-width: 220px;
    padding-right: 0;
    position: sticky;
    left: 0;
    top: 0;
    background: #fff;
    z-index: 9;
    .firstCol{
      position: relative;
      &::after {
        position: absolute;
        content: '';
        background: #fff;
        width: 15px;
        height: 100%;
        left: -15px;
        top: 0;
      }
    }
    li{
      line-height: 1.4;
      height: 50px;
      border: 1px solid #ccc;
      border-top: 0;
      align-items: center;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      padding: 7px;

      &.dataTotal{
        justify-content: center;
      }
    }
  }
  .secondBlock{
    flex: 1;
    // min-width: 960px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    // width: calc(100% - 180px);
    position: relative;
    z-index: 8;
    .row{
      border-bottom: 1px solid #ccc;
      padding: 0 5px;
      line-height: 1.4;
      text-align: center;
      height: 50px;
      .col{
        
      }
    }
  }
  .middleCol {
    // flex: 0 0 calc(100% - 100px);
    flex: 0 0 calc(100%);
    min-width: 880px;
    position: relative;
    z-index: 1;
    .scroll{
      width: 100%;
    }
    .row{
      display: table;
      border-collapse: collapse;
      border-spacing: 0;
      table-layout: fixed;
      width: 100%;
      .col{
        display: table-cell;
        vertical-align: middle;
        position: relative;
        border-right: 1px solid #ccc;
        width: 120px;
        &:last-child { 
          border-right: 0;
        }
      }
      .ico{
        position: absolute;
        top: 2px;
        font-size: 6px;
        color: #5F646E;
        border: 1px solid #999;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        padding-top: 2px;
        width: 14px;
        height: 14px;
        z-index: 1;
        background: #fff;
        &-rank1{
          background: url(../images/rank1.svg) no-repeat top center;
          background-size: 15px;
          height: 17px;
          border: 0;
        }
        &-rank2{
          background: url(../images/rank2.svg) no-repeat top center;
          background-size: 12px;
          height: 18px;
          border: 0;
        }
        &-rank3{
          background: url(../images/rank3.svg) no-repeat top center;
          background-size: 12px;
          height: 18px;
          border: 0;
          color: #fff;
        }
      }
    }
    .listUserName{
      position: sticky;
      top: 0;
      left: 0;
      background: #fff;
      z-index: 2;
      border-left: 1px solid #ccc;
      // border-right: 1px solid #ccc;

      &.hasProcess {
        border-left: 0;
      }
    }
  }
  .lastCol{
    flex: 0 0 100px;
    width: 100px;
    min-width: 100px;
    text-align: center;
    position: sticky;
    right: 0;
    top: 0;
    background: #fff;
    z-index: 9;
    border-right: 1px solid #cdd5e5;
    .listUserName{
      border-bottom: 1px solid #ccc;
      position: sticky;
      right: 0;
      top: 0;
      background: #fff;
      z-index: 10;
      border-left: 1px solid #ccc;
      .col{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 48.5px;
      }
    }
    .listAverage{
      position: relative;
      z-index: 9;
      .row{
        border-bottom: 1px solid #ccc;
        position: sticky;
        right: 0;
        min-height: 50px;
        background: #fff;
        border-left: 1px solid #ccc;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .rankIconLeft{
    .ico{
      left: 2px;
    }
  }
  .rankIconRight{
    .ico{
      right: 2px;
    }
  }
  .rankCompare{
    .dataTime{
      position: relative;
      &::after {
        position: absolute;
        content: '';
        background: url(../images/line.svg) no-repeat center center;
        background-size: 50px;
        width: calc(100% - 10px);
        height: 40px;
        left: 15px;
        top: 3px;
      }
      .bestTime{
        position: absolute;
        right: 5px;
        bottom: 2px;
      }
      span:not(.bestTime):not(.ico){
        position: absolute;
        left: 30px;
        top: 2px;
      }
      @media all and (min-width: 1500px) {
        .bestTime{
          right: 20px;
        }
        span:not(.bestTime):not(.ico){
          left: 40px;
        }
      }
    }
  }

  .silverRank {
    background:#E6E5E6;
  }
  .goldRank {
    background:#F6E4AB;
  }
  .copperRank {
    background:#BE873B;

    span.timePeriod {
      color: #FFF;
    }
  }

  .noRank {
    background:#8D8D8D;

    span.timePeriod {
      color: #FFF;
    }
  }
}
.justify-content-center {
  justify-content: center !important;
}

.modal-conflict {
  .modal-body {
    padding-top: 45px;
    h2 {
      font-weight: bolder !important;
      font-size: 1.3rem !important;
    }
  }

  .group_content {
    background-color: rgb(240 240 240 / 80%);
    border-radius: 10px;
    padding: 10px;

    p {
      font-size: 1rem;
      line-height: 2rem;
      line-height: 2rem;
    }
  }
}

#statisticRankingPage {
  .blockdatetime .datetime.disabled, .blockfilter  .selectstyle.disabled{
    opacity: 0.4;
    pointer-events: none;
  }

  .selectstyle input[type="checkbox"] {
    border: 1px solid #5f5f5f;
    &:checked {
      border: 1px solid #518ced;
    }
  }
}


@media print {
  body {
    background: #fff;
  }
}

.elementDisabled {
  filter: grayscale(1);
  pointer-events: none;
}
.error-modal {
  .modal-body {
    overflow: inherit !important;
    max-height: 70vh;
  }
}

.mw-800 {
  max-width: 800px !important;
}

.error-table {
  display: block;
  max-height: 60vh;
  overflow: scroll;
  th:nth-child(1) {
    width: 20%;
  }
  th:nth-child(2) {
    width: 20%;
  }
  th:nth-child(3) {
    width: 20%;
  }
}

.blockTeeth-teethSelected .teethBottom .round, .blockTeeth-teethSelected .teethBottom .triangle {
  &:after {
    bottom: -9px  !important;
  }
}

.item_head {
  .ico-three-dots {
    margin-left: auto;
  }
}

.instructionSearchPrevious {
  .blockImageCaptured .images {
    max-height: 265px;
    width: 100%;
  }
}
.blockImg .image figure.non-image {
  min-height: 265px;
  span {
    color: #fff;
    font-size: 22px;
    position: absolute;
    left: 30%;
    top: 45%;
    font-weight: bold;
  }
}

#comfromDeleteBranch .modal-body_paras {
  text-align: center;
}
