@charset "UTF-8";
@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-30px);
    -ms-transform: translateX(-30px);
    transform: translateX(-30px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-30px);
    -ms-transform: translateX(-30px);
    transform: translateX(-30px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fadeOutDown {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
  }
}

@keyframes fadeOutDown {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
  }
}

@-webkit-keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    transform: translateY(2000px);
  }
}

@keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    transform: translateY(2000px);
  }
}

@-webkit-keyframes fadeOutLeft {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    transform: translateX(-20px);
  }
}

@keyframes fadeOutLeft {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    transform: translateX(-20px);
  }
}

@-webkit-keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
}

@keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
}

@-webkit-keyframes fadeOutRight {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }
}

@keyframes fadeOutRight {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }
}

@-webkit-keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    transform: translateX(2000px);
  }
}

@keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    transform: translateX(2000px);
  }
}

@-webkit-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}

@keyframes fadeOutUp {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}

@-webkit-keyframes fadeOutUpBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
}

@keyframes fadeOutUpBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
  -webkit-animation-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  animation-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-transition: border-box;
  box-sizing: border-box;
  overflow-x: hidden;
}

body {
  -webkit-text-size-adjust: 100%;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: 0 0;
}

h1 {
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
}

pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  /* 2 */
}

b,
strong {
  font-weight: bold !important;
}

code,
kbd,
pre,
samp,
tt {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  position: relative;
  vertical-align: baseline;
  border: 0;
  outline: 0;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
}

button,
input {
  /* 1 */
  overflow: visible;
  -webkit-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}

button,
select {
  /* 1 */
  text-transform: none;
}

input,
select {
  vertical-align: middle;
}

input:focus {
  outline: 0;
}

textarea {
  overflow: auto;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
  table-layout: fixed;
}

progress {
  vertical-align: baseline;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

summary {
  display: list-item;
}

template,
[hidden],
.hidden {
  display: none !important;
}

blockquote,
q {
  quotes: none;
}

blockquote:after,
blockquote:before,
q:after,
q:before {
  content: "";
  content: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section {
  display: block;
}

img {
  vertical-align: top;
  border-style: none;
  line-height: 0;
  font-size: 0;
  -ms-interpolation-mode: bicubic;
  background: 0 0;
  zoom: 1;
}

ol,
ul {
  list-style: none;
}

a {
  background-color: transparent;
  font-size: 100%;
  vertical-align: baseline;
  text-decoration: none;
  background: 0 0;
}

a:focus {
  outline: 0;
}

a img {
  opacity: 1;
  -webkit-transition: ease-out 0.3s;
  transition: ease-out 0.3s;
}

a img:hover {
  opacity: 0.7;
  -webkit-transition: ease-out 0.3s;
  transition: ease-out 0.3s;
}

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: 700;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

a,
button,
input {
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

/* CSS Document */
/*====================================================================================
笆�01.MARGIN DEFAULT - PIXEL
====================================================================================*/
/*MARGIN*/
.m00 {
  margin: 0px !important;
}

.m01 {
  margin: 1px !important;
}

.m02 {
  margin: 2px !important;
}

.m03 {
  margin: 3px !important;
}

.m04 {
  margin: 4px !important;
}

.m05 {
  margin: 5px !important;
}

.m06 {
  margin: 6px !important;
}

.m07 {
  margin: 7px !important;
}

.m08 {
  margin: 8px !important;
}

.m09 {
  margin: 9px !important;
}

.m10 {
  margin: 10px !important;
}

.m11 {
  margin: 11px !important;
}

.m12 {
  margin: 12px !important;
}

.m13 {
  margin: 13px !important;
}

.m14 {
  margin: 14px !important;
}

.m15 {
  margin: 15px !important;
}

.m16 {
  margin: 16px !important;
}

.m17 {
  margin: 17px !important;
}

.m18 {
  margin: 18px !important;
}

.m19 {
  margin: 19px !important;
}

.m20 {
  margin: 20px !important;
}

.m21 {
  margin: 21px !important;
}

.m22 {
  margin: 22px !important;
}

.m23 {
  margin: 23px !important;
}

.m24 {
  margin: 24px !important;
}

.m25 {
  margin: 25px !important;
}

.m26 {
  margin: 26px !important;
}

.m27 {
  margin: 27px !important;
}

.m28 {
  margin: 28px !important;
}

.m29 {
  margin: 29px !important;
}

.m30 {
  margin: 30px !important;
}

.m31 {
  margin: 31px !important;
}

.m32 {
  margin: 32px !important;
}

.m33 {
  margin: 33px !important;
}

.m34 {
  margin: 34px !important;
}

.m35 {
  margin: 35px !important;
}

.m36 {
  margin: 36px !important;
}

.m37 {
  margin: 37px !important;
}

.m38 {
  margin: 38px !important;
}

.m39 {
  margin: 39px !important;
}

.m40 {
  margin: 40px !important;
}

.m41 {
  margin: 41px !important;
}

.m42 {
  margin: 42px !important;
}

.m43 {
  margin: 43px !important;
}

.m44 {
  margin: 44px !important;
}

.m45 {
  margin: 45px !important;
}

.m46 {
  margin: 46px !important;
}

.m47 {
  margin: 47px !important;
}

.m48 {
  margin: 48px !important;
}

.m49 {
  margin: 49px !important;
}

.m50 {
  margin: 50px !important;
}

.m51 {
  margin: 51px !important;
}

.m52 {
  margin: 52px !important;
}

.m53 {
  margin: 53px !important;
}

.m54 {
  margin: 54px !important;
}

.m55 {
  margin: 55px !important;
}

.m56 {
  margin: 56px !important;
}

.m57 {
  margin: 57px !important;
}

.m58 {
  margin: 58px !important;
}

.m59 {
  margin: 59px !important;
}

.m60 {
  margin: 60px !important;
}

.m61 {
  margin: 61px !important;
}

.m62 {
  margin: 62px !important;
}

.m63 {
  margin: 63px !important;
}

.m64 {
  margin: 64px !important;
}

.m65 {
  margin: 65px !important;
}

.m66 {
  margin: 66px !important;
}

.m67 {
  margin: 67px !important;
}

.m68 {
  margin: 68px !important;
}

.m69 {
  margin: 69px !important;
}

.m70 {
  margin: 70px !important;
}

.m71 {
  margin: 71px !important;
}

.m72 {
  margin: 72px !important;
}

.m73 {
  margin: 73px !important;
}

.m74 {
  margin: 74px !important;
}

.m75 {
  margin: 75px !important;
}

.m76 {
  margin: 76px !important;
}

.m77 {
  margin: 77px !important;
}

.m78 {
  margin: 78px !important;
}

.m79 {
  margin: 79px !important;
}

.m80 {
  margin: 80px !important;
}

.m81 {
  margin: 81px !important;
}

.m82 {
  margin: 82px !important;
}

.m83 {
  margin: 83px !important;
}

.m84 {
  margin: 84px !important;
}

.m85 {
  margin: 85px !important;
}

.m86 {
  margin: 86px !important;
}

.m87 {
  margin: 87px !important;
}

.m88 {
  margin: 88px !important;
}

.m89 {
  margin: 89px !important;
}

.m90 {
  margin: 90px !important;
}

.m91 {
  margin: 91px !important;
}

.m92 {
  margin: 92px !important;
}

.m93 {
  margin: 93px !important;
}

.m94 {
  margin: 94px !important;
}

.m95 {
  margin: 95px !important;
}

.m96 {
  margin: 96px !important;
}

.m97 {
  margin: 97px !important;
}

.m98 {
  margin: 98px !important;
}

.m99 {
  margin: 99px !important;
}

.m100 {
  margin: 100px !important;
}

/*MARGIN - TOP*/
.mt00 {
  margin-top: 0px !important;
}

.mt01 {
  margin-top: 1px !important;
}

.mt02 {
  margin-top: 2px !important;
}

.mt03 {
  margin-top: 3px !important;
}

.mt04 {
  margin-top: 4px !important;
}

.mt05 {
  margin-top: 5px !important;
}

.mt06 {
  margin-top: 6px !important;
}

.mt07 {
  margin-top: 7px !important;
}

.mt08 {
  margin-top: 8px !important;
}

.mt09 {
  margin-top: 9px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt11 {
  margin-top: 11px !important;
}

.mt12 {
  margin-top: 12px !important;
}

.mt13 {
  margin-top: 13px !important;
}

.mt14 {
  margin-top: 14px !important;
}

.mt15 {
  margin-top: 15px !important;
}

.mt16 {
  margin-top: 16px !important;
}

.mt17 {
  margin-top: 17px !important;
}

.mt18 {
  margin-top: 18px !important;
}

.mt19 {
  margin-top: 19px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.mt21 {
  margin-top: 21px !important;
}

.mt22 {
  margin-top: 22px !important;
}

.mt23 {
  margin-top: 23px !important;
}

.mt24 {
  margin-top: 24px !important;
}

.mt25 {
  margin-top: 25px !important;
}

.mt26 {
  margin-top: 26px !important;
}

.mt27 {
  margin-top: 27px !important;
}

.mt28 {
  margin-top: 28px !important;
}

.mt29 {
  margin-top: 29px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.mt31 {
  margin-top: 31px !important;
}

.mt32 {
  margin-top: 32px !important;
}

.mt33 {
  margin-top: 33px !important;
}

.mt34 {
  margin-top: 34px !important;
}

.mt35 {
  margin-top: 35px !important;
}

.mt36 {
  margin-top: 36px !important;
}

.mt37 {
  margin-top: 37px !important;
}

.mt38 {
  margin-top: 38px !important;
}

.mt39 {
  margin-top: 39px !important;
}

.mt40 {
  margin-top: 40px !important;
}

.mt41 {
  margin-top: 41px !important;
}

.mt42 {
  margin-top: 42px !important;
}

.mt43 {
  margin-top: 43px !important;
}

.mt44 {
  margin-top: 44px !important;
}

.mt45 {
  margin-top: 45px !important;
}

.mt46 {
  margin-top: 46px !important;
}

.mt47 {
  margin-top: 47px !important;
}

.mt48 {
  margin-top: 48px !important;
}

.mt49 {
  margin-top: 49px !important;
}

.mt50 {
  margin-top: 50px !important;
}

.mt51 {
  margin-top: 51px !important;
}

.mt52 {
  margin-top: 52px !important;
}

.mt53 {
  margin-top: 53px !important;
}

.mt54 {
  margin-top: 54px !important;
}

.mt55 {
  margin-top: 55px !important;
}

.mt56 {
  margin-top: 56px !important;
}

.mt57 {
  margin-top: 57px !important;
}

.mt58 {
  margin-top: 58px !important;
}

.mt59 {
  margin-top: 59px !important;
}

.mt60 {
  margin-top: 60px !important;
}

.mt61 {
  margin-top: 61px !important;
}

.mt62 {
  margin-top: 62px !important;
}

.mt63 {
  margin-top: 63px !important;
}

.mt64 {
  margin-top: 64px !important;
}

.mt65 {
  margin-top: 65px !important;
}

.mt66 {
  margin-top: 66px !important;
}

.mt67 {
  margin-top: 67px !important;
}

.mt68 {
  margin-top: 68px !important;
}

.mt69 {
  margin-top: 69px !important;
}

.mt70 {
  margin-top: 70px !important;
}

.mt71 {
  margin-top: 71px !important;
}

.mt72 {
  margin-top: 72px !important;
}

.mt73 {
  margin-top: 73px !important;
}

.mt74 {
  margin-top: 74px !important;
}

.mt75 {
  margin-top: 75px !important;
}

.mt76 {
  margin-top: 76px !important;
}

.mt77 {
  margin-top: 77px !important;
}

.mt78 {
  margin-top: 78px !important;
}

.mt79 {
  margin-top: 79px !important;
}

.mt80 {
  margin-top: 80px !important;
}

.mt81 {
  margin-top: 81px !important;
}

.mt82 {
  margin-top: 82px !important;
}

.mt83 {
  margin-top: 83px !important;
}

.mt84 {
  margin-top: 84px !important;
}

.mt85 {
  margin-top: 85px !important;
}

.mt86 {
  margin-top: 86px !important;
}

.mt87 {
  margin-top: 87px !important;
}

.mt88 {
  margin-top: 88px !important;
}

.mt89 {
  margin-top: 89px !important;
}

.mt90 {
  margin-top: 90px !important;
}

.mt91 {
  margin-top: 91px !important;
}

.mt92 {
  margin-top: 92px !important;
}

.mt93 {
  margin-top: 93px !important;
}

.mt94 {
  margin-top: 94px !important;
}

.mt95 {
  margin-top: 95px !important;
}

.mt96 {
  margin-top: 96px !important;
}

.mt97 {
  margin-top: 97px !important;
}

.mt98 {
  margin-top: 98px !important;
}

.mt99 {
  margin-top: 99px !important;
}

.mt100 {
  margin-top: 100px !important;
}

/*MARGIN - RIGHT*/
.mr00 {
  margin-right: 0px !important;
}

.mr01 {
  margin-right: 1px !important;
}

.mr02 {
  margin-right: 2px !important;
}

.mr03 {
  margin-right: 3px !important;
}

.mr04 {
  margin-right: 4px !important;
}

.mr05 {
  margin-right: 5px !important;
}

.mr06 {
  margin-right: 6px !important;
}

.mr07 {
  margin-right: 7px !important;
}

.mr08 {
  margin-right: 8px !important;
}

.mr09 {
  margin-right: 9px !important;
}

.mr10 {
  margin-right: 10px !important;
}

.mr11 {
  margin-right: 11px !important;
}

.mr12 {
  margin-right: 12px !important;
}

.mr13 {
  margin-right: 13px !important;
}

.mr14 {
  margin-right: 14px !important;
}

.mr15 {
  margin-right: 15px !important;
}

.mr16 {
  margin-right: 16px !important;
}

.mr17 {
  margin-right: 17px !important;
}

.mr18 {
  margin-right: 18px !important;
}

.mr19 {
  margin-right: 19px !important;
}

.mr20 {
  margin-right: 20px !important;
}

.mr21 {
  margin-right: 21px !important;
}

.mr22 {
  margin-right: 22px !important;
}

.mr23 {
  margin-right: 23px !important;
}

.mr24 {
  margin-right: 24px !important;
}

.mr25 {
  margin-right: 25px !important;
}

.mr26 {
  margin-right: 26px !important;
}

.mr27 {
  margin-right: 27px !important;
}

.mr28 {
  margin-right: 28px !important;
}

.mr29 {
  margin-right: 29px !important;
}

.mr30 {
  margin-right: 30px !important;
}

.mr31 {
  margin-right: 31px !important;
}

.mr32 {
  margin-right: 32px !important;
}

.mr33 {
  margin-right: 33px !important;
}

.mr34 {
  margin-right: 34px !important;
}

.mr35 {
  margin-right: 35px !important;
}

.mr36 {
  margin-right: 36px !important;
}

.mr37 {
  margin-right: 37px !important;
}

.mr38 {
  margin-right: 38px !important;
}

.mr39 {
  margin-right: 39px !important;
}

.mr40 {
  margin-right: 40px !important;
}

.mr41 {
  margin-right: 41px !important;
}

.mr42 {
  margin-right: 42px !important;
}

.mr43 {
  margin-right: 43px !important;
}

.mr44 {
  margin-right: 44px !important;
}

.mr45 {
  margin-right: 45px !important;
}

.mr46 {
  margin-right: 46px !important;
}

.mr47 {
  margin-right: 47px !important;
}

.mr48 {
  margin-right: 48px !important;
}

.mr49 {
  margin-right: 49px !important;
}

.mr50 {
  margin-right: 50px !important;
}

.mr51 {
  margin-right: 51px !important;
}

.mr52 {
  margin-right: 52px !important;
}

.mr53 {
  margin-right: 53px !important;
}

.mr54 {
  margin-right: 54px !important;
}

.mr55 {
  margin-right: 55px !important;
}

.mr56 {
  margin-right: 56px !important;
}

.mr57 {
  margin-right: 57px !important;
}

.mr58 {
  margin-right: 58px !important;
}

.mr59 {
  margin-right: 59px !important;
}

.mr60 {
  margin-right: 60px !important;
}

.mr61 {
  margin-right: 61px !important;
}

.mr62 {
  margin-right: 62px !important;
}

.mr63 {
  margin-right: 63px !important;
}

.mr64 {
  margin-right: 64px !important;
}

.mr65 {
  margin-right: 65px !important;
}

.mr66 {
  margin-right: 66px !important;
}

.mr67 {
  margin-right: 67px !important;
}

.mr68 {
  margin-right: 68px !important;
}

.mr69 {
  margin-right: 69px !important;
}

.mr70 {
  margin-right: 70px !important;
}

.mr71 {
  margin-right: 71px !important;
}

.mr72 {
  margin-right: 72px !important;
}

.mr73 {
  margin-right: 73px !important;
}

.mr74 {
  margin-right: 74px !important;
}

.mr75 {
  margin-right: 75px !important;
}

.mr76 {
  margin-right: 76px !important;
}

.mr77 {
  margin-right: 77px !important;
}

.mr78 {
  margin-right: 78px !important;
}

.mr79 {
  margin-right: 79px !important;
}

.mr80 {
  margin-right: 80px !important;
}

.mr81 {
  margin-right: 81px !important;
}

.mr82 {
  margin-right: 82px !important;
}

.mr83 {
  margin-right: 83px !important;
}

.mr84 {
  margin-right: 84px !important;
}

.mr85 {
  margin-right: 85px !important;
}

.mr86 {
  margin-right: 86px !important;
}

.mr87 {
  margin-right: 87px !important;
}

.mr88 {
  margin-right: 88px !important;
}

.mr89 {
  margin-right: 89px !important;
}

.mr90 {
  margin-right: 90px !important;
}

.mr91 {
  margin-right: 91px !important;
}

.mr92 {
  margin-right: 92px !important;
}

.mr93 {
  margin-right: 93px !important;
}

.mr94 {
  margin-right: 94px !important;
}

.mr95 {
  margin-right: 95px !important;
}

.mr96 {
  margin-right: 96px !important;
}

.mr97 {
  margin-right: 97px !important;
}

.mr98 {
  margin-right: 98px !important;
}

.mr99 {
  margin-right: 99px !important;
}

.mr100 {
  margin-right: 100px !important;
}

/*MARGIN - BOTTOM*/
.mb00 {
  margin-bottom: 0px !important;
}

.mb01 {
  margin-bottom: 1px !important;
}

.mb02 {
  margin-bottom: 2px !important;
}

.mb03 {
  margin-bottom: 3px !important;
}

.mb04 {
  margin-bottom: 4px !important;
}

.mb05 {
  margin-bottom: 5px !important;
}

.mb06 {
  margin-bottom: 6px !important;
}

.mb07 {
  margin-bottom: 7px !important;
}

.mb08 {
  margin-bottom: 8px !important;
}

.mb09 {
  margin-bottom: 9px !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.mb11 {
  margin-bottom: 11px !important;
}

.mb12 {
  margin-bottom: 12px !important;
}

.mb13 {
  margin-bottom: 13px !important;
}

.mb14 {
  margin-bottom: 14px !important;
}

.mb15 {
  margin-bottom: 15px !important;
}

.mb16 {
  margin-bottom: 16px !important;
}

.mb17 {
  margin-bottom: 17px !important;
}

.mb18 {
  margin-bottom: 18px !important;
}

.mb19 {
  margin-bottom: 19px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mb21 {
  margin-bottom: 21px !important;
}

.mb22 {
  margin-bottom: 22px !important;
}

.mb23 {
  margin-bottom: 23px !important;
}

.mb24 {
  margin-bottom: 24px !important;
}

.mb25 {
  margin-bottom: 25px !important;
}

.mb26 {
  margin-bottom: 26px !important;
}

.mb27 {
  margin-bottom: 27px !important;
}

.mb28 {
  margin-bottom: 28px !important;
}

.mb29 {
  margin-bottom: 29px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}

.mb31 {
  margin-bottom: 31px !important;
}

.mb32 {
  margin-bottom: 32px !important;
}

.mb33 {
  margin-bottom: 33px !important;
}

.mb34 {
  margin-bottom: 34px !important;
}

.mb35 {
  margin-bottom: 35px !important;
}

.mb36 {
  margin-bottom: 36px !important;
}

.mb37 {
  margin-bottom: 37px !important;
}

.mb38 {
  margin-bottom: 38px !important;
}

.mb39 {
  margin-bottom: 39px !important;
}

.mb40 {
  margin-bottom: 40px !important;
}

.mb41 {
  margin-bottom: 41px !important;
}

.mb42 {
  margin-bottom: 42px !important;
}

.mb43 {
  margin-bottom: 43px !important;
}

.mb44 {
  margin-bottom: 44px !important;
}

.mb45 {
  margin-bottom: 45px !important;
}

.mb46 {
  margin-bottom: 46px !important;
}

.mb47 {
  margin-bottom: 47px !important;
}

.mb48 {
  margin-bottom: 48px !important;
}

.mb49 {
  margin-bottom: 49px !important;
}

.mb50 {
  margin-bottom: 50px !important;
}

.mb51 {
  margin-bottom: 51px !important;
}

.mb52 {
  margin-bottom: 52px !important;
}

.mb53 {
  margin-bottom: 53px !important;
}

.mb54 {
  margin-bottom: 54px !important;
}

.mb55 {
  margin-bottom: 55px !important;
}

.mb56 {
  margin-bottom: 56px !important;
}

.mb57 {
  margin-bottom: 57px !important;
}

.mb58 {
  margin-bottom: 58px !important;
}

.mb59 {
  margin-bottom: 59px !important;
}

.mb60 {
  margin-bottom: 60px !important;
}

.mb61 {
  margin-bottom: 61px !important;
}

.mb62 {
  margin-bottom: 62px !important;
}

.mb63 {
  margin-bottom: 63px !important;
}

.mb64 {
  margin-bottom: 64px !important;
}

.mb65 {
  margin-bottom: 65px !important;
}

.mb66 {
  margin-bottom: 66px !important;
}

.mb67 {
  margin-bottom: 67px !important;
}

.mb68 {
  margin-bottom: 68px !important;
}

.mb69 {
  margin-bottom: 69px !important;
}

.mb70 {
  margin-bottom: 70px !important;
}

.mb71 {
  margin-bottom: 71px !important;
}

.mb72 {
  margin-bottom: 72px !important;
}

.mb73 {
  margin-bottom: 73px !important;
}

.mb74 {
  margin-bottom: 74px !important;
}

.mb75 {
  margin-bottom: 75px !important;
}

.mb76 {
  margin-bottom: 76px !important;
}

.mb77 {
  margin-bottom: 77px !important;
}

.mb78 {
  margin-bottom: 78px !important;
}

.mb79 {
  margin-bottom: 79px !important;
}

.mb80 {
  margin-bottom: 80px !important;
}

.mb81 {
  margin-bottom: 81px !important;
}

.mb82 {
  margin-bottom: 82px !important;
}

.mb83 {
  margin-bottom: 83px !important;
}

.mb84 {
  margin-bottom: 84px !important;
}

.mb85 {
  margin-bottom: 85px !important;
}

.mb86 {
  margin-bottom: 86px !important;
}

.mb87 {
  margin-bottom: 87px !important;
}

.mb88 {
  margin-bottom: 88px !important;
}

.mb89 {
  margin-bottom: 89px !important;
}

.mb90 {
  margin-bottom: 90px !important;
}

.mb91 {
  margin-bottom: 91px !important;
}

.mb92 {
  margin-bottom: 92px !important;
}

.mb93 {
  margin-bottom: 93px !important;
}

.mb94 {
  margin-bottom: 94px !important;
}

.mb95 {
  margin-bottom: 95px !important;
}

.mb96 {
  margin-bottom: 96px !important;
}

.mb97 {
  margin-bottom: 97px !important;
}

.mb98 {
  margin-bottom: 98px !important;
}

.mb99 {
  margin-bottom: 99px !important;
}

.mb100 {
  margin-bottom: 100px !important;
}

/*MARGIN - LEFT*/
.ml00 {
  margin-left: 0px !important;
}

.ml01 {
  margin-left: 1px !important;
}

.ml02 {
  margin-left: 2px !important;
}

.ml03 {
  margin-left: 3px !important;
}

.ml04 {
  margin-left: 4px !important;
}

.ml05 {
  margin-left: 5px !important;
}

.ml06 {
  margin-left: 6px !important;
}

.ml07 {
  margin-left: 7px !important;
}

.ml08 {
  margin-left: 8px !important;
}

.ml09 {
  margin-left: 9px !important;
}

.ml10 {
  margin-left: 10px !important;
}

.ml11 {
  margin-left: 11px !important;
}

.ml12 {
  margin-left: 12px !important;
}

.ml13 {
  margin-left: 13px !important;
}

.ml14 {
  margin-left: 14px !important;
}

.ml15 {
  margin-left: 15px !important;
}

.ml16 {
  margin-left: 16px !important;
}

.ml17 {
  margin-left: 17px !important;
}

.ml18 {
  margin-left: 18px !important;
}

.ml19 {
  margin-left: 19px !important;
}

.ml20 {
  margin-left: 20px !important;
}

.ml21 {
  margin-left: 21px !important;
}

.ml22 {
  margin-left: 22px !important;
}

.ml23 {
  margin-left: 23px !important;
}

.ml24 {
  margin-left: 24px !important;
}

.ml25 {
  margin-left: 25px !important;
}

.ml26 {
  margin-left: 26px !important;
}

.ml27 {
  margin-left: 27px !important;
}

.ml28 {
  margin-left: 28px !important;
}

.ml29 {
  margin-left: 29px !important;
}

.ml30 {
  margin-left: 30px !important;
}

.ml31 {
  margin-left: 31px !important;
}

.ml32 {
  margin-left: 32px !important;
}

.ml33 {
  margin-left: 33px !important;
}

.ml34 {
  margin-left: 34px !important;
}

.ml35 {
  margin-left: 35px !important;
}

.ml36 {
  margin-left: 36px !important;
}

.ml37 {
  margin-left: 37px !important;
}

.ml38 {
  margin-left: 38px !important;
}

.ml39 {
  margin-left: 39px !important;
}

.ml40 {
  margin-left: 40px !important;
}

.ml41 {
  margin-left: 41px !important;
}

.ml42 {
  margin-left: 42px !important;
}

.ml43 {
  margin-left: 43px !important;
}

.ml44 {
  margin-left: 44px !important;
}

.ml45 {
  margin-left: 45px !important;
}

.ml46 {
  margin-left: 46px !important;
}

.ml47 {
  margin-left: 47px !important;
}

.ml48 {
  margin-left: 48px !important;
}

.ml49 {
  margin-left: 49px !important;
}

.ml50 {
  margin-left: 50px !important;
}

.ml51 {
  margin-left: 51px !important;
}

.ml52 {
  margin-left: 52px !important;
}

.ml53 {
  margin-left: 53px !important;
}

.ml54 {
  margin-left: 54px !important;
}

.ml55 {
  margin-left: 55px !important;
}

.ml56 {
  margin-left: 56px !important;
}

.ml57 {
  margin-left: 57px !important;
}

.ml58 {
  margin-left: 58px !important;
}

.ml59 {
  margin-left: 59px !important;
}

.ml60 {
  margin-left: 60px !important;
}

.ml61 {
  margin-left: 61px !important;
}

.ml62 {
  margin-left: 62px !important;
}

.ml63 {
  margin-left: 63px !important;
}

.ml64 {
  margin-left: 64px !important;
}

.ml65 {
  margin-left: 65px !important;
}

.ml66 {
  margin-left: 66px !important;
}

.ml67 {
  margin-left: 67px !important;
}

.ml68 {
  margin-left: 68px !important;
}

.ml69 {
  margin-left: 69px !important;
}

.ml70 {
  margin-left: 70px !important;
}

.ml71 {
  margin-left: 71px !important;
}

.ml72 {
  margin-left: 72px !important;
}

.ml73 {
  margin-left: 73px !important;
}

.ml74 {
  margin-left: 74px !important;
}

.ml75 {
  margin-left: 75px !important;
}

.ml76 {
  margin-left: 76px !important;
}

.ml77 {
  margin-left: 77px !important;
}

.ml78 {
  margin-left: 78px !important;
}

.ml79 {
  margin-left: 79px !important;
}

.ml80 {
  margin-left: 80px !important;
}

.ml81 {
  margin-left: 81px !important;
}

.ml82 {
  margin-left: 82px !important;
}

.ml83 {
  margin-left: 83px !important;
}

.ml84 {
  margin-left: 84px !important;
}

.ml85 {
  margin-left: 85px !important;
}

.ml86 {
  margin-left: 86px !important;
}

.ml87 {
  margin-left: 87px !important;
}

.ml88 {
  margin-left: 88px !important;
}

.ml89 {
  margin-left: 89px !important;
}

.ml90 {
  margin-left: 90px !important;
}

.ml91 {
  margin-left: 91px !important;
}

.ml92 {
  margin-left: 92px !important;
}

.ml93 {
  margin-left: 93px !important;
}

.ml94 {
  margin-left: 94px !important;
}

.ml95 {
  margin-left: 95px !important;
}

.ml96 {
  margin-left: 96px !important;
}

.ml97 {
  margin-left: 97px !important;
}

.ml98 {
  margin-left: 98px !important;
}

.ml99 {
  margin-left: 99px !important;
}

.ml100 {
  margin-left: 100px !important;
}

/*====================================================================================
笆�02.PADDING DEFAULT - PIXEL
====================================================================================*/
/*PADDING*/
.p00 {
  padding: 0px !important;
}

.p01 {
  padding: 1px !important;
}

.p02 {
  padding: 2px !important;
}

.p03 {
  padding: 3px !important;
}

.p04 {
  padding: 4px !important;
}

.p05 {
  padding: 5px !important;
}

.p06 {
  padding: 6px !important;
}

.p07 {
  padding: 7px !important;
}

.p08 {
  padding: 8px !important;
}

.p09 {
  padding: 9px !important;
}

.p10 {
  padding: 10px !important;
}

.p11 {
  padding: 11px !important;
}

.p12 {
  padding: 12px !important;
}

.p13 {
  padding: 13px !important;
}

.p14 {
  padding: 14px !important;
}

.p15 {
  padding: 15px !important;
}

.p16 {
  padding: 16px !important;
}

.p17 {
  padding: 17px !important;
}

.p18 {
  padding: 18px !important;
}

.p19 {
  padding: 19px !important;
}

.p20 {
  padding: 20px !important;
}

.p21 {
  padding: 21px !important;
}

.p22 {
  padding: 22px !important;
}

.p23 {
  padding: 23px !important;
}

.p24 {
  padding: 24px !important;
}

.p25 {
  padding: 25px !important;
}

.p26 {
  padding: 26px !important;
}

.p27 {
  padding: 27px !important;
}

.p28 {
  padding: 28px !important;
}

.p29 {
  padding: 29px !important;
}

.p30 {
  padding: 30px !important;
}

.p31 {
  padding: 31px !important;
}

.p32 {
  padding: 32px !important;
}

.p33 {
  padding: 33px !important;
}

.p34 {
  padding: 34px !important;
}

.p35 {
  padding: 35px !important;
}

.p36 {
  padding: 36px !important;
}

.p37 {
  padding: 37px !important;
}

.p38 {
  padding: 38px !important;
}

.p39 {
  padding: 39px !important;
}

.p40 {
  padding: 40px !important;
}

.p41 {
  padding: 41px !important;
}

.p42 {
  padding: 42px !important;
}

.p43 {
  padding: 43px !important;
}

.p44 {
  padding: 44px !important;
}

.p45 {
  padding: 45px !important;
}

.p46 {
  padding: 46px !important;
}

.p47 {
  padding: 47px !important;
}

.p48 {
  padding: 48px !important;
}

.p49 {
  padding: 49px !important;
}

.p50 {
  padding: 50px !important;
}

.p51 {
  padding: 51px !important;
}

.p52 {
  padding: 52px !important;
}

.p53 {
  padding: 53px !important;
}

.p54 {
  padding: 54px !important;
}

.p55 {
  padding: 55px !important;
}

.p56 {
  padding: 56px !important;
}

.p57 {
  padding: 57px !important;
}

.p58 {
  padding: 58px !important;
}

.p59 {
  padding: 59px !important;
}

.p60 {
  padding: 60px !important;
}

.p61 {
  padding: 61px !important;
}

.p62 {
  padding: 62px !important;
}

.p63 {
  padding: 63px !important;
}

.p64 {
  padding: 64px !important;
}

.p65 {
  padding: 65px !important;
}

.p66 {
  padding: 66px !important;
}

.p67 {
  padding: 67px !important;
}

.p68 {
  padding: 68px !important;
}

.p69 {
  padding: 69px !important;
}

.p70 {
  padding: 70px !important;
}

.p71 {
  padding: 71px !important;
}

.p72 {
  padding: 72px !important;
}

.p73 {
  padding: 73px !important;
}

.p74 {
  padding: 74px !important;
}

.p75 {
  padding: 75px !important;
}

.p76 {
  padding: 76px !important;
}

.p77 {
  padding: 77px !important;
}

.p78 {
  padding: 78px !important;
}

.p79 {
  padding: 79px !important;
}

.p80 {
  padding: 80px !important;
}

.p81 {
  padding: 81px !important;
}

.p82 {
  padding: 82px !important;
}

.p83 {
  padding: 83px !important;
}

.p84 {
  padding: 84px !important;
}

.p85 {
  padding: 85px !important;
}

.p86 {
  padding: 86px !important;
}

.p87 {
  padding: 87px !important;
}

.p88 {
  padding: 88px !important;
}

.p89 {
  padding: 89px !important;
}

.p90 {
  padding: 90px !important;
}

.p91 {
  padding: 91px !important;
}

.p92 {
  padding: 92px !important;
}

.p93 {
  padding: 93px !important;
}

.p94 {
  padding: 94px !important;
}

.p95 {
  padding: 95px !important;
}

.p96 {
  padding: 96px !important;
}

.p97 {
  padding: 97px !important;
}

.p98 {
  padding: 98px !important;
}

.p99 {
  padding: 99px !important;
}

.p100 {
  padding: 100px !important;
}

/*PADDING - TOP*/
.pt00 {
  padding-top: 0px !important;
}

.pt01 {
  padding-top: 1px !important;
}

.pt02 {
  padding-top: 2px !important;
}

.pt03 {
  padding-top: 3px !important;
}

.pt04 {
  padding-top: 4px !important;
}

.pt05 {
  padding-top: 5px !important;
}

.pt06 {
  padding-top: 6px !important;
}

.pt07 {
  padding-top: 7px !important;
}

.pt08 {
  padding-top: 8px !important;
}

.pt09 {
  padding-top: 9px !important;
}

.pt10 {
  padding-top: 10px !important;
}

.pt11 {
  padding-top: 11px !important;
}

.pt12 {
  padding-top: 12px !important;
}

.pt13 {
  padding-top: 13px !important;
}

.pt14 {
  padding-top: 14px !important;
}

.pt15 {
  padding-top: 15px !important;
}

.pt16 {
  padding-top: 16px !important;
}

.pt17 {
  padding-top: 17px !important;
}

.pt18 {
  padding-top: 18px !important;
}

.pt19 {
  padding-top: 19px !important;
}

.pt20 {
  padding-top: 20px !important;
}

.pt21 {
  padding-top: 21px !important;
}

.pt22 {
  padding-top: 22px !important;
}

.pt23 {
  padding-top: 23px !important;
}

.pt24 {
  padding-top: 24px !important;
}

.pt25 {
  padding-top: 25px !important;
}

.pt26 {
  padding-top: 26px !important;
}

.pt27 {
  padding-top: 27px !important;
}

.pt28 {
  padding-top: 28px !important;
}

.pt29 {
  padding-top: 29px !important;
}

.pt30 {
  padding-top: 30px !important;
}

.pt31 {
  padding-top: 31px !important;
}

.pt32 {
  padding-top: 32px !important;
}

.pt33 {
  padding-top: 33px !important;
}

.pt34 {
  padding-top: 34px !important;
}

.pt35 {
  padding-top: 35px !important;
}

.pt36 {
  padding-top: 36px !important;
}

.pt37 {
  padding-top: 37px !important;
}

.pt38 {
  padding-top: 38px !important;
}

.pt39 {
  padding-top: 39px !important;
}

.pt40 {
  padding-top: 40px !important;
}

.pt41 {
  padding-top: 41px !important;
}

.pt42 {
  padding-top: 42px !important;
}

.pt43 {
  padding-top: 43px !important;
}

.pt44 {
  padding-top: 44px !important;
}

.pt45 {
  padding-top: 45px !important;
}

.pt46 {
  padding-top: 46px !important;
}

.pt47 {
  padding-top: 47px !important;
}

.pt48 {
  padding-top: 48px !important;
}

.pt49 {
  padding-top: 49px !important;
}

.pt50 {
  padding-top: 50px !important;
}

.pt51 {
  padding-top: 51px !important;
}

.pt52 {
  padding-top: 52px !important;
}

.pt53 {
  padding-top: 53px !important;
}

.pt54 {
  padding-top: 54px !important;
}

.pt55 {
  padding-top: 55px !important;
}

.pt56 {
  padding-top: 56px !important;
}

.pt57 {
  padding-top: 57px !important;
}

.pt58 {
  padding-top: 58px !important;
}

.pt59 {
  padding-top: 59px !important;
}

.pt60 {
  padding-top: 60px !important;
}

.pt61 {
  padding-top: 61px !important;
}

.pt62 {
  padding-top: 62px !important;
}

.pt63 {
  padding-top: 63px !important;
}

.pt64 {
  padding-top: 64px !important;
}

.pt65 {
  padding-top: 65px !important;
}

.pt66 {
  padding-top: 66px !important;
}

.pt67 {
  padding-top: 67px !important;
}

.pt68 {
  padding-top: 68px !important;
}

.pt69 {
  padding-top: 69px !important;
}

.pt70 {
  padding-top: 70px !important;
}

.pt71 {
  padding-top: 71px !important;
}

.pt72 {
  padding-top: 72px !important;
}

.pt73 {
  padding-top: 73px !important;
}

.pt74 {
  padding-top: 74px !important;
}

.pt75 {
  padding-top: 75px !important;
}

.pt76 {
  padding-top: 76px !important;
}

.pt77 {
  padding-top: 77px !important;
}

.pt78 {
  padding-top: 78px !important;
}

.pt79 {
  padding-top: 79px !important;
}

.pt80 {
  padding-top: 80px !important;
}

.pt81 {
  padding-top: 81px !important;
}

.pt82 {
  padding-top: 82px !important;
}

.pt83 {
  padding-top: 83px !important;
}

.pt84 {
  padding-top: 84px !important;
}

.pt85 {
  padding-top: 85px !important;
}

.pt86 {
  padding-top: 86px !important;
}

.pt87 {
  padding-top: 87px !important;
}

.pt88 {
  padding-top: 88px !important;
}

.pt89 {
  padding-top: 89px !important;
}

.pt90 {
  padding-top: 90px !important;
}

.pt91 {
  padding-top: 91px !important;
}

.pt92 {
  padding-top: 92px !important;
}

.pt93 {
  padding-top: 93px !important;
}

.pt94 {
  padding-top: 94px !important;
}

.pt95 {
  padding-top: 95px !important;
}

.pt96 {
  padding-top: 96px !important;
}

.pt97 {
  padding-top: 97px !important;
}

.pt98 {
  padding-top: 98px !important;
}

.pt99 {
  padding-top: 99px !important;
}

.pt100 {
  padding-top: 100px !important;
}

/*PADDING - RIGHT*/
.pr00 {
  padding-right: 0px !important;
}

.pr01 {
  padding-right: 1px !important;
}

.pr02 {
  padding-right: 2px !important;
}

.pr03 {
  padding-right: 3px !important;
}

.pr04 {
  padding-right: 4px !important;
}

.pr05 {
  padding-right: 5px !important;
}

.pr06 {
  padding-right: 6px !important;
}

.pr07 {
  padding-right: 7px !important;
}

.pr08 {
  padding-right: 8px !important;
}

.pr09 {
  padding-right: 9px !important;
}

.pr10 {
  padding-right: 10px !important;
}

.pr11 {
  padding-right: 11px !important;
}

.pr12 {
  padding-right: 12px !important;
}

.pr13 {
  padding-right: 13px !important;
}

.pr14 {
  padding-right: 14px !important;
}

.pr15 {
  padding-right: 15px !important;
}

.pr16 {
  padding-right: 16px !important;
}

.pr17 {
  padding-right: 17px !important;
}

.pr18 {
  padding-right: 18px !important;
}

.pr19 {
  padding-right: 19px !important;
}

.pr20 {
  padding-right: 20px !important;
}

.pr21 {
  padding-right: 21px !important;
}

.pr22 {
  padding-right: 22px !important;
}

.pr23 {
  padding-right: 23px !important;
}

.pr24 {
  padding-right: 24px !important;
}

.pr25 {
  padding-right: 25px !important;
}

.pr26 {
  padding-right: 26px !important;
}

.pr27 {
  padding-right: 27px !important;
}

.pr28 {
  padding-right: 28px !important;
}

.pr29 {
  padding-right: 29px !important;
}

.pr30 {
  padding-right: 30px !important;
}

.pr31 {
  padding-right: 31px !important;
}

.pr32 {
  padding-right: 32px !important;
}

.pr33 {
  padding-right: 33px !important;
}

.pr34 {
  padding-right: 34px !important;
}

.pr35 {
  padding-right: 35px !important;
}

.pr36 {
  padding-right: 36px !important;
}

.pr37 {
  padding-right: 37px !important;
}

.pr38 {
  padding-right: 38px !important;
}

.pr39 {
  padding-right: 39px !important;
}

.pr40 {
  padding-right: 40px !important;
}

.pr41 {
  padding-right: 41px !important;
}

.pr42 {
  padding-right: 42px !important;
}

.pr43 {
  padding-right: 43px !important;
}

.pr44 {
  padding-right: 44px !important;
}

.pr45 {
  padding-right: 45px !important;
}

.pr46 {
  padding-right: 46px !important;
}

.pr47 {
  padding-right: 47px !important;
}

.pr48 {
  padding-right: 48px !important;
}

.pr49 {
  padding-right: 49px !important;
}

.pr50 {
  padding-right: 50px !important;
}

.pr51 {
  padding-right: 51px !important;
}

.pr52 {
  padding-right: 52px !important;
}

.pr53 {
  padding-right: 53px !important;
}

.pr54 {
  padding-right: 54px !important;
}

.pr55 {
  padding-right: 55px !important;
}

.pr56 {
  padding-right: 56px !important;
}

.pr57 {
  padding-right: 57px !important;
}

.pr58 {
  padding-right: 58px !important;
}

.pr59 {
  padding-right: 59px !important;
}

.pr60 {
  padding-right: 60px !important;
}

.pr61 {
  padding-right: 61px !important;
}

.pr62 {
  padding-right: 62px !important;
}

.pr63 {
  padding-right: 63px !important;
}

.pr64 {
  padding-right: 64px !important;
}

.pr65 {
  padding-right: 65px !important;
}

.pr66 {
  padding-right: 66px !important;
}

.pr67 {
  padding-right: 67px !important;
}

.pr68 {
  padding-right: 68px !important;
}

.pr69 {
  padding-right: 69px !important;
}

.pr70 {
  padding-right: 70px !important;
}

.pr71 {
  padding-right: 71px !important;
}

.pr72 {
  padding-right: 72px !important;
}

.pr73 {
  padding-right: 73px !important;
}

.pr74 {
  padding-right: 74px !important;
}

.pr75 {
  padding-right: 75px !important;
}

.pr76 {
  padding-right: 76px !important;
}

.pr77 {
  padding-right: 77px !important;
}

.pr78 {
  padding-right: 78px !important;
}

.pr79 {
  padding-right: 79px !important;
}

.pr80 {
  padding-right: 80px !important;
}

.pr81 {
  padding-right: 81px !important;
}

.pr82 {
  padding-right: 82px !important;
}

.pr83 {
  padding-right: 83px !important;
}

.pr84 {
  padding-right: 84px !important;
}

.pr85 {
  padding-right: 85px !important;
}

.pr86 {
  padding-right: 86px !important;
}

.pr87 {
  padding-right: 87px !important;
}

.pr88 {
  padding-right: 88px !important;
}

.pr89 {
  padding-right: 89px !important;
}

.pr90 {
  padding-right: 90px !important;
}

.pr91 {
  padding-right: 91px !important;
}

.pr92 {
  padding-right: 92px !important;
}

.pr93 {
  padding-right: 93px !important;
}

.pr94 {
  padding-right: 94px !important;
}

.pr95 {
  padding-right: 95px !important;
}

.pr96 {
  padding-right: 96px !important;
}

.pr97 {
  padding-right: 97px !important;
}

.pr98 {
  padding-right: 98px !important;
}

.pr99 {
  padding-right: 99px !important;
}

.pr100 {
  padding-right: 100px !important;
}

/*PADDING - BOTTOM*/
.pb00 {
  padding-bottom: 0px !important;
}

.pb01 {
  padding-bottom: 1px !important;
}

.pb02 {
  padding-bottom: 2px !important;
}

.pb03 {
  padding-bottom: 3px !important;
}

.pb04 {
  padding-bottom: 4px !important;
}

.pb05 {
  padding-bottom: 5px !important;
}

.pb06 {
  padding-bottom: 6px !important;
}

.pb07 {
  padding-bottom: 7px !important;
}

.pb08 {
  padding-bottom: 8px !important;
}

.pb09 {
  padding-bottom: 9px !important;
}

.pb10 {
  padding-bottom: 10px !important;
}

.pb11 {
  padding-bottom: 11px !important;
}

.pb12 {
  padding-bottom: 12px !important;
}

.pb13 {
  padding-bottom: 13px !important;
}

.pb14 {
  padding-bottom: 14px !important;
}

.pb15 {
  padding-bottom: 15px !important;
}

.pb16 {
  padding-bottom: 16px !important;
}

.pb17 {
  padding-bottom: 17px !important;
}

.pb18 {
  padding-bottom: 18px !important;
}

.pb19 {
  padding-bottom: 19px !important;
}

.pb20 {
  padding-bottom: 20px !important;
}

.pb21 {
  padding-bottom: 21px !important;
}

.pb22 {
  padding-bottom: 22px !important;
}

.pb23 {
  padding-bottom: 23px !important;
}

.pb24 {
  padding-bottom: 24px !important;
}

.pb25 {
  padding-bottom: 25px !important;
}

.pb26 {
  padding-bottom: 26px !important;
}

.pb27 {
  padding-bottom: 27px !important;
}

.pb28 {
  padding-bottom: 28px !important;
}

.pb29 {
  padding-bottom: 29px !important;
}

.pb30 {
  padding-bottom: 30px !important;
}

.pb31 {
  padding-bottom: 31px !important;
}

.pb32 {
  padding-bottom: 32px !important;
}

.pb33 {
  padding-bottom: 33px !important;
}

.pb34 {
  padding-bottom: 34px !important;
}

.pb35 {
  padding-bottom: 35px !important;
}

.pb36 {
  padding-bottom: 36px !important;
}

.pb37 {
  padding-bottom: 37px !important;
}

.pb38 {
  padding-bottom: 38px !important;
}

.pb39 {
  padding-bottom: 39px !important;
}

.pb40 {
  padding-bottom: 40px !important;
}

.pb41 {
  padding-bottom: 41px !important;
}

.pb42 {
  padding-bottom: 42px !important;
}

.pb43 {
  padding-bottom: 43px !important;
}

.pb44 {
  padding-bottom: 44px !important;
}

.pb45 {
  padding-bottom: 45px !important;
}

.pb46 {
  padding-bottom: 46px !important;
}

.pb47 {
  padding-bottom: 47px !important;
}

.pb48 {
  padding-bottom: 48px !important;
}

.pb49 {
  padding-bottom: 49px !important;
}

.pb50 {
  padding-bottom: 50px !important;
}

.pb51 {
  padding-bottom: 51px !important;
}

.pb52 {
  padding-bottom: 52px !important;
}

.pb53 {
  padding-bottom: 53px !important;
}

.pb54 {
  padding-bottom: 54px !important;
}

.pb55 {
  padding-bottom: 55px !important;
}

.pb56 {
  padding-bottom: 56px !important;
}

.pb57 {
  padding-bottom: 57px !important;
}

.pb58 {
  padding-bottom: 58px !important;
}

.pb59 {
  padding-bottom: 59px !important;
}

.pb60 {
  padding-bottom: 60px !important;
}

.pb61 {
  padding-bottom: 61px !important;
}

.pb62 {
  padding-bottom: 62px !important;
}

.pb63 {
  padding-bottom: 63px !important;
}

.pb64 {
  padding-bottom: 64px !important;
}

.pb65 {
  padding-bottom: 65px !important;
}

.pb66 {
  padding-bottom: 66px !important;
}

.pb67 {
  padding-bottom: 67px !important;
}

.pb68 {
  padding-bottom: 68px !important;
}

.pb69 {
  padding-bottom: 69px !important;
}

.pb70 {
  padding-bottom: 70px !important;
}

.pb71 {
  padding-bottom: 71px !important;
}

.pb72 {
  padding-bottom: 72px !important;
}

.pb73 {
  padding-bottom: 73px !important;
}

.pb74 {
  padding-bottom: 74px !important;
}

.pb75 {
  padding-bottom: 75px !important;
}

.pb76 {
  padding-bottom: 76px !important;
}

.pb77 {
  padding-bottom: 77px !important;
}

.pb78 {
  padding-bottom: 78px !important;
}

.pb79 {
  padding-bottom: 79px !important;
}

.pb80 {
  padding-bottom: 80px !important;
}

.pb81 {
  padding-bottom: 81px !important;
}

.pb82 {
  padding-bottom: 82px !important;
}

.pb83 {
  padding-bottom: 83px !important;
}

.pb84 {
  padding-bottom: 84px !important;
}

.pb85 {
  padding-bottom: 85px !important;
}

.pb86 {
  padding-bottom: 86px !important;
}

.pb87 {
  padding-bottom: 87px !important;
}

.pb88 {
  padding-bottom: 88px !important;
}

.pb89 {
  padding-bottom: 89px !important;
}

.pb90 {
  padding-bottom: 90px !important;
}

.pb91 {
  padding-bottom: 91px !important;
}

.pb92 {
  padding-bottom: 92px !important;
}

.pb93 {
  padding-bottom: 93px !important;
}

.pb94 {
  padding-bottom: 94px !important;
}

.pb95 {
  padding-bottom: 95px !important;
}

.pb96 {
  padding-bottom: 96px !important;
}

.pb97 {
  padding-bottom: 97px !important;
}

.pb98 {
  padding-bottom: 98px !important;
}

.pb99 {
  padding-bottom: 99px !important;
}

.pb100 {
  padding-bottom: 100px !important;
}

/*PADDING - LEFT*/
.pl00 {
  padding-left: 0px !important;
}

.pl01 {
  padding-left: 1px !important;
}

.pl02 {
  padding-left: 2px !important;
}

.pl03 {
  padding-left: 3px !important;
}

.pl04 {
  padding-left: 4px !important;
}

.pl05 {
  padding-left: 5px !important;
}

.pl06 {
  padding-left: 6px !important;
}

.pl07 {
  padding-left: 7px !important;
}

.pl08 {
  padding-left: 8px !important;
}

.pl09 {
  padding-left: 9px !important;
}

.pl10 {
  padding-left: 10px !important;
}

.pl11 {
  padding-left: 11px !important;
}

.pl12 {
  padding-left: 12px !important;
}

.pl13 {
  padding-left: 13px !important;
}

.pl14 {
  padding-left: 14px !important;
}

.pl15 {
  padding-left: 15px !important;
}

.pl16 {
  padding-left: 16px !important;
}

.pl17 {
  padding-left: 17px !important;
}

.pl18 {
  padding-left: 18px !important;
}

.pl19 {
  padding-left: 19px !important;
}

.pl20 {
  padding-left: 20px !important;
}

.pl21 {
  padding-left: 21px !important;
}

.pl22 {
  padding-left: 22px !important;
}

.pl23 {
  padding-left: 23px !important;
}

.pl24 {
  padding-left: 24px !important;
}

.pl25 {
  padding-left: 25px !important;
}

.pl26 {
  padding-left: 26px !important;
}

.pl27 {
  padding-left: 27px !important;
}

.pl28 {
  padding-left: 28px !important;
}

.pl29 {
  padding-left: 29px !important;
}

.pl30 {
  padding-left: 30px !important;
}

.pl31 {
  padding-left: 31px !important;
}

.pl32 {
  padding-left: 32px !important;
}

.pl33 {
  padding-left: 33px !important;
}

.pl34 {
  padding-left: 34px !important;
}

.pl35 {
  padding-left: 35px !important;
}

.pl36 {
  padding-left: 36px !important;
}

.pl37 {
  padding-left: 37px !important;
}

.pl38 {
  padding-left: 38px !important;
}

.pl39 {
  padding-left: 39px !important;
}

.pl40 {
  padding-left: 40px !important;
}

.pl41 {
  padding-left: 41px !important;
}

.pl42 {
  padding-left: 42px !important;
}

.pl43 {
  padding-left: 43px !important;
}

.pl44 {
  padding-left: 44px !important;
}

.pl45 {
  padding-left: 45px !important;
}

.pl46 {
  padding-left: 46px !important;
}

.pl47 {
  padding-left: 47px !important;
}

.pl48 {
  padding-left: 48px !important;
}

.pl49 {
  padding-left: 49px !important;
}

.pl50 {
  padding-left: 50px !important;
}

.pl51 {
  padding-left: 51px !important;
}

.pl52 {
  padding-left: 52px !important;
}

.pl53 {
  padding-left: 53px !important;
}

.pl54 {
  padding-left: 54px !important;
}

.pl55 {
  padding-left: 55px !important;
}

.pl56 {
  padding-left: 56px !important;
}

.pl57 {
  padding-left: 57px !important;
}

.pl58 {
  padding-left: 58px !important;
}

.pl59 {
  padding-left: 59px !important;
}

.pl60 {
  padding-left: 60px !important;
}

.pl61 {
  padding-left: 61px !important;
}

.pl62 {
  padding-left: 62px !important;
}

.pl63 {
  padding-left: 63px !important;
}

.pl64 {
  padding-left: 64px !important;
}

.pl65 {
  padding-left: 65px !important;
}

.pl66 {
  padding-left: 66px !important;
}

.pl67 {
  padding-left: 67px !important;
}

.pl68 {
  padding-left: 68px !important;
}

.pl69 {
  padding-left: 69px !important;
}

.pl70 {
  padding-left: 70px !important;
}

.pl71 {
  padding-left: 71px !important;
}

.pl72 {
  padding-left: 72px !important;
}

.pl73 {
  padding-left: 73px !important;
}

.pl74 {
  padding-left: 74px !important;
}

.pl75 {
  padding-left: 75px !important;
}

.pl76 {
  padding-left: 76px !important;
}

.pl77 {
  padding-left: 77px !important;
}

.pl78 {
  padding-left: 78px !important;
}

.pl79 {
  padding-left: 79px !important;
}

.pl80 {
  padding-left: 80px !important;
}

.pl81 {
  padding-left: 81px !important;
}

.pl82 {
  padding-left: 82px !important;
}

.pl83 {
  padding-left: 83px !important;
}

.pl84 {
  padding-left: 84px !important;
}

.pl85 {
  padding-left: 85px !important;
}

.pl86 {
  padding-left: 86px !important;
}

.pl87 {
  padding-left: 87px !important;
}

.pl88 {
  padding-left: 88px !important;
}

.pl89 {
  padding-left: 89px !important;
}

.pl90 {
  padding-left: 90px !important;
}

.pl91 {
  padding-left: 91px !important;
}

.pl92 {
  padding-left: 92px !important;
}

.pl93 {
  padding-left: 93px !important;
}

.pl94 {
  padding-left: 94px !important;
}

.pl95 {
  padding-left: 95px !important;
}

.pl96 {
  padding-left: 96px !important;
}

.pl97 {
  padding-left: 97px !important;
}

.pl98 {
  padding-left: 98px !important;
}

.pl99 {
  padding-left: 99px !important;
}

.pl100 {
  padding-left: 100px !important;
}

/*====================================================================================
笆�03.MARGIN DEFAULT - PERCENT
====================================================================================*/
/*MARGIN*/
.m00pc {
  margin: 0% !important;
}

.m01pc {
  margin: 1% !important;
}

.m02pc {
  margin: 2% !important;
}

.m03pc {
  margin: 3% !important;
}

.m04pc {
  margin: 4% !important;
}

.m05pc {
  margin: 5% !important;
}

.m06pc {
  margin: 6% !important;
}

.m07pc {
  margin: 7% !important;
}

.m08pc {
  margin: 8% !important;
}

.m09pc {
  margin: 9% !important;
}

.m10pc {
  margin: 10% !important;
}

.m11pc {
  margin: 11% !important;
}

.m12pc {
  margin: 12% !important;
}

.m13pc {
  margin: 13% !important;
}

.m14pc {
  margin: 14% !important;
}

.m15pc {
  margin: 15% !important;
}

.m16pc {
  margin: 16% !important;
}

.m17pc {
  margin: 17% !important;
}

.m18pc {
  margin: 18% !important;
}

.m19pc {
  margin: 19% !important;
}

.m20pc {
  margin: 20% !important;
}

.m21pc {
  margin: 21% !important;
}

.m22pc {
  margin: 22% !important;
}

.m23pc {
  margin: 23% !important;
}

.m24pc {
  margin: 24% !important;
}

.m25pc {
  margin: 25% !important;
}

.m26pc {
  margin: 26% !important;
}

.m27pc {
  margin: 27% !important;
}

.m28pc {
  margin: 28% !important;
}

.m29pc {
  margin: 29% !important;
}

.m30pc {
  margin: 30% !important;
}

.m31pc {
  margin: 31% !important;
}

.m32pc {
  margin: 32% !important;
}

.m33pc {
  margin: 33% !important;
}

.m34pc {
  margin: 34% !important;
}

.m35pc {
  margin: 35% !important;
}

.m36pc {
  margin: 36% !important;
}

.m37pc {
  margin: 37% !important;
}

.m38pc {
  margin: 38% !important;
}

.m39pc {
  margin: 39% !important;
}

.m40pc {
  margin: 40% !important;
}

.m41pc {
  margin: 41% !important;
}

.m42pc {
  margin: 42% !important;
}

.m43pc {
  margin: 43% !important;
}

.m44pc {
  margin: 44% !important;
}

.m45pc {
  margin: 45% !important;
}

.m46pc {
  margin: 46% !important;
}

.m47pc {
  margin: 47% !important;
}

.m48pc {
  margin: 48% !important;
}

.m49pc {
  margin: 49% !important;
}

.m50pc {
  margin: 50% !important;
}

.m51pc {
  margin: 51% !important;
}

.m52pc {
  margin: 52% !important;
}

.m53pc {
  margin: 53% !important;
}

.m54pc {
  margin: 54% !important;
}

.m55pc {
  margin: 55% !important;
}

.m56pc {
  margin: 56% !important;
}

.m57pc {
  margin: 57% !important;
}

.m58pc {
  margin: 58% !important;
}

.m59pc {
  margin: 59% !important;
}

.m60pc {
  margin: 60% !important;
}

.m61pc {
  margin: 61% !important;
}

.m62pc {
  margin: 62% !important;
}

.m63pc {
  margin: 63% !important;
}

.m64pc {
  margin: 64% !important;
}

.m65pc {
  margin: 65% !important;
}

.m66pc {
  margin: 66% !important;
}

.m67pc {
  margin: 67% !important;
}

.m68pc {
  margin: 68% !important;
}

.m69pc {
  margin: 69% !important;
}

.m70pc {
  margin: 70% !important;
}

.m71pc {
  margin: 71% !important;
}

.m72pc {
  margin: 72% !important;
}

.m73pc {
  margin: 73% !important;
}

.m74pc {
  margin: 74% !important;
}

.m75pc {
  margin: 75% !important;
}

.m76pc {
  margin: 76% !important;
}

.m77pc {
  margin: 77% !important;
}

.m78pc {
  margin: 78% !important;
}

.m79pc {
  margin: 79% !important;
}

.m80pc {
  margin: 80% !important;
}

.m81pc {
  margin: 81% !important;
}

.m82pc {
  margin: 82% !important;
}

.m83pc {
  margin: 83% !important;
}

.m84pc {
  margin: 84% !important;
}

.m85pc {
  margin: 85% !important;
}

.m86pc {
  margin: 86% !important;
}

.m87pc {
  margin: 87% !important;
}

.m88pc {
  margin: 88% !important;
}

.m89pc {
  margin: 89% !important;
}

.m90pc {
  margin: 90% !important;
}

.m91pc {
  margin: 91% !important;
}

.m92pc {
  margin: 92% !important;
}

.m93pc {
  margin: 93% !important;
}

.m94pc {
  margin: 94% !important;
}

.m95pc {
  margin: 95% !important;
}

.m96pc {
  margin: 96% !important;
}

.m97pc {
  margin: 97% !important;
}

.m98pc {
  margin: 98% !important;
}

.m99pc {
  margin: 99% !important;
}

.m100pc {
  margin: 100% !important;
}

/*MARGIN - TOP*/
.mt00pc {
  margin-top: 0% !important;
}

.mt01pc {
  margin-top: 1% !important;
}

.mt02pc {
  margin-top: 2% !important;
}

.mt03pc {
  margin-top: 3% !important;
}

.mt04pc {
  margin-top: 4% !important;
}

.mt05pc {
  margin-top: 5% !important;
}

.mt06pc {
  margin-top: 6% !important;
}

.mt07pc {
  margin-top: 7% !important;
}

.mt08pc {
  margin-top: 8% !important;
}

.mt09pc {
  margin-top: 9% !important;
}

.mt10pc {
  margin-top: 10% !important;
}

.mt11pc {
  margin-top: 11% !important;
}

.mt12pc {
  margin-top: 12% !important;
}

.mt13pc {
  margin-top: 13% !important;
}

.mt14pc {
  margin-top: 14% !important;
}

.mt15pc {
  margin-top: 15% !important;
}

.mt16pc {
  margin-top: 16% !important;
}

.mt17pc {
  margin-top: 17% !important;
}

.mt18pc {
  margin-top: 18% !important;
}

.mt19pc {
  margin-top: 19% !important;
}

.mt20pc {
  margin-top: 20% !important;
}

.mt21pc {
  margin-top: 21% !important;
}

.mt22pc {
  margin-top: 22% !important;
}

.mt23pc {
  margin-top: 23% !important;
}

.mt24pc {
  margin-top: 24% !important;
}

.mt25pc {
  margin-top: 25% !important;
}

.mt26pc {
  margin-top: 26% !important;
}

.mt27pc {
  margin-top: 27% !important;
}

.mt28pc {
  margin-top: 28% !important;
}

.mt29pc {
  margin-top: 29% !important;
}

.mt30pc {
  margin-top: 30% !important;
}

.mt31pc {
  margin-top: 31% !important;
}

.mt32pc {
  margin-top: 32% !important;
}

.mt33pc {
  margin-top: 33% !important;
}

.mt34pc {
  margin-top: 34% !important;
}

.mt35pc {
  margin-top: 35% !important;
}

.mt36pc {
  margin-top: 36% !important;
}

.mt37pc {
  margin-top: 37% !important;
}

.mt38pc {
  margin-top: 38% !important;
}

.mt39pc {
  margin-top: 39% !important;
}

.mt40pc {
  margin-top: 40% !important;
}

.mt41pc {
  margin-top: 41% !important;
}

.mt42pc {
  margin-top: 42% !important;
}

.mt43pc {
  margin-top: 43% !important;
}

.mt44pc {
  margin-top: 44% !important;
}

.mt45pc {
  margin-top: 45% !important;
}

.mt46pc {
  margin-top: 46% !important;
}

.mt47pc {
  margin-top: 47% !important;
}

.mt48pc {
  margin-top: 48% !important;
}

.mt49pc {
  margin-top: 49% !important;
}

.mt50pc {
  margin-top: 50% !important;
}

.mt51pc {
  margin-top: 51% !important;
}

.mt52pc {
  margin-top: 52% !important;
}

.mt53pc {
  margin-top: 53% !important;
}

.mt54pc {
  margin-top: 54% !important;
}

.mt55pc {
  margin-top: 55% !important;
}

.mt56pc {
  margin-top: 56% !important;
}

.mt57pc {
  margin-top: 57% !important;
}

.mt58pc {
  margin-top: 58% !important;
}

.mt59pc {
  margin-top: 59% !important;
}

.mt60pc {
  margin-top: 60% !important;
}

.mt61pc {
  margin-top: 61% !important;
}

.mt62pc {
  margin-top: 62% !important;
}

.mt63pc {
  margin-top: 63% !important;
}

.mt64pc {
  margin-top: 64% !important;
}

.mt65pc {
  margin-top: 65% !important;
}

.mt66pc {
  margin-top: 66% !important;
}

.mt67pc {
  margin-top: 67% !important;
}

.mt68pc {
  margin-top: 68% !important;
}

.mt69pc {
  margin-top: 69% !important;
}

.mt70pc {
  margin-top: 70% !important;
}

.mt71pc {
  margin-top: 71% !important;
}

.mt72pc {
  margin-top: 72% !important;
}

.mt73pc {
  margin-top: 73% !important;
}

.mt74pc {
  margin-top: 74% !important;
}

.mt75pc {
  margin-top: 75% !important;
}

.mt76pc {
  margin-top: 76% !important;
}

.mt77pc {
  margin-top: 77% !important;
}

.mt78pc {
  margin-top: 78% !important;
}

.mt79pc {
  margin-top: 79% !important;
}

.mt80pc {
  margin-top: 80% !important;
}

.mt81pc {
  margin-top: 81% !important;
}

.mt82pc {
  margin-top: 82% !important;
}

.mt83pc {
  margin-top: 83% !important;
}

.mt84pc {
  margin-top: 84% !important;
}

.mt85pc {
  margin-top: 85% !important;
}

.mt86pc {
  margin-top: 86% !important;
}

.mt87pc {
  margin-top: 87% !important;
}

.mt88pc {
  margin-top: 88% !important;
}

.mt89pc {
  margin-top: 89% !important;
}

.mt90pc {
  margin-top: 90% !important;
}

.mt91pc {
  margin-top: 91% !important;
}

.mt92pc {
  margin-top: 92% !important;
}

.mt93pc {
  margin-top: 93% !important;
}

.mt94pc {
  margin-top: 94% !important;
}

.mt95pc {
  margin-top: 95% !important;
}

.mt96pc {
  margin-top: 96% !important;
}

.mt97pc {
  margin-top: 97% !important;
}

.mt98pc {
  margin-top: 98% !important;
}

.mt99pc {
  margin-top: 99% !important;
}

.mt100pc {
  margin-top: 100% !important;
}

/*MARGIN - RIGHT*/
.mr00pc {
  margin-right: 0% !important;
}

.mr01pc {
  margin-right: 1% !important;
}

.mr02pc {
  margin-right: 2% !important;
}

.mr03pc {
  margin-right: 3% !important;
}

.mr04pc {
  margin-right: 4% !important;
}

.mr05pc {
  margin-right: 5% !important;
}

.mr06pc {
  margin-right: 6% !important;
}

.mr07pc {
  margin-right: 7% !important;
}

.mr08pc {
  margin-right: 8% !important;
}

.mr09pc {
  margin-right: 9% !important;
}

.mr10pc {
  margin-right: 10% !important;
}

.mr11pc {
  margin-right: 11% !important;
}

.mr12pc {
  margin-right: 12% !important;
}

.mr13pc {
  margin-right: 13% !important;
}

.mr14pc {
  margin-right: 14% !important;
}

.mr15pc {
  margin-right: 15% !important;
}

.mr16pc {
  margin-right: 16% !important;
}

.mr17pc {
  margin-right: 17% !important;
}

.mr18pc {
  margin-right: 18% !important;
}

.mr19pc {
  margin-right: 19% !important;
}

.mr20pc {
  margin-right: 20% !important;
}

.mr21pc {
  margin-right: 21% !important;
}

.mr22pc {
  margin-right: 22% !important;
}

.mr23pc {
  margin-right: 23% !important;
}

.mr24pc {
  margin-right: 24% !important;
}

.mr25pc {
  margin-right: 25% !important;
}

.mr26pc {
  margin-right: 26% !important;
}

.mr27pc {
  margin-right: 27% !important;
}

.mr28pc {
  margin-right: 28% !important;
}

.mr29pc {
  margin-right: 29% !important;
}

.mr30pc {
  margin-right: 30% !important;
}

.mr31pc {
  margin-right: 31% !important;
}

.mr32pc {
  margin-right: 32% !important;
}

.mr33pc {
  margin-right: 33% !important;
}

.mr34pc {
  margin-right: 34% !important;
}

.mr35pc {
  margin-right: 35% !important;
}

.mr36pc {
  margin-right: 36% !important;
}

.mr37pc {
  margin-right: 37% !important;
}

.mr38pc {
  margin-right: 38% !important;
}

.mr39pc {
  margin-right: 39% !important;
}

.mr40pc {
  margin-right: 40% !important;
}

.mr41pc {
  margin-right: 41% !important;
}

.mr42pc {
  margin-right: 42% !important;
}

.mr43pc {
  margin-right: 43% !important;
}

.mr44pc {
  margin-right: 44% !important;
}

.mr45pc {
  margin-right: 45% !important;
}

.mr46pc {
  margin-right: 46% !important;
}

.mr47pc {
  margin-right: 47% !important;
}

.mr48pc {
  margin-right: 48% !important;
}

.mr49pc {
  margin-right: 49% !important;
}

.mr50pc {
  margin-right: 50% !important;
}

.mr51pc {
  margin-right: 51% !important;
}

.mr52pc {
  margin-right: 52% !important;
}

.mr53pc {
  margin-right: 53% !important;
}

.mr54pc {
  margin-right: 54% !important;
}

.mr55pc {
  margin-right: 55% !important;
}

.mr56pc {
  margin-right: 56% !important;
}

.mr57pc {
  margin-right: 57% !important;
}

.mr58pc {
  margin-right: 58% !important;
}

.mr59pc {
  margin-right: 59% !important;
}

.mr60pc {
  margin-right: 60% !important;
}

.mr61pc {
  margin-right: 61% !important;
}

.mr62pc {
  margin-right: 62% !important;
}

.mr63pc {
  margin-right: 63% !important;
}

.mr64pc {
  margin-right: 64% !important;
}

.mr65pc {
  margin-right: 65% !important;
}

.mr66pc {
  margin-right: 66% !important;
}

.mr67pc {
  margin-right: 67% !important;
}

.mr68pc {
  margin-right: 68% !important;
}

.mr69pc {
  margin-right: 69% !important;
}

.mr70pc {
  margin-right: 70% !important;
}

.mr71pc {
  margin-right: 71% !important;
}

.mr72pc {
  margin-right: 72% !important;
}

.mr73pc {
  margin-right: 73% !important;
}

.mr74pc {
  margin-right: 74% !important;
}

.mr75pc {
  margin-right: 75% !important;
}

.mr76pc {
  margin-right: 76% !important;
}

.mr77pc {
  margin-right: 77% !important;
}

.mr78pc {
  margin-right: 78% !important;
}

.mr79pc {
  margin-right: 79% !important;
}

.mr80pc {
  margin-right: 80% !important;
}

.mr81pc {
  margin-right: 81% !important;
}

.mr82pc {
  margin-right: 82% !important;
}

.mr83pc {
  margin-right: 83% !important;
}

.mr84pc {
  margin-right: 84% !important;
}

.mr85pc {
  margin-right: 85% !important;
}

.mr86pc {
  margin-right: 86% !important;
}

.mr87pc {
  margin-right: 87% !important;
}

.mr88pc {
  margin-right: 88% !important;
}

.mr89pc {
  margin-right: 89% !important;
}

.mr90pc {
  margin-right: 90% !important;
}

.mr91pc {
  margin-right: 91% !important;
}

.mr92pc {
  margin-right: 92% !important;
}

.mr93pc {
  margin-right: 93% !important;
}

.mr94pc {
  margin-right: 94% !important;
}

.mr95pc {
  margin-right: 95% !important;
}

.mr96pc {
  margin-right: 96% !important;
}

.mr97pc {
  margin-right: 97% !important;
}

.mr98pc {
  margin-right: 98% !important;
}

.mr99pc {
  margin-right: 99% !important;
}

.mr100pc {
  margin-right: 100% !important;
}

/*MARGIN - BOTTOM*/
.mb00pc {
  margin-bottom: 0% !important;
}

.mb01pc {
  margin-bottom: 1% !important;
}

.mb02pc {
  margin-bottom: 2% !important;
}

.mb03pc {
  margin-bottom: 3% !important;
}

.mb04pc {
  margin-bottom: 4% !important;
}

.mb05pc {
  margin-bottom: 5% !important;
}

.mb06pc {
  margin-bottom: 6% !important;
}

.mb07pc {
  margin-bottom: 7% !important;
}

.mb08pc {
  margin-bottom: 8% !important;
}

.mb09pc {
  margin-bottom: 9% !important;
}

.mb10pc {
  margin-bottom: 10% !important;
}

.mb11pc {
  margin-bottom: 11% !important;
}

.mb12pc {
  margin-bottom: 12% !important;
}

.mb13pc {
  margin-bottom: 13% !important;
}

.mb14pc {
  margin-bottom: 14% !important;
}

.mb15pc {
  margin-bottom: 15% !important;
}

.mb16pc {
  margin-bottom: 16% !important;
}

.mb17pc {
  margin-bottom: 17% !important;
}

.mb18pc {
  margin-bottom: 18% !important;
}

.mb19pc {
  margin-bottom: 19% !important;
}

.mb20pc {
  margin-bottom: 20% !important;
}

.mb21pc {
  margin-bottom: 21% !important;
}

.mb22pc {
  margin-bottom: 22% !important;
}

.mb23pc {
  margin-bottom: 23% !important;
}

.mb24pc {
  margin-bottom: 24% !important;
}

.mb25pc {
  margin-bottom: 25% !important;
}

.mb26pc {
  margin-bottom: 26% !important;
}

.mb27pc {
  margin-bottom: 27% !important;
}

.mb28pc {
  margin-bottom: 28% !important;
}

.mb29pc {
  margin-bottom: 29% !important;
}

.mb30pc {
  margin-bottom: 30% !important;
}

.mb31pc {
  margin-bottom: 31% !important;
}

.mb32pc {
  margin-bottom: 32% !important;
}

.mb33pc {
  margin-bottom: 33% !important;
}

.mb34pc {
  margin-bottom: 34% !important;
}

.mb35pc {
  margin-bottom: 35% !important;
}

.mb36pc {
  margin-bottom: 36% !important;
}

.mb37pc {
  margin-bottom: 37% !important;
}

.mb38pc {
  margin-bottom: 38% !important;
}

.mb39pc {
  margin-bottom: 39% !important;
}

.mb40pc {
  margin-bottom: 40% !important;
}

.mb41pc {
  margin-bottom: 41% !important;
}

.mb42pc {
  margin-bottom: 42% !important;
}

.mb43pc {
  margin-bottom: 43% !important;
}

.mb44pc {
  margin-bottom: 44% !important;
}

.mb45pc {
  margin-bottom: 45% !important;
}

.mb46pc {
  margin-bottom: 46% !important;
}

.mb47pc {
  margin-bottom: 47% !important;
}

.mb48pc {
  margin-bottom: 48% !important;
}

.mb49pc {
  margin-bottom: 49% !important;
}

.mb50pc {
  margin-bottom: 50% !important;
}

.mb51pc {
  margin-bottom: 51% !important;
}

.mb52pc {
  margin-bottom: 52% !important;
}

.mb53pc {
  margin-bottom: 53% !important;
}

.mb54pc {
  margin-bottom: 54% !important;
}

.mb55pc {
  margin-bottom: 55% !important;
}

.mb56pc {
  margin-bottom: 56% !important;
}

.mb57pc {
  margin-bottom: 57% !important;
}

.mb58pc {
  margin-bottom: 58% !important;
}

.mb59pc {
  margin-bottom: 59% !important;
}

.mb60pc {
  margin-bottom: 60% !important;
}

.mb61pc {
  margin-bottom: 61% !important;
}

.mb62pc {
  margin-bottom: 62% !important;
}

.mb63pc {
  margin-bottom: 63% !important;
}

.mb64pc {
  margin-bottom: 64% !important;
}

.mb65pc {
  margin-bottom: 65% !important;
}

.mb66pc {
  margin-bottom: 66% !important;
}

.mb67pc {
  margin-bottom: 67% !important;
}

.mb68pc {
  margin-bottom: 68% !important;
}

.mb69pc {
  margin-bottom: 69% !important;
}

.mb70pc {
  margin-bottom: 70% !important;
}

.mb71pc {
  margin-bottom: 71% !important;
}

.mb72pc {
  margin-bottom: 72% !important;
}

.mb73pc {
  margin-bottom: 73% !important;
}

.mb74pc {
  margin-bottom: 74% !important;
}

.mb75pc {
  margin-bottom: 75% !important;
}

.mb76pc {
  margin-bottom: 76% !important;
}

.mb77pc {
  margin-bottom: 77% !important;
}

.mb78pc {
  margin-bottom: 78% !important;
}

.mb79pc {
  margin-bottom: 79% !important;
}

.mb80pc {
  margin-bottom: 80% !important;
}

.mb81pc {
  margin-bottom: 81% !important;
}

.mb82pc {
  margin-bottom: 82% !important;
}

.mb83pc {
  margin-bottom: 83% !important;
}

.mb84pc {
  margin-bottom: 84% !important;
}

.mb85pc {
  margin-bottom: 85% !important;
}

.mb86pc {
  margin-bottom: 86% !important;
}

.mb87pc {
  margin-bottom: 87% !important;
}

.mb88pc {
  margin-bottom: 88% !important;
}

.mb89pc {
  margin-bottom: 89% !important;
}

.mb90pc {
  margin-bottom: 90% !important;
}

.mb91pc {
  margin-bottom: 91% !important;
}

.mb92pc {
  margin-bottom: 92% !important;
}

.mb93pc {
  margin-bottom: 93% !important;
}

.mb94pc {
  margin-bottom: 94% !important;
}

.mb95pc {
  margin-bottom: 95% !important;
}

.mb96pc {
  margin-bottom: 96% !important;
}

.mb97pc {
  margin-bottom: 97% !important;
}

.mb98pc {
  margin-bottom: 98% !important;
}

.mb99pc {
  margin-bottom: 99% !important;
}

.mb100pc {
  margin-bottom: 100% !important;
}

/*MARGIN - LEFT*/
.ml00pc {
  margin-left: 0% !important;
}

.ml01pc {
  margin-left: 1% !important;
}

.ml02pc {
  margin-left: 2% !important;
}

.ml03pc {
  margin-left: 3% !important;
}

.ml04pc {
  margin-left: 4% !important;
}

.ml05pc {
  margin-left: 5% !important;
}

.ml06pc {
  margin-left: 6% !important;
}

.ml07pc {
  margin-left: 7% !important;
}

.ml08pc {
  margin-left: 8% !important;
}

.ml09pc {
  margin-left: 9% !important;
}

.ml10pc {
  margin-left: 10% !important;
}

.ml11pc {
  margin-left: 11% !important;
}

.ml12pc {
  margin-left: 12% !important;
}

.ml13pc {
  margin-left: 13% !important;
}

.ml14pc {
  margin-left: 14% !important;
}

.ml15pc {
  margin-left: 15% !important;
}

.ml16pc {
  margin-left: 16% !important;
}

.ml17pc {
  margin-left: 17% !important;
}

.ml18pc {
  margin-left: 18% !important;
}

.ml19pc {
  margin-left: 19% !important;
}

.ml20pc {
  margin-left: 20% !important;
}

.ml21pc {
  margin-left: 21% !important;
}

.ml22pc {
  margin-left: 22% !important;
}

.ml23pc {
  margin-left: 23% !important;
}

.ml24pc {
  margin-left: 24% !important;
}

.ml25pc {
  margin-left: 25% !important;
}

.ml26pc {
  margin-left: 26% !important;
}

.ml27pc {
  margin-left: 27% !important;
}

.ml28pc {
  margin-left: 28% !important;
}

.ml29pc {
  margin-left: 29% !important;
}

.ml30pc {
  margin-left: 30% !important;
}

.ml31pc {
  margin-left: 31% !important;
}

.ml32pc {
  margin-left: 32% !important;
}

.ml33pc {
  margin-left: 33% !important;
}

.ml34pc {
  margin-left: 34% !important;
}

.ml35pc {
  margin-left: 35% !important;
}

.ml36pc {
  margin-left: 36% !important;
}

.ml37pc {
  margin-left: 37% !important;
}

.ml38pc {
  margin-left: 38% !important;
}

.ml39pc {
  margin-left: 39% !important;
}

.ml40pc {
  margin-left: 40% !important;
}

.ml41pc {
  margin-left: 41% !important;
}

.ml42pc {
  margin-left: 42% !important;
}

.ml43pc {
  margin-left: 43% !important;
}

.ml44pc {
  margin-left: 44% !important;
}

.ml45pc {
  margin-left: 45% !important;
}

.ml46pc {
  margin-left: 46% !important;
}

.ml47pc {
  margin-left: 47% !important;
}

.ml48pc {
  margin-left: 48% !important;
}

.ml49pc {
  margin-left: 49% !important;
}

.ml50pc {
  margin-left: 50% !important;
}

.ml51pc {
  margin-left: 51% !important;
}

.ml52pc {
  margin-left: 52% !important;
}

.ml53pc {
  margin-left: 53% !important;
}

.ml54pc {
  margin-left: 54% !important;
}

.ml55pc {
  margin-left: 55% !important;
}

.ml56pc {
  margin-left: 56% !important;
}

.ml57pc {
  margin-left: 57% !important;
}

.ml58pc {
  margin-left: 58% !important;
}

.ml59pc {
  margin-left: 59% !important;
}

.ml60pc {
  margin-left: 60% !important;
}

.ml61pc {
  margin-left: 61% !important;
}

.ml62pc {
  margin-left: 62% !important;
}

.ml63pc {
  margin-left: 63% !important;
}

.ml64pc {
  margin-left: 64% !important;
}

.ml65pc {
  margin-left: 65% !important;
}

.ml66pc {
  margin-left: 66% !important;
}

.ml67pc {
  margin-left: 67% !important;
}

.ml68pc {
  margin-left: 68% !important;
}

.ml69pc {
  margin-left: 69% !important;
}

.ml70pc {
  margin-left: 70% !important;
}

.ml71pc {
  margin-left: 71% !important;
}

.ml72pc {
  margin-left: 72% !important;
}

.ml73pc {
  margin-left: 73% !important;
}

.ml74pc {
  margin-left: 74% !important;
}

.ml75pc {
  margin-left: 75% !important;
}

.ml76pc {
  margin-left: 76% !important;
}

.ml77pc {
  margin-left: 77% !important;
}

.ml78pc {
  margin-left: 78% !important;
}

.ml79pc {
  margin-left: 79% !important;
}

.ml80pc {
  margin-left: 80% !important;
}

.ml81pc {
  margin-left: 81% !important;
}

.ml82pc {
  margin-left: 82% !important;
}

.ml83pc {
  margin-left: 83% !important;
}

.ml84pc {
  margin-left: 84% !important;
}

.ml85pc {
  margin-left: 85% !important;
}

.ml86pc {
  margin-left: 86% !important;
}

.ml87pc {
  margin-left: 87% !important;
}

.ml88pc {
  margin-left: 88% !important;
}

.ml89pc {
  margin-left: 89% !important;
}

.ml90pc {
  margin-left: 90% !important;
}

.ml91pc {
  margin-left: 91% !important;
}

.ml92pc {
  margin-left: 92% !important;
}

.ml93pc {
  margin-left: 93% !important;
}

.ml94pc {
  margin-left: 94% !important;
}

.ml95pc {
  margin-left: 95% !important;
}

.ml96pc {
  margin-left: 96% !important;
}

.ml97pc {
  margin-left: 97% !important;
}

.ml98pc {
  margin-left: 98% !important;
}

.ml99pc {
  margin-left: 99% !important;
}

.ml100pc {
  margin-left: 100% !important;
}

/*====================================================================================
笆�04.PADDING DEFAULT - PERCENT
====================================================================================*/
/*PADDING*/
.p00pc {
  padding: 0% !important;
}

.p01pc {
  padding: 1% !important;
}

.p02pc {
  padding: 2% !important;
}

.p03pc {
  padding: 3% !important;
}

.p04pc {
  padding: 4% !important;
}

.p05pc {
  padding: 5% !important;
}

.p06pc {
  padding: 6% !important;
}

.p07pc {
  padding: 7% !important;
}

.p08pc {
  padding: 8% !important;
}

.p09pc {
  padding: 9% !important;
}

.p10pc {
  padding: 10% !important;
}

.p11pc {
  padding: 11% !important;
}

.p12pc {
  padding: 12% !important;
}

.p13pc {
  padding: 13% !important;
}

.p14pc {
  padding: 14% !important;
}

.p15pc {
  padding: 15% !important;
}

.p16pc {
  padding: 16% !important;
}

.p17pc {
  padding: 17% !important;
}

.p18pc {
  padding: 18% !important;
}

.p19pc {
  padding: 19% !important;
}

.p20pc {
  padding: 20% !important;
}

.p21pc {
  padding: 21% !important;
}

.p22pc {
  padding: 22% !important;
}

.p23pc {
  padding: 23% !important;
}

.p24pc {
  padding: 24% !important;
}

.p25pc {
  padding: 25% !important;
}

.p26pc {
  padding: 26% !important;
}

.p27pc {
  padding: 27% !important;
}

.p28pc {
  padding: 28% !important;
}

.p29pc {
  padding: 29% !important;
}

.p30pc {
  padding: 30% !important;
}

.p31pc {
  padding: 31% !important;
}

.p32pc {
  padding: 32% !important;
}

.p33pc {
  padding: 33% !important;
}

.p34pc {
  padding: 34% !important;
}

.p35pc {
  padding: 35% !important;
}

.p36pc {
  padding: 36% !important;
}

.p37pc {
  padding: 37% !important;
}

.p38pc {
  padding: 38% !important;
}

.p39pc {
  padding: 39% !important;
}

.p40pc {
  padding: 40% !important;
}

.p41pc {
  padding: 41% !important;
}

.p42pc {
  padding: 42% !important;
}

.p43pc {
  padding: 43% !important;
}

.p44pc {
  padding: 44% !important;
}

.p45pc {
  padding: 45% !important;
}

.p46pc {
  padding: 46% !important;
}

.p47pc {
  padding: 47% !important;
}

.p48pc {
  padding: 48% !important;
}

.p49pc {
  padding: 49% !important;
}

.p50pc {
  padding: 50% !important;
}

.p51pc {
  padding: 51% !important;
}

.p52pc {
  padding: 52% !important;
}

.p53pc {
  padding: 53% !important;
}

.p54pc {
  padding: 54% !important;
}

.p55pc {
  padding: 55% !important;
}

.p56pc {
  padding: 56% !important;
}

.p57pc {
  padding: 57% !important;
}

.p58pc {
  padding: 58% !important;
}

.p59pc {
  padding: 59% !important;
}

.p60pc {
  padding: 60% !important;
}

.p61pc {
  padding: 61% !important;
}

.p62pc {
  padding: 62% !important;
}

.p63pc {
  padding: 63% !important;
}

.p64pc {
  padding: 64% !important;
}

.p65pc {
  padding: 65% !important;
}

.p66pc {
  padding: 66% !important;
}

.p67pc {
  padding: 67% !important;
}

.p68pc {
  padding: 68% !important;
}

.p69pc {
  padding: 69% !important;
}

.p70pc {
  padding: 70% !important;
}

.p71pc {
  padding: 71% !important;
}

.p72pc {
  padding: 72% !important;
}

.p73pc {
  padding: 73% !important;
}

.p74pc {
  padding: 74% !important;
}

.p75pc {
  padding: 75% !important;
}

.p76pc {
  padding: 76% !important;
}

.p77pc {
  padding: 77% !important;
}

.p78pc {
  padding: 78% !important;
}

.p79pc {
  padding: 79% !important;
}

.p80pc {
  padding: 80% !important;
}

.p81pc {
  padding: 81% !important;
}

.p82pc {
  padding: 82% !important;
}

.p83pc {
  padding: 83% !important;
}

.p84pc {
  padding: 84% !important;
}

.p85pc {
  padding: 85% !important;
}

.p86pc {
  padding: 86% !important;
}

.p87pc {
  padding: 87% !important;
}

.p88pc {
  padding: 88% !important;
}

.p89pc {
  padding: 89% !important;
}

.p90pc {
  padding: 90% !important;
}

.p91pc {
  padding: 91% !important;
}

.p92pc {
  padding: 92% !important;
}

.p93pc {
  padding: 93% !important;
}

.p94pc {
  padding: 94% !important;
}

.p95pc {
  padding: 95% !important;
}

.p96pc {
  padding: 96% !important;
}

.p97pc {
  padding: 97% !important;
}

.p98pc {
  padding: 98% !important;
}

.p99pc {
  padding: 99% !important;
}

.p100pc {
  padding: 100% !important;
}

/*PADDING - TOP*/
.pt00pc {
  padding-top: 0% !important;
}

.pt01pc {
  padding-top: 1% !important;
}

.pt02pc {
  padding-top: 2% !important;
}

.pt03pc {
  padding-top: 3% !important;
}

.pt04pc {
  padding-top: 4% !important;
}

.pt05pc {
  padding-top: 5% !important;
}

.pt06pc {
  padding-top: 6% !important;
}

.pt07pc {
  padding-top: 7% !important;
}

.pt08pc {
  padding-top: 8% !important;
}

.pt09pc {
  padding-top: 9% !important;
}

.pt10pc {
  padding-top: 10% !important;
}

.pt11pc {
  padding-top: 11% !important;
}

.pt12pc {
  padding-top: 12% !important;
}

.pt13pc {
  padding-top: 13% !important;
}

.pt14pc {
  padding-top: 14% !important;
}

.pt15pc {
  padding-top: 15% !important;
}

.pt16pc {
  padding-top: 16% !important;
}

.pt17pc {
  padding-top: 17% !important;
}

.pt18pc {
  padding-top: 18% !important;
}

.pt19pc {
  padding-top: 19% !important;
}

.pt20pc {
  padding-top: 20% !important;
}

.pt21pc {
  padding-top: 21% !important;
}

.pt22pc {
  padding-top: 22% !important;
}

.pt23pc {
  padding-top: 23% !important;
}

.pt24pc {
  padding-top: 24% !important;
}

.pt25pc {
  padding-top: 25% !important;
}

.pt26pc {
  padding-top: 26% !important;
}

.pt27pc {
  padding-top: 27% !important;
}

.pt28pc {
  padding-top: 28% !important;
}

.pt29pc {
  padding-top: 29% !important;
}

.pt30pc {
  padding-top: 30% !important;
}

.pt31pc {
  padding-top: 31% !important;
}

.pt32pc {
  padding-top: 32% !important;
}

.pt33pc {
  padding-top: 33% !important;
}

.pt34pc {
  padding-top: 34% !important;
}

.pt35pc {
  padding-top: 35% !important;
}

.pt36pc {
  padding-top: 36% !important;
}

.pt37pc {
  padding-top: 37% !important;
}

.pt38pc {
  padding-top: 38% !important;
}

.pt39pc {
  padding-top: 39% !important;
}

.pt40pc {
  padding-top: 40% !important;
}

.pt41pc {
  padding-top: 41% !important;
}

.pt42pc {
  padding-top: 42% !important;
}

.pt43pc {
  padding-top: 43% !important;
}

.pt44pc {
  padding-top: 44% !important;
}

.pt45pc {
  padding-top: 45% !important;
}

.pt46pc {
  padding-top: 46% !important;
}

.pt47pc {
  padding-top: 47% !important;
}

.pt48pc {
  padding-top: 48% !important;
}

.pt49pc {
  padding-top: 49% !important;
}

.pt50pc {
  padding-top: 50% !important;
}

.pt51pc {
  padding-top: 51% !important;
}

.pt52pc {
  padding-top: 52% !important;
}

.pt53pc {
  padding-top: 53% !important;
}

.pt54pc {
  padding-top: 54% !important;
}

.pt55pc {
  padding-top: 55% !important;
}

.pt56pc {
  padding-top: 56% !important;
}

.pt57pc {
  padding-top: 57% !important;
}

.pt58pc {
  padding-top: 58% !important;
}

.pt59pc {
  padding-top: 59% !important;
}

.pt60pc {
  padding-top: 60% !important;
}

.pt61pc {
  padding-top: 61% !important;
}

.pt62pc {
  padding-top: 62% !important;
}

.pt63pc {
  padding-top: 63% !important;
}

.pt64pc {
  padding-top: 64% !important;
}

.pt65pc {
  padding-top: 65% !important;
}

.pt66pc {
  padding-top: 66% !important;
}

.pt67pc {
  padding-top: 67% !important;
}

.pt68pc {
  padding-top: 68% !important;
}

.pt69pc {
  padding-top: 69% !important;
}

.pt70pc {
  padding-top: 70% !important;
}

.pt71pc {
  padding-top: 71% !important;
}

.pt72pc {
  padding-top: 72% !important;
}

.pt73pc {
  padding-top: 73% !important;
}

.pt74pc {
  padding-top: 74% !important;
}

.pt75pc {
  padding-top: 75% !important;
}

.pt76pc {
  padding-top: 76% !important;
}

.pt77pc {
  padding-top: 77% !important;
}

.pt78pc {
  padding-top: 78% !important;
}

.pt79pc {
  padding-top: 79% !important;
}

.pt80pc {
  padding-top: 80% !important;
}

.pt81pc {
  padding-top: 81% !important;
}

.pt82pc {
  padding-top: 82% !important;
}

.pt83pc {
  padding-top: 83% !important;
}

.pt84pc {
  padding-top: 84% !important;
}

.pt85pc {
  padding-top: 85% !important;
}

.pt86pc {
  padding-top: 86% !important;
}

.pt87pc {
  padding-top: 87% !important;
}

.pt88pc {
  padding-top: 88% !important;
}

.pt89pc {
  padding-top: 89% !important;
}

.pt90pc {
  padding-top: 90% !important;
}

.pt91pc {
  padding-top: 91% !important;
}

.pt92pc {
  padding-top: 92% !important;
}

.pt93pc {
  padding-top: 93% !important;
}

.pt94pc {
  padding-top: 94% !important;
}

.pt95pc {
  padding-top: 95% !important;
}

.pt96pc {
  padding-top: 96% !important;
}

.pt97pc {
  padding-top: 97% !important;
}

.pt98pc {
  padding-top: 98% !important;
}

.pt99pc {
  padding-top: 99% !important;
}

.pt100pc {
  padding-top: 100% !important;
}

/*PADDING - RIGHT*/
.pr00pc {
  padding-right: 0% !important;
}

.pr01pc {
  padding-right: 1% !important;
}

.pr02pc {
  padding-right: 2% !important;
}

.pr03pc {
  padding-right: 3% !important;
}

.pr04pc {
  padding-right: 4% !important;
}

.pr05pc {
  padding-right: 5% !important;
}

.pr06pc {
  padding-right: 6% !important;
}

.pr07pc {
  padding-right: 7% !important;
}

.pr08pc {
  padding-right: 8% !important;
}

.pr09pc {
  padding-right: 9% !important;
}

.pr10pc {
  padding-right: 10% !important;
}

.pr11pc {
  padding-right: 11% !important;
}

.pr12pc {
  padding-right: 12% !important;
}

.pr13pc {
  padding-right: 13% !important;
}

.pr14pc {
  padding-right: 14% !important;
}

.pr15pc {
  padding-right: 15% !important;
}

.pr16pc {
  padding-right: 16% !important;
}

.pr17pc {
  padding-right: 17% !important;
}

.pr18pc {
  padding-right: 18% !important;
}

.pr19pc {
  padding-right: 19% !important;
}

.pr20pc {
  padding-right: 20% !important;
}

.pr21pc {
  padding-right: 21% !important;
}

.pr22pc {
  padding-right: 22% !important;
}

.pr23pc {
  padding-right: 23% !important;
}

.pr24pc {
  padding-right: 24% !important;
}

.pr25pc {
  padding-right: 25% !important;
}

.pr26pc {
  padding-right: 26% !important;
}

.pr27pc {
  padding-right: 27% !important;
}

.pr28pc {
  padding-right: 28% !important;
}

.pr29pc {
  padding-right: 29% !important;
}

.pr30pc {
  padding-right: 30% !important;
}

.pr31pc {
  padding-right: 31% !important;
}

.pr32pc {
  padding-right: 32% !important;
}

.pr33pc {
  padding-right: 33% !important;
}

.pr34pc {
  padding-right: 34% !important;
}

.pr35pc {
  padding-right: 35% !important;
}

.pr36pc {
  padding-right: 36% !important;
}

.pr37pc {
  padding-right: 37% !important;
}

.pr38pc {
  padding-right: 38% !important;
}

.pr39pc {
  padding-right: 39% !important;
}

.pr40pc {
  padding-right: 40% !important;
}

.pr41pc {
  padding-right: 41% !important;
}

.pr42pc {
  padding-right: 42% !important;
}

.pr43pc {
  padding-right: 43% !important;
}

.pr44pc {
  padding-right: 44% !important;
}

.pr45pc {
  padding-right: 45% !important;
}

.pr46pc {
  padding-right: 46% !important;
}

.pr47pc {
  padding-right: 47% !important;
}

.pr48pc {
  padding-right: 48% !important;
}

.pr49pc {
  padding-right: 49% !important;
}

.pr50pc {
  padding-right: 50% !important;
}

.pr51pc {
  padding-right: 51% !important;
}

.pr52pc {
  padding-right: 52% !important;
}

.pr53pc {
  padding-right: 53% !important;
}

.pr54pc {
  padding-right: 54% !important;
}

.pr55pc {
  padding-right: 55% !important;
}

.pr56pc {
  padding-right: 56% !important;
}

.pr57pc {
  padding-right: 57% !important;
}

.pr58pc {
  padding-right: 58% !important;
}

.pr59pc {
  padding-right: 59% !important;
}

.pr60pc {
  padding-right: 60% !important;
}

.pr61pc {
  padding-right: 61% !important;
}

.pr62pc {
  padding-right: 62% !important;
}

.pr63pc {
  padding-right: 63% !important;
}

.pr64pc {
  padding-right: 64% !important;
}

.pr65pc {
  padding-right: 65% !important;
}

.pr66pc {
  padding-right: 66% !important;
}

.pr67pc {
  padding-right: 67% !important;
}

.pr68pc {
  padding-right: 68% !important;
}

.pr69pc {
  padding-right: 69% !important;
}

.pr70pc {
  padding-right: 70% !important;
}

.pr71pc {
  padding-right: 71% !important;
}

.pr72pc {
  padding-right: 72% !important;
}

.pr73pc {
  padding-right: 73% !important;
}

.pr74pc {
  padding-right: 74% !important;
}

.pr75pc {
  padding-right: 75% !important;
}

.pr76pc {
  padding-right: 76% !important;
}

.pr77pc {
  padding-right: 77% !important;
}

.pr78pc {
  padding-right: 78% !important;
}

.pr79pc {
  padding-right: 79% !important;
}

.pr80pc {
  padding-right: 80% !important;
}

.pr81pc {
  padding-right: 81% !important;
}

.pr82pc {
  padding-right: 82% !important;
}

.pr83pc {
  padding-right: 83% !important;
}

.pr84pc {
  padding-right: 84% !important;
}

.pr85pc {
  padding-right: 85% !important;
}

.pr86pc {
  padding-right: 86% !important;
}

.pr87pc {
  padding-right: 87% !important;
}

.pr88pc {
  padding-right: 88% !important;
}

.pr89pc {
  padding-right: 89% !important;
}

.pr90pc {
  padding-right: 90% !important;
}

.pr91pc {
  padding-right: 91% !important;
}

.pr92pc {
  padding-right: 92% !important;
}

.pr93pc {
  padding-right: 93% !important;
}

.pr94pc {
  padding-right: 94% !important;
}

.pr95pc {
  padding-right: 95% !important;
}

.pr96pc {
  padding-right: 96% !important;
}

.pr97pc {
  padding-right: 97% !important;
}

.pr98pc {
  padding-right: 98% !important;
}

.pr99pc {
  padding-right: 99% !important;
}

.pr100pc {
  padding-right: 100% !important;
}

/*PADDING - BOTTOM*/
.pb00pc {
  padding-bottom: 0% !important;
}

.pb01pc {
  padding-bottom: 1% !important;
}

.pb02pc {
  padding-bottom: 2% !important;
}

.pb03pc {
  padding-bottom: 3% !important;
}

.pb04pc {
  padding-bottom: 4% !important;
}

.pb05pc {
  padding-bottom: 5% !important;
}

.pb06pc {
  padding-bottom: 6% !important;
}

.pb07pc {
  padding-bottom: 7% !important;
}

.pb08pc {
  padding-bottom: 8% !important;
}

.pb09pc {
  padding-bottom: 9% !important;
}

.pb10pc {
  padding-bottom: 10% !important;
}

.pb11pc {
  padding-bottom: 11% !important;
}

.pb12pc {
  padding-bottom: 12% !important;
}

.pb13pc {
  padding-bottom: 13% !important;
}

.pb14pc {
  padding-bottom: 14% !important;
}

.pb15pc {
  padding-bottom: 15% !important;
}

.pb16pc {
  padding-bottom: 16% !important;
}

.pb17pc {
  padding-bottom: 17% !important;
}

.pb18pc {
  padding-bottom: 18% !important;
}

.pb19pc {
  padding-bottom: 19% !important;
}

.pb20pc {
  padding-bottom: 20% !important;
}

.pb21pc {
  padding-bottom: 21% !important;
}

.pb22pc {
  padding-bottom: 22% !important;
}

.pb23pc {
  padding-bottom: 23% !important;
}

.pb24pc {
  padding-bottom: 24% !important;
}

.pb25pc {
  padding-bottom: 25% !important;
}

.pb26pc {
  padding-bottom: 26% !important;
}

.pb27pc {
  padding-bottom: 27% !important;
}

.pb28pc {
  padding-bottom: 28% !important;
}

.pb29pc {
  padding-bottom: 29% !important;
}

.pb30pc {
  padding-bottom: 30% !important;
}

.pb31pc {
  padding-bottom: 31% !important;
}

.pb32pc {
  padding-bottom: 32% !important;
}

.pb33pc {
  padding-bottom: 33% !important;
}

.pb34pc {
  padding-bottom: 34% !important;
}

.pb35pc {
  padding-bottom: 35% !important;
}

.pb36pc {
  padding-bottom: 36% !important;
}

.pb37pc {
  padding-bottom: 37% !important;
}

.pb38pc {
  padding-bottom: 38% !important;
}

.pb39pc {
  padding-bottom: 39% !important;
}

.pb40pc {
  padding-bottom: 40% !important;
}

.pb41pc {
  padding-bottom: 41% !important;
}

.pb42pc {
  padding-bottom: 42% !important;
}

.pb43pc {
  padding-bottom: 43% !important;
}

.pb44pc {
  padding-bottom: 44% !important;
}

.pb45pc {
  padding-bottom: 45% !important;
}

.pb46pc {
  padding-bottom: 46% !important;
}

.pb47pc {
  padding-bottom: 47% !important;
}

.pb48pc {
  padding-bottom: 48% !important;
}

.pb49pc {
  padding-bottom: 49% !important;
}

.pb50pc {
  padding-bottom: 50% !important;
}

.pb51pc {
  padding-bottom: 51% !important;
}

.pb52pc {
  padding-bottom: 52% !important;
}

.pb53pc {
  padding-bottom: 53% !important;
}

.pb54pc {
  padding-bottom: 54% !important;
}

.pb55pc {
  padding-bottom: 55% !important;
}

.pb56pc {
  padding-bottom: 56% !important;
}

.pb57pc {
  padding-bottom: 57% !important;
}

.pb58pc {
  padding-bottom: 58% !important;
}

.pb59pc {
  padding-bottom: 59% !important;
}

.pb60pc {
  padding-bottom: 60% !important;
}

.pb61pc {
  padding-bottom: 61% !important;
}

.pb62pc {
  padding-bottom: 62% !important;
}

.pb63pc {
  padding-bottom: 63% !important;
}

.pb64pc {
  padding-bottom: 64% !important;
}

.pb65pc {
  padding-bottom: 65% !important;
}

.pb66pc {
  padding-bottom: 66% !important;
}

.pb67pc {
  padding-bottom: 67% !important;
}

.pb68pc {
  padding-bottom: 68% !important;
}

.pb69pc {
  padding-bottom: 69% !important;
}

.pb70pc {
  padding-bottom: 70% !important;
}

.pb71pc {
  padding-bottom: 71% !important;
}

.pb72pc {
  padding-bottom: 72% !important;
}

.pb73pc {
  padding-bottom: 73% !important;
}

.pb74pc {
  padding-bottom: 74% !important;
}

.pb75pc {
  padding-bottom: 75% !important;
}

.pb76pc {
  padding-bottom: 76% !important;
}

.pb77pc {
  padding-bottom: 77% !important;
}

.pb78pc {
  padding-bottom: 78% !important;
}

.pb79pc {
  padding-bottom: 79% !important;
}

.pb80pc {
  padding-bottom: 80% !important;
}

.pb81pc {
  padding-bottom: 81% !important;
}

.pb82pc {
  padding-bottom: 82% !important;
}

.pb83pc {
  padding-bottom: 83% !important;
}

.pb84pc {
  padding-bottom: 84% !important;
}

.pb85pc {
  padding-bottom: 85% !important;
}

.pb86pc {
  padding-bottom: 86% !important;
}

.pb87pc {
  padding-bottom: 87% !important;
}

.pb88pc {
  padding-bottom: 88% !important;
}

.pb89pc {
  padding-bottom: 89% !important;
}

.pb90pc {
  padding-bottom: 90% !important;
}

.pb91pc {
  padding-bottom: 91% !important;
}

.pb92pc {
  padding-bottom: 92% !important;
}

.pb93pc {
  padding-bottom: 93% !important;
}

.pb94pc {
  padding-bottom: 94% !important;
}

.pb95pc {
  padding-bottom: 95% !important;
}

.pb96pc {
  padding-bottom: 96% !important;
}

.pb97pc {
  padding-bottom: 97% !important;
}

.pb98pc {
  padding-bottom: 98% !important;
}

.pb99pc {
  padding-bottom: 99% !important;
}

.pb100pc {
  padding-bottom: 100% !important;
}

/*PADDING - LEFT*/
.pl0pc {
  padding-left: 0% !important;
}

.pl1pc {
  padding-left: 1% !important;
}

.pl2pc {
  padding-left: 2% !important;
}

.pl3pc {
  padding-left: 3% !important;
}

.pl4pc {
  padding-left: 4% !important;
}

.pl5pc {
  padding-left: 5% !important;
}

.pl6pc {
  padding-left: 6% !important;
}

.pl7pc {
  padding-left: 7% !important;
}

.pl8pc {
  padding-left: 8% !important;
}

.pl9pc {
  padding-left: 9% !important;
}

.pl10pc {
  padding-left: 10% !important;
}

.pl11pc {
  padding-left: 11% !important;
}

.pl12pc {
  padding-left: 12% !important;
}

.pl13pc {
  padding-left: 13% !important;
}

.pl14pc {
  padding-left: 14% !important;
}

.pl15pc {
  padding-left: 15% !important;
}

.pl16pc {
  padding-left: 16% !important;
}

.pl17pc {
  padding-left: 17% !important;
}

.pl18pc {
  padding-left: 18% !important;
}

.pl19pc {
  padding-left: 19% !important;
}

.pl20pc {
  padding-left: 20% !important;
}

.pl21pc {
  padding-left: 21% !important;
}

.pl22pc {
  padding-left: 22% !important;
}

.pl23pc {
  padding-left: 23% !important;
}

.pl24pc {
  padding-left: 24% !important;
}

.pl25pc {
  padding-left: 25% !important;
}

.pl26pc {
  padding-left: 26% !important;
}

.pl27pc {
  padding-left: 27% !important;
}

.pl28pc {
  padding-left: 28% !important;
}

.pl29pc {
  padding-left: 29% !important;
}

.pl30pc {
  padding-left: 30% !important;
}

.pl31pc {
  padding-left: 31% !important;
}

.pl32pc {
  padding-left: 32% !important;
}

.pl33pc {
  padding-left: 33% !important;
}

.pl34pc {
  padding-left: 34% !important;
}

.pl35pc {
  padding-left: 35% !important;
}

.pl36pc {
  padding-left: 36% !important;
}

.pl37pc {
  padding-left: 37% !important;
}

.pl38pc {
  padding-left: 38% !important;
}

.pl39pc {
  padding-left: 39% !important;
}

.pl40pc {
  padding-left: 40% !important;
}

.pl41pc {
  padding-left: 41% !important;
}

.pl42pc {
  padding-left: 42% !important;
}

.pl43pc {
  padding-left: 43% !important;
}

.pl44pc {
  padding-left: 44% !important;
}

.pl45pc {
  padding-left: 45% !important;
}

.pl46pc {
  padding-left: 46% !important;
}

.pl47pc {
  padding-left: 47% !important;
}

.pl48pc {
  padding-left: 48% !important;
}

.pl49pc {
  padding-left: 49% !important;
}

.pl50pc {
  padding-left: 50% !important;
}

.pl51pc {
  padding-left: 51% !important;
}

.pl52pc {
  padding-left: 52% !important;
}

.pl53pc {
  padding-left: 53% !important;
}

.pl54pc {
  padding-left: 54% !important;
}

.pl55pc {
  padding-left: 55% !important;
}

.pl56pc {
  padding-left: 56% !important;
}

.pl57pc {
  padding-left: 57% !important;
}

.pl58pc {
  padding-left: 58% !important;
}

.pl59pc {
  padding-left: 59% !important;
}

.pl60pc {
  padding-left: 60% !important;
}

.pl61pc {
  padding-left: 61% !important;
}

.pl62pc {
  padding-left: 62% !important;
}

.pl63pc {
  padding-left: 63% !important;
}

.pl64pc {
  padding-left: 64% !important;
}

.pl65pc {
  padding-left: 65% !important;
}

.pl66pc {
  padding-left: 66% !important;
}

.pl67pc {
  padding-left: 67% !important;
}

.pl68pc {
  padding-left: 68% !important;
}

.pl69pc {
  padding-left: 69% !important;
}

.pl70pc {
  padding-left: 70% !important;
}

.pl71pc {
  padding-left: 71% !important;
}

.pl72pc {
  padding-left: 72% !important;
}

.pl73pc {
  padding-left: 73% !important;
}

.pl74pc {
  padding-left: 74% !important;
}

.pl75pc {
  padding-left: 75% !important;
}

.pl76pc {
  padding-left: 76% !important;
}

.pl77pc {
  padding-left: 77% !important;
}

.pl78pc {
  padding-left: 78% !important;
}

.pl79pc {
  padding-left: 79% !important;
}

.pl80pc {
  padding-left: 80% !important;
}

.pl81pc {
  padding-left: 81% !important;
}

.pl82pc {
  padding-left: 82% !important;
}

.pl83pc {
  padding-left: 83% !important;
}

.pl84pc {
  padding-left: 84% !important;
}

.pl85pc {
  padding-left: 85% !important;
}

.pl86pc {
  padding-left: 86% !important;
}

.pl87pc {
  padding-left: 87% !important;
}

.pl88pc {
  padding-left: 88% !important;
}

.pl89pc {
  padding-left: 89% !important;
}

.pl90pc {
  padding-left: 90% !important;
}

.pl91pc {
  padding-left: 91% !important;
}

.pl92pc {
  padding-left: 92% !important;
}

.pl93pc {
  padding-left: 93% !important;
}

.pl94pc {
  padding-left: 94% !important;
}

.pl95pc {
  padding-left: 95% !important;
}

.pl96pc {
  padding-left: 96% !important;
}

.pl97pc {
  padding-left: 97% !important;
}

.pl98pc {
  padding-left: 98% !important;
}

.pl99pc {
  padding-left: 99% !important;
}

.pl100pc {
  padding-left: 100% !important;
}

/*====================================================================================
笆�05.w DEFAULT - PIXEL
====================================================================================*/
.w00 {
  width: 0px !important;
}

.w01 {
  width: 1px !important;
}

.w02 {
  width: 2px !important;
}

.w03 {
  width: 3px !important;
}

.w04 {
  width: 4px !important;
}

.w05 {
  width: 5px !important;
}

.w06 {
  width: 6px !important;
}

.w07 {
  width: 7px !important;
}

.w08 {
  width: 8px !important;
}

.w09 {
  width: 9px !important;
}

.w10 {
  width: 10px !important;
}

.w11 {
  width: 11px !important;
}

.w12 {
  width: 12px !important;
}

.w13 {
  width: 13px !important;
}

.w14 {
  width: 14px !important;
}

.w15 {
  width: 15px !important;
}

.w16 {
  width: 16px !important;
}

.w17 {
  width: 17px !important;
}

.w18 {
  width: 18px !important;
}

.w19 {
  width: 19px !important;
}

.w20 {
  width: 20px !important;
}

.w21 {
  width: 21px !important;
}

.w22 {
  width: 22px !important;
}

.w23 {
  width: 23px !important;
}

.w24 {
  width: 24px !important;
}

.w25 {
  width: 25px !important;
}

.w26 {
  width: 26px !important;
}

.w27 {
  width: 27px !important;
}

.w28 {
  width: 28px !important;
}

.w29 {
  width: 29px !important;
}

.w30 {
  width: 30px !important;
}

.w31 {
  width: 31px !important;
}

.w32 {
  width: 32px !important;
}

.w33 {
  width: 33px !important;
}

.w34 {
  width: 34px !important;
}

.w35 {
  width: 35px !important;
}

.w36 {
  width: 36px !important;
}

.w37 {
  width: 37px !important;
}

.w38 {
  width: 38px !important;
}

.w39 {
  width: 39px !important;
}

.w40 {
  width: 40px !important;
}

.w41 {
  width: 41px !important;
}

.w42 {
  width: 42px !important;
}

.w43 {
  width: 43px !important;
}

.w44 {
  width: 44px !important;
}

.w45 {
  width: 45px !important;
}

.w46 {
  width: 46px !important;
}

.w47 {
  width: 47px !important;
}

.w48 {
  width: 48px !important;
}

.w49 {
  width: 49px !important;
}

.w50 {
  width: 50px !important;
}

.w51 {
  width: 51px !important;
}

.w52 {
  width: 52px !important;
}

.w53 {
  width: 53px !important;
}

.w54 {
  width: 54px !important;
}

.w55 {
  width: 55px !important;
}

.w56 {
  width: 56px !important;
}

.w57 {
  width: 57px !important;
}

.w58 {
  width: 58px !important;
}

.w59 {
  width: 59px !important;
}

.w60 {
  width: 60px !important;
}

.w61 {
  width: 61px !important;
}

.w62 {
  width: 62px !important;
}

.w63 {
  width: 63px !important;
}

.w64 {
  width: 64px !important;
}

.w65 {
  width: 65px !important;
}

.w66 {
  width: 66px !important;
}

.w67 {
  width: 67px !important;
}

.w68 {
  width: 68px !important;
}

.w69 {
  width: 69px !important;
}

.w70 {
  width: 70px !important;
}

.w71 {
  width: 71px !important;
}

.w72 {
  width: 72px !important;
}

.w73 {
  width: 73px !important;
}

.w74 {
  width: 74px !important;
}

.w75 {
  width: 75px !important;
}

.w76 {
  width: 76px !important;
}

.w77 {
  width: 77px !important;
}

.w78 {
  width: 78px !important;
}

.w79 {
  width: 79px !important;
}

.w80 {
  width: 80px !important;
}

.w81 {
  width: 81px !important;
}

.w82 {
  width: 82px !important;
}

.w83 {
  width: 83px !important;
}

.w84 {
  width: 84px !important;
}

.w85 {
  width: 85px !important;
}

.w86 {
  width: 86px !important;
}

.w87 {
  width: 87px !important;
}

.w88 {
  width: 88px !important;
}

.w89 {
  width: 89px !important;
}

.w90 {
  width: 90px !important;
}

.w91 {
  width: 91px !important;
}

.w92 {
  width: 92px !important;
}

.w93 {
  width: 93px !important;
}

.w94 {
  width: 94px !important;
}

.w95 {
  width: 95px !important;
}

.w96 {
  width: 96px !important;
}

.w97 {
  width: 97px !important;
}

.w98 {
  width: 98px !important;
}

.w99 {
  width: 99px !important;
}

.w100 {
  width: 100px !important;
}

/*====================================================================================
笆�06.w DEFAULT - PERCENT
====================================================================================*/
.w00pc {
  width: 0% !important;
}

.w01pc {
  width: 1% !important;
}

.w02pc {
  width: 2% !important;
}

.w03pc {
  width: 3% !important;
}

.w04pc {
  width: 4% !important;
}

.w05pc {
  width: 5% !important;
}

.w06pc {
  width: 6% !important;
}

.w07pc {
  width: 7% !important;
}

.w08pc {
  width: 8% !important;
}

.w09pc {
  width: 9% !important;
}

.w10pc {
  width: 10% !important;
}

.w11pc {
  width: 11% !important;
}

.w12pc {
  width: 12% !important;
}

.w13pc {
  width: 13% !important;
}

.w14pc {
  width: 14% !important;
}

.w15pc {
  width: 15% !important;
}

.w16pc {
  width: 16% !important;
}

.w17pc {
  width: 17% !important;
}

.w18pc {
  width: 18% !important;
}

.w19pc {
  width: 19% !important;
}

.w20pc {
  width: 20% !important;
}

.w21pc {
  width: 21% !important;
}

.w22pc {
  width: 22% !important;
}

.w23pc {
  width: 23% !important;
}

.w24pc {
  width: 24% !important;
}

.w25pc {
  width: 25% !important;
}

.w26pc {
  width: 26% !important;
}

.w27pc {
  width: 27% !important;
}

.w28pc {
  width: 28% !important;
}

.w29pc {
  width: 29% !important;
}

.w30pc {
  width: 30% !important;
}

.w31pc {
  width: 31% !important;
}

.w32pc {
  width: 32% !important;
}

.w33pc {
  width: 33% !important;
}

.w34pc {
  width: 34% !important;
}

.w35pc {
  width: 35% !important;
}

.w36pc {
  width: 36% !important;
}

.w37pc {
  width: 37% !important;
}

.w38pc {
  width: 38% !important;
}

.w39pc {
  width: 39% !important;
}

.w40pc {
  width: 40% !important;
}

.w41pc {
  width: 41% !important;
}

.w42pc {
  width: 42% !important;
}

.w43pc {
  width: 43% !important;
}

.w44pc {
  width: 44% !important;
}

.w45pc {
  width: 45% !important;
}

.w46pc {
  width: 46% !important;
}

.w47pc {
  width: 47% !important;
}

.w48pc {
  width: 48% !important;
}

.w49pc {
  width: 49% !important;
}

.w50pc {
  width: 50% !important;
}

.w51pc {
  width: 51% !important;
}

.w52pc {
  width: 52% !important;
}

.w53pc {
  width: 53% !important;
}

.w54pc {
  width: 54% !important;
}

.w55pc {
  width: 55% !important;
}

.w56pc {
  width: 56% !important;
}

.w57pc {
  width: 57% !important;
}

.w58pc {
  width: 58% !important;
}

.w59pc {
  width: 59% !important;
}

.w60pc {
  width: 60% !important;
}

.w61pc {
  width: 61% !important;
}

.w62pc {
  width: 62% !important;
}

.w63pc {
  width: 63% !important;
}

.w64pc {
  width: 64% !important;
}

.w65pc {
  width: 65% !important;
}

.w66pc {
  width: 66% !important;
}

.w67pc {
  width: 67% !important;
}

.w68pc {
  width: 68% !important;
}

.w69pc {
  width: 69% !important;
}

.w70pc {
  width: 70% !important;
}

.w71pc {
  width: 71% !important;
}

.w72pc {
  width: 72% !important;
}

.w73pc {
  width: 73% !important;
}

.w74pc {
  width: 74% !important;
}

.w75pc {
  width: 75% !important;
}

.w76pc {
  width: 76% !important;
}

.w77pc {
  width: 77% !important;
}

.w78pc {
  width: 78% !important;
}

.w79pc {
  width: 79% !important;
}

.w80pc {
  width: 80% !important;
}

.w81pc {
  width: 81% !important;
}

.w82pc {
  width: 82% !important;
}

.w83pc {
  width: 83% !important;
}

.w84pc {
  width: 84% !important;
}

.w85pc {
  width: 85% !important;
}

.w86pc {
  width: 86% !important;
}

.w87pc {
  width: 87% !important;
}

.w88pc {
  width: 88% !important;
}

.w89pc {
  width: 89% !important;
}

.w90pc {
  width: 90% !important;
}

.w91pc {
  width: 91% !important;
}

.w92pc {
  width: 92% !important;
}

.w93pc {
  width: 93% !important;
}

.w94pc {
  width: 94% !important;
}

.w95pc {
  width: 95% !important;
}

.w96pc {
  width: 96% !important;
}

.w97pc {
  width: 97% !important;
}

.w98pc {
  width: 98% !important;
}

.w99pc {
  width: 99% !important;
}

.w100pc {
  width: 100% !important;
}

/*====================================================================================
笆�07.HEIGHT DEFAULT - PIXEL
====================================================================================*/
.h00 {
  height: 0px !important;
}

.h01 {
  height: 1px !important;
}

.h02 {
  height: 2px !important;
}

.h03 {
  height: 3px !important;
}

.h04 {
  height: 4px !important;
}

.h05 {
  height: 5px !important;
}

.h06 {
  height: 6px !important;
}

.h07 {
  height: 7px !important;
}

.h08 {
  height: 8px !important;
}

.h09 {
  height: 9px !important;
}

.h10 {
  height: 10px !important;
}

.h11 {
  height: 11px !important;
}

.h12 {
  height: 12px !important;
}

.h13 {
  height: 13px !important;
}

.h14 {
  height: 14px !important;
}

.h15 {
  height: 15px !important;
}

.h16 {
  height: 16px !important;
}

.h17 {
  height: 17px !important;
}

.h18 {
  height: 18px !important;
}

.h19 {
  height: 19px !important;
}

.h20 {
  height: 20px !important;
}

.h21 {
  height: 21px !important;
}

.h22 {
  height: 22px !important;
}

.h23 {
  height: 23px !important;
}

.h24 {
  height: 24px !important;
}

.h25 {
  height: 25px !important;
}

.h26 {
  height: 26px !important;
}

.h27 {
  height: 27px !important;
}

.h28 {
  height: 28px !important;
}

.h29 {
  height: 29px !important;
}

.h30 {
  height: 30px !important;
}

.h31 {
  height: 31px !important;
}

.h32 {
  height: 32px !important;
}

.h33 {
  height: 33px !important;
}

.h34 {
  height: 34px !important;
}

.h35 {
  height: 35px !important;
}

.h36 {
  height: 36px !important;
}

.h37 {
  height: 37px !important;
}

.h38 {
  height: 38px !important;
}

.h39 {
  height: 39px !important;
}

.h40 {
  height: 40px !important;
}

.h41 {
  height: 41px !important;
}

.h42 {
  height: 42px !important;
}

.h43 {
  height: 43px !important;
}

.h44 {
  height: 44px !important;
}

.h45 {
  height: 45px !important;
}

.h46 {
  height: 46px !important;
}

.h47 {
  height: 47px !important;
}

.h48 {
  height: 48px !important;
}

.h49 {
  height: 49px !important;
}

.h50 {
  height: 50px !important;
}

.h51 {
  height: 51px !important;
}

.h52 {
  height: 52px !important;
}

.h53 {
  height: 53px !important;
}

.h54 {
  height: 54px !important;
}

.h55 {
  height: 55px !important;
}

.h56 {
  height: 56px !important;
}

.h57 {
  height: 57px !important;
}

.h58 {
  height: 58px !important;
}

.h59 {
  height: 59px !important;
}

.h60 {
  height: 60px !important;
}

.h61 {
  height: 61px !important;
}

.h62 {
  height: 62px !important;
}

.h63 {
  height: 63px !important;
}

.h64 {
  height: 64px !important;
}

.h65 {
  height: 65px !important;
}

.h66 {
  height: 66px !important;
}

.h67 {
  height: 67px !important;
}

.h68 {
  height: 68px !important;
}

.h69 {
  height: 69px !important;
}

.h70 {
  height: 70px !important;
}

.h71 {
  height: 71px !important;
}

.h72 {
  height: 72px !important;
}

.h73 {
  height: 73px !important;
}

.h74 {
  height: 74px !important;
}

.h75 {
  height: 75px !important;
}

.h76 {
  height: 76px !important;
}

.h77 {
  height: 77px !important;
}

.h78 {
  height: 78px !important;
}

.h79 {
  height: 79px !important;
}

.h80 {
  height: 80px !important;
}

.h81 {
  height: 81px !important;
}

.h82 {
  height: 82px !important;
}

.h83 {
  height: 83px !important;
}

.h84 {
  height: 84px !important;
}

.h85 {
  height: 85px !important;
}

.h86 {
  height: 86px !important;
}

.h87 {
  height: 87px !important;
}

.h88 {
  height: 88px !important;
}

.h89 {
  height: 89px !important;
}

.h90 {
  height: 90px !important;
}

.h91 {
  height: 91px !important;
}

.h92 {
  height: 92px !important;
}

.h93 {
  height: 93px !important;
}

.h94 {
  height: 94px !important;
}

.h95 {
  height: 95px !important;
}

.h96 {
  height: 96px !important;
}

.h97 {
  height: 97px !important;
}

.h98 {
  height: 98px !important;
}

.h99 {
  height: 99px !important;
}

/*====================================================================================
笆�08.HEIGHT DEFAULT - PERCENT
====================================================================================*/
.h00pc {
  height: 0% !important;
}

.h01pc {
  height: 1% !important;
}

.h02pc {
  height: 2% !important;
}

.h03pc {
  height: 3% !important;
}

.h04pc {
  height: 4% !important;
}

.h05pc {
  height: 5% !important;
}

.h06pc {
  height: 6% !important;
}

.h07pc {
  height: 7% !important;
}

.h08pc {
  height: 8% !important;
}

.h09pc {
  height: 9% !important;
}

.h10pc {
  height: 10% !important;
}

.h11pc {
  height: 11% !important;
}

.h12pc {
  height: 12% !important;
}

.h13pc {
  height: 13% !important;
}

.h14pc {
  height: 14% !important;
}

.h15pc {
  height: 15% !important;
}

.h16pc {
  height: 16% !important;
}

.h17pc {
  height: 17% !important;
}

.h18pc {
  height: 18% !important;
}

.h19pc {
  height: 19% !important;
}

.h20pc {
  height: 20% !important;
}

.h21pc {
  height: 21% !important;
}

.h22pc {
  height: 22% !important;
}

.h23pc {
  height: 23% !important;
}

.h24pc {
  height: 24% !important;
}

.h25pc {
  height: 25% !important;
}

.h26pc {
  height: 26% !important;
}

.h27pc {
  height: 27% !important;
}

.h28pc {
  height: 28% !important;
}

.h29pc {
  height: 29% !important;
}

.h30pc {
  height: 30% !important;
}

.h31pc {
  height: 31% !important;
}

.h32pc {
  height: 32% !important;
}

.h33pc {
  height: 33% !important;
}

.h34pc {
  height: 34% !important;
}

.h35pc {
  height: 35% !important;
}

.h36pc {
  height: 36% !important;
}

.h37pc {
  height: 37% !important;
}

.h38pc {
  height: 38% !important;
}

.h39pc {
  height: 39% !important;
}

.h40pc {
  height: 40% !important;
}

.h41pc {
  height: 41% !important;
}

.h42pc {
  height: 42% !important;
}

.h43pc {
  height: 43% !important;
}

.h44pc {
  height: 44% !important;
}

.h45pc {
  height: 45% !important;
}

.h46pc {
  height: 46% !important;
}

.h47pc {
  height: 47% !important;
}

.h48pc {
  height: 48% !important;
}

.h49pc {
  height: 49% !important;
}

.h50pc {
  height: 50% !important;
}

.h51pc {
  height: 51% !important;
}

.h52pc {
  height: 52% !important;
}

.h53pc {
  height: 53% !important;
}

.h54pc {
  height: 54% !important;
}

.h55pc {
  height: 55% !important;
}

.h56pc {
  height: 56% !important;
}

.h57pc {
  height: 57% !important;
}

.h58pc {
  height: 58% !important;
}

.h59pc {
  height: 59% !important;
}

.h60pc {
  height: 60% !important;
}

.h61pc {
  height: 61% !important;
}

.h62pc {
  height: 62% !important;
}

.h63pc {
  height: 63% !important;
}

.h64pc {
  height: 64% !important;
}

.h65pc {
  height: 65% !important;
}

.h66pc {
  height: 66% !important;
}

.h67pc {
  height: 67% !important;
}

.h68pc {
  height: 68% !important;
}

.h69pc {
  height: 69% !important;
}

.h70pc {
  height: 70% !important;
}

.h71pc {
  height: 71% !important;
}

.h72pc {
  height: 72% !important;
}

.h73pc {
  height: 73% !important;
}

.h74pc {
  height: 74% !important;
}

.h75pc {
  height: 75% !important;
}

.h76pc {
  height: 76% !important;
}

.h77pc {
  height: 77% !important;
}

.h78pc {
  height: 78% !important;
}

.h79pc {
  height: 79% !important;
}

.h80pc {
  height: 80% !important;
}

.h81pc {
  height: 81% !important;
}

.h82pc {
  height: 82% !important;
}

.h83pc {
  height: 83% !important;
}

.h84pc {
  height: 84% !important;
}

.h85pc {
  height: 85% !important;
}

.h86pc {
  height: 86% !important;
}

.h87pc {
  height: 87% !important;
}

.h88pc {
  height: 88% !important;
}

.h89pc {
  height: 89% !important;
}

.h90pc {
  height: 90% !important;
}

.h91pc {
  height: 91% !important;
}

.h92pc {
  height: 92% !important;
}

.h93pc {
  height: 93% !important;
}

.h94pc {
  height: 94% !important;
}

.h95pc {
  height: 95% !important;
}

.h96pc {
  height: 96% !important;
}

.h97pc {
  height: 97% !important;
}

.h98pc {
  height: 98% !important;
}

.h99pc {
  height: 99% !important;
}

.h100pc {
  height: 100% !important;
}

/*====================================================================================
笆�09.f-SIZE
====================================================================================*/
.f00 {
  font-size: 0px !important;
}

.f01 {
  font-size: 1px !important;
}

.f02 {
  font-size: 2px !important;
}

.f03 {
  font-size: 3px !important;
}

.f04 {
  font-size: 4px !important;
}

.f05 {
  font-size: 5px !important;
}

.f06 {
  font-size: 6px !important;
}

.f07 {
  font-size: 7px !important;
}

.f08 {
  font-size: 8px !important;
}

.f09 {
  font-size: 9px !important;
}

.f10 {
  font-size: 10px !important;
}

.f11 {
  font-size: 11px !important;
}

.f12 {
  font-size: 12px !important;
}

.f13 {
  font-size: 13px !important;
}

.f14 {
  font-size: 14px !important;
}

.f15 {
  font-size: 15px !important;
}

.f16 {
  font-size: 16px !important;
}

.f17 {
  font-size: 17px !important;
}

.f18 {
  font-size: 18px !important;
}

.f19 {
  font-size: 19px !important;
}

.f20 {
  font-size: 20px !important;
}

.f21 {
  font-size: 21px !important;
}

.f22 {
  font-size: 22px !important;
}

.f23 {
  font-size: 23px !important;
}

.f24 {
  font-size: 24px !important;
}

.f25 {
  font-size: 25px !important;
}

.f26 {
  font-size: 26px !important;
}

.f27 {
  font-size: 27px !important;
}

.f28 {
  font-size: 28px !important;
}

.f29 {
  font-size: 29px !important;
}

.f30 {
  font-size: 30px !important;
}

.f31 {
  font-size: 31px !important;
}

.f32 {
  font-size: 32px !important;
}

.f33 {
  font-size: 33px !important;
}

.f34 {
  font-size: 34px !important;
}

.f35 {
  font-size: 35px !important;
}

.f36 {
  font-size: 36px !important;
}

.f37 {
  font-size: 37px !important;
}

.f38 {
  font-size: 38px !important;
}

.f39 {
  font-size: 39px !important;
}

.f40 {
  font-size: 40px !important;
}

.f41 {
  font-size: 41px !important;
}

.f42 {
  font-size: 42px !important;
}

.f43 {
  font-size: 43px !important;
}

.f44 {
  font-size: 44px !important;
}

.f45 {
  font-size: 45px !important;
}

.f46 {
  font-size: 46px !important;
}

.f47 {
  font-size: 47px !important;
}

.f48 {
  font-size: 48px !important;
}

.f49 {
  font-size: 49px !important;
}

.f50 {
  font-size: 50px !important;
}

.f51 {
  font-size: 51px !important;
}

.f52 {
  font-size: 52px !important;
}

.f53 {
  font-size: 53px !important;
}

.f54 {
  font-size: 54px !important;
}

.f55 {
  font-size: 55px !important;
}

/*====================================================================================
笆�10.BORDER-RADIUS
====================================================================================*/
.bdr_rds00 {
  border-radius: 0px !important;
}

.bdr_rds01 {
  border-radius: 1px !important;
}

.bdr_rds02 {
  border-radius: 2px !important;
}

.bdr_rds03 {
  border-radius: 3px !important;
}

.bdr_rds04 {
  border-radius: 4px !important;
}

.bdr_rds05 {
  border-radius: 5px !important;
}

.bdr_rds06 {
  border-radius: 6px !important;
}

.bdr_rds07 {
  border-radius: 7px !important;
}

.bdr_rds08 {
  border-radius: 8px !important;
}

.bdr_rds09 {
  border-radius: 9px !important;
}

.bdr_rds10 {
  border-radius: 10px !important;
}

.bdr_rds11 {
  border-radius: 11px !important;
}

.bdr_rds12 {
  border-radius: 12px !important;
}

.bdr_rds13 {
  border-radius: 13px !important;
}

.bdr_rds14 {
  border-radius: 14px !important;
}

.bdr_rds15 {
  border-radius: 15px !important;
}

.bdr_rds16 {
  border-radius: 16px !important;
}

.bdr_rds17 {
  border-radius: 17px !important;
}

.bdr_rds18 {
  border-radius: 18px !important;
}

.bdr_rds19 {
  border-radius: 19px !important;
}

.bdr_rds20 {
  border-radius: 20px !important;
}

.bdr_rds21 {
  border-radius: 21px !important;
}

.bdr_rds22 {
  border-radius: 22px !important;
}

.bdr_rds23 {
  border-radius: 23px !important;
}

.bdr_rds24 {
  border-radius: 24px !important;
}

.bdr_rds25 {
  border-radius: 25px !important;
}

.bdr_rds26 {
  border-radius: 26px !important;
}

.bdr_rds27 {
  border-radius: 27px !important;
}

.bdr_rds28 {
  border-radius: 28px !important;
}

.bdr_rds29 {
  border-radius: 29px !important;
}

.bdr_rds30 {
  border-radius: 30px !important;
}

.bdr_rds31 {
  border-radius: 31px !important;
}

.bdr_rds32 {
  border-radius: 32px !important;
}

.bdr_rds33 {
  border-radius: 33px !important;
}

.bdr_rds34 {
  border-radius: 34px !important;
}

.bdr_rds35 {
  border-radius: 35px !important;
}

.bdr_rds36 {
  border-radius: 36px !important;
}

.bdr_rds37 {
  border-radius: 37px !important;
}

.bdr_rds38 {
  border-radius: 38px !important;
}

.bdr_rds39 {
  border-radius: 39px !important;
}

.bdr_rds40 {
  border-radius: 40px !important;
}

.bdr_rds41 {
  border-radius: 41px !important;
}

.bdr_rds42 {
  border-radius: 42px !important;
}

.bdr_rds43 {
  border-radius: 43px !important;
}

.bdr_rds44 {
  border-radius: 44px !important;
}

.bdr_rds45 {
  border-radius: 45px !important;
}

.bdr_rds46 {
  border-radius: 46px !important;
}

.bdr_rds47 {
  border-radius: 47px !important;
}

.bdr_rds48 {
  border-radius: 48px !important;
}

.bdr_rds49 {
  border-radius: 49px !important;
}

.bdr_rds50 {
  border-radius: 50px !important;
}

/*====================================================================================
笆�11.LETTER SPACING
====================================================================================*/
.letter_spc0 {
  letter-spacing: 0px !important;
}

.letter_spc1 {
  letter-spacing: 1px !important;
}

.letter_spc2 {
  letter-spacing: 2px !important;
}

.letter_spc3 {
  letter-spacing: 3px !important;
}

.letter_spc4 {
  letter-spacing: 4px !important;
}

.letter_spc5 {
  letter-spacing: 5px !important;
}

.letter_spc6 {
  letter-spacing: 6px !important;
}

.letter_spc7 {
  letter-spacing: 7px !important;
}

.letter_spc8 {
  letter-spacing: 8px !important;
}

.letter_spc9 {
  letter-spacing: 9px !important;
}

.letter_spc10 {
  letter-spacing: 10px !important;
}

/*====================================================================================
笆�12.LETTER SPACING - NEGATIVE
====================================================================================*/
.letter_spc_negative00 {
  letter-spacing: 0px !important;
}

.letter_spc_negative01 {
  letter-spacing: -1px !important;
}

.letter_spc_negative02 {
  letter-spacing: -2px !important;
}

.letter_spc_negative03 {
  letter-spacing: -3px !important;
}

.letter_spc_negative04 {
  letter-spacing: -4px !important;
}

.letter_spc_negative05 {
  letter-spacing: -5px !important;
}

.letter_spc_negative06 {
  letter-spacing: -6px !important;
}

.letter_spc_negative07 {
  letter-spacing: -7px !important;
}

.letter_spc_negative08 {
  letter-spacing: -8px !important;
}

.letter_spc_negative09 {
  letter-spacing: -9px !important;
}

.letter_spc_negative10 {
  letter-spacing: -10px !important;
}

/*====================================================================================
笆�13.LINE-HEIGHT
====================================================================================*/
.lh00 {
  line-height: 0px !important;
}

.lh01 {
  line-height: 0.1px !important;
}

.lh02 {
  line-height: 0.2px !important;
}

.lh03 {
  line-height: 0.3px !important;
}

.lh04 {
  line-height: 0.4px !important;
}

.lh05 {
  line-height: 0.5px !important;
}

.lh06 {
  line-height: 0.6px !important;
}

.lh07 {
  line-height: 0.7px !important;
}

.lh08 {
  line-height: 0.8px !important;
}

.lh09 {
  line-height: 0.9px !important;
}

.lh10 {
  line-height: 1px !important;
}

.lh11 {
  line-height: 1.1px !important;
}

.lh12 {
  line-height: 1.2px !important;
}

.lh13 {
  line-height: 1.3px !important;
}

.lh14 {
  line-height: 1.4px !important;
}

.lh15 {
  line-height: 1.5px !important;
}

.lh16 {
  line-height: 1.6px !important;
}

.lh17 {
  line-height: 1.7px !important;
}

.lh18 {
  line-height: 1.8px !important;
}

.lh19 {
  line-height: 1.9px !important;
}

.lh20 {
  line-height: 2px !important;
}

.lh21 {
  line-height: 2.1px !important;
}

.lh22 {
  line-height: 2.2px !important;
}

.lh23 {
  line-height: 2.3px !important;
}

.lh24 {
  line-height: 2.4px !important;
}

.lh25 {
  line-height: 2.5px !important;
}

.lh26 {
  line-height: 2.6px !important;
}

.lh27 {
  line-height: 2.7px !important;
}

.lh28 {
  line-height: 2.8px !important;
}

.lh29 {
  line-height: 2.9px !important;
}

.lh30 {
  line-height: 3px !important;
}

.lh31 {
  line-height: 3.1px !important;
}

.lh32 {
  line-height: 3.2px !important;
}

.lh33 {
  line-height: 3.3px !important;
}

.lh34 {
  line-height: 3.4px !important;
}

.lh35 {
  line-height: 3.5px !important;
}

.lh36 {
  line-height: 3.6px !important;
}

.lh37 {
  line-height: 3.7px !important;
}

.lh38 {
  line-height: 3.8px !important;
}

.lh39 {
  line-height: 3.9px !important;
}

.lh40 {
  line-height: 4px !important;
}

.lh41 {
  line-height: 4.1px !important;
}

.lh42 {
  line-height: 4.2px !important;
}

.lh43 {
  line-height: 4.3px !important;
}

.lh44 {
  line-height: 4.4px !important;
}

.lh45 {
  line-height: 4.5px !important;
}

.lh46 {
  line-height: 4.6px !important;
}

.lh47 {
  line-height: 4.7px !important;
}

.lh48 {
  line-height: 4.8px !important;
}

.lh49 {
  line-height: 4.9px !important;
}

.lh50 {
  line-height: 5px !important;
}

/*====================================================================================
笆�14.OTHER
====================================================================================*/
.text_normal {
  font-weight: normal !important;
}

.text_bold {
  font-weight: bold !important;
}

.text_left {
  text-align: left !important;
}

.text_right {
  text-align: right !important;
}

.text_center {
  text-align: center !important;
}

.text_under {
  text-decoration: underline !important;
}

.text_n-under {
  text-decoration: none !important;
}

.bg_none {
  background: none !important;
}

.bg_fff {
  background: #fff !important;
}

.bg_000 {
  background: #000 !important;
}

.co_fff {
  color: #fff !important;
}

.co_000 {
  color: #000 !important;
}

.co_333 {
  color: #333 !important;
}

.border_none {
  border: none !important;
}

.bor_top_none {
  border-top: none !important;
}

.bor_right_none {
  border-right: none !important;
}

.bor_bottom_none {
  border-bottom: none !important;
}

.bor_left_none {
  border-left: none !important;
}

.beforeload {
  margin: 0;
  height: 100%;
  overflow: hidden;
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
  -webkit-animation-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  animation-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.preload {
  position: fixed;
  height: 100%;
  width: 100%;
  background: #518ced;
  z-index: 99999;
  text-align: center;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  display: table;
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.26, 0.8, 0.62, 0.9);
  transition: -webkit-transform 0.4s cubic-bezier(0.26, 0.8, 0.62, 0.9);
  transition: transform 0.4s cubic-bezier(0.26, 0.8, 0.62, 0.9);
  transition: transform 0.4s cubic-bezier(0.26, 0.8, 0.62, 0.9),
    -webkit-transform 0.4s cubic-bezier(0.26, 0.8, 0.62, 0.9);
  transition-delay: 0;
  -webkit-transition-delay: 0;
}

.preload .tit {
  margin-top: 25px;
  display: block;
}

.preload .tit img {
  height: 32px;
}

.preload--inside {
  display: table-cell;
  vertical-align: middle;
}

.preload--inside .logo {
  margin-top: -80px;
}

.preload--inside .logo img {
  height: 80px;
}

.preload .progress span {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  background: #fff;
  height: 4px;
  width: 4px;
  display: inline-block;
  border-radius: 10px;
  -webkit-animation: wave 3s ease infinite;
  animation: wave 3s ease infinite;
}

.preload .progress span:nth-child(1) {
  -webkit-animation-delay: 0.05s;
  animation-delay: 0.05s;
}

.preload .progress span:nth-child(2) {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.preload .progress span:nth-child(3) {
  -webkit-animation-delay: 0.15s;
  animation-delay: 0.15s;
}

.preload .progress span:nth-child(4) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.preload .progress span:nth-child(5) {
  -webkit-animation-delay: 0.25s;
  animation-delay: 0.25s;
}

.preload .progress span:nth-child(6) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.preload .progress span:nth-child(7) {
  -webkit-animation-delay: 0.35s;
  animation-delay: 0.35s;
}

.preload .progress span:nth-child(8) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.pageloaded .preload {
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
  height: 100%;
  background: #fff;
  -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.26, 0.8, 0.62, 0.9);
  transition: -webkit-transform 0.5s cubic-bezier(0.26, 0.8, 0.62, 0.9);
  transition: transform 0.5s cubic-bezier(0.26, 0.8, 0.62, 0.9);
  transition: transform 0.5s cubic-bezier(0.26, 0.8, 0.62, 0.9),
    -webkit-transform 0.5s cubic-bezier(0.26, 0.8, 0.62, 0.9);
  transition-delay: 0;
  -webkit-transition-delay: 0;
}

.textpreload.txt_end span:nth-of-type(1) {
  -webkit-animation: opacity 0.2s cubic-bezier(0.3, 0.6, 0.5, 1) 0.1s forwards
    alternate;
  animation: opacity 0.2s cubic-bezier(0.3, 0.6, 0.5, 1) 0.1s forwards alternate;
}

.textpreload.txt_end span:nth-of-type(1) {
  -webkit-animation: opacity 0.4s cubic-bezier(0.3, 0.6, 0.5, 1) 0.1s forwards
    alternate;
  animation: opacity 0.4s cubic-bezier(0.3, 0.6, 0.5, 1) 0.1s forwards alternate;
}

.textpreload.txt_end span:nth-of-type(1) {
  -webkit-animation: opacity 0.6s cubic-bezier(0.3, 0.6, 0.5, 1) 0.1s forwards
    alternate;
  animation: opacity 0.6s cubic-bezier(0.3, 0.6, 0.5, 1) 0.1s forwards alternate;
}

.textpreload.txt_end span:nth-of-type(1) {
  -webkit-animation: opacity 0.8s cubic-bezier(0.3, 0.6, 0.5, 1) 0.1s forwards
    alternate;
  animation: opacity 0.8s cubic-bezier(0.3, 0.6, 0.5, 1) 0.1s forwards alternate;
}

.textpreload.txt_end span:nth-of-type(1) {
  -webkit-animation: opacity 1s cubic-bezier(0.3, 0.6, 0.5, 1) 0.1s forwards
    alternate;
  animation: opacity 1s cubic-bezier(0.3, 0.6, 0.5, 1) 0.1s forwards alternate;
}

.textpreload.txt_end span:nth-of-type(1) {
  -webkit-animation: opacity 1.2s cubic-bezier(0.3, 0.6, 0.5, 1) 0.1s forwards
    alternate;
  animation: opacity 1.2s cubic-bezier(0.3, 0.6, 0.5, 1) 0.1s forwards alternate;
}

.textpreload.txt_end span:nth-of-type(1) {
  -webkit-animation: opacity 1.4s cubic-bezier(0.3, 0.6, 0.5, 1) 0.1s forwards
    alternate;
  animation: opacity 1.4s cubic-bezier(0.3, 0.6, 0.5, 1) 0.1s forwards alternate;
}

.loader {
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  margin: 0 auto;
  border-top: 2px solid rgba(0, 0, 0, 0.2);
  border-right: 2px solid rgba(0, 0, 0, 0.2);
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  border-left: 2px solid #333;
  -webkit-animation: gonoloader 0.8s infinite linear;
  animation: gonoloader 0.8s infinite linear;
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

@-webkit-keyframes gonoloader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes gonoloader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@-webkit-keyframes wave {
  0%,
  40%,
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    background-color: #eee;
  }
  10% {
    -webkit-transform: translate(0, -15px);
    transform: translate(0, -15px);
    background-color: #000;
  }
}

@keyframes wave {
  0%,
  40%,
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    background-color: #eee;
  }
  10% {
    -webkit-transform: translate(0, -15px);
    transform: translate(0, -15px);
    background-color: #000;
  }
}

:root {
  --bs-blue: #518ced;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #fd64a5;
  --bs-red: #ed5555;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #34c759;
  --bs-teal: #2ecc87;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-primary: #518ced;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #ed5555;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-gradient: linear-gradient(
    180deg,
    hsla(0, 0%, 100%, 0.15),
    hsla(0, 0%, 100%, 0)
  );
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

img,
svg {
  vertical-align: middle;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
  background-color: #e9ecef !important;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: button;
}

[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

[hidden] {
  display: none !important;
}

.form-label {
  margin-bottom: 0.5rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d;
}

.dataTable-input,
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .dataTable-input,
  .form-control {
    -webkit-transition: none;
    transition: none;
  }
}

.form-control[type="file"],
[type="file"].dataTable-input {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]),
[type="file"].dataTable-input:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.dataTable-input:focus,
.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.dataTable-input::-webkit-date-and-time-value,
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}

.dataTable-input:-ms-input-placeholder,
.form-control:-ms-input-placeholder {
  color: rgba(95, 95, 95, 0.5);
  opacity: 1;
}

.dataTable-input::-webkit-input-placeholder,
.form-control::-webkit-input-placeholder {
  color: rgba(95, 95, 95, 0.5);
  opacity: 1;
}

.dataTable-input::-moz-placeholder,
.form-control::-moz-placeholder {
  color: rgba(95, 95, 95, 0.5);
  opacity: 1;
}

.dataTable-input:-ms-input-placeholder,
.form-control:-ms-input-placeholder {
  color: rgba(95, 95, 95, 0.5);
  opacity: 1;
}

.dataTable-input::-ms-input-placeholder,
.form-control::-ms-input-placeholder {
  color: rgba(95, 95, 95, 0.5);
  opacity: 1;
}

.dataTable-input::-webkit-input-placeholder,
.form-control::-webkit-input-placeholder {
  color: rgba(95, 95, 95, 0.5);
  opacity: 1;
}

.dataTable-input::-moz-placeholder,
.form-control::-moz-placeholder {
  color: rgba(95, 95, 95, 0.5);
  opacity: 1;
}

.dataTable-input:-ms-input-placeholder,
.form-control:-ms-input-placeholder {
  color: rgba(95, 95, 95, 0.5);
  opacity: 1;
}

.dataTable-input::-ms-input-placeholder,
.form-control::-ms-input-placeholder {
  color: rgba(95, 95, 95, 0.5);
  opacity: 1;
}

.dataTable-input::placeholder,
.form-control::placeholder {
  color: rgba(95, 95, 95, 0.5);
  opacity: 1;
}

.dataTable-input:disabled,
.form-control:disabled,
input:disabled,
.form-control[readonly],
[readonly].dataTable-input {
  background-color: #e9ecef !important;
  opacity: 1;
}

.dataTable-input::file-selector-button,
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  -webkit-margin-end: 0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .dataTable-input::file-selector-button,
  .form-control::file-selector-button {
    -webkit-transition: none;
    transition: none;
  }
}

.dataTable-input:hover:not(:disabled):not([readonly])::file-selector-button,
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

.dataTable-input::-webkit-file-upload-button,
.form-control::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  -webkit-margin-end: 0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .dataTable-input::-webkit-file-upload-button,
  .form-control::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none;
  }
}

.dataTable-input:hover:not(:disabled):not(
    [readonly]
  )::-webkit-file-upload-button,
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3;
}

.dataTable-selector,
.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #5f5f5f;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .dataTable-selector,
  .form-select {
    -webkit-transition: none;
    transition: none;
  }
}

.dataTable-selector:focus,
.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-select[multiple],
.form-select[size]:not([size="1"]),
[multiple].dataTable-selector,
[size].dataTable-selector:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}

.dataTable-selector:disabled,
.form-select:disabled {
  background-color: #e9ecef;
}

.dataTable-selector:-moz-focusring,
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #212529;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1.2em;
  height: 1.2em;
  margin-top: 0.1em;
  margin-right: 10px;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}

.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  -webkit-filter: brightness(90%);
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3 6-6'/%3E%3C/svg%3E");
}

.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='2' fill='%23fff'/%3E%3C/svg%3E");
}

.form-check-input[type="checkbox"]:indeterminate {
  background-color: #0d6efd;
  border-color: #0d6efd;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3E%3C/svg%3E");
}

.form-check-input:disabled {
  pointer-events: none;
  -webkit-filter: none;
  filter: none;
  opacity: 0.5;
}

.form-check-input:disabled ~ .form-check-label,
.form-check-input[disabled] ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='rgba(0, 0, 0, 0.25)'/%3E%3C/svg%3E");
  background-position: 0;
  border-radius: 2em;
  -webkit-transition: background-position 0.15s ease-in-out;
  transition: background-position 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    -webkit-transition: none;
    transition: none;
  }
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%2386b7fe'/%3E%3C/svg%3E");
}

.form-switch .form-check-input:checked {
  background-position: 100%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.btn-check:disabled + .btn,
.btn-check[disabled] + .btn {
  pointer-events: none;
  -webkit-filter: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #b6d4fe;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #b6d4fe;
}

.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.input-group {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}

.input-group > .dataTable-input,
.input-group > .dataTable-selector,
.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.input-group > .dataTable-input:focus,
.input-group > .dataTable-selector:focus,
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}

.input-group .btn {
  position: relative;
  z-index: 2;
}

.input-group .btn:focus {
  z-index: 3;
}

.btn {
  display: inline-block;
  font-weight: 700;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 16px;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media all and (max-width: 1340px) {
  .btn {
    font-size: 14px;
  }
}

a.btn {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    -webkit-transition: none;
    transition: none;
  }
}

.btn:hover {
  opacity: 0.8;
}

.btn-check:focus + .btn,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
  background-color: #ccc;
}

.btn-primary {
  color: #fff;
  background-color: #518ced;
  border-color: #518ced;
}

.btn-check:focus + .btn-primary,
.btn-primary:focus,
.btn-primary:hover {
  color: #fff;
  background-color: #0b5ed7;
  border-color: #0a58ca;
}

.btn-check:focus + .btn-primary,
.btn-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
}

.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #518ced;
  border-color: #518ced;
}

.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.btn-secondary {
  color: #fff;
  background-color: #5f5f5f;
  border-color: #5f5f5f;
}

.btn-check:focus + .btn-secondary,
.btn-secondary:focus,
.btn-secondary:hover {
  color: #fff;
  background-color: #5f5f5f;
  border-color: #5f5f5f;
}

.btn-check:focus + .btn-secondary,
.btn-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(131, 138, 145, 0.5);
}

.btn-check:active + .btn-secondary,
.btn-check:checked + .btn-secondary,
.btn-secondary.active,
.btn-secondary:active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #5f5f5f;
  border-color: #5f5f5f;
}

.btn-check:active + .btn-secondary:focus,
.btn-check:checked + .btn-secondary:focus,
.btn-secondary.active:focus,
.btn-secondary:active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(131, 138, 145, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-success {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.btn-check:focus + .btn-success,
.btn-success:focus,
.btn-success:hover {
  color: #fff;
  background-color: #157347;
  border-color: #146c43;
}

.btn-check:focus + .btn-success,
.btn-success:focus {
  box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
}

.btn-check:active + .btn-success,
.btn-check:checked + .btn-success,
.btn-success.active,
.btn-success:active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #146c43;
  border-color: #13653f;
}

.btn-check:active + .btn-success:focus,
.btn-check:checked + .btn-success:focus,
.btn-success.active:focus,
.btn-success:active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.btn-info {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-check:focus + .btn-info,
.btn-info:focus,
.btn-info:hover {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
}

.btn-check:focus + .btn-info,
.btn-info:focus {
  box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}

.btn-check:active + .btn-info,
.btn-check:checked + .btn-info,
.btn-info.active,
.btn-info:active,
.show > .btn-info.dropdown-toggle {
  color: #000;
  background-color: #3dd5f3;
  border-color: #25cff2;
}

.btn-check:active + .btn-info:focus,
.btn-check:checked + .btn-info:focus,
.btn-info.active:focus,
.btn-info:active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-warning {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-check:focus + .btn-warning,
.btn-warning:focus,
.btn-warning:hover {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720;
}

.btn-check:focus + .btn-warning,
.btn-warning:focus {
  box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
}

.btn-check:active + .btn-warning,
.btn-check:checked + .btn-warning,
.btn-warning.active,
.btn-warning:active,
.show > .btn-warning.dropdown-toggle {
  color: #000;
  background-color: #ffcd39;
  border-color: #ffc720;
}

.btn-check:active + .btn-warning:focus,
.btn-check:checked + .btn-warning:focus,
.btn-warning.active:focus,
.btn-warning:active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-check:focus + .btn-danger,
.btn-danger:focus,
.btn-danger:hover {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
}

.btn-check:focus + .btn-danger,
.btn-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}

.btn-check:active + .btn-danger,
.btn-check:checked + .btn-danger,
.btn-danger.active,
.btn-danger:active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #b02a37;
  border-color: #a52834;
}

.btn-check:active + .btn-danger:focus,
.btn-check:checked + .btn-danger:focus,
.btn-danger.active:focus,
.btn-danger:active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #ccc;
  border-color: #ccc;
}

.btn-light {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-check:focus + .btn-light,
.btn-light:focus,
.btn-light:hover {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
}

.btn-check:focus + .btn-light,
.btn-light:focus {
  box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
}

.btn-check:active + .btn-light,
.btn-check:checked + .btn-light,
.btn-light.active,
.btn-light:active,
.show > .btn-light.dropdown-toggle {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
}

.btn-check:active + .btn-light:focus,
.btn-check:checked + .btn-light:focus,
.btn-light.active:focus,
.btn-light:active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-dark {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-check:focus + .btn-dark,
.btn-dark:focus,
.btn-dark:hover {
  color: #fff;
  background-color: #1c1f23;
  border-color: #1a1e21;
}

.btn-check:focus + .btn-dark,
.btn-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
}

.btn-check:active + .btn-dark,
.btn-check:checked + .btn-dark,
.btn-dark.active,
.btn-dark:active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1a1e21;
  border-color: #191c1f;
}

.btn-check:active + .btn-dark:focus,
.btn-check:checked + .btn-dark:focus,
.btn-dark.active:focus,
.btn-dark:active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-outline-primary {
  color: #518ced;
  border-color: #518ced;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.btn-check:focus + .btn-outline-primary,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5);
}

.btn-check:active + .btn-outline-primary,
.btn-check:checked + .btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.btn-check:active + .btn-outline-primary:focus,
.btn-check:checked + .btn-outline-primary:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus,
.btn-outline-primary:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #0d6efd;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #5f5f5f;
  border-color: #5f5f5f;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #5f5f5f;
  border-color: #5f5f5f;
}

.btn-check:focus + .btn-outline-secondary,
.btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(109, 118, 126, 0.5);
}

.btn-check:active + .btn-outline-secondary,
.btn-check:checked + .btn-outline-secondary,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show,
.btn-outline-secondary:active {
  color: #fff;
  background-color: #5f5f5f;
  border-color: #5f5f5f;
}

.btn-check:active + .btn-outline-secondary:focus,
.btn-check:checked + .btn-outline-secondary:focus,
.btn-outline-secondary.active:focus,
.btn-outline-secondary.dropdown-toggle.show:focus,
.btn-outline-secondary:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(109, 118, 126, 0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-success {
  color: #198754;
  border-color: #198754;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.btn-check:focus + .btn-outline-success,
.btn-outline-success:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
}

.btn-check:active + .btn-outline-success,
.btn-check:checked + .btn-outline-success,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show,
.btn-outline-success:active {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.btn-check:active + .btn-outline-success:focus,
.btn-check:checked + .btn-outline-success:focus,
.btn-outline-success.active:focus,
.btn-outline-success.dropdown-toggle.show:focus,
.btn-outline-success:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #198754;
  background-color: transparent;
}

.btn-outline-info {
  color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-outline-info:hover {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-check:focus + .btn-outline-info,
.btn-outline-info:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}

.btn-check:active + .btn-outline-info,
.btn-check:checked + .btn-outline-info,
.btn-outline-info.active,
.btn-outline-info.dropdown-toggle.show,
.btn-outline-info:active {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-check:active + .btn-outline-info:focus,
.btn-check:checked + .btn-outline-info:focus,
.btn-outline-info.active:focus,
.btn-outline-info.dropdown-toggle.show:focus,
.btn-outline-info:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #0dcaf0;
  background-color: transparent;
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-check:focus + .btn-outline-warning,
.btn-outline-warning:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}

.btn-check:active + .btn-outline-warning,
.btn-check:checked + .btn-outline-warning,
.btn-outline-warning.active,
.btn-outline-warning.dropdown-toggle.show,
.btn-outline-warning:active {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-check:active + .btn-outline-warning:focus,
.btn-check:checked + .btn-outline-warning:focus,
.btn-outline-warning.active:focus,
.btn-outline-warning.dropdown-toggle.show:focus,
.btn-outline-warning:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-check:focus + .btn-outline-danger,
.btn-outline-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}

.btn-check:active + .btn-outline-danger,
.btn-check:checked + .btn-outline-danger,
.btn-outline-danger.active,
.btn-outline-danger.dropdown-toggle.show,
.btn-outline-danger:active {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-check:active + .btn-outline-danger:focus,
.btn-check:checked + .btn-outline-danger:focus,
.btn-outline-danger.active:focus,
.btn-outline-danger.dropdown-toggle.show:focus,
.btn-outline-danger:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-check:focus + .btn-outline-light,
.btn-outline-light:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}

.btn-check:active + .btn-outline-light,
.btn-check:checked + .btn-outline-light,
.btn-outline-light.active,
.btn-outline-light.dropdown-toggle.show,
.btn-outline-light:active {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-check:active + .btn-outline-light:focus,
.btn-check:checked + .btn-outline-light:focus,
.btn-outline-light.active:focus,
.btn-outline-light.dropdown-toggle.show:focus,
.btn-outline-light:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-dark {
  color: #212529;
  border-color: #212529;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-check:focus + .btn-outline-dark,
.btn-outline-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
}

.btn-check:active + .btn-outline-dark,
.btn-check:checked + .btn-outline-dark,
.btn-outline-dark.active,
.btn-outline-dark.dropdown-toggle.show,
.btn-outline-dark:active {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-check:active + .btn-outline-dark:focus,
.btn-check:checked + .btn-outline-dark:focus,
.btn-outline-dark.active:focus,
.btn-outline-dark.dropdown-toggle.show:focus,
.btn-outline-dark:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #212529;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #0d6efd;
  text-decoration: underline;
}

.btn-link:hover {
  color: #0a58ca;
}

.btn-link.disabled,
.btn-link:disabled {
  color: #6c757d;
}

.btn-group-lg > .btn,
.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.btn-group-sm > .btn,
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.fade {
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    -webkit-transition: none;
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.dropdown,
.dropend,
.dropstart,
.dropup {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle:after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  z-index: 5;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0.125rem;
}

.modal-open {
  overflow: hidden;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  overflow: hidden;
  outline: 0;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-open .modal::after {
  position: absolute;
  content: "";
  background: rgba(95, 95, 95, 0.8);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
}

.modal.fade.show {
  opacity: 1;
  display: block;
  z-index: 999;
}

.modal-dialog {
  position: relative;
  width: auto;
  pointer-events: none;
  max-width: 94%;
  max-height: 94vh;
  border-radius: 8px;
  overflow: hidden;
  margin: 3vh auto;
  z-index: 99;
}

.modal.fade .modal-dialog {
  -webkit-transform: translate(0, -25%);
  -ms-transform: translate(0, -25%);
  transform: translate(0, -25%);
}

.modal.show .modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}

.modal-dialog-centered {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  min-height: calc(100% - (1.75rem * 2));
}

.modal-content {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  outline: 0;
  background: #fff;
  border-radius: 8px;
}

.modal-content .blockinput {
  margin: 20px 0 0;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.modal-content .blockinput input {
  height: 40px;
  margin-left: 10px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #5f5f5f;
}

.modal-header {
  padding: 15px;
}

.modal-body {
  padding: 15px;
  overflow-y: auto;
}

.modal-footer {
  background: #fff;
  padding: 15px;
  box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.16);
  position: relative;
  z-index: 2;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0;
}

.modal-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.modal-title {
  margin-bottom: 0;
}

.modal-body {
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.modal-footer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

.modal .close {
  background: #99a3b1;
  width: 28px;
  height: 28px;
  border-radius: 3px;
  position: absolute;
  right: 12px;
  top: 12px;
}

.modal .modal-header {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.modal .filter {
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.modal .filter .form-control {
  border-width: 1px;
  font-size: 14px;
}

.modal .filter .form-control.searchClinic {
  min-height: 40px;
  min-width: 300px;
}

.modal .filter .txtresult {
  margin-left: auto;
  margin-right: 0;
}

.modal .modal-footer .issued {
  margin-left: auto;
  margin-right: 0;
}

.modal .modal-footer .btn:disabled {
  background: #dcdfe6;
  border-color: #dcdfe6;
}

/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #eee #fff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: #fff;
}

*::-webkit-scrollbar-thumb {
  background-color: #eee;
  border-radius: 10px;
  border: 2px solid #fff;
}

body {
  font-family: "Hiragino Sans", "Helvetica Neue", "Helvetica", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif !important;
  color: #5f5f5f;
  font-size: 16px;
  background: #f5f6fa;
}

body p {
  font-size: 16px;
  line-height: 1.8;
  color: #5f5f5f;
  font-family: "Hiragino Sans", "Helvetica Neue", "Helvetica", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif !important;
}

body a {
  color: #5f5f5f;
  line-height: normal;
  font-family: "Hiragino Sans", "Helvetica Neue", "Helvetica", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif !important;
}

body img,
body iframe {
  max-width: 100%;
  line-height: 0;
}

body .img {
  overflow: hidden;
  line-height: 0;
}

body .img img {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition-duration: 4s;
  transition-duration: 4s;
  -webkit-transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-transition-property: -webkit-transform;
  transition-property: transform, -webkit-transform;
}

.bgWhite {
  background: #fff;
}

.dispc,
.breakpc {
  display: block;
}

@media all and (max-width: 1180px) {
  .dispc,
  .breakpc {
    display: none;
  }
}

.dissp,
.breaksp {
  display: block;
}

@media all and (min-width: 1181px) {
  .dissp,
  .breaksp {
    display: none;
  }
}

.dflex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.dflex_start {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.dflex_end {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.dflex_space {
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.dflex_center {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.dflex-wrap {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.dflex-nowrap {
  -webkit-flex-wrap: nowrap !important;
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.align_start {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.align_end {
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.align_center {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.container {
  margin: 0 auto;
  padding: 0 15px;
}

@media all and (max-width: 960px) {
  .container {
    padding: 0 15px;
  }
}

@media all and (max-width: 639px) {
  .container {
    padding: 0 3%;
  }
}

.clmain {
  color: #518ced;
}

.clpage {
  color: #518ced;
}

.clwhite {
  color: #fff;
}

.clgreen {
  color: #51c17d;
}

.clred {
  color: #ed5555;
}

.clgray {
  color: #acacac;
}

.gap0 {
  gap: 0 !important;
}

.vshidden {
  visibility: hidden !important;
}

.mlauto {
  margin-left: 0;
  margin-right: auto;
}

.mrauto {
  margin-right: 0;
  margin-left: auto;
}

.mw70 {
  max-width: 70px;
}

.mw80 {
  max-width: 80px;
}

.mw155 {
  max-width: 155px;
}

.mw160 {
  max-width: 160px;
}

.mw320 {
  max-width: 320px;
}

.mw350 {
  max-width: 350px !important;
}

.mw360 {
  max-width: 360px !important;
}

.mw500 {
  max-width: 500px;
}

.mw200 {
  max-width: 200px;
}

.mw1060 {
  max-width: 1060px;
}

.minw280 {
  min-width: 280px;
}

.minw440 {
  min-width: 440px;
}

.minw140 {
  min-width: 140px;
}

.clop50 {
  color: rgba(95, 95, 95, 0.5);
}

.mh40 {
  min-height: 40px !important;
}

.mh50 {
  min-height: 50px;
}

.bordertop {
  border-top: 1px solid #518ced;
}

.btn-Breakdown {
  background: #6b93be;
}

.dblock {
  display: block !important;
}

@media all and (min-width: 1181px) {
  a[href*="tel:"] {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
  }
}

.alink {
  position: relative;
}

.alink a {
  position: relative;
  color: #518ced;
}

.alink a:hover {
  text-decoration: underline;
}

.alink p,
.alink button {
  background: none;
  color: #518ced;
}

.arrowDropdown {
  position: relative;
  cursor: pointer;
}

.arrowDropdown::after {
  position: absolute;
  content: "";
  border-top: 2px solid #518ced;
  border-left: 2px solid #518ced;
  right: 40px;
  z-index: 11;
  display: block;
  width: 8px;
  height: 8px;
  top: 50%;
  margin-top: -2px;
  cursor: pointer;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.arrowDropdown.isOpen::after {
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
  margin-top: -6px;
}

.shadow {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.16);
  background: #fff;
  border-radius: 4px;
}

.blockNav {
  display: none;
}

input[type="text"],
input[type="email"],
input[type="name"],
input[type="tel"],
input[type="number"],
input[type="password"],
select,
textarea {
  height: 40px;
  border: 1px solid #5f5f5f;
  padding: 5px 10px;
  background: #fff;
  width: 100%;
  border-radius: 4px;
}

select {
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  appearance: menulist;
}

select:focus {
  outline: none;
}

textarea {
  padding: 10px;
  height: 170px;
}

table {
  width: 100%;
  table-layout: auto;
}

table th,
table td {
  text-align: left;
  padding: 8px 10px;
  padding-left: 20px;
  font-weight: normal;
  position: relative;
  font-size: 14px;
}

table thead th {
  font-weight: bold;
  padding: 12px 10px;
}

table tbody .btn.disabled {
  border-color: #dcdfe6;
  color: #dcdfe6;
}

table th.edit {
  width: 80px;
}

table td.edit {
  text-align: center;
  width: 80px;
  padding: 0 !important;
}

table td.postcode {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

table td.postcode input {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 72%;
  -ms-flex: 0 0 72%;
  flex: 0 0 72%;
  margin-right: 2%;
}

table td.postcode button {
  min-height: 40px;
  max-width: 120px;
  padding-left: 10px;
  padding-right: 10px;
}

table td.postcode button:disabled {
  color: #dddfe5;
  border-color: #dddfe5;
  pointer-events: none;
}

table td .require {
  color: #fff;
  border-radius: 4px;
  background: #ed5555;
  padding: 2px 7px;
  font-size: 12px;
  position: absolute;
  margin-left: 10px;
  font-weight: bold;
}

table td.middle {
  vertical-align: middle !important;
}

table td.top {
  vertical-align: top !important;
}

table td label.tags {
  font-size: 12px;
  border: 2px solid #5f5f5f;
  padding: 2px 5px;
  margin-right: 10px;
  min-width: 72px;
  text-align: center;
  display: inline-block;
}

table td:last-child button.btn {
  min-width: 64px;
}

@media all and (min-width: 1280px) {
  table .w49pc {
    max-width: 300px;
  }
}

table.tbinputgray input[type="text"],
table.tbinputgray input[type="email"],
table.tbinputgray input[type="name"],
table.tbinputgray input[type="tel"],
table.tbinputgray input[type="number"],
table.tbinputgray input[type="password"],
table.tbinputgray select,
table.tbinputgray textarea {
  background-color: #e9ecef !important;
}

table.tbstyle1 td:first-child {
  min-width: 220px;
  width: 220px;
  font-weight: bold;
  font-size: 16px;
}

table.tbstyle1 td:last-child {
  padding-right: 0;
}

table.tbstyle1 tr.checklist td {
  height: 54px;
  vertical-align: bottom;
  padding-bottom: 10px;
}

table.tbstyle1 .fixedpos td {
  padding-bottom: 35px;
}

table.tbstyle1 .fixedpos span.clred {
  position: absolute;
  left: -200px;
  bottom: 5px;
}

table.tbstyle2 th,
table.tbstyle2 td,
table.tbstyle3 th,
table.tbstyle3 td {
  padding-left: 10px;
}

table.tbstyle2 thead,
table.tbstyle3 thead {
  background: #e0ebff;
  position: relative;
}

table.tbstyle2 thead th:first-child,
table.tbstyle3 thead th:first-child {
  padding-left: 20px;
}

table.tbstyle2 tbody td,
table.tbstyle3 tbody td {
  border-bottom: 1px solid rgba(153, 153, 171, 0.29);
}

table.tbstyle2 tbody td:first-child,
table.tbstyle3 tbody td:first-child {
  padding-left: 20px;
}

table.tbstyle2 tbody.f16 td,
table.tbstyle3 tbody.f16 td {
  font-size: 16px;
}

table.tbstyle2 tbody.f14 td,
table.tbstyle3 tbody.f14 td {
  font-size: 14px;
}

table.tbstyle2.sortTable th,
table.tbstyle3.sortTable th {
  vertical-align: middle;
}

table.tbstyle2.sortTable th:first-child,
table.tbstyle2.sortTable td:first-child,
table.tbstyle3.sortTable th:first-child,
table.tbstyle3.sortTable td:first-child {
  padding-left: 10px;
}

table.tbstyle3 thead {
  background: #ebebeb;
}

table.tbstyle3 thead.bgblue {
  background: #e7ecf5;
}

table.tbstyle3 td {
  vertical-align: middle;
}

table.tbstyle3 tfoot {
  background: #f2f2f2;
  border-bottom: 1px solid rgba(153, 153, 171, 0.29);
}

table.table-draggable thead {
  background: #e7ecf5;
}

table.table-draggable td {
  border-bottom: 1px solid #cdd5e5;
  padding-left: 10px;
}

table.tbstyle4 td {
  border-bottom: 1px solid #5f5f5f;
  padding: 20px 0;
}

table.tbstyle4 td:first-child {
  padding-right: 20px;
  font-weight: bold;
  width: 240px;
}

table.tbstyle4 td .require {
  bottom: auto;
  top: auto;
  right: -15px;
  top: -3px;
  position: relative;
}

table.tbstyle5 th {
  border-bottom: 1px solid #cdd5e5;
  padding: 12px 15px;
  padding-left: 0;
}

table.tbstyle5 th small {
  margin-left: 40px;
}

table.tbstyle5 td {
  border-bottom: 1px solid #cdd5e5;
  padding: 12px 15px;
  vertical-align: middle;
}

table.tbstyle5 .btn {
  min-width: 70px;
  min-height: 40px;
}

table.tbstyle5 .selectstyle {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

table.tbstyle6 th,
table.tbstyle6 td {
  padding-left: 10px;
}

table.tbstyle6 th small,
table.tbstyle6 th span,
table.tbstyle6 td small,
table.tbstyle6 td span {
  display: block;
}

table.tbstyle6 th {
  background: #e7ecf5;
  vertical-align: middle;
  font-weight: bold;
}

table.tbstyle6 th:first-child {
  min-width: 160px;
  left: 0;
  z-index: 1;
}

table.tbstyle6 th:nth-child(2) {
  min-width: 80px;
  padding-left: 0;
  left: 160px;
  z-index: 2;
}

table.tbstyle6 th:nth-child(3) {
  min-width: 70px;
  padding-left: 0;
  padding-right: 0;
  z-index: 3;
  left: 240px;
}

table.tbstyle6 th:first-child,
table.tbstyle6 th:nth-child(2),
table.tbstyle6 th:nth-child(3) {
  position: -webkit-sticky;
  position: sticky;
}

table.tbstyle6 td {
  border-bottom: 1px solid #cdd5e5;
  background: #fff;
}

table.tbstyle6 td:first-child,
table.tbstyle6 td:nth-child(2),
table.tbstyle6 td:nth-child(3) {
  position: -webkit-sticky;
  position: sticky;
}

table.tbstyle6 td:nth-child(2),
table.tbstyle6 td:first-child {
  vertical-align: middle;
}

table.tbstyle6 td:nth-child(2),
table.tbstyle6 td:nth-child(3) {
  padding-left: 0;
}

table.tbstyle6 td:first-child {
  z-index: 1;
  left: 0;
}

table.tbstyle6 td:nth-child(2) {
  z-index: 2;
  left: 160px;
}

table.tbstyle6 td:nth-child(3) {
  padding-right: 0;
  z-index: 3;
  left: 240px;
}

table.tbstyle6 td span,
table.tbstyle6 td select {
  font-size: 14px;
}

table.tbstyle6 td select {
  padding-left: 0;
  min-width: 80px;
  margin-bottom: 8px;
}

table.tbstyle6 td.tit {
  font-size: 16px;
  font-weight: bold;
  padding-left: 0;
  padding-top: 25px;
}

table.tbstyle6 td .tit {
  font-size: 18px;
  line-height: 1;
}

table.tbstyle6 td span {
  color: #99a3b1;
  font-size: 13px;
}

table.tbstyle6 td span.clpage {
  color: #518ced;
  text-align: center;
}

table.tbstyle6 td .label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 5px 0;
}

table.tbstyle6 td .label label {
  background: #99a3b1;
  font-size: 12px;
  color: #fff;
  height: 20px;
  text-align: center;
  padding: 2px 10px;
  margin-right: 10px;
}

table.tbstyle6 td .label span.num {
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
}

table.tbstyle6 tr.borderblue {
  border-bottom: 2px solid #518ced;
}

table.tbstyle6 tr.borderblue td {
  position: inherit;
}

table.tbstyle6 tr.borderblue td:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
}

table.tbstyle7 tfoot {
  background: #e7ecf5;
}

table.tbstyle7 th {
  padding: 12px 10px;
  font-weight: bold;
  font-size: 12px;
}

table.tbstyle7 th span.num {
  font-size: 16px;
  margin-right: 8px;
}

table.tbstyle8 thead th {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  top: 0;
  background: #e7ecf5;
  z-index: 1;
}

table.tbstyle8 th {
  vertical-align: middle;
  font-size: 12px;
  padding-left: 5px;
  padding-right: 5px;
}

table.tbstyle8 td {
  padding-left: 5px;
  padding-right: 5px;
  min-width: 80px;
}

table.tbstyle8 td:first-child,
table.tbstyle8 td:last-child {
  vertical-align: middle;
}

table.tbstyle8 td:last-child {
  max-width: 80px;
}

table.tbstyle8 td:nth-child(3) {
  min-width: 200px;
}

table.tbstyle8 td:nth-child(4) {
  width: 50px;
  min-width: 50px;
}

table.tbstyle8 td:nth-child(4) label:first-child {
  margin-bottom: 25px;
}

table.tbstyle8 td label {
  background: #99a3b1;
  height: 20px;
  width: 32px;
  line-height: 20px;
  display: block;
  color: #fff;
  font-weight: bold;
  text-align: center;
}

table.tbstyle8 td input {
  max-width: 66px;
}

table.tbstyle8 td input:first-child {
  margin-bottom: 8px;
}

table.tbstyle8 td p.point {
  min-height: 40px;
  line-height: 38px;
}

table.tbstyle8 td p.point:first-child {
  margin-bottom: 8px;
}

.content {
  height: 100%;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  overflow: hidden;
}

.content_head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #cdd5e5;
  padding: 14px 0;
  padding-right: 10px;
  max-height: 56px;
  margin-bottom: 10px;
  position: relative;
}

.content_head .logo a {
  width: 56px;
  height: 56px;
  background: #518ced;
  padding: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.content_head .logo a:hover {
  opacity: 0.7;
}

.content_head .logo a:hover img {
  opacity: 1;
}

.content_head .logo img {
  height: 28px;
}

.content_head .backbutton {
  background: none;
  width: 48px;
  height: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 10px;
}

.content_head .backbutton button {
  background: none;
  -webkit-transition: all .3s;
  transition: all .3s;
  width: 48px;
  height: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

.content_head .backbutton:hover button {
  opacity: 0.7;
}

.content_head .button {
  margin-left: auto;
  margin-right: 0;
}

.content_head .button button {
  min-height: 40px;
  max-height: 40px;
}

.content_head .title {
  font-size: 18px;
  font-weight: bold;
}

.content_head .ico-more {
  position: absolute;
  right: 20px;
  top: 15px;
  cursor: pointer;
}

.content_head .ico-more .blockPrintCsv {
  display: none;
  border-radius: 4px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.16);
  background: #fff;
  position: absolute;
  z-index: 99;
  width: 230px;
  right: 0;
  top: 40px;
}

.content_head .ico-more .blockPrintCsv button {
  padding: 10px;
  background: none;
  width: 100%;
  text-align: left;
}

.content_head .ico-more .blockPrintCsv button:hover {
  opacity: 0.5;
}

.content_head .ico-more .blockPrintCsv button span {
  line-height: 1;
}

.content_head .ico-more .blockPrintCsv button small {
  font-size: 12px;
  display: block;
}

.content_head .ico-more .blockPrintCsv button.btn-print {
  background: url(../images/ico_printer_b.svg) no-repeat left 12px center;
  padding-left: 45px;
}

.content_head .ico-more .blockPrintCsv button.btn-uploadcsv {
  background: url(../images/ico_upload_b.svg) no-repeat left 12px center;
  padding-left: 45px;
}

.content_inside {
  width: 100%;
  height: calc(100vh - 56px);
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 12px;
  overflow-y: scroll;
  position: relative;
  background: #fff;
}

.content_inside .tbNohorizontal,
.content_inside .tbhorizontal {
  max-height: calc(100vh - 86px);
}

.content_inside.hastabs .tbNohorizontal,
.content_inside.hastabs .tbhorizontal {
  max-height: calc(100vh - 142px);
}

.content_inside.hastabs .blockinfo_hasChart .tbhorizontal {
  max-height: calc(100vh - 75px);
}

@media screen and (min-height: 768px) {
  .content_inside.hastabs .blockinfo_hasChart .tbhorizontal {
    max-height: calc(100vh - 610px);
  }
}

.content_inside.hastabs.hasbottom .tbNohorizontal,
.content_inside.hastabs.hasbottom .tbhorizontal {
  max-height: calc(100vh - 205px);
}

.content_inside.hasbottom .tbNohorizontal,
.content_inside.hasbottom .tbhorizontal {
  max-height: calc(100vh - 152px);
}

.sidebar {
  background: #6986b1;
  height: 100vh;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  overflow-y: auto;
  width: 200px;
  z-index: 2;
  position: relative;
}

.sidebar.isToggle {
  width: 56px;
}

.sidebar.isToggle .sidebar_nav a span {
  display: none;
}

.sidebar_head {
  height: 56px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.sidebar_nav li {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.sidebar_nav li a {
  padding: 16px 15px;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.sidebar_nav li a span {
  margin-left: 20px;
  font-size: 16px;
  font-weight: bold;
}

.sidebar_nav li:hover {
  background: #518ced;
}

.sidebar .icon__hamburger {
  width: 56px;
  height: 56px;
  position: relative;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.sidebar .icon__hamburger .icon--bar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 38%;
  width: 50%;
}

.sidebar .icon__hamburger span {
  left: 0;
  height: 3px;
  position: absolute;
  background: #fff;
  display: inline-block;
  width: 100%;
  border-radius: 3px;
  -webkit-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

.sidebar .icon__hamburger span:first-child {
  top: 0;
}

.sidebar .icon__hamburger span:nth-child(2) {
  top: 0;
  bottom: 0;
  margin: auto;
  position: absolute;
}

.sidebar .icon__hamburger span:last-child {
  bottom: 0;
}

/* -----------------------------------------------
/* BUTTON COMMON
-------------------------------------------------- */
button {
  border: none;
  outline: none;
  cursor: pointer;
}

.cm__btn {
  margin: 0 auto;
  width: 100%;
  max-width: 210px;
  margin-top: 30px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media all and (max-width: 1180px) {
  .cm__btn {
    margin-top: 20px;
    max-width: 180px;
  }
}

.cm__btn a,
.cm__btn button {
  display: block;
  text-align: center;
  padding: 10px 0;
  font-size: 20px;
  color: #fff;
  background: #000;
}

@media all and (max-width: 1180px) {
  .cm__btn a,
  .cm__btn button {
    font-size: 18px;
    padding: 5px 0;
  }
}

.cm__btn a span,
.cm__btn button span {
  z-index: 2;
  position: relative;
}

.cm__btn a span::before,
.cm__btn button span::before {
  content: "";
  background: transparent;
  position: absolute;
  width: 8px;
  height: 8px;
  right: 0;
  top: 50%;
  margin-top: -4px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.cm__btn--back {
  margin-top: 0;
  margin-left: 0;
}

.cm__btn--back a span::before {
  position: absolute;
  top: 50%;
  width: 8px;
  height: 8px;
  background: transparent;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  right: inherit;
  left: 0%;
  margin-left: -30px;
  -webkit-transform: rotate(-135deg) !important;
  -ms-transform: rotate(-135deg) !important;
  transform: rotate(-135deg) !important;
  margin-right: 0;
}

.cm__btn--next {
  margin-top: 0;
  margin-right: 0;
}

.btn {
  border-radius: 6px;
  min-height: 48px;
}

@media all and (max-width: 1340px) {
  .btn {
    min-height: 44px;
  }
}

.btn img {
  margin-right: 10px;
}

.btn-primary {
  background: #518ced;
  color: #fff;
}

.btn-darkprimary {
  background: #718093;
  color: #fff;
}

.btn-light {
  color: #fff;
}

.btn-darklight {
  color: #fff;
  background: #777;
}

.btn-white {
  color: #518ced;
  background: #fff;
}

.btn-secondary {
  background: #52a8ff;
  color: #fff;
}

.btn-danger {
  background: #ed5555;
  color: #fff;
}

.btn-dblack {
  background: #6d8095;
  color: #fff;
}

.btn-pink {
  background: #fd64a5;
  color: #fff;
}

.btn-pink2 {
  background: #ea3d86;
  color: #fff;
}

.btn-wgreen {
  background: #43db78;
  color: #fff;
}

.btn-green {
  background: #34c759;
  color: #fff;
}

.btn-org {
  background: #f0932b;
  color: #fff;
}

.btn-gray {
  background: #99a3b1;
  color: #fff;
}

.btn-dgray {
  background: #7f8fa6;
  color: #fff;
}

.btn-outline-dgray {
  background: #fff;
  border: 1px solid #7f8fa6;
  color: #7f8fa6;
}

.btn-dblue {
  background: #496ca0;
  color: #fff;
}

.btn-link {
  background: none;
  font-weight: normal;
  padding: 0;
  margin: 0 auto;
}

.btn-back {
  background: url(../images/arrowback.svg) no-repeat left center;
  padding-left: 18px;
  background-size: 15px;
}

.btn-more {
  text-align: center;
  width: 100%;
  margin-top: 20px;
  background: none;
  border: 1px dashed #5f5f5f;
  border-radius: 0;
  padding: 14px;
}

.btn-images {
  background: url(../images/arrowdown.svg) no-repeat right 10px center;
  border: 1px solid rgba(153, 153, 171, 0.29);
  cursor: pointer;
  padding: 15px 15px;
}

.btn-images img {
  margin-right: 0;
}

.btn.ico-camera {
  background: #518ced url(../images/ico_camera.svg) no-repeat left 8px center;
  padding-left: 45px;
}

.btn.ico-instruction {
  background: #518ced url(../images/ico_instruction.svg) no-repeat left 8px
    center;
  padding-left: 45px;
}

.btn.ico-print {
  background: #7f8fa6 url(../images/ico_printer.svg) no-repeat left 8px center;
  padding-left: 45px;
  color: #fff;
}

.btn.ico-download {
  background: #7f8fa6 url(../images/ico_download.svg) no-repeat left 8px center;
  padding-left: 45px;
  color: #fff;
}

.btn.ico-refresh {
  background: #7f8fa6 url(../images/ico_refresh.svg) no-repeat left 8px center;
  padding-left: 45px;
  color: #fff;
  background-size: 30px 30px;
}

.btn-small {
  min-height: 40px;
  font-size: 14px;
}

.btn-smaller {
  min-height: 32px;
  height: 32px;
  font-size: 14px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.btnlogout {
  margin-left: 20px;
}

.btnlogout button {
  color: #518ced;
  background: #fff;
  font-weight: bold;
  padding: 6px 15px;
}

/* toggle button */
.switch input {
  display: none;
}

.switch {
  display: inline-block;
  width: 60px;
  /*=w*/
  height: 30px;
  /*=h*/
  position: relative;
}

.slider {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 30px;
  box-shadow: 0 0 0 2px #979797, 0 0 4px #979797;
  cursor: pointer;
  border: 1px solid transparent;
  overflow: hidden;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  background: #979797;
}

.slider:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 30px;
  -webkit-transform: translateX(-30px);
  -ms-transform: translateX(-30px);
  transform: translateX(-30px);
  /*translateX(-(w-h))*/
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

input:checked + .slider:before {
  -webkit-transform: translateX(30px);
  -ms-transform: translateX(30px);
  transform: translateX(30px);
  /*translateX(w-h)*/
  background-color: #ffffff;
}

input:checked + .slider {
  box-shadow: 0 0 0 2px #34c759, 0 0 8px #34c759;
  background-color: #34c759;
}

/* scrollbar style */
/* The radioform */
.radioform {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 25px;
  height: 25px;
}

/* Hide the browser's default radio button */
.radioform input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  position: absolute;
  z-index: 9;
  width: 100%;
  height: 100%;
}

/* Create a custom radio button */
.radioform .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border: 2px solid #ccc;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radioform:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.radioform input:checked ~ .checkmark {
  background-color: #2196f3;
  border: none;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radioform .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  border: none;
}

/* Show the indicator (dot/circle) when checked */
.radioform input:checked ~ .checkmark:after {
  display: block;
  border: none;
}

/* Style the indicator (dot/circle) */
.radioform .checkmark:after {
  top: 7px;
  left: 7px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: white;
  border: none;
}

.btn_normal.btn_border.disabled {
  background-color: #ededed !important;
  border: none !important;
  color: #ffffff;
}

input[type="checkbox"]:disabled {
  cursor: default;
}

.setting-sec .materialsetting .ulline li.haspadding {
  padding: 20px !important;
}

.blockbtnhead {
  margin-bottom: 15px;
  gap: 8px;
}

.blockbtnhead button {
  min-width: 56px;
}

.blockbtnhead button.isActive {
  background: #518ced;
  color: #fff;
}

.content_right .btn {
  line-height: 1;
}

.blockfixbottom {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  padding: 10px 20px;
  background: #ffffff;
  box-shadow: 0 -4px 15px -4px rgba(0, 0, 0, 0.16);
}

.blockfixbottom .blockbtn {
  margin: 0;
}

@media all and (max-width: 1180px) {
  .blockfixbottom {
    padding: 10px;
  }
}

.blockinfo {
  margin-bottom: 40px;
  position: relative;
}

.blockinfo:last-child {
  margin-bottom: 0;
}

.blocksearch {
  background: #eee;
  padding: 20px;
}

.blocksearch_body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 15px 0;
}

.blocksearch_body .condition {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 50px;
}

.blocksearch_body .condition:last-child {
  margin-right: 0;
}

.blocksearch_body .condition span {
  word-break: keep-all;
}

.blocksearch_body .condition ul {
  margin-left: 20px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.blocksearch_body .condition li {
  margin: 3px 0;
  margin-right: 20px;
}

.blocksearch_body .condition li:last-child {
  margin-right: 0;
}

.blocksearch_body .selectstyle input {
  border-radius: 4px;
  border-color: #52a8ff;
}

.blocksearch_body .selectstyle input:checked {
  background: #52a8ff;
}

.blocksearch .form-check-custom input:disabled {
  pointer-events: none;
}

.blocksearch .form-check-custom input:disabled + label {
  color: #b8bbc1;
}

.blockswitchtabs {
  margin-bottom: 15px;
  overflow-x: auto;
  min-height: 40px;
}

.blockswitchtabs ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.blockswitchtabs li {
  position: relative;
  margin-right: 30px;
  padding-bottom: 3px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.blockswitchtabs li::after {
  position: absolute;
  content: "";
  background: #518ced;
  width: 100%;
  height: 2px;
  left: 0;
  bottom: 0;
  opacity: 0;
}

.blockswitchtabs li.isActive,
.blockswitchtabs li:hover {
  color: #518ced;
  font-weight: bold;
}

.blockswitchtabs li.isActive::after,
.blockswitchtabs li:hover::after {
  opacity: 1;
}

.blockswitchtabs li.isActive a,
.blockswitchtabs li:hover a {
  color: #518ced;
  font-weight: bold;
}

.blocktabs {
  margin-bottom: 15px;
  overflow-x: auto;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.blocktabs .addmore {
  padding-top: 0;
  padding-bottom: 0;
}

.blocktabs .listtabs {
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin-right: 8px;
  overflow-x: auto;
}

.blocktabs .listtabs * {
  white-space: nowrap;
}

.blocktabs .blockbtnhead {
  margin-left: auto;
  margin-right: 0;
}

.blocktabs .blockbtnhead button {
  min-width: 86px;
}

.blockbtn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 20px 0;
}

.blockbtn .btn {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  padding: 14px 20px;
  min-width: 140px;
  margin-left: 15px;
}

@media all and (max-width: 1180px) {
  .blockbtn .btn {
    margin-left: 5px;
  }
}

.blockbtn.blockposition {
  position: absolute;
  right: 0;
  bottom: 20px;
}

.blockborder {
  max-width: 530px;
  margin: 0 auto;
  padding: 40px;
  border: 1px solid #5f5f5f;
  border-radius: 10px;
}

.blockSearch {
  background: #f5f6fa;
  padding: 15px;
}

.blockSearch-content {
  margin-bottom: 10px;
}

.blockSearch-content h3 {
  font-size: 1.6vw;
}

@media all and (min-width: 1181px) {
  .blockSearch-content h3 {
    font-size: 1.48vw;
  }
}

.blockSearch-content p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.blockSearch-input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.blockSearch .form-group {
  margin-right: 25px;
}

.blockSearch .form-group label {
  font-weight: bold;
  font-size: 14px;
}

.blockSearch .form-group input,
.blockSearch .form-group select {
  max-height: 40px;
}

.blockSearch .form-group-ins {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.blockSearch .form-group:first-child input {
  max-width: 110px;
  margin-right: 8px;
}

.blockSearch .form-group:nth-child(2) input {
  max-width: 130px;
  margin-right: 8px;
}

.blockSearch .form-group:last-child {
  margin-right: 0;
}

.blockbtnfixed {
  position: fixed;
  right: 0;
  bottom: 0;
  width: calc(31% - 1px);
  height: 64px;
  background: #fff;
  z-index: 9;
  max-width: 479px;
  box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.16);
  padding: 8px 10px;
}

@media all and (min-width: 1181px) {
  .blockbtnfixed {
    min-width: 479px;
  }
}

.blockbtnfixed .btn:disabled {
  background-color: #ecedf2;
  border: 1px solid #ecedf2;
}

.blockbtnfixed.fixHeight {
  height: 104px;
}

.blockfavoriteProcess {
  min-height: 55px;
  padding: 12px 15px;
  position: relative;
  text-align: center;
}

.blockfavoriteProcess ul {
  border-radius: 6px;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin: 0 auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  border: 1px solid #518ced;
}

.blockfavoriteProcess ul li {
  min-width: 96px;
  text-align: center;
  font-weight: bold;
  color: #518ced;
  cursor: pointer;
  font-size: 14px;
  height: 32px;
  line-height: 32px;
}

.blockfavoriteProcess ul li.-active {
  background: #518ced;
  color: #fff;
}

.blockfavoriteProcess .ico {
  position: absolute;
  right: 15px;
  top: 15px;
}

.blockfavoriteProcess .ico_setting {
  cursor: pointer;
}

.blockCard {
  background: #fff;
  border: 1px solid #5f5f5f;
  border-radius: 6px;
  padding: 12px;
  margin-bottom: 12px;
  position: relative;
}

.blockCard.blockCardInfo {
  padding: 0;
  border: none;
  margin: 0;
  background: #f5f6fa;
  padding-bottom: 0;
}

.blockCard.hasScroll {
  overflow-y: auto;
  max-height: calc(100vh - 220px);
}

.blockCard.-active {
  border-width: 2px;
  border-color: #ed5555;
}

.blockCard_process p {
  font-weight: 500;
}

.blockCard_process ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  gap: 5px;
  border-bottom: 1px solid #cdd5e5;
  padding-bottom: 12px;
  margin-bottom: 15px;
}

.blockCard_process ul li {
  border: 1px solid #5f5f5f;
  border-radius: 4px;
  padding: 5px;
  margin-right: 10px;
}

.blockCard_process ul li label {
  border: 2px solid #5f5f5f;
  font-size: 12px;
  font-weight: 500;
  padding: 3px 5px;
}

.blockCard_process ul li span {
  font-weight: 500;
  display: block;
  line-height: 1.2;
  margin-top: 4px;
}

.blockCard_Head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-bottom: 8px;
}

.blockCard_checkBox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 15px;
}

.blockCard_checkBox .form-check-input {
  border-radius: 100%;
  margin-right: 0;
  width: 24px;
  height: 24px;
}

.blockCard_Name {
  margin-bottom: 10px;
}

.blockCard_Info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.blockCard_Info .name {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 40%;
  -ms-flex: 0 0 40%;
  flex: 0 0 40%;
  font-weight: bold;
}

.blockCard_Info .date {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  font-weight: bold;
}

.blockCard .num {
  font-size: 16px;
  font-weight: bold;
}

.blockCard .label:not(.label-square) span {
  font-size: 12px;
  font-weight: bold;
  border-radius: 15px;
  padding: 3px 8px;
  margin-left: 5px;
  margin-bottom: 5px;
  border-width: 2px;
  border-style: solid;
  display: inline-block;
}

.blockCard .label:not(.label-square)-outline {
  border-width: 2px;
  border-style: solid;
}

.blockCard .label-square {
  margin-right: 10px;
}

.blockCard .title {
  font-size: 1.2vw;
  font-weight: bold;
}

@media all and (min-width: 1181px) {
  .blockCard .title {
    font-size: 20px;
  }
}

.blockCard .name,
.blockCard .date {
  font-size: 1.1vw;
}

@media all and (min-width: 1181px) {
  .blockCard .name,
  .blockCard .date {
    font-size: 14px;
  }
}

.blockCard_Desc .ico-del {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 30px;
  -ms-flex: 0 0 30px;
  flex: 0 0 30px;
  background: none;
}

.blockCard_Desc .btn {
  min-height: 38px;
}

.blockCard_Desc-group {
  border-top: 1px solid #cdd5e5;
  margin-top: 10px;
  padding-top: 10px;
}

.blockCard_Desc-head .dflex_start {
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  margin-bottom: 12px;
}

.blockCard_Desc-head .leftside {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 calc(100% - 40px);
  -ms-flex: 0 0 calc(100% - 40px);
  flex: 0 0 calc(100% - 40px);
}

.blockCard_Desc-head .label {
  margin-right: 10px;
}

.blockCard_Desc-head .label span {
  border: 1px solid #5f5f5f;
  padding: 3px 10px;
  font-size: 14px;
  display: inline-block;
  font-weight: bold;
}

.blockCard_Desc-head p {
  line-height: 1.2;
}

.blockCard_Desc-footer {
  border-top: 1px solid #cdd5e5;
  margin-top: 10px;
  padding-top: 10px;
}

.blockCard_Desc-footer .status {
  font-size: 14px;
  font-weight: bold;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 calc(100% - 120px);
  -ms-flex: 0 0 calc(100% - 120px);
  flex: 0 0 calc(100% - 120px);
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.blockCard_Desc-footer .status dt {
  font-size: 12px;
}

.blockCard_Desc-number {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.blockCard_Desc-number .btn {
  margin-right: 0;
  margin-left: auto;
}

.blockCard_Desc .form-check-input {
  width: 22px;
  height: 22px;
  border-radius: 100%;
  margin-top: 0.01em;
}

.blockCard_Desc-info {
  padding-left: 20px;
}

.blockCard_Desc-info .listPro .item {
  margin-top: 12px;
  border-top: 1px solid #cdd5e5;
  padding: 12px 0 0;
}

.blockCard_Desc-info .listPro dl {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 calc(100% - 40px);
  -ms-flex: 0 0 calc(100% - 40px);
  flex: 0 0 calc(100% - 40px);
}

.blockCard_Desc-info .listPro dd {
  margin-left: auto;
  margin-right: 0;
}

.blockCard_Desc-info .listPro .form-select {
  max-width: 200px;
  min-width: 200px;
  height: 40px;
}

.blockCard_Desc-btnadd {
  border-top: 1px solid #cdd5e5;
  padding-top: 12px;
  margin-top: 12px;
}

.blockCard_Request {
  background: #fffbeb;
  padding: 12px;
  padding-top: 0;
  margin-top: 12px;
  width: calc(100% + 24px);
  margin-left: -12px;
}

.blockCard_Request .orgtit {
  width: calc(100% + 24px);
  margin-left: -12px;
}

.blockCard_Request .orgtit + .blockCard_Desc-group {
  border-top: 0;
  margin-top: 0;
}

.blockCard_Request + .blockCard_Desc-footer {
  border-top: 0;
  margin-top: 0;
}

.blockfilter {
  margin-bottom: 15px;
  min-height: 40px;
  overflow-x: auto;
}

.blockfilter label {
  font-weight: bold;
}

.blockfilter .selectbox {
  margin-right: 10px;
}

.blockfilter .selectbox select {
  border-radius: 30px;
  height: 32px;
}

.blockfilter .checkbox {
  margin-left: auto;
  margin-right: 0;
}

.blockfilter .inputfind {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-width: 250px;
  max-width: 400px;
  margin-left: 0;
  margin-right: auto;
}

.blockfilter .blockbtnhead {
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 calc(100% - 100px);
  -ms-flex: 1 1 calc(100% - 100px);
  flex: 1 1 calc(100% - 100px);
  margin-right: 8px;
}

.blockfilter .blockbtnhead * {
  white-space: nowrap;
}

.blockfilter .blockbtnhead:nth-child(2) {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  min-width: inherit;
  margin-left: 0;
  margin-right: auto;
}

.blockfilter button.btn-small.f12 {
  min-width: 72px;
  min-height: 32px;
}

.blockfilter .blockNoteChart {
  border: 1px solid #cdd5e5;
  padding: 5px 12px;
}

.blockfilter .blockNoteChart span {
  font-size: 12px;
  font-weight: bold;
  margin: 0 5px;
  position: relative;
  padding-left: 22px;
  color: #000;
}

.blockfilter .blockNoteChart span::after {
  position: absolute;
  content: "";
  width: 16px;
  height: 16px;
  left: 0;
  top: 50%;
  margin-top: -8px;
}

.blockfilter .blockNoteChart span.red::after {
  background: #ed5555;
}

.blockfilter .blockNoteChart span.green::after {
  background: #51c17d;
}

.blockfilter .blockNoteChart span.yellow::after {
  background: #f79f1f;
}

.blockfilter .blockNoteChart span.blue::after {
  background: #518ced;
}

.blockfilter_tabs .tabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.blockfilter_tabs .tabs button {
  border-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: #e7ecf5;
  color: #5e6d8e;
  font-size: 14px;
}

.blockfilter_tabs .tabs button.isActive,
.blockfilter_tabs .tabs button:hover {
  background: #5e6d8e;
  color: #fff;
  opacity: 1;
}

.blockdate {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 200px;
  position: absolute;
  left: 50%;
  margin-left: -100px;
}

.blockdate button {
  background: none;
}

.blockdate button:nth-child(2) {
  margin: 0 25px;
  font-weight: bold;
  font-size: 16px;
}

.blockdate button:nth-child(2) span {
  margin-right: 5px;
}

.blockdate button:hover {
  opacity: 0.7;
}

.blockdate .dateSelect {
  position: absolute;
  background: #ecf1f6;
  border-radius: 4px;
  top: 42px;
}

.blockdate .dateSelect ul {
  height: 200px;
  overflow-y: scroll;
  padding: 5px 0;
}

.blockdate .dateSelect ul::-webkit-scrollbar {
  width: 8px;
  height: 12px;
}

.blockdate .dateSelect ul::-webkit-scrollbar-track {
  background: #ecf1f6;
}

.blockdate .dateSelect ul::-webkit-scrollbar-thumb {
  background-color: rgba(114, 128, 146, 0.7);
  border-radius: 10px;
  border: 1px solid #fff;
}

.blockdate .dateSelect ul li {
  font-style: normal;
  font-size: 15px;
  padding: 5px 15px;
  cursor: pointer;
  position: relative;
}

.blockdate .dateSelect ul li:hover {
  background: #cfd8e0;
}

.blocktechnics {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-bottom: 20px;
}

.blocktechnics p {
  font-size: 14px;
}

.blocktechnics .require {
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  background: #ed5555;
  border-radius: 4px;
  display: inline-block;
  padding: 2px 5px 1px;
  margin-left: 5px;
}

.blocktechnics .left .text_bold {
  margin-bottom: 5px;
}

.blocktechnics .right {
  padding-left: 15px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 calc(100% - 240px);
  -ms-flex: 0 0 calc(100% - 240px);
  flex: 0 0 calc(100% - 240px);
}

.blocktechnics .right p.text_bold {
  margin-bottom: 5px;
}

.blocktechnics .right .name {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 calc(100% - 350px);
  -ms-flex: 0 0 calc(100% - 350px);
  flex: 0 0 calc(100% - 350px);
}

.blocktechnics .right .dflex_start {
  margin-bottom: 5px;
}

.blocktechnics .right .dflex_start:last-child {
  margin-bottom: 0;
}

.blocktechnics .right .partselect {
  margin-right: 15px;
}

.blocktechnics .right .prosthesis .selectstyle {
  margin-left: 15px;
}

.blocktechnics .right .point {
  margin-right: 30px;
}

.blocktechnics .right .request .selectstyle {
  margin-right: 20px;
}

.blocktechnics .bridge {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 330px;
  -ms-flex: 0 0 330px;
  flex: 0 0 330px;
  min-width: 330px;
  padding-left: 15px;
}

.blocktechnics .bridge .numberofbridge {
  margin-left: 15px;
}

.blocktechnics .bridge .selectstyle {
  margin-bottom: 4px;
}

.blocktechnics .selectstyle input[type="checkbox"] {
  border-radius: 4px;
  height: 20px;
  width: 20px;
  min-width: 20px;
  margin-right: 0;
}

.blocktechnics .selectstyle input[type="checkbox"]:checked::before {
  width: 18px;
  height: 18px;
  min-width: 18px;
}

.blocktechnics .selectstyle input[type="radio"] {
  margin-right: 0;
}

.blocktechnics .form-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.no-overflow {
  overflow: initial;
  position: relative;
}

.filterSelection {
  position: relative;
}

.filterSelection .filterGroup {
  z-index: 999;
}

.filterSelection span.txtlable {
  border: 1px solid #748091;
  font-size: 14px;
  border-radius: 30px;
  height: 32px;
  display: inline-block;
  line-height: 31px;
  padding: 0 30px 0 15px;
  cursor: pointer;
  background: url(../images/arr_downbold.svg) no-repeat right 5px center;
  background-size: 10px;
  margin-right: 10px;
}

.filterSelection .filterRow p {
  margin-bottom: 3px;
}

.filterSelection.filterEmployee .filterGroup {
  min-width: 760px;
  max-width: 760px;
}

.filterSelection.filterEmployee .listSelection {
  gap: 10px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.filterSelection.filterDate .filterGroup {
  max-width: 320px;
  min-width: 320px;
}

.filterSelection.filterDate .filterGroup .dflex {
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.filterSelection.filterDate .filterGroup input {
  padding: 10px 5px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.filterSelection.filterProject .filterGroup {
  max-width: 400px;
  min-width: 400px;
}

.filterSelection.filterProject .filterGroup .dflex {
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.filterSelection.filterProject .filterGroup select:first-child {
  width: 160px;
  margin-right: 10px;
}

.filterSelection.filterProject .filterGroup select:nth-child(2) {
  width: 200px;
}

.filterGroup {
  position: absolute;
  left: 0;
  top: 45px;
  z-index: 9;
  min-width: 680px;
  max-width: 680px;
  background: #f1f3f7;
  border-radius: 4px;
  padding: 20px 15px;
}

.filterGroup .head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
  background: none !important;
  height: auto !important;
  border: none !important;
}

.filterGroup .head p.text_bold {
  margin-right: 20px;
}

.filterGroup .head button {
  background: #99a3b1;
  border-radius: 15px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  padding: 2px 15px;
  margin: 0 10px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.16);
}

.filterGroup .head span {
  font-weight: normal;
  font-style: normal;
}

.filterGroup .listSelection {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.filterGroup .listSelection > .form-check-custom label {
  font-size: 14px;
  font-weight: bold;
  padding: 0 10px;
  min-width: 77px;
}

.filterGroup .listSelection > .form-check-custom .form-check-primary:checked {
  background: none;
}

.filterGroup
  .listSelection
  > .form-check-custom
  .form-check-primary:checked
  + label {
  background: #518ced;
  border-color: #518ced;
  color: #fff;
  font-weight: bold;
  padding: 0 10px;
  min-width: 77px;
}

.filterGroup .listSelection .buttonSel {
  background: #fff;
  border: 2px solid #a9a9a9;
  border-radius: 5px;
  padding: 5px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 calc(25% - 10px);
  -ms-flex: 0 0 calc(25% - 10px);
  flex: 0 0 calc(25% - 10px);
  cursor: pointer;
}

.filterGroup .listSelection .buttonSel span {
  font-weight: bold;
  font-size: 16px;
  color: #5f5f5f;
}

.filterGroup .listSelection .buttonSel span.num {
  margin-right: 5px;
}

.filterGroup .listSelection .buttonSel .status {
  color: #99a3b1;
}

.filterGroup .listSelection .buttonSel .status label {
  background: #99a3b1;
  font-weight: bold;
  color: #fff;
  font-size: 14px;
  padding: 2px 8px;
  margin-right: 10px;
}

.filterGroup .listSelection .buttonSel .status span {
  color: #99a3b1;
  font-size: 14px;
}

.filterGroup .listSelection .buttonSel.isActive {
  background: #f8fbff;
  border: 2px solid #5a90d9;
}

.filterGroup .listSelection .buttonSel.isActive .status label {
  background: #5a90d9;
}

.filterGroup .listSelection .buttonSel.isActive span {
  color: #5a90d9;
}

.filterGroup .filterRow {
  margin-top: 15px;
}

.filterGroup .filterRow:first-child {
  margin-top: 0;
}

.filterGroup .sublist {
  gap: 10px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.filterGroup .sublist .form-check-custom {
  margin-right: 0;
}

.filterGroup .sublist .form-check-custom label {
  font-size: 14px;
}

.filterGroup .listSelEmployee {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  gap: 10px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.filterGroup .listSelEmployee .buttonName {
  background: #f8fbff;
  border: 2px solid #5a90d9;
  border-radius: 5px;
  padding: 5px;
}

.filterGroup .listSelEmployee .buttonName span {
  font-weight: bold;
  font-size: 14px;
  color: #5a90d9;
}

.filterGroup .listSelEmployee .buttonName span.num {
  margin-right: 5px;
}

.filterGroup .listSelEmployee .buttonName .close {
  width: 12px;
  height: 12px;
  display: inline-block;
  background: url(../images/close2.svg) no-repeat center center;
  background-size: 12px;
  cursor: pointer;
  margin-left: 10px;
}

.filterGroup .scrollBlock {
  margin-top: 20px;
  overflow-y: auto;
  max-height: 300px;
}

.selectionList {
  background: #f5f6fa;
  border-radius: 4px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
  padding: 15px;
  position: absolute;
  max-width: 644px;
}

.selectionList::after {
  position: absolute;
  content: "";
}

.selectionList .listVariation {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-top: 5px;
}

.selectionList .listVariation li {
  background: #fff;
  border: 1px solid #5f646e;
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #5f646e;
  min-height: 48px;
  min-width: 144px;
  padding: 0 5px;
  margin-right: 12px;
  font-weight: bold;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  margin-bottom: 12px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 calc(25% - 12px);
  -ms-flex: 0 0 calc(25% - 12px);
  flex: 0 0 calc(25% - 12px);
}

.selectionList .listVariation li:nth-child(4n) {
  margin-right: 0;
}

.selectionList .listVariation li:hover {
  background: #518ced;
  color: #fff;
}

.selectionList-up::after {
  background: url(../images/polygon.png) no-repeat left center;
  background-size: cover;
  width: 59px;
  height: 38px;
  right: 140px;
  bottom: -38px;
}

.selectionList-down::after {
  background: url(../images/polygon.png) no-repeat left center;
  background-size: cover;
  width: 59px;
  height: 38px;
  right: 140px;
  bottom: -38px;
}

.blockCapture {
  background: url(../images/imgdum2.png) no-repeat center center;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.blockImageCaptured .images {
  background: #5f5f5f;
  border-radius: 8px;
  aspect-ratio: 4 / 3;
  overflow: hidden;
  max-height: 336px;
  position: relative;
}

.blockImageCaptured .images figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 4 / 3;
}

.blockImageCaptured .images .note {
  position: absolute;
  color: #fff;
  font-weight: bold;
  text-align: center;
  display: block;
  z-index: 9;
  width: 100%;
  top: 50%;
  margin-top: -12px;
  font-size: 22px;
}

.blockImageCaptured .images .dflex {
  position: absolute;
  right: 10px;
  top: 10px;
}

.blockImageCaptured .listbtnimg {
  margin: 10px 0;
}

.blockImageCaptured .listbtnimg button {
  margin-right: 10px;
}

.blockImageCaptured .listbtnimg button:last-child {
  margin-right: 0;
}

.blockImageCaptured .listbtnimg button:disabled {
  background: #dcdfe6;
  border-color: #dcdfe6;
}

.listPhoto .blockbtnfixed {
  max-width: 100%;
  width: 100%;
}

.listPhoto .status {
  font-size: 12px;
  font-weight: bold;
  border-radius: 15px;
  padding: 5px 10px;
  border-width: 2px;
  display: inline-block;
}

.listPhoto .form-check {
  min-height: inherit;
  margin-bottom: 0;
  padding-left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.listPhoto .form-check .form-check-input {
  cursor: pointer;
  margin: 0;
  vertical-align: middle;
}

.listPhoto table {
  position: relative;
}

.listPhoto table th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: #e7ecf5;
  z-index: 9;
}

.listPhoto table th:first-child .form-group {
  position: relative;
  top: 2px;
}

.listPhotoFilter {
  margin-bottom: 10px;
}

.listPhotoFilter .filterGroup {
  max-width: 880px;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.listPhotoFilter .filterGroup .filterRow {
  margin-top: 0;
  margin-right: 20px;
}

.listPhotoFilter .filterGroup .filterRow:last-child {
  margin-right: 0;
}

.listPhotoFilter .checkbox {
  margin-left: 20px;
}

.listPhotoFilter .inputbox {
  margin-left: 10px;
}

.listPhotoFilter .txtresult {
  margin-left: auto;
  margin-right: 0;
  position: relative;
}

.listPhotoFilter .txtresult p {
  font-size: 14px;
  font-weight: bold;
}

.listPhotoFilter .txtresult p.txtsort {
  background: url(../images/icon_sort.svg) no-repeat left center;
  padding-left: 30px;
  background-size: 20px;
  font-size: 12px;
  margin-left: 15px;
  cursor: pointer;
}

.listPhotoFilter .txtresult .list {
  position: absolute;
  right: 0;
  top: 42px;
  max-height: 370px;
  overflow-y: scroll;
  width: 230px;
  z-index: 99;
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  background-color: rgba(205, 213, 229, 0.26);
}

@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .listPhotoFilter .txtresult .list {
    -webkit-backdrop-filter: blur(25px);
    backdrop-filter: blur(25px);
  }
}

@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .listPhotoFilter .txtresult .list {
    background-color: rgba(205, 213, 229, 0.26);
  }
}

.listPhotoFilter .txtresult .list li {
  height: 46px;
  font-weight: bold;
  padding: 10px;
  cursor: pointer;
}

.listPhotoFilter .txtresult .list li.isActive {
  color: #518ced;
  background: url(../images/ico_check-primary.svg) no-repeat top 16px right 8px;
}

.blockdatetime {
  margin: 20px 0 10px;
}

.blockdatetime .datetime {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.blockdatetime .datetime button {
  margin: 0 20px;
}

.blockdatetime .dateSelect {
  position: absolute;
  background: #ecf1f6;
  border-radius: 4px;
  top: 55px;
  left: 50%;
  margin-left: -60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.blockdatetime .dateSelect ul {
  height: 200px;
  overflow-y: scroll;
  padding: 0;
}

.blockdatetime .dateSelect ul::-webkit-scrollbar {
  width: 8px;
  height: 12px;
}

.blockdatetime .dateSelect ul::-webkit-scrollbar-track {
  background: #ecf1f6;
}

.blockdatetime .dateSelect ul::-webkit-scrollbar-thumb {
  background-color: rgba(114, 128, 146, 0.7);
  border-radius: 10px;
  border: 1px solid #fff;
}

.blockdatetime .dateSelect ul li {
  font-style: normal;
  font-size: 15px;
  padding: 5px 15px;
  min-width: 60px;
  cursor: pointer;
  position: relative;
}

.blockdatetime .dateSelect ul li:hover {
  background: #cfd8e0;
}

.blockselectDental {
  background: #f5f6fa;
  border: 1px solid #748091;
  padding: 20px 30px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 65%;
  -ms-flex: 0 0 65%;
  flex: 0 0 65%;
  margin-right: 2%;
  border-radius: 10px;
}

.blockselectDental h5 {
  margin-bottom: 15px;
}

.blockselectDental .block .form-group-ins {
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-top: 5px;
}

.blockselectDental .block .ico-camera {
  background: #fff url(../images/ico_camera_b.svg) no-repeat left 8px center;
  padding-left: 45px;
}

.blockselectDental .block .ico-camera:hover {
  color: #518ced;
}

.blockselectDental .block .inputbox {
  margin-right: 10px;
}

.blockselectDental .block .form-select {
  max-width: 380px;
  margin-right: 10px;
}

.blockselectDental .block:last-child {
  margin-top: 15px;
  width: 100%;
}

.blockselectDental .block:last-child .inputbox {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 calc(45% - 30px);
  -ms-flex: 0 0 calc(45% - 30px);
  flex: 0 0 calc(45% - 30px);
  margin-right: 0;
}

.blockselectDental .block:last-child span {
  margin: 0 20px;
}

.blockselectDental .block:last-child button {
  min-width: 60px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 8%;
  -ms-flex: 0 0 8%;
  flex: 0 0 8%;
  margin-left: 10px;
}

.blockregistCsv {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 33%;
  -ms-flex: 0 0 33%;
  flex: 0 0 33%;
  border-radius: 10px;
  border: 1px solid #748091;
  padding: 20px 30px;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  position: relative;
}

.blockregistCsv h5 {
  color: #496ca0;
  margin-bottom: 15px;
}

.blockregistCsv .btn {
  position: absolute;
  right: 30px;
  bottom: 20px;
}

.instructionSearch {
  background: #f5f6fa;
  max-width: 90%;
  margin: 0 auto;
  padding: 30px;
  margin-bottom: 20px;
}

.instructionSearch .form-group {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  margin-right: 1%;
}

.instructionSearch .form-group:last-child {
  margin-right: 0;
}

.instructionSearch .dflex:first-child .form-group {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 20%;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
}

.instructionSearch .dflex:first-child .form-group:last-child {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 58%;
  -ms-flex: 0 0 58%;
  flex: 0 0 58%;
}

.instructionSearch .dflex:last-child {
  margin-top: 10px;
}

.instructionSearch .dflex:last-child .form-group .dflex {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-top: 0;
}

.instructionSearch .dflex:last-child .form-group .dflex .form-check {
  margin-right: 2%;
}

.instructionSearch .dflex:last-child .form-group .dflex .form-check:last-child {
  margin-right: 0;
}

.instructionSearch .dflex:last-child .form-group:first-child {
  max-width: 130px;
}

.instructionSearch .dflex:last-child .form-group:last-child {
  text-align: right;
  margin-top: 20px;
}

.statusregist {
  color: #fff;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 30px;
  font-size: 12px;
}

.blockTeeths {
  margin-top: 50px;
}

.blockTeeths span.text_right {
  font-weight: bold;
}

.blockTeeth {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 20px;
}

.blockTeeth-txt {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  height: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.blockTeeth-txt p {
  color: #fff;
  background-color: #2491ff;
  font-size: 18px;
  padding: 0 6px;
  border-radius: 20px;
  min-width: 30px;
  height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.blockTeeth-txtRight {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.blockTeeth-teethSelected {
  position: relative;
  margin: 0 10px;
}

.blockTeeth-teethSelected::after {
  position: absolute;
  content: "";
  background: #000;
  width: 100%;
  height: 1px;
  left: 0;
  top: 50%;
}

.blockTeeth-teethSelected::before {
  position: absolute;
  content: "";
  background: #000;
  width: 1px;
  height: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 0;
}

.blockTeeth-teethSelected .tooth {
  width: 40px;
  height: 40px;
  line-height: 1;
  margin: 0 0.05em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.blockTeeth-teethSelected .tooth.tooth1.tooth_left {
  margin-right: 5px;
}

.blockTeeth-teethSelected .tooth.tooth1.tooth_right {
  margin-left: 5px;
}

.blockTeeth-teethSelected .tooth i {
  font-style: normal !important;
  font-weight: 500;
  font-size: 30px;
}

.blockTeeth-teethSelected .tooth.round {
  border: 1px solid #000;
  border-radius: 50%;
}

.blockTeeth-teethSelected .tooth.triangle {
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid;
  padding-top: 12px;
}

.blockTeeth-teethSelected .tooth.triangle:before {
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  border-left: 1px solid;
  border-top: 1px solid;
  border-bottom: 1px solid transparent;
  -webkit-transform: rotate(45deg) skew(17deg, 17deg);
  -ms-transform: rotate(45deg) skew(17deg, 17deg);
  transform: rotate(45deg) skew(17deg, 17deg);
  left: -1px;
  bottom: -21px;
}

.blockTeeth-teethSelected .tooth.triangle i {
  font-size: 28px;
}

.blockTeeth-teethSelected .teethAlphabet {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.blockTeeth-teethSelected .teethAlphabet .triangle {
  padding-top: 14px;
}

.blockTeeth-teethSelected .listteeths {
  padding: 10px 0;
}

.blockTeeth-teethSelected .itemStart::after,
.blockTeeth-teethSelected .itemEnd::after,
.blockTeeth-teethSelected .itemMid::after {
  position: absolute;
  content: "";
  min-width: 30px;
  height: 7px;
  background: transparent;
}

.blockTeeth-teethSelected .itemStart::after {
  border-left: 1px solid #000;
  right: -12px;
}

.blockTeeth-teethSelected .itemMid::after {
  min-width: 42px;
}

.blockTeeth-teethSelected .itemMid i::after {
  position: absolute;
  content: "";
  background: #000;
  width: 1px;
  height: 7px;
  left: 1px;
  right: 0;
  margin: 0 auto;
}

.blockTeeth-teethSelected .itemEnd::after {
  border-right: 1px solid #000;
  left: -11px;
}

.blockTeeth-teethSelected .teethTop .itemStart::after,
.blockTeeth-teethSelected .teethTop .itemEnd::after,
.blockTeeth-teethSelected .teethTop .itemMid::after {
  border-top: 1px solid #000;
  top: -8px;
  height: 7px;
}

.blockTeeth-teethSelected .teethTop .itemMid i::after {
  top: -8px;
}

.blockTeeth-teethSelected .teethTop .triangle.itemStart::after,
.blockTeeth-teethSelected .teethTop .triangle.itemEnd::after,
.blockTeeth-teethSelected .teethTop .triangle.itemMid::after {
  height: 8px;
}

.blockTeeth-teethSelected .teethTop .triangle.itemMid i::after {
  height: 10px;
}

.blockTeeth-teethSelected .teethBottom .itemStart::after,
.blockTeeth-teethSelected .teethBottom .itemEnd::after,
.blockTeeth-teethSelected .teethBottom .itemMid::after {
  border-bottom: 1px solid #000;
  bottom: -8px;
}

.blockTeeth-teethSelected .teethBottom .itemMid i::after {
  bottom: -8px;
}

.blockTeeth-teethSelected .toLeft.itemStart::after {
  border-right: 1px solid #000;
  border-left: 0;
  right: 18px;
}

.blockTeeth-teethSelected .toLeft.itemEnd::after {
  border-left: 1px solid #000;
  border-right: 0;
  left: 18px;
}

ul.listtabs {
  border-bottom: 4px solid #2ecc87;
}

ul.listtabs li {
  font-size: 14px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding: 10px 10px 7px;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

ul.listtabs li:hover {
  opacity: 0.7;
}

ul.listtabs li.isActive {
  font-weight: bold;
  color: #fff;
}

ul.listtabs.listtabsSetting {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  border-bottom: 0;
}

ul.listtabs.listtabsSetting li {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  background: #e7ecf5;
}

ul.listtabs.listtabsSetting li.isActive,
ul.listtabs.listtabsSetting li:hover {
  opacity: 1;
  color: #fff;
  background: #5e6d8e;
}

ul.listtabs.listtabsmaxw {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

ul.listtabs.listtabsmaxw li {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}

ul.listtabs-icon {
  border-color: #518ced;
}

ul.listtabs-icon li {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  color: #518ced;
}

ul.listtabs-icon li.isActive,
ul.listtabs-icon li:hover {
  opacity: 1;
  color: #fff;
  background: #518ced;
}

ul.listtabs-photo {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 12px;
  border-bottom: 0;
}

ul.listtabs-photo li {
  color: #518ced;
  font-weight: 500;
  padding: 7px 10px;
  font-size: 14px;
  border: 1px solid #518ced;
  background: #fff;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  min-width: 100px;
  border-radius: 0;
}

ul.listtabs-photo li.-active {
  background: #518ced;
  color: #fff;
}

ul.listtabs-photo li:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

ul.listtabs-photo li:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.selectstyle {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.selectstyle.dflex-wrap li {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
}

.selectstyle label {
  cursor: pointer;
}

.selectstyle .showblock {
  margin-top: 10px;
  display: none;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
}

.selectstyle .showblock dl {
  margin-bottom: 10px;
}

.selectstyle .showblock dl input {
  margin-right: 10px;
}

.selectstyle .showblock dl:last-child {
  margin-bottom: 0;
}

.selectstyle li {
  margin-right: 40px;
}

.selectstyle li:last-child {
  margin-right: 0;
}

.selectstyle li label {
  cursor: pointer;
}

.selectstyle input[type="radio"],
.selectstyle input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 24px;
  min-width: 24px;
  width: 24px;
  -webkit-transition: all 0.15s ease-out 0s;
  transition: all 0.15s ease-out 0s;
  background: #fff;
  border: 1px solid #518ced;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  margin-right: 0.5rem;
  outline: none;
  top: -1px;
  z-index: 10;
}

.selectstyle input:checked {
  background: #518ced;
}

.selectstyle input:checked ~ .showblock {
  display: block;
}

.selectstyle input:checked::before {
  width: 22px;
  min-width: 22px;
  height: 22px;
  content: "";
  background: url(../images/icon_check_w.svg) no-repeat center center;
  background-size: 14px;
  position: absolute;
}

.selectstyle input:checked::after {
  background: #518ced;
  content: "";
  display: block;
  position: relative;
  z-index: 100;
}

.selectstyle input[type="radio"] {
  border-radius: 50%;
}

.selectstyle input[type="radio"]::after {
  border-radius: 50%;
}

.selectstyle-rouder {
  gap: 10px;
}

.selectstyle-rouder input[type="radio"],
.selectstyle-rouder input[type="checkbox"] {
  border-radius: 100%;
}

.selectstyle-rouder li {
  margin-right: 15px;
}

.listul_l {
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.listul_l ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  position: relative;
  padding-bottom: 4px;
  width: auto;
}

.listul_l .flexfullw li {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.listul_l .tab-container {
  border-radius: 6px 6px 0 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.listul_l .tab-container .box {
  white-space: nowrap;
  border-radius: 6px 6px 0 0;
  height: 46px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
}

.listul_l .tab-container > .box {
  width: auto;
  min-width: 88px;
}

.listul_l .tab-container > .box .text {
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-weight: bold;
  color: currentColor;
}

.listul_l .tab-container:hover .box {
  background: currentColor;
}

.listul_l .tab-container:hover .text {
  color: #fff;
}

.listul_l .tab-container:hover .line {
  background-color: currentColor;
}

.listul_l .tab-container.-active > .line {
  min-width: calc(100% - 150px);
  display: block;
  position: absolute;
  content: "";
  width: 100%;
  height: 4px;
  left: 0;
  bottom: 0;
  background-color: currentColor;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.listul_l .tab-container.-active > .box {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background-color: currentColor;
}

.listul_l .tab-container.-active > .box .text {
  color: #fff;
}

.listproducts {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 15px;
  position: relative;
}

.listproducts .item {
  -webkit-align-self: stretch;
      -ms-flex-item-align: stretch;
          align-self: stretch;
}

.listproducts .item.-active {
  background: #518ced;
}

.listproducts .item.-active span,
.listproducts .item.-active p {
  color: #fff;
}

.listproducts p,
.listproducts span {
  font-size: 14px;
  color: #518ced;
  font-weight: bold;
  line-height: 1.2;
}

.listproducts.listImplant .item img {
  top: -20px;
}

.listproducts.listImplant span {
  font-size: 12px;
}

.listproducts span.star {
  background: url(../images/ico_star.svg) no-repeat center center;
  background-size: 20px;
  width: 20px !important;
  height: 20px;
  position: absolute !important;
  right: 5px;
  top: 5px;
}

.listproducts .item {
  width: calc(20% - 12px);
  width: -ms-calc(20% - 12px);
  width: -o-calc(20% - 12px);
  max-width: 145px;
  float: left;
  text-align: center;
  border: 1px solid #518ced;
  border-radius: 5px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 10px;
  background: #fff;
  min-height: 100px;
}

@media all and (max-width: 1140px) {
  .listproducts .item {
    max-width: inherit;
  }
}

.listproducts .item:hover {
  opacity: 0.7;
}

.listproducts .item:hover img {
  opacity: 1;
}

.listproducts-noimg .item {
  min-height: 88px;
}

.listproducts .item img {
  position: relative;
  top: -5px;
  max-height: 50px;
  max-width: 60%;
}

.listproducts .item:nth-child(5n) {
  margin-right: 0px;
}

.listproducts .item p,
.listproducts .item span {
  width: 100%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  position: absolute;
  bottom: 3px;
  margin-bottom: 0px;
  line-height: 1.2;
}

.listproducts-noimg .item p,
.listproducts-noimg .item span {
  bottom: 0;
}

.listproducts-noimg .item p,
.listproducts-noimg .item span {
  position: relative;
}

.listproducts .item .radiobtn {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: -32px;
}

.listproducts input[name="productname"] {
  height: 26px;
  width: 90%;
  margin-bottom: 10px;
  font-size: 13px;
}

.listproducts input[name="productname"] ~ img {
  top: 0px;
}

.issuedList {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 12px;
  overflow-y: auto;
}

.issuedList .item {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 calc(25% - 9px);
  -ms-flex: 0 0 calc(25% - 9px);
  flex: 0 0 calc(25% - 9px);
  background: #fff;
  border: 1px solid #cdd5e5;
  border-radius: 4px;
  padding: 12px;
  margin-right: 12px;
  margin-bottom: 12px;
  font-weight: bold;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}

.issuedList .item:nth-child(4n) {
  margin-right: 0;
}

.issuedList .item_head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.issuedList .item_name {
  font-size: 16px;
  margin-top: 15px;
  margin-bottom: 20px;
}

.issuedList .item_address {
  font-size: 14px;
}

.issuedList .item .label label {
  border-radius: 15px;
  padding: 5px 10px;
  font-size: 12px;
  border: 2px solid currentColor;
  display: inline-block;
  color: currentColor;
}

.listMaterial {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.listMaterial li {
  border: 1px solid #518ced;
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #518ced;
  min-height: 48px;
  min-width: 144px;
  padding: 0 5px;
  margin-right: 12px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  margin-bottom: 12px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 calc(20% - 12px);
  -ms-flex: 0 0 calc(20% - 12px);
  flex: 0 0 calc(20% - 12px);
}

.listMaterial li:nth-child(5n) {
  margin-right: 0;
}

.listMaterial li:hover {
  background: #518ced;
  color: #fff;
}

.boxLoginQR {
  border: 1px solid #5f5f5f;
  border-radius: 8px;
  padding: 15px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 11;
  background: #fff;
  min-width: 240px;
}

.boxLoginQR .img {
  text-align: center;
  margin: 10px 0;
}

.implantpart .button {
  position: relative;
  border: 1px solid #748091;
  border-radius: 4px;
  width: 240px;
  height: 140px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.implantpart .button button {
  background: none;
  width: 100%;
  height: 100%;
}

.implantpart .button::after {
  position: absolute;
  content: "";
  background: url(../images/arrowdown.svg) no-repeat center center;
  width: 13px;
  height: 8px;
  background-size: contain;
  z-index: 9;
  top: 0;
  bottom: 0;
  right: 5px;
  margin: auto 0;
}

.listultag {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.listultag label {
  font-size: 12px;
  font-weight: bold;
  display: block;
  padding: 3px 15px;
  margin-right: 5px;
}

.listultag label.-active {
  border: 2px solid #5f5f5f;
}

.tableVariation p {
  font-size: 14px;
}

.tableVariation .dflex {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 100%;
}

.tableVariation thead .dflex .txtcode {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 200px;
  -ms-flex: 0 0 200px;
  flex: 0 0 200px;
}

.tableVariation td.hasDropdown {
  padding-bottom: 0;
}

.tableVariation .arrowDropdown {
  color: #518ced;
  font-size: 14px;
}

.tableVariation .txtCode {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 200px;
  -ms-flex: 0 0 200px;
  flex: 0 0 200px;
}

.tableVariation .txtVariation {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.listVariation {
  display: table;
  table-layout: auto;
  width: 100%;
  margin-top: 20px;
  border-top: 1px solid #cdd5e5;
}

.listVariation dl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #cdd5e5;
  min-height: 57px;
  position: relative;
}

.listVariation dl::after {
  position: absolute;
  content: "";
  background: #cdd5e5;
  width: 94px;
  right: -94px;
  height: 1px;
  bottom: -1px;
}

.listVariation dl:last-child {
  border-bottom: 0;
}

.listVariation dl:last-child::after {
  background: none;
}

.listVariation dl:first-child::before {
  position: absolute;
  content: "";
  background: #cdd5e5;
  width: 94px;
  right: -94px;
  top: -1px;
  height: 1px;
}

.listVariation dl dt {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 200px;
  -ms-flex: 0 0 200px;
  flex: 0 0 200px;
  padding: 8px 0;
}

.listVariation dl dd {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 8px 0;
}

.listMetalbridges {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 15px;
  margin-top: 5px;
  gap: 5px;
}

.listMetalbridges li {
  border: 1px solid #000;
  border-radius: 4px;
  padding: 3px 10px;
  font-weight: bold;
  font-size: 14px;
}

.listMetalbridges li:last-child {
  margin-right: 0;
}

.listMetalbridges li.isActive {
  border-color: #518ced;
  color: #518ced;
}

.listMetalbridges li.-active {
  background: #fffae8;
}

.instruction_tblist .tbhorizontal {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

.instruction_tblist table thead {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  top: 0;
  z-index: 9;
}

.instruction_tblist table .label span {
  font-size: 12px;
  font-weight: bold;
  border-radius: 15px;
  padding: 3px 8px;
  margin-left: 5px;
  border-width: 2px;
  border-style: solid;
}

.title-ico-question {
  background: url(../images/icon_question.svg) no-repeat right center;
  padding-right: 35px;
}

.modal-dialog .modal-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.modal-dialog .modal-title span {
  margin-left: 10px;
}

.modal-dialog .listproducts span.star {
  width: 24px !important;
  height: 24px;
  background-size: 24px;
}

.modal-dialog .listproducts span.star.hasNum {
  position: relative;
}

.modal-dialog .listproducts span.star.hasNum::after {
  position: absolute;
  content: attr(data-number);
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  padding-top: 2px;
  font-size: 12px;
  font-weight: bold;
  width: 24px;
  height: 24px;
}

#instruction .modal-content {
  background: none;
}

#instruction .modal-dialog {
  background: #f5f6fa;
}

#instruction .modal-body .issuedList {
  max-height: calc(100vh - 280px);
}

.modal-footer {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

#addCategory .modal-dialog {
  max-width: 500px;
}

#addCategory .modal-body {
  padding: 30px 15px;
}

#instructionCfr .modal-dialog {
  width: 96vw;
  max-width: 960px;
  max-height: 710px;
}

#instructionCfr .modal-body {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding: 0;
}

@media screen and (max-height: 760px) {
  #instructionCfr .modal-body {
    overflow-y: hidden;
    max-height: calc(100vh - 120px);
  }
}

#instructionCfr .modal-body .blockLeft,
#instructionCfr .modal-body .blockRight {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 50%;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
}

#instructionCfr .modal-body .blockRight {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  max-height: calc(100% - 52px);
}

#instructionCfr .modal-body .blockRight .blockImg {
  height: calc(100% - 52px);
  overflow: hidden;
  z-index: 1;
  position: relative;
}

#instructionCfr .modal-body .blockRight .blockBtn {
  height: 52px;
  position: relative;
  z-index: 2;
}

#instructionCfr .modal-body .blockRight .img {
  height: 100%;
  position: relative;
}

#instructionCfr .modal-body .blockRight .img img {
  object-fit: cover;
  width: 100%;
  left: 50%;
  top: 50%;
  position: absolute;
  min-height: 100%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#instructionCfr .modal-body .blockLeft {
  max-height: calc(100vh - (1.75rem * 2) - 75px);
  overflow-y: auto;
  border-right: 1px solid #cdd5e5;
}

#instructionCfr .modal-body .blockLeft .blockCard:last-child {
  margin-bottom: 0;
}

#instructionCfr .modal-body .blockCard {
  border: none;
}

#instructionCfr .modal-body .blockBtn button {
  min-height: 32px;
  height: 32px;
}

.modalAlert .modal-dialog {
  max-width: 450px;
}

.modalAlert .modal-body {
  padding-top: 60px;
  position: relative;
  overflow-y: inherit;
}

.modalAlert .modal-body .ico {
  position: absolute;
  left: 50%;
  margin-left: -32px;
  top: -30px;
}

.modalAlert .modal-body .title {
  margin-bottom: 20px;
  font-weight: bold !important;
}

.modalAlert .modal-body p {
  font-size: 18px;
  line-height: 1.4;
}

.modalAlert .modal-footer {
  box-shadow: none;
}

.modalAlert .modal-footer .btn-outline-success {
  border-color: #51c17d;
  color: #51c17d;
}

.modalAlert .modal-footer .btn-outline-success:hover {
  background: #fff;
}

.modalAlert .modal-footer .btn-success {
  background: #51c17d;
  border-color: #51c17d;
}

.modalAlert .noteInfo {
  background: #f5f6fa;
  border-radius: 4px;
  padding: 10px;
  margin-top: 20px;
}

.modalAlert .noteInfo p {
  margin-bottom: 0;
  line-height: 1.4;
}

#selectionMaterial .modal-dialog,
#choosingImplant .modal-dialog {
  max-width: 800px;
}

#selectionMaterial .modal-body,
#choosingImplant .modal-body {
  min-height: 260px;
}

#implantSelection .modal-dialog {
  max-width: 800px;
}

#implantSelection .modal-body {
  min-height: 520px;
}

@media screen and (max-height: 700px) {
  #implantSelection .modal-body {
    min-height: inherit;
  }
}

#implantSelection .listImplant {
  max-width: 100%;
  overflow-y: auto;
  max-height: 520px;
  padding-bottom: 15px;
}

@media screen and (max-height: 700px) {
  #implantSelection .listImplant {
    min-height: inherit;
    max-height: calc(100vh - 260px);
  }
}

#implantSelection .listImplant label {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}

#implantSelection .listImplant img {
  top: -20px;
}

#implantSelection .listImplant p {
  font-size: 12px;
}

#Selection .modal-dialog {
  max-width: 800px;
}

#Selection .modal-body {
  padding: 0;
  min-height: 520px;
}

@media screen and (max-height: 700px) {
  #Selection .modal-body {
    min-height: inherit;
  }
}

#Selection .listultag {
  padding: 0 15px;
}

#Selection .listproducts {
  padding: 15px;
  padding-bottom: 30px;
  overflow-y: auto;
  max-height: 520px;
}

@media screen and (max-height: 700px) {
  #Selection .listproducts {
    min-height: inherit;
    max-height: calc(100vh - 230px);
  }
}

#Selection .scrollblock {
  overflow-y: auto;
  max-height: 520px;
  padding-bottom: 75px;
}

@media screen and (max-height: 700px) {
  #Selection .scrollblock {
    min-height: inherit;
    max-height: calc(100vh - 230px);
  }
}

#Selection .scrollblock .listproducts {
  max-height: inherit;
  overflow-y: initial;
  padding-bottom: 15px;
}

#selectionMetal .modal-dialog {
  width: 1200px;
}

#selectionMetal .bluetit {
  text-align: left;
}

#selectionMetal .modal-body {
  padding: 0;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  min-height: 520px;
}

@media screen and (max-height: 700px) {
  #selectionMetal .modal-body {
    min-height: inherit;
  }
}

#selectionMetal .blockLeft {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 calc(100% - 380px);
  -ms-flex: 1 1 calc(100% - 380px);
  flex: 1 1 calc(100% - 380px);
  padding: 20px;
  overflow-y: auto;
  min-height: 450px;
  max-height: 600px;
}

#selectionMetal .blockRight {
  width: 380px;
  background: #f5f6fa;
  border-left: 1px solid #cdd5e5;
  overflow-y: auto;
  padding: 15px;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  max-height: 600px;
}

#selectionMetal .blockRight .blockCard_Desc-group:first-child {
  border-top: 0;
  margin-top: 0;
  padding-top: 0;
}

#completionModal .modal-dialog {
  max-width: 500px;
}

#completionModal .bridge {
  margin: 15px 0 0;
  padding: 10px;
  background: #f5f6fa;
}

#completionModal .bridge li {
  font-weight: bold;
  padding: 5px;
}

#teethsSelection .modal-dialog {
  max-width: 850px;
}

#teethsSelection .modal-body {
  padding: 20px;
  min-height: 520px;
}

@media screen and (max-height: 700px) {
  #teethsSelection .modal-body {
    min-height: inherit;
  }
}

#teethsSelection .modal-footer {
  box-shadow: none;
}

.selectionTrials {
  max-width: 350px;
  margin: 0 auto;
}

.selectionTrials button {
  text-align: center;
  width: 150px;
  height: 64px;
}

.selectionTrials button small {
  display: block;
}

.instructionsCheck .modal-dialog {
  max-width: 500px;
}

.instructionSearchPrevious .modal-content {
  max-height: 94vh;
}

.instructionSearchPrevious .modal-body {
  padding: 0;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  overflow-y: hidden;
}

.instructionSearchPrevious .blockLeft,
.instructionSearchPrevious .blockRight {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 50%;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
}

.instructionSearchPrevious .blockCard {
  border: none;
  margin: 0;
  background: #f5f6fa;
}

.instructionSearchPrevious .blockRight {
  padding: 10px;
}

.instructionSearchPrevious .blockRight ul.dflex {
  background: #fff;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 12px;
}

.instructionSearchPrevious .blockRight ul.dflex li {
  color: #518ced;
  font-weight: 500;
  padding: 7px 18px;
  font-size: 14px;
  border: 1px solid #518ced;
}

.instructionSearchPrevious .blockRight ul.dflex li.-active {
  background: #518ced;
  color: #fff;
}

.instructionSearchPrevious .blockRight ul.dflex li:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.instructionSearchPrevious .blockRight ul.dflex li:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.instructionSearchPrevious .blockRight button {
  padding-left: 1rem;
  padding-right: 1rem;
}

.instructionSearchPrevious .blockRight button:disabled {
  background: #dcdfe6;
  color: #fff;
  border-color: #dcdfe6;
}

.instructionSearchPrevious .table {
  margin-top: 10px;
  max-height: calc(94vh - 400px);
  overflow-y: auto;
}

.instructionSearchPrevious .table table thead {
  background: #e7ecf5;
}

.instructionSearchPrevious .table table thead th {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  top: 0;
  z-index: 9;
  background: #e7ecf5;
}

.instructionSearchPrevious .table table tr.hasbg {
  background: #ffefef;
}

.instructionSearchPrevious .table table td {
  padding: 12px 10px;
  border-bottom: 1px solid #cdd5e5;
}

.instructionSearchPrevious .table table td.hasbg {
  background: #ffefef;
}

.instructionSearchPrevious .table table td label {
  color: #fff;
  padding: 7px 15px;
  border-radius: 12px;
  margin-right: 10px;
}

.instructionSearchPrevious .table table td label.bgred {
  background: #ed5555;
}

#numberEdit h4 {
  margin-bottom: 20px;
}

#numberEdit .modal-dialog {
  max-width: 620px;
}

#numberEdit .modal-dialog p,
#numberEdit .modal-dialog input,
#numberEdit .modal-dialog select {
  font-size: 14px;
}

#numberEdit .modal-dialog .form-group.dflex .form-group-ins {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

#numberEdit .modal-dialog .form-group.dflex .form-group-ins:nth-child(2) {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 10px;
  -ms-flex: 0 0 10px;
  flex: 0 0 10px;
  margin: 0 20px;
}

#personSelect .modal-dialog {
  max-width: 380px;
}

#personSelect .info {
  margin-top: 20px;
  margin-bottom: 50px;
}

#personSelect .info dl {
  margin-bottom: 12px;
}

#personSelect .info dt {
  font-size: 14px;
  margin-bottom: 5px;
}

#personSelect .info .checkbox label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

#personSelect .info .form-check-input {
  border-radius: 100%;
}

#processConfirm .modal-dialog {
  max-width: 590px;
}

#processConfirm .info {
  margin-top: 20px;
  margin-bottom: 30px;
}

#processConfirm .info .name p:not(.note) {
  font-size: 12px;
  color: #99a3b1;
  font-weight: bold;
}

#processConfirm .info .name p:not(.note).text_bold {
  font-size: 16px;
  color: #000;
}

#processConfirm .info .name label {
  border: 2px solid #000;
  padding: 1px 8px 0;
  font-weight: bold;
  display: inline-block;
  margin-top: 10px;
}

#processConfirm .info .number {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
  width: auto;
  margin-right: 0;
}

#processConfirm .info .number .dflex {
  width: 320px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-right: 20px;
}

#processConfirm .info .number p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 13px;
  font-weight: bold;
  line-height: 1;
}

#processConfirm .info .number p span {
  font-weight: bold;
  font-size: 20px;
  line-height: 1;
  margin: 0 3px;
  position: relative;
  top: -2px;
}

#processConfirm .info .goal,
#processConfirm .info .total {
  width: 80px;
}

#processConfirm .info .revised {
  width: 80px;
}

#processConfirm table th,
#processConfirm table td {
  padding: 5px 0;
}

#processConfirm table th:first-child,
#processConfirm table td:first-child {
  width: 320px;
}

#processConfirm table th:nth-child(2),
#processConfirm table td:nth-child(2) {
  width: 80px;
  text-align: right;
  padding-right: 10px;
}

#processConfirm table label {
  background: #99a3b1;
  padding: 3px 8px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  font-size: 12px;
}

#processConfirm table label.outline {
  border: 2px solid #000;
  padding: 1px 8px 0;
  background: #fff;
  margin-right: 5px;
  color: #000;
}

#processConfirm table thead th {
  padding: 0;
}

#processConfirm table thead th .goal {
  width: 85px;
  display: inline-block;
  margin-right: 16px;
}

#processConfirm table thead th label {
  background: #fff;
  width: 72px;
  display: inline-block;
}

#processConfirm table tbody tr:first-child td {
  padding-top: 2px;
}

#processConfirm table tbody td {
  vertical-align: middle;
}

#processConfirm table tbody td:last-child {
  text-align: right;
}

#processConfirm table.tbUnmeasured th:first-child,
#processConfirm table.tbUnmeasured td:first-child {
  width: 240px;
}

#processConfirm table.tbUnmeasured th:nth-child(2),
#processConfirm table.tbUnmeasured td:nth-child(2) {
  width: auto;
}

#processConfirm table.tbUnmeasured th:nth-child(2) span {
  display: inline-block;
  text-align: left;
}

#processConfirm table.tbUnmeasured th:nth-child(2) .total {
  width: 80px;
  margin-right: 55px;
}

#processConfirm table button:disabled {
  background: #dcdfe6;
}

#processConfirm table .time {
  max-width: 80px;
}

#processConfirm table .num {
  min-width: 55px;
  text-align: right;
}

#processConfirm table .num span {
  font-size: 20px;
  font-weight: bold;
}

#processConfirm table .num span small {
  font-size: 13px;
  font-weight: bold;
}

.titblue {
  background: #518ced;
  margin-top: 20px;
}

.titblue p {
  font-weight: bold;
  font-size: 17px;
  color: #fff;
  text-align: center;
  padding: 10px;
}

.titgray {
  background: #ebebeb;
  margin: 15px 0;
}

.titgray p {
  font-size: 17px;
  padding: 10px 20px;
}

.titline {
  position: relative;
  text-align: center;
}

.titline::after {
  position: absolute;
  content: "";
  background: #7f8fa6;
  width: 100%;
  height: 1px;
  left: 0;
  top: 50%;
}

.titline span {
  background: #fff;
  padding: 5px 20px;
  position: relative;
  z-index: 2;
}

.titHead {
  text-align: center;
  margin-bottom: 40px;
}

.titHead h2 {
  font-size: 32px;
  background: url(../images/logo3.svg) no-repeat left center;
  background-size: 36px;
  padding-left: 45px;
  font-weight: normal;
  display: inline-block;
}

.bluetit {
  background: #518ced;
  padding: 10px;
}

.bluetit h3 {
  color: #fff;
}

.orgtit {
  background: #f0932b;
  padding: 10px;
}

.orgtit h3 {
  color: #fff;
}

.noborder {
  border: none !important;
  margin: 0 !important;
}

.boxborder {
  border: 1px solid #cdd5e5;
  border-radius: 6px;
  padding: 15px;
}

.layoutFirst .head {
  background: #fff;
  height: 56px;
  border-bottom: 1px solid #cdd5e5;
}

.layoutFirst .head .logo a {
  height: 56px;
  width: 56px;
  background: #518ced;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.layoutFirst .head .logo a:hover {
  opacity: 0.7;
}

.layoutFirst .head .logo a:hover img {
  opacity: 1;
}

.layoutFirst .head .logo img {
  height: 28px;
}

.layoutFirst .head .btnBack {
  margin-left: 25px;
}

.layoutFirst .head .btnBack a, .layoutFirst .head .btnBack span {
  background: url(../images/arr_backbold.svg) no-repeat left center;
  padding-left: 24px;
  font-weight: 500;
  cursor: pointer;
}

.layoutFirst .head .btnBack.ico-q {
  background: url(../images/icon_question.svg) no-repeat right center;
  padding-right: 35px;
}

.layoutFirst .head_left {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  max-width: calc(100% - 480px);
}

@media all and (max-width: 1180px) {
  .layoutFirst .head_left {
    max-width: 69%;
  }
}

.layoutFirst .head_right {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding: 0 15px;
  height: 100%;
  min-width: 420px;
}

@media all and (min-width: 1180px) {
  .layoutFirst .head_right {
    min-width: 480px;
  }
}

.layoutFirst .head_right button {
  font-size: 14px;
  min-height: 40px;
}

.layoutFirst .head_right-txt p,
.layoutFirst .head_right-txt .num {
  line-height: 1.2;
}

.layoutFirst .chooseBlock {
  padding: 15px;
}

.layoutFirst .scrollBlock {
  overflow: auto;
  height: calc(100vh - 200px);
  padding: 0 15px;
}

.layoutFirst .scrollBlock > .dflex {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  gap: 15px;
  padding-right: 15px;
}

.layoutFirst .scrollBlock .blockCard {
  max-width: 450px;
  min-width: 450px;
}

.layoutFirst .scrollBlock.lockedScroll {
  overflow-y: hidden;
}

.layoutFirst .scrollBlock.lockedScroll.noFooter {
  height: calc(100vh - 130px);
}

.layoutFirst .scrollBlock.lockedScroll.noFooter .blockCard.hasScroll {
  max-height: calc(100vh - 150px);
}

.layoutFirst .blockbtnfixed {
  max-width: 100%;
  width: 100%;
}

.layoutSecond .head {
  background: #fff;
  height: 56px;
  border-bottom: 1px solid #cdd5e5;
  z-index: 1;
  position: relative;
}

.layoutSecond .head .logo a {
  height: 56px;
  width: 56px;
  background: #518ced;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
}

.layoutSecond .head .logo a:hover {
  opacity: 0.7;
}

.layoutSecond .head .logo a:hover img {
  opacity: 1;
}

.layoutSecond .head .logo img {
  height: 28px;
}

.layoutSecond .head .titleHead {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
}

.layoutSecond .head .titleHead button {
  background: none;
  -webkit-transition: all .3s;
  transition: all .3s;
  width: 48px;
  height: 48px;
  margin: 0 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

.layoutSecond .head .titleHead button:hover button {
  opacity: .7;
}

.layoutSecond .head .titleHead .btnBack {
  margin-left: 0;
}

.layoutSecond .head .titleHead .btnBack h2 {
  font-size: 18px;
}

.layoutSecond .head .btnBack {
  margin-left: 25px;
}

.layoutSecond .head .btnBack a, .layoutSecond .head .btnBack span {
  background: url(../images/arr_backbold.svg) no-repeat left center;
  padding-left: 24px;
  font-weight: 500;
  cursor: pointer;
}

.layoutSecond .head .btnBack.ico-q {
  background: url(../images/icon_question.svg) no-repeat right center;
  padding-right: 35px;
}

.layoutSecond .head_left {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  max-width: calc(100% - 480px);
}

@media all and (max-width: 1180px) {
  .layoutSecond .head_left {
    max-width: 69%;
  }
}

.layoutSecond .head_right {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 31%;
  -ms-flex: 0 0 31%;
  flex: 0 0 31%;
  max-width: 480px;
  padding: 0 15px;
  border-left: 1px solid #cdd5e5;
  height: 100%;
}

@media all and (min-width: 1180px) {
  .layoutSecond .head_right {
    min-width: 480px;
  }
}

.layoutSecond .head_right.borderNone {
  border-left: 0;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  max-width: inherit;
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
}

.layoutSecond .head_right button {
  font-size: 14px;
  min-height: 40px;
}

.layoutSecond .head_right button.f12 {
  min-height: 32px;
}

.layoutSecond .head_right-btn button:disabled {
  background-color: #dcdfe6;
  border-color: #dcdfe6;
}

.layoutSecond .head_right-txt p,
.layoutSecond .head_right-txt .num {
  line-height: 1.2;
}

.layoutSecond .head_right-txt p {
  font-size: 12px;
}

.layoutSecond .head_right .ico-more {
  position: relative;
  margin-left: 10px;
}

.layoutSecond .head_right .blockPrintCsv {
  display: none;
  border-radius: 4px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.16);
  background: #fff;
  position: absolute;
  z-index: 99;
  width: 230px;
  right: 0;
  top: 40px;
}

.layoutSecond .head_right .blockPrintCsv button {
  padding: 10px;
  background: none;
  width: 100%;
  text-align: left;
}

.layoutSecond .head_right .blockPrintCsv button:hover {
  opacity: 0.5;
}

.layoutSecond .head_right .blockPrintCsv button span {
  line-height: 1;
}

.layoutSecond .head_right .blockPrintCsv button small {
  font-size: 12px;
  display: block;
}

.layoutSecond .head_right .blockPrintCsv button.btn-print {
  background: url(../images/ico_printer_b.svg) no-repeat left 12px center;
  padding-left: 45px;
}

.layoutSecond .head_right .blockPrintCsv button.btn-uploadcsv {
  background: url(../images/ico_upload_b.svg) no-repeat left 12px center;
  padding-left: 45px;
}

.layoutSecond .head_center .blockdatetime {
  margin: 0;
}

.layoutSecond .head_center .blockdatetime span small {
  font-size: 12px;
  display: block;
}

.layoutSecond .head_hasDateTime {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.layoutSecond .head_hasDateTime .head_left,
.layoutSecond .head_hasDateTime .head_right,
.layoutSecond .head_hasDateTime .head_center {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 33%;
  -ms-flex: 0 0 33%;
  flex: 0 0 33%;
  min-width: inherit;
  margin: 0;
}

/* fix responsive EVMRE-4801 */
.head_left_custom {
  margin: 0;
  display: flex;
  flex: auto;
}

.layoutSecond .head_hasDateTime .head_left .buttonTimeline {
  margin-left: auto;
  margin-right: 0;
}

.layoutSecond .head_hasDateTime .head_left .buttonTimeline button {
  margin-left: 10px;
}

.layoutSecond .content {
  height: calc(100vh - 56px);
  overflow: hidden;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.layoutSecond .content_left {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  overflow-y: auto;
  height: 100%;
  max-width: calc(100% - 480px);
  background: #fff;
  position: relative;
}

.layoutSecond .content_left.hasCapture {
  overflow-y: hidden;
}

@media all and (max-width: 1180px) {
  .layoutSecond .content_left {
    max-width: 69%;
  }
}

.layoutSecond .content_right {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 31%;
  -ms-flex: 0 0 31%;
  flex: 0 0 31%;
  max-width: 480px;
  overflow-y: auto;
  background: #f5f6fa;
  height: 100%;
  border-left: 1px solid #cdd5e5;
  position: relative;
}

@media all and (min-width: 1180px) {
  .layoutSecond .content_right {
    min-width: 480px;
  }
}

.layoutSecond .content_right.fixedScroll {
  overflow-y: hidden;
}

.blockScrolly {
  overflow-y: scroll;
}

.blockScrollx {
  overflow-x: scroll;
}

.layoutThird {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  height: 100vh;
}

.layoutThird input[type="text"],
.layoutThird input[type="email"],
.layoutThird input[type="name"],
.layoutThird input[type="tel"],
.layoutThird input[type="number"],
.layoutThird input[type="password"],
.layoutThird select,
.layoutThird textarea {
  border: 1px solid #748091;
  font-size: 14px;
}

.layoutThird input[type="text"]::-webkit-input-placeholder,
.layoutThird input[type="email"]::-webkit-input-placeholder,
.layoutThird input[type="name"]::-webkit-input-placeholder,
.layoutThird input[type="tel"]::-webkit-input-placeholder,
.layoutThird input[type="number"]::-webkit-input-placeholder,
.layoutThird input[type="password"]::-webkit-input-placeholder,
.layoutThird select::-webkit-input-placeholder,
.layoutThird textarea::-webkit-input-placeholder {
  color: #999;
}

.layoutThird input[type="text"]::-moz-placeholder,
.layoutThird input[type="email"]::-moz-placeholder,
.layoutThird input[type="name"]::-moz-placeholder,
.layoutThird input[type="tel"]::-moz-placeholder,
.layoutThird input[type="number"]::-moz-placeholder,
.layoutThird input[type="password"]::-moz-placeholder,
.layoutThird select::-moz-placeholder,
.layoutThird textarea::-moz-placeholder {
  color: #999;
}

.layoutThird input[type="text"]:-ms-input-placeholder,
.layoutThird input[type="email"]:-ms-input-placeholder,
.layoutThird input[type="name"]:-ms-input-placeholder,
.layoutThird input[type="tel"]:-ms-input-placeholder,
.layoutThird input[type="number"]:-ms-input-placeholder,
.layoutThird input[type="password"]:-ms-input-placeholder,
.layoutThird select:-ms-input-placeholder,
.layoutThird textarea:-ms-input-placeholder {
  color: #999;
}

.layoutThird input[type="text"]::-ms-input-placeholder,
.layoutThird input[type="email"]::-ms-input-placeholder,
.layoutThird input[type="name"]::-ms-input-placeholder,
.layoutThird input[type="tel"]::-ms-input-placeholder,
.layoutThird input[type="number"]::-ms-input-placeholder,
.layoutThird input[type="password"]::-ms-input-placeholder,
.layoutThird select::-ms-input-placeholder,
.layoutThird textarea::-ms-input-placeholder {
  color: #999;
}

.layoutThird input[type="text"]::placeholder,
.layoutThird input[type="email"]::placeholder,
.layoutThird input[type="name"]::placeholder,
.layoutThird input[type="tel"]::placeholder,
.layoutThird input[type="number"]::placeholder,
.layoutThird input[type="password"]::placeholder,
.layoutThird select::placeholder,
.layoutThird textarea::placeholder {
  color: #999;
}

.layoutThird table.tbstyle1 th,
.layoutThird table.tbstyle1 td {
  padding-left: 0;
}

.layoutThird .blockfixbottom {
  max-width: calc(100% - 200px);
  z-index: 99;
}

.layoutThird .blockbtn .btn {
  min-width: 96px;
  padding: 11px 10px;
}

.layoutThird .blockbtn .btn:first-child {
  margin-left: 0;
}

.layoutThird .content {
  background: #fff;
}

.layoutThird .content_head {
  z-index: 1;
}

.layoutThird .content_inside {
  z-index: 0;
}

#layoutSetting .layoutThird .content_head,
#layoutSetting .layoutThird .content_inside{
  z-index: inherit;
}

.tbhorizontal {
  height: 100%;
  overflow: auto;
}

@media all and (max-width: 1140px) {
  .tbhorizontal {
    overflow: auto;
  }
  .tbhorizontal table {
    min-width: 900px;
  }
}

.tbNohorizontal {
  height: 100%;
  overflow: auto;
}

.inputsearch {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.inputsearch input {
  border: 1px solid #518ced;
  width: 215px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.inputsearch button {
  background: #518ced;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 44px;
  height: 44px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.toggle-button-cover {
  display: table-cell;
  position: relative;
  box-sizing: border-box;
  width: 96px;
  height: 32px;
}

.toggle-button-cover label {
  font-size: 12px;
  color: #518ced;
  font-weight: bold;
  cursor: pointer;
}

.toggle-button-cover .mode-switch {
  display: inline-block;
  margin: 0;
  position: relative;
  cursor: pointer;
}

.toggle-button-cover .mode-switch > label.mode-switch-inner {
  margin: 0;
  width: 96px;
  height: 32px;
  background: #0055b1;
  border-radius: 26px;
  overflow: hidden;
  position: relative;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  display: block;
}

.toggle-button-cover .mode-switch > label.mode-switch-inner:before {
  content: attr(data-on);
  position: absolute;
  font-size: 12px;
  font-weight: bold;
  top: 7px;
  right: 12px;
}

.toggle-button-cover .mode-switch > label.mode-switch-inner:after {
  content: attr(data-off);
  width: 48px;
  height: 28px;
  line-height: 28px;
  background: #fff;
  border-radius: 15px;
  position: absolute;
  left: 3px;
  top: 2px;
  text-align: center;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.toggle-button-cover .mode-switch input[type="checkbox"] {
  cursor: pointer;
  width: 38px;
  height: 28px;
  opacity: 0;
  position: absolute;
  top: 0;
  z-index: 1;
  margin: 0;
}

.toggle-button-cover
  .mode-switch
  input[type="checkbox"]:checked
  + label.mode-switch-inner {
  background: #0055b1;
  color: #fff;
}

.toggle-button-cover
  .mode-switch
  input[type="checkbox"]:checked
  + label.mode-switch-inner:after {
  content: attr(data-on);
  left: 46px;
  color: #518ced;
}

.toggle-button-cover
  .mode-switch
  input[type="checkbox"]:checked
  + label.mode-switch-inner:before {
  content: attr(data-off);
  right: auto;
  left: 12px;
  color: #518ced;
}

.page_login,
.page_regist {
  padding-top: 80px;
  padding-bottom: 50px;
  min-height: 100vh;
  background: #fff;
}

.login {
  max-width: 450px;
}

.login .btn {
  min-width: 200px;
}

.regist {
  max-width: 960px;
}

.regist .registProcess {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  max-width: 520px;
  margin: 0 auto;
  margin-bottom: 50px;
  position: relative;
}

.regist .registProcess::after {
  position: absolute;
  content: "";
  background: #5f5f5f;
  width: 400px;
  height: 2px;
  left: 50%;
  margin-left: -200px;
  top: 0%;
}

.regist .registProcess li {
  font-size: 14px;
  color: rgba(95, 95, 95, 0.5);
  position: relative;
  padding-top: 20px;
  width: 160px;
  text-align: center;
  z-index: 2;
}

.regist .registProcess li::after {
  position: absolute;
  content: "";
  background: #5f5f5f;
  width: 12px;
  height: 12px;
  left: 50%;
  margin-left: -6px;
  top: 0;
  margin-top: -5px;
  border-radius: 100%;
}

.regist .registProcess li:first-child {
  text-align: left;
}

.regist .registProcess li:first-child::after {
  margin-left: -20px;
}

.regist .registProcess li:last-child {
  text-align: right;
}

.regist .registProcess li:last-child::after {
  margin-right: -20px;
  margin-left: inherit;
  left: inherit;
  right: 50%;
}

.regist .registProcess li.active::after {
  width: 20px;
  height: 20px;
  background: #518ced;
  margin-top: -9px;
}

.regist .registProcess li.active span {
  color: #518ced;
}

.regist table .w49pc {
  max-width: inherit;
}

.regist .blockborder:not(.boxlabsearch) {
  min-height: 330px;
  position: relative;
  padding-bottom: 124px;
}

.regist .blockborder:not(.boxlabsearch) .btn-primary {
  position: absolute;
  bottom: 40px;
  max-width: calc(100% - 80px);
  font-size: 14px;
}

.boxlabsearch {
  max-width: 840px;
}

.boxSearchresult {
  max-width: 840px;
  margin: 0 auto;
  max-height: 220px;
  overflow-y: auto;
  padding: 10px 0;
}

.boxSearchresult .item {
  border: 1px solid #5f5f5f;
  padding: 10px;
  border-radius: 10px;
  width: 80%;
}

.boxSearchresult .btn {
  width: 18%;
}

.txtSearchresult {
  max-width: 840px;
  margin: 0 auto;
}

.labinformation .form-select,
.labinformation .form-control {
  max-width: 520px;
}

.postalcode .btn-outline-primary {
  min-height: 44px;
}

.layoutTop {
  padding: 15px;
  background: #f5f6fa;
  height: 100%;
  position: relative;
}

.layoutTop .blockInfo {
  min-height: 760px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  height: calc(100vh - 111px);
  margin-bottom: 15px;
}

.layoutTop .dflex {
  gap: 15px;
}

@media all and (max-width: 1340px) {
  .layoutTop .dflex {
    gap: 10px;
  }
}

.layoutTop .leftside {
  max-width: 350px;
  min-width: 250px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 35%;
  -ms-flex: 0 0 35%;
  flex: 0 0 35%;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media all and (max-width: 1180px) {
  .layoutTop .leftside {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 280px;
    -ms-flex: 0 0 280px;
    flex: 0 0 280px;
    min-width: 280px;
  }
}

.layoutTop .rightside {
  margin: 0 auto;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 calc(100% - 365px);
  -ms-flex: 0 0 calc(100% - 365px);
  flex: 0 0 calc(100% - 365px);
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  position: relative;
}

@media all and (max-width: 1180px) {
  .layoutTop .rightside {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 calc(100% - 290px);
    -ms-flex: 0 0 calc(100% - 290px);
    flex: 0 0 calc(100% - 290px);
  }
}

.layoutTop .rightside .blockBusiness {
  padding-top: 40px;
  position: relative;
}

.layoutTop .rightside .blockBusiness .img {
  text-align: center;
}

.layoutTop .rightside .blockBusiness h3 {
  text-align: center;
  font-size: 20px;
  margin: 35px 0 25px;
}

.layoutTop .rightside .blockBusiness .box {
  margin: 0 auto;
  max-width: 480px;
  border: 1px solid #5f5f5f;
  padding: 15px;
  margin-bottom: 15px;
  text-align: center;
  background: rgba(255, 255, 255, 0.39);
}

.layoutTop .rightside .blockBusiness .box h5 {
  font-size: 16px;
}

.layoutTop .rightside .blockBusiness .box .dflex_center {
  margin: 25px 0 15px 0;
}

.layoutTop .rightside .blockBusiness .box p {
  font-size: 14px;
  font-weight: bold;
}

.layoutTop .rightside .blockBtn {
  position: absolute;
  right: 0;
  bottom: 0;
}

.layoutTop .rightside .blockBtn ul {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.layoutTop .rightside .blockBtn ul .btn {
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.2;
  min-width: 104px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  height: 48px;
}

.layoutTop .rightside .blockBtn ul .btn-hasInstruc {
  background: #7f8fa6;
}

.layoutTop .rightside .blockBtn ul .btn-addInstruc {
  background: #718093;
}

.layoutTop .rightside .blockBtn ul .btn-addProcess {
  background: #518ced;
}

.layoutTop .rightside .blockBtn ul .btn.disabled {
  background: #dcdfe6;
  color: #fff;
}

.layoutTop .process {
  height: 66px;
}

.layoutTop .process .blockprocess {
  background-color: #cdd5e5;
  background-image: -webkit-linear-gradient(left, transparent 1px, #7f8fa6 1px);
  background-image: linear-gradient(90deg, transparent 1px, #7f8fa6 1px);
  background-size: 11.11% 11.11%;
  background-repeat: repeat;
  height: 40px;
}

.layoutTop .process .time {
  font-weight: bold;
  font-size: 14px;
}

.layoutTop .process_list {
  padding: 2px 3px;
}

.layoutTop .process_list li {
  background: #518ced;
  max-width: 11%;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  min-height: 36px;
  padding: 0 5px;
}

.layoutTop .process_list li span {
  font-size: 16px;
  display: block;
  text-align: right;
  line-height: 1;
}

.layoutTop .process_list li span small {
  font-size: 10px;
}

.blockBtnNav {
  padding: 15px;
  margin-bottom: 15px;
  height: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.blockBtnNav .blockTime {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-bottom: 30px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
}

.blockBtnNav .blockTime p {
  font-size: 18px;
  line-height: 1.4;
}

@media all and (max-width: 1180px) {
  .blockBtnNav .blockTime p {
    font-size: 14px;
  }
}

.blockBtnNav .blockTime p.time {
  font-size: 30px;
  line-height: 1;
}

@media all and (max-width: 1180px) {
  .blockBtnNav .blockTime p.time {
    font-size: 26px;
  }
}

.blockBtnNav .blockTime p.time small {
  font-size: 20px;
}

@media all and (max-width: 1180px) {
  .blockBtnNav .blockTime p.time small {
    font-size: 18px;
  }
}

.blockBtnNav .blockTime .icon {
  gap: 0;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 96px;
  -ms-flex: 0 0 96px;
  flex: 0 0 96px;
  position: relative;
}

@media all and (max-width: 1180px) {
  .blockBtnNav .blockTime .icon {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 86px;
    -ms-flex: 0 0 86px;
    flex: 0 0 86px;
  }
}

.blockBtnNav .blockTime .icon .icon_infor,
.blockBtnNav .blockTime .icon .icon_user {
  width: 48px;
  height: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}

@media all and (max-width: 1180px) {
  .blockBtnNav .blockTime .icon .icon_infor,
  .blockBtnNav .blockTime .icon .icon_user {
    width: 42px;
    height: 42px;
  }
}

.blockBtnNav .blockTime .icon .icon_infor img,
.blockBtnNav .blockTime .icon .icon_user img {
  max-height: 32px;
}

.blockBtnNav .blockBtn {
  display: -moz-inline-box;
  display: inline-box;
  gap: 0;
}

.blockBtnNav .blockBtn li {
  margin-top: 15px;
}

.blockBtnNav .blockBtn li a {
  font-weight: bold;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.blockBtnNav .blockBtn li:first-child {
  width: 100%;
  margin-top: 0;
}

.blockBtnNav .blockBtn li:first-child a {
  font-size: 20px;
  min-height: 70px;
}

@media all and (max-width: 1180px) {
  .blockBtnNav .blockBtn li:first-child a {
    font-size: 18px;
  }
}

.blockBtnNav .blockBtn li:nth-child(2) a,
.blockBtnNav .blockBtn li:nth-child(3) a {
  font-size: 18px;
  min-height: 56px;
}

@media all and (max-width: 1180px) {
  .blockBtnNav .blockBtn li:nth-child(2) a,
  .blockBtnNav .blockBtn li:nth-child(3) a {
    font-size: 16px;
  }
}

.blockBtnNav .blockBtn li:nth-child(4) a,
.blockBtnNav .blockBtn li:nth-child(5) a {
  font-size: 16px;
  min-height: 56px;
}

@media all and (max-width: 1180px) {
  .blockBtnNav .blockBtn li:nth-child(4) a,
  .blockBtnNav .blockBtn li:nth-child(5) a {
    font-size: 14px;
  }
}

.blockBtnNav .blockBtn li:nth-child(6) a,
.blockBtnNav .blockBtn li:nth-child(7) a,
.blockBtnNav .blockBtn li:nth-child(8) a {
  font-size: 14px;
  min-height: 40px;
}

@media all and (max-width: 1180px) {
  .blockBtnNav .blockBtn li:nth-child(6) a,
  .blockBtnNav .blockBtn li:nth-child(7) a,
  .blockBtnNav .blockBtn li:nth-child(8) a {
    font-size: 13px;
  }
}

.blockPerform {
  overflow: hidden;
  width: 100%;
}

.blockPerform-title {
  background: #518ced;
  padding: 10px 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.blockPerform-title h2 {
  color: #fff;
}

.blockPerform-content {
  padding: 15px 5px;
  height: calc(100% - 52px);
  position: relative;
}

@media all and (max-width: 1180px) {
  .blockPerform-content {
    padding: 15px 10px;
  }
}

.blockPerform-content .btnbtom {
  position: absolute;
  right: 15px;
  bottom: 15px;
}

.blockPerform-content .date {
  margin-bottom: 15px;
}

.blockPerform-content .date p {
  color: #518ced;
  font-weight: bold;
  text-align: center;
}

@media all and (max-width: 1180px) {
  .blockPerform-content .date p {
    font-size: 14px;
  }
}

.blockPerform-content .date .ico {
  cursor: pointer;
}

.blockPerform-content .btn-primary {
  min-height: 32px;
  font-size: 14px;
}

.blockPerform-content .content {
  height: auto;
}

.blockPerform-content .content .dflex {
  gap: 3px;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin-top: 10px;
}

.blockPerform-content .content .dflex:first-child {
  margin-top: 0;
}

.blockPerform-content .content .item {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  min-width: 28%;
}

.blockPerform-content .content .item:last-child {
  margin-right: 0;
}

.blockPerform-content .content .item:last-child p.num {
  margin-right: 3px;
}

.blockPerform-content .content .item p {
  font-size: 13px;
  font-weight: bold;
  line-height: 1.4;
  margin-bottom: 5px;
}

.blockPerform-content .content .item p.num {
  text-align: right;
  font-size: 28px;
  font-weight: bold;
  line-height: 1;
}

@media all and (max-width: 1340px) {
  .blockPerform-content .content .item p.num {
    font-size: 24px;
  }
}

.blockPerform-content .content .item p small {
  font-size: 14px;
}

.blockBusiness_regist .item {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 calc(50% - 8px);
  -ms-flex: 0 0 calc(50% - 8px);
  flex: 0 0 calc(50% - 8px);
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
  padding: 15px;
  position: relative;
  overflow: hidden;
}

.blockBusiness_regist .item_mark {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(95, 95, 95, 0.6);
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 50px;
}

@media all and (max-width: 1340px) {
  .blockBusiness_regist .item_mark {
    padding: 0 30px;
  }
}

@media all and (max-width: 1180px) {
  .blockBusiness_regist .item_mark {
    padding: 0 15px;
  }
}

.blockBusiness_regist .item_mark .ico {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  min-width: 90px;
}

.blockBusiness_regist .item_mark .ico span {
  display: block;
  font-weight: bold;
  font-size: 24px;
  color: #fff;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  text-align: center;
  margin-top: 10px;
}

@media all and (max-width: 1340px) {
  .blockBusiness_regist .item_mark .ico span {
    font-size: 20px;
  }
}

.blockBusiness_regist .item_mark ul {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 60%;
  -ms-flex: 0 0 60%;
  flex: 0 0 60%;
  max-width: 230px;
}

.blockBusiness_regist .item_mark li {
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 14px 0;
}

@media all and (max-width: 1340px) {
  .blockBusiness_regist .item_mark li {
    font-size: 18px;
  }
}

.blockBusiness_regist .item_mark li span {
  font-weight: bold;
}

.blockBusiness_regist .item_head {
  margin-bottom: 15px;
}

.blockBusiness_regist .item_head h4 {
  font-size: 20px;
  font-weight: bold;
}

@media all and (max-width: 1180px) {
  .blockBusiness_regist .item_head h4 {
    font-size: 18px;
  }
}

.blockBusiness_regist .item_head label {
  font-size: 12px;
  border: 2px solid #5f5f5f;
  padding: 0 5px;
  font-weight: bold;
}

.blockBusiness_regist .item_info p {
  font-size: 12px;
  font-weight: bold;
  line-height: 1.4;
}

.blockBusiness_regist .item_info p.num {
  font-size: 28px;
}

@media all and (max-width: 1340px) {
  .blockBusiness_regist .item_info p.num {
    font-size: 24px;
  }
}

.blockBusiness_regist .item_info p.num small {
  font-size: 14px;
}

.blockBusiness_regist .item_info .btn-green {
  min-width: 70px;
  margin-right: 0;
  margin-left: auto;
  padding: 20px 40px;
}

.blockBusiness_regist .item_process p {
  font-size: 13px;
  font-weight: bold;
  color: #51c17d;
  line-height: 1.2;
}

.blockBusiness_regist .item_process .num {
  font-size: 20px;
  font-weight: bold;
}

.blockBusiness_regist .item .process {
  height: 8px;
  background: #cdd5e5;
  position: relative;
}

.blockBusiness_regist .item .process span {
  position: absolute;
  left: 0;
  top: 0;
  height: 8px;
  background: #51c17d;
}

.blockpager {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  left: 50%;
  bottom: 60px;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.blockpager ul {
  background: rgba(185, 185, 185, 0.3);
  border-radius: 12px;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin: 0 auto;
  min-height: 24px;
  padding: 0 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 0 !important;
}

.blockpager ul li {
  margin: 0 5px;
  width: 8px;
  height: 8px;
}

.blockpager ul li span {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: #b7b7b7;
  display: block;
  cursor: pointer;
}

.blockpager ul li.active span {
  background: #fff;
}

.blockDetail {
  padding-bottom: 15px;
}

.blockDetail-fixedHeight {
  height: calc(100vh - 220px);
  padding-bottom: 0;
  overflow-y: hidden;
}

.form-check-custom {
  margin-right: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.form-check-custom label {
  width: 100%;
  border: 1px solid #5f646e;
  border-radius: 16px;
  padding: 0 20px;
  height: 36px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.form-check-custom .form-check-input {
  position: absolute;
  left: 5px;
  margin-right: 0;
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
}

.form-check-custom .form-check-input:focus {
  outline: none;
  box-shadow: none;
}

.form-check-custom .form-check-input:checked + label {
  border-width: 2px;
  padding-left: 30px;
}

.form-check-custom .form-check-primary:checked {
  background: url("../images/ico_check-primary.svg") no-repeat center center;
}

.form-check-custom .form-check-primary:checked + label {
  background: #eaf4ff;
  border-color: #518ced;
  color: #518ced;
}

.form-check-custom .form-check-danger:checked {
  background: url("../images/ico_check-danger.svg") no-repeat center center;
}

.form-check-custom .form-check-danger:checked + label {
  border-color: #ed5555;
  color: #ed5555;
}

.form-check-custom .form-check-warning:checked {
  background: url("../images/ico_check-warning.svg") no-repeat center center;
}

.form-check-custom .form-check-warning:checked + label {
  border-color: #f0932b;
  color: #f0932b;
}

.form-check-custom .form-check-success:checked {
  background: url("../images/ico_check-success.svg") no-repeat center center;
}

.form-check-custom .form-check-success:checked + label {
  border-color: #51c17d;
  color: #51c17d;
}

.blockreadinstructions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 200px 0;
}

label.square {
  font-size: 12px;
  font-weight: bold;
  border: 2px solid #5f5f5f;
  padding: 3px 6px;
  display: inline-block;
}

.businessRegisted .boxborder {
  position: relative;
  margin-top: 15px;
}

.businessRegisted .boxborder .info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  font-weight: bold;
  position: absolute;
  right: 10px;
  top: 10px;
}

.businessRegisted .boxborder .listMetalbridges {
  margin-bottom: 0;
}

.businessRegisted .boxborder .name h4 {
  margin-top: 4px;
}

.businessRegisted .boxborder dl {
  margin-left: 15px;
}

.businessRegisted .boxborder dl dd {
  font-size: 30px;
}

.businessRegisted .boxborder dl dd small {
  font-size: 14px;
}

.form-calender {
  background: #fff url(../images/ico_calendar.svg) no-repeat right 6px center !important;
}

.form-calender:disabled {
  background: #e9ecef url(../images/ico_calendar.svg) no-repeat right 6px center !important;
}

.number {
  width: 132px;
  margin-right: 15px;
}

.number button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #5f5f5f;
  height: 32px;
  width: 40px;
  background: #fff;
}

.number button.btnminus {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.number button.btnadd {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.number .num {
  background: #fff;
  color: #518ced;
  font-weight: bold;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 24px;
  line-height: 32px;
  border-top: 1px solid #5f5f5f;
  border-bottom: 1px solid #5f5f5f;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  height: 32px;
}

.breakdown {
  background: #6b93be;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  margin-right: 10px;
  min-height: 24px;
  padding: 3px 10px;
}

.clientclinic .dflex {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-bottom: 10px;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

@media all and (max-width: 1280px) {
  .clientclinic .dflex {
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
}

.clientclinic .boxregist {
  border: 1px solid #748091;
  border-radius: 10px;
  padding: 25px 30px;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

@media all and (max-width: 1180px) {
  .clientclinic .boxregist {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 40%;
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
    padding: 15px;
  }
}

.clientclinic .boxregist:first-child {
  background: #f5f6fa;
  margin-right: 20px;
}

@media all and (max-width: 1180px) {
  .clientclinic .boxregist:first-child {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 calc(60% - 20px);
    -ms-flex: 0 0 calc(60% - 20px);
    flex: 0 0 calc(60% - 20px);
  }
}

.clientclinic .boxregist input {
  margin: 10px 0;
}

.clientclinic .boxregist h5 {
  font-size: 16px;
  color: #518ced;
  margin-bottom: 10px;
}

.clientclinic .boxregist p {
  font-size: 14px;
}

.clientclinic .boxregist .btn {
  min-width: 96px;
  min-height: 40px;
}

.clientclinic .issuedList .item_name {
  margin-top: 0;
}

.settingdate {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  margin-top: 20px;
  position: relative;
  display: none;
}

.settingdate::before {
  position: absolute;
  content: "";
  width: 100%;
  background: #cdd5e5;
  height: 1px;
  left: 0;
  top: 0;
}

.settingdate .dflex {
  border-bottom: 1px solid #cdd5e5;
  padding: 12px 0;
  max-width: 460px;
  min-width: 460px;
}

.settingdate .dflex:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}

.settingdate .txtmonth {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 100px;
  -ms-flex: 0 0 100px;
  flex: 0 0 100px;
}

.tbfixed {
  width: 100%;
}

.tbfixed .tbstyle6 {
  min-width: 1500px;
  overflow-x: auto;
}

.isShow {
  display: block !important;
}

.isHide {
  display: none !important;
}

.endOfBreak {
  background: #5f5f5f;
  height: 100vh;
  width: calc(100% + 30px);
  margin-left: -15px;
}

.endOfBreak .dflex {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}

.endOfBreak .dflex * {
  color: #fff;
  font-weight: bold;
}

.endOfBreak .dflex .btn-success {
  background: #51c17d;
  border-color: #51c17d;
}

.endOfBreak .dflex .tit {
  font-size: 24px;
  margin-bottom: 30px;
}

.endOfBreak .dflex .time {
  font-size: 32px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.form-check-rouder input[type="radio"],
.form-check-rouder input[type="checkbox"] {
  border-radius: 100%;
}

.absoluteSide {
  position: absolute;
  width: 30%;
  min-width: 280px;
  box-shadow: 1px 3px 5px 3px rgba(0, 0, 0, 0.2);
  left: 0;
  top: 0;
  background: #fff;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 100;
}

.absoluteSide a.iconclose {
  width: 30px;
  height: 30px;
  display: inline-block;
  float: right;
  position: -webkit-sticky;
  position: sticky;
  top: 10px;
  opacity: 0.6;
}

.process_list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.boxlist {
  padding: 30px;
  margin-top: 20px;
}

.boxlist ul {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 25px;
}

.boxlist ul li {
  width: calc(50% - 10px);
  width: -ms-calc(50% - 10px);
  width: -o-calc(50% - 10px);
  margin-right: 20px;
  float: left;
  background-color: #508CED;
  border-radius: 7px;
  text-align: center;
  margin-bottom: 15px;
  position: relative;
}

.boxlist ul li a:hover {
  opacity: 0.7;
}

.boxlist ul li * {
  color: #fff;
}

.boxlist ul li:nth-child(2n) {
  margin-right: 0;
}

.boxlist ul li .txt {
  padding: 12px 10px;
  display: block;
  border-radius: 7px;
}

.boxlist ul li .txtdot {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  background-color: #e66466;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  top: -10px;
  left: -10px;
}

.boxlist .iconclose {
  float: right;
}

.absoluteSide {
  display: none;
}

.divlogout {
  width: 180px;
  position: absolute;
  background: #fff;
  top: 50px;
  right: 0;
  padding: 10px;
  box-shadow: 1px 3px 12px 2px rgba(0, 0, 0, 0.2);
  z-index: 99999;
}

.divlogout ul li {
  margin-left: 10px;
  margin-bottom: 10px;
}

.divlogout ul li:last-child {
  margin-bottom: 0;
}

.divlogout ul li a {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 5px 0;
}

.divlogout ul li a img {
  margin-right: 7px;
}

.overflowHidden {
  overflow-y: hidden;
}

.form-check-danger {
  border-color: #ed5555;
  color: #ed5555;
}

.form-check-warning {
  border-color: #f0932b;
  color: #f0932b;
}

.form-check-success {
  border-color: #51c17d;
  color: #51c17d;
}

.hasTooltip {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.hasTooltip .ico_tooltip {
  margin-left: 10px;
}

.btn-primary-blue.btn.ico-print {
  background-color: #518ced;
}

.printSelected .blockCard {
  left: 36px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.printSelected .blockCard_checkBox {
  position: absolute;
  left: -36px;
  top: 50%;
  margin-top: -12px;
  margin-bottom: 0;
}

.printSelected.nonSelected .blockCard {
  left: 0;
}

.printSelected.nonSelected .blockCard_checkBox {
  opacity: 0;
  visibility: hidden;
}

.blockCardScroll {
  max-height: calc(100vh - 530px);
}

.blockScan {
  padding-bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: calc(100% - 60px);
}

.blockScan .scanner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.blockScan .scanner_inside {
  width: 460px;
  height: 460px;
  background: #e9ecef;
}

.registnumberCsv {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 80%;
  -ms-flex: 0 0 80%;
  flex: 0 0 80%;
  max-width: 730px;
  margin: 30px auto;
  border-radius: 0;
  padding-left: 20px;
  padding-right: 20px;
}

.registnumberCsv .form-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 15px;
}

.registnumberCsv .form-group input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  height: 48px;
}

.registnumberCsv .form-group button {
  background: #5f5f5f;
  font-weight: bold;
  color: #fff;
  padding: 0 30px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  width: 100px;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}

.registnumberCsv p {
  font-size: 14px;
  font-weight: bold;
}

.registnumberCsv .btn {
  position: relative;
  right: 0;
  bottom: 0;
  margin-top: 15px;
}

.statusPhoto {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 12px;
}

.statusPhoto li {
  background: #fff;
  color: #518ced;
  font-weight: 500;
  padding: 7px 18px;
  font-size: 14px;
  border: 1px solid #518ced;
}

.statusPhoto li.-active {
  background: #518ced;
  color: #fff;
}

.statusPhoto li:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.statusPhoto li:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.bgthead {
  background: #e7ecf5;
}

.craftingDetail {
  max-height: calc(94vh - 415px);
  overflow-y: scroll;
  padding-bottom: 0;
}

.craftingDetail table th {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  top: 0;
  z-index: 9;
  background: #e7ecf5;
}

.craftingDetail table th,
.craftingDetail table td {
  padding: 12px 10px;
}

.craftingDetail table tr.hasbg {
  background: #ffefef;
}

.craftingDetail table td {
  border-bottom: 1px solid #cdd5e5;
}

.craftingDetail table td.hasbg {
  background: #ffefef;
}

.craftingDetail table td label {
  color: #fff;
  padding: 7px 15px;
  border-radius: 12px;
  margin-right: 10px;
}

.craftingDetail table td label.bgred {
  background: #ed5555;
}

.tags span {
  font-size: 12px;
  font-weight: bold;
  border-radius: 15px;
  padding: 3px 8px;
  margin-left: 5px;
  border-width: 2px;
  border-style: solid;
}

.tags-outline {
  border-width: 2px;
  border-style: solid;
}

.switchLayout {
  margin-left: auto;
  margin-right: 0;
}

.switchLayout .btn-icoList {
  background: #fff url(../images/ico_list_gray.svg) no-repeat left 10px center;
  border: 1px solid #718093;
  padding-left: 33px;
  color: #718093;
}

.switchLayout .btn-icoList.active {
  background: #718093 url(../images/ico_list.svg) no-repeat left 10px center;
  color: #fff;
}

.switchLayout .btn-icoGrid {
  background: #fff url(../images/ico_grid_gray.svg) no-repeat left 10px center;
  border: 1px solid #718093;
  padding-left: 33px;
  color: #718093;
}

.switchLayout .btn-icoGrid.active {
  background: #718093 url(../images/ico_grid.svg) no-repeat left 10px center;
  color: #fff;
}

.tbhistoryLabo span.status {
  padding: 5px 7px;
  font-size: 12px;
  display: inline-block;
  border-radius: 4px;
  font-weight: bold;
}

.tbhistoryLabo span.status-round {
  border-radius: 15px;
}

.tbhistoryLabo .label {
  display: inline-block;
}

.tbhistoryLabo .label span {
  border: 2px solid;
  display: inline-block;
  padding: 3px 9px;
  border-radius: 15px;
  margin-right: 5px;
  font-size: 12px;
  font-weight: bold;
}

.tbhistoryLabo button {
  background: none;
}

.tbhistoryLabo_List {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 10px;
}

.tbhistoryLabo_List .item {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 calc(20% - 8px);
  -ms-flex: 0 0 calc(20% - 8px);
  flex: 0 0 calc(20% - 8px);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  overflow: hidden;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}

.tbhistoryLabo_List figure {
  position: relative;
  height: 200px;
  overflow: hidden;
}

.tbhistoryLabo_List figure .status {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 9;
  padding: 15px;
}

.tbhistoryLabo_List figure img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.tbhistoryLabo_List .info {
  border: 1px solid #cdd5e5;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 10px;
  height: calc(100% - 200px);
}

.tbhistoryLabo_List .info p.tit {
  margin: 5px 0;
}

.tbhistoryLabo_List .info * {
  font-size: 14px;
}

.tbhistoryLabo_List .info .label span {
  margin-right: 0 !important;
  margin-left: 5px;
}

.tbsticky thead th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: #e7ecf5;
  z-index: 9;
}

.reasonRegist {
  background: #fff;
}

.reasonRegist.scrollBlock {
  padding-top: 15px;
  height: calc(100vh - 122px);
  overflow-y: auto !important;
}

.reasonRegist .blockCard {
  padding-bottom: 0;
  overflow: hidden;
}

.reasonRegist .blockCard_Desc-head .leftside:last-child dl.dflex_start {
  margin-bottom: 0;
}

.reasonRegist .blockCard_Request {
  background: #fff;
}

.reasonRegist .blockImages {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 30%;
  -ms-flex: 0 0 30%;
  flex: 0 0 30%;
  max-width: 450px;
}

.reasonRegist .blockImages button:disabled {
  background: #dcdfe6;
  color: #fff;
  border-color: #dcdfe6;
}

.reasonRegist .blockReason ul.list {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin: 10px 0;
}

.reasonRegist .blockReason ul.list li {
  max-width: 90px;
}

.reasonRegist .blockReason .buttonList {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  gap: 5px;
  margin: 10px 0;
}

.reasonRegist .blockReason .form-control {
  padding: 0;
  margin: 10px 0;
  background: #518ced;
}

.btnDate,
.btnYear,
.btnhalfYear,
.btnMonth,
.btnWeek {
  min-width: 56px;
}

.tbpointAdjust .statusregist {
  display: inline-block;
}

.tbSituation table span.linethrough {
  text-decoration: line-through;
}

.tbSituation table td small {
  display: block;
  font-size: 12px;
}

.tbSituation table td small span {
  color: #99a3b1;
}

.blockChart {
  border: 1px solid #cdd5e5;
  max-height: 470px;
  margin-bottom: 10px;
}

.blockChart_head {
  margin-bottom: 12px;
}

.blockChart_head button.f12 {
  min-height: 32px;
}

.blockChart_body .inside {
  overflow-x: auto;
}

.blockChart canvas {
  min-height: 385px;
  max-height: 385px;
  min-width: 100%;
}

.infoReproDetails {
  padding: 0 0 10px 0;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  position: relative;
}

.infoReproDetails p.clred {
  font-weight: bold;
}

.infoReproDetails p,
.infoReproDetails span {
  font-size: 14px;
}

.infoReproDetails p.result {
  font-size: 32px;
  font-weight: bold;
  line-height: 1.2;
}

.infoReproDetails .name {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 37%;
  -ms-flex: 0 0 37%;
  flex: 0 0 37%;
  background: url(../images/arrowdown-gray.svg) no-repeat right center;
  padding-right: 30px;
  cursor: pointer;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}

.infoReproDetails .name h2 {
  font-size: 20px;
}

.infoReproDetails .name .num {
  font-size: 14px;
  color: #99a3b1;
  font-weight: bold;
}

.infoReproDetails .name .num label {
  background: #99a3b1;
  padding: 3px 10px;
  color: #fff;
  display: inline-block;
  margin-right: 8px;
  line-height: 1.2;
  font-size: 12px;
}

.infoReproDetails .name P {
  font-size: 16px;
  line-height: 1.4;
  font-weight: bold;
}

.infoReproDetails .name P span {
  font-size: 14px;
  color: #99a3b1;
  font-weight: bold;
}

.infoReproDetails .rate {
  min-width: 130px;
  text-align: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 12%;
  -ms-flex: 0 0 12%;
  flex: 0 0 12%;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}

.infoReproDetails .numOfRepro {
  border-left: 1px solid #cdd5e5;
  border-right: 1px solid #cdd5e5;
  text-align: center;
  min-width: 130px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 12%;
  -ms-flex: 0 0 12%;
  flex: 0 0 12%;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}

.infoReproDetails .breakdowntext {
  padding-left: 15px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 37%;
  -ms-flex: 0 0 37%;
  flex: 0 0 37%;
  max-height: 70px;
  overflow-y: scroll;
}

.infoReproDetails .item {
  margin-right: 10px;
}

.infoReproDetails .item span:first-child {
  margin-right: 5px;
}

.reproductDetails {
  background: #f5f6fa;
}

.reproductDetails .content_inside {
  background: #f5f6fa;
  padding-top: 0;
}

@media screen and (max-height: 812px) {
  .reproductDetails .content_inside {
    overflow-y: scroll;
  }
}

.reproductDetails .infoReproDetails {
  background: #fff;
  padding-top: 10px;
}

.reproductDetails .detailsChart {
  padding: 10px;
  padding-bottom: 0;
  width: calc(100% + 12px);
  margin-left: -12px;
  border-top: 1px solid #cdd5e5;
}

.reproductDetails .detailsChart .listbtn {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-bottom: 10px;
  gap: 5px;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  overflow-x: auto;
}

.reproductDetails .detailsChart .listbtn button {
  border-radius: 17px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.reproductDetails .detailsChart .listbtn button.isActive {
  background: #518ced;
  color: #fff;
}

.reproductDetails .detailsChart .listbtn button:disabled {
  color: #cdd5e5;
  border-color: #cdd5e5;
}

.reproductDetails .detailsChart .dflex {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.reproductDetails .detailsChart .blockChart {
  min-width: 600px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 50%;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  border: none;
  margin-top: 13px;
  margin-bottom: 0;
}

.reproductDetails .detailsChart .blockChart_head {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.reproductDetails .detailsChart .blockChart_head .left,
.reproductDetails .detailsChart .blockChart_head .center,
.reproductDetails .detailsChart .blockChart_head .right {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 33%;
  -ms-flex: 1 1 33%;
  flex: 1 1 33%;
}

.reproductDetails .detailsChart .blockChart_head .center {
  text-align: center;
}

.reproductDetails .detailsChart .blockChart_head .right {
  text-align: right;
}

.reproductDetails .detailsChart .blockChart canvas {
  max-height: 340px;
  min-height: 340px;
}

.reproductDetails .detailsChart .blockReason {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 50%;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: calc(100% - 600px);
  padding-left: 10px;
  position: relative;
  max-height: 450px;
}

.reproductDetails .detailsChart .blockReason .tit {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  font-weight: bold;
}

.reproductDetails .detailsChart .blockReason .left {
  min-width: 170px;
  max-width: 230px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 35%;
  -ms-flex: 0 0 35%;
  flex: 0 0 35%;
  background: #ffedf1;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  max-height: 412px;
  overflow-y: auto;
}

.reproductDetails .detailsChart .blockReason .left ul {
  width: 100%;
}

.reproductDetails .detailsChart .blockReason .left li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px;
  font-weight: bold;
}

.reproductDetails .detailsChart .blockReason .left li span {
  font-weight: 300;
  font-family: Arial, Helvetica, sans-serif !important;
}

.reproductDetails .detailsChart .blockReason .left li.isActive {
  color: #fff;
  background: #ed5555;
}

.reproductDetails .detailsChart .blockReason .right {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 65%;
  -ms-flex: 0 0 65%;
  flex: 0 0 65%;
  max-width: calc(100% - 170px);
  min-width: calc(100% - 230px);
}

.reproductDetails .detailsChart .policies .dflex {
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.reproductDetails .detailsChart .policies textarea {
  max-height: 110px;
  max-width: calc(100% - 115px);
  white-space: pre-wrap;
}

.reproductDetails .detailsChart .policies button {
  min-width: 105px;
}

.reproductDetails .detailsChart .tbhorizontal {
  max-height: 412px !important;
}

.reproductDetails .detailsChart .tbhorizontal.hasRedBorder {
  border: 2px solid #ed5555;
}

.reproductDetails .detailsChart .tbstyle3 {
  background: #fff;
  min-width: inherit;
}

.reproductDetails .detailsChart .tbstyle3 th,
.reproductDetails .detailsChart .tbstyle3 td {
  padding-left: 5px;
  padding-right: 5px;
}

.chartResults .blockChart_head {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.chartResults .blockChart_head .left,
.chartResults .blockChart_head .center,
.chartResults .blockChart_head .right {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 33%;
  -ms-flex: 1 1 33%;
  flex: 1 1 33%;
}

.chartResults .blockChart_head .center {
  text-align: center;
}

.chartResults .blockChart_head .right {
  text-align: right;
}

.chartResults .blockChart_head .right p {
  font-size: 14px;
}

.chartResults .blockChart_head .right button {
  min-width: 70px;
  margin-left: 10px;
}

.chartResults .blockChart_time {
  position: relative;
}

.chartResults .blockChart_time button {
  min-height: 32px;
  font-size: 12px;
}

.chartResults .blockChart_time button.isActive,
.chartResults .blockChart_time button:hover {
  background: #7f8fa6;
  color: #fff;
}

.chartResults .blockChart_time button.btnzoom {
  right: 0;
  top: 0;
  position: absolute;
}

.chartResults .blockChart_time button.btnzoom:hover {
  background: none;
}

.chartResults .blockChart_info {
  margin: 12px 0;
}

.chartResults .blockChart_info .item {
  border-radius: 6px;
  border: 1px solid #cdd5e5;
  padding: 5px 15px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-bottom: 10px;
  overflow: hidden;
}

.chartResults .blockChart_info .item .colMonth {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: -15px !important;
  margin-top: -10px;
  margin-bottom: -10px;
  min-height: calc(100% + 20px);
}

.chartResults .blockChart_info .item .colMonth p {
  color: #fff;
}

.chartResults .blockChart_info .item:last-child {
  margin-bottom: 0;
}

.chartResults .blockChart_info .item .col {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  max-width: 20%;
  margin-left: 20px;
}

.chartResults .blockChart_info .item .col:first-child {
  margin-left: 0;
}

.chartResults .blockChart_info .item p {
  font-size: 12px;
}

.chartResults .blockChart_info .item p.num {
  font-size: 22px;
  line-height: 1.2;
  font-family: Arial, Helvetica, sans-serif !important;
  text-align: right;
}

.chartResults .blockChart_info .item p.clgray {
  text-align: right;
}

.chartResults table.tbstyle3 p.clgray {
  font-size: 12px;
  color: #99a3b1;
  font-weight: bold;
}

.chartResults table.tbstyle3 p.num {
  font-size: 26px;
  line-height: 1.2;
  font-family: Arial, Helvetica, sans-serif !important;
}

.chartResults table.tbstyle3 p.num small {
  font-size: 14px;
}

.chartResults table.tbstyle3 .name h2 {
  font-size: 20px;
}

.chartResults table.tbstyle3 .name .num {
  font-size: 14px;
  color: #99a3b1;
}

.chartResults table.tbstyle3 .name .num label {
  background: #99a3b1;
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  height: 20px;
  padding: 0 10px;
  display: inline-block;
  margin-right: 5px;
}

.chartResults table.tbstyle3 .alphabet {
  font-size: 26px;
  font-family: Arial, Helvetica, sans-serif !important;
}

.chartResults .listPhotoFilter .filterGroup .filterRow {
  margin-top: 15px;
}

.chartResults .listPhotoFilter .filterGroup .filterRow:first-child {
  margin-top: 0;
}

.performanceDetails {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  overflow-y: hidden;
  padding: 0;
}

.performanceDetails .blockLeftcol {
  min-width: 200px;
  max-width: 220px;
  height: calc(100vh - 56px);
  overflow-y: auto;
  background: #f5f6fa;
}

.performanceDetails .blockLeftcol .name {
  padding: 15px 10px;
  background: #fff;
}

.performanceDetails .blockLeftcol .name h2 {
  font-size: 20px;
}

.performanceDetails .blockLeftcol .name .num {
  font-size: 14px;
  color: #99a3b1;
  font-weight: bold;
}

.performanceDetails .blockLeftcol .name .num label {
  background: #99a3b1;
  padding: 3px 10px;
  color: #fff;
  display: inline-block;
  margin-right: 8px;
  line-height: 1.2;
  font-size: 12px;
}

.performanceDetails .blockLeftcol .name P {
  font-size: 16px;
  line-height: 1.4;
  font-weight: bold;
}

.performanceDetails .blockLeftcol .name P span {
  font-size: 14px;
  color: #99a3b1;
  font-weight: bold;
}

.performanceDetails .blockLeftcol .name.hasArrow {
  position: relative;
}

.performanceDetails .blockLeftcol .name.hasArrow .arr {
  background: none;
  position: absolute;
  right: 5px;
  top: 50%;
  margin-top: -10px;
}

.performanceDetails .blockLeftcol .targetDay {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 15px 10px;
  border-top: 1px solid #dcdfe6;
  border-bottom: 1px solid #dcdfe6;
  background: #fff;
}

.performanceDetails .blockLeftcol .targetDay .txt p {
  font-size: 13px;
}

.performanceDetails .blockLeftcol .targetDay .txt p small {
  font-size: 13px;
}

.performanceDetails .blockLeftcol .targetDay .txt .num {
  font-size: 20px;
  line-height: 1.2;
  font-family: Arial, Helvetica, sans-serif !important;
}

.performanceDetails .blockLeftcol .targetDay .txt .num small {
  font-family: "Hiragino Sans", "Helvetica Neue", "Helvetica", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif !important;
}

.performanceDetails .blockLeftcol .achieve {
  padding: 10px;
}

.performanceDetails .blockLeftcol .achieve_head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 0 10px;
}

.performanceDetails .blockLeftcol .achieve_head p {
  color: #718093;
  font-size: 14px;
  font-weight: bold;
}

.performanceDetails .blockLeftcol .achieve_body dl {
  padding: 10px 0;
  border-bottom: 1px solid #cdd5e5;
}

.performanceDetails .blockLeftcol .achieve_body dl:first-child {
  border-top: 1px solid #cdd5e5;
}

.performanceDetails .blockLeftcol .achieve_body dl dt {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 1.2;
  font-weight: bold;
  font-size: 15px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
  min-height: 40px;
}

.performanceDetails .blockLeftcol .achieve_body dl dt small {
  font-size: 12px;
  font-weight: bold;
  display: block;
  width: 100%;
}

.performanceDetails .blockLeftcol .achieve_body dl dt .arr {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  right: -10px;
  top: 0;
}

.performanceDetails .blockLeftcol .achieve_body dl dt button {
  background: none;
}

.performanceDetails .blockLeftcol .achieve_body dl dt button:hover {
  opacity: 0.7;
}

.performanceDetails .blockLeftcol .achieve_body dl dd {
  margin-top: 5px;
}

.performanceDetails .blockLeftcol .achieve_body dl dd p {
  line-height: 1.4;
  text-align: right;
  font-family: Arial, Helvetica, sans-serif !important;
  font-weight: bold;
}

.performanceDetails .blockLeftcol .achieve_body dl dd p:first-child {
  font-size: 20px;
}

.performanceDetails .blockLeftcol .achieve_body dl dd .clgray {
  font-size: 14px;
  font-weight: bold;
  font-family: "Hiragino Sans", "Helvetica Neue", "Helvetica", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif !important;
}

.performanceDetails .blockinfo {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  overflow-y: auto;
  height: calc(100vh - 56px);
  padding: 10px;
}

.performanceDetails .blockinfo .blockChart {
  border: 0;
}

.performanceDetails .blockinfo .blockChart_head {
  margin-bottom: 0;
}

.performanceDetails .blockinfo .blockChart_body {
  border: 1px solid #5e6d8e;
  position: relative;
}

.performanceDetails .blockinfo .blockChart .btnzoom {
  position: absolute;
  right: 5px;
  top: 5px;
}

.performanceDetails .blockinfo ul.list {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.performanceDetails .blockinfo ul.list li {
  background: #e7ecf5;
  font-size: 12px;
  color: #5e6d8e;
  font-weight: bold;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 6px 15px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.performanceDetails .blockinfo ul.list li.isActive,
.performanceDetails .blockinfo ul.list li:hover {
  background: #5e6d8e;
  color: #fff;
}

.performanceDetails .blockRightcol {
  max-width: 380px;
  min-width: 380px;
  overflow-y: auto;
  height: calc(100vh - 56px);
  background: #f5f6fa;
  padding: 10px;
}

.performanceDetails .blockRightcol_head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.performanceDetails .blockRightcol_body {
  background: #fff;
  margin-top: 10px;
}

.performanceDetails .blockRightcol_body dl {
  border-bottom: 1px solid #cdd5e5;
  padding: 10px;
}

.performanceDetails .blockRightcol_body dl:first-child {
  border-top: 1px solid #cdd5e5;
}

.performanceDetails .blockRightcol_body dl dt {
  position: relative;
}

.performanceDetails .blockRightcol_body dl dt label {
  background: #fff;
  padding: 3px 7px;
  color: #5e6d8e;
  display: inline-block;
  border: 2px solid #5e6d8e;
  line-height: 1.2;
  font-size: 12px;
  font-weight: bold;
}

.performanceDetails .blockRightcol_body dl dt p {
  font-size: 18px;
  color: #5e6d8e;
  font-weight: bold;
  line-height: 1.2;
  margin-top: 5px;
}

.performanceDetails .blockRightcol_body dl dt .alphabet {
  position: absolute;
  right: 0;
  top: 0;
  width: 44px;
  height: 44px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 26px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif !important;
}

.performanceDetails .blockRightcol_body dl dd .row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 3px 0;
}

.performanceDetails .blockRightcol_body dl dd .item {
  margin-left: 20px;
  min-width: 75px;
}

.performanceDetails .blockRightcol_body dl dd .item:first-child {
  margin-left: 0;
}

.performanceDetails .blockRightcol_body dl dd .item p {
  font-size: 12px;
  font-weight: bold;
}

.performanceDetails .blockRightcol_body dl dd .item .num {
  font-size: 22px;
  font-weight: normal;
  line-height: 1.2;
  text-align: right;
  font-family: Arial, Helvetica, sans-serif !important;
}

.performanceDetails .blockRightcol_body dl dd .item .num small {
  font-size: 12px;
  font-family: "Hiragino Sans", "Helvetica Neue", "Helvetica", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif !important;
}

.performanceDetails .blockRightcol_body dl dd .item .clgray {
  color: #99a3b1;
  margin-left: 20px;
}

.largeChart {
  min-width: 1320px !important;
}

.clinicSearch {
  position: absolute;
  left: 0;
  top: 90px;
  width: 94%;
  z-index: 99;
  padding: 15px;
  -webkit-backdrop-filter: blur(20);
  backdrop-filter: blur(20);
  background-color: rgba(245, 245, 245, 0.9);
  /* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
}

@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .clinicSearch {
    -webkit-backdrop-filter: blur(20);
    backdrop-filter: blur(20);
  }
}

@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .clinicSearch {
    background-color: rgba(245, 245, 245, 0.9);
  }
}

.clinicSearch .issuedList {
  max-height: calc(100vh - 220px);
  overflow-y: auto;
}

.clinicSearch .issuedList .item_name {
  margin-top: 0;
}

.clinicSearch .form-control {
  width: 300px;
  border-width: 1px;
}

.clinicSearch.filterEmployee {
  min-width: 760px;
  max-width: 760px;
  left: 12px;
  top: 106px;
}

.clinicSearch .filterGroup {
  position: relative;
  background: none;
  top: 0;
  padding: 0;
  max-height: 550px;
  overflow-y: auto;
  max-width: inherit;
}

.clinicSearch .filterGroup .listSelection {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 8px;
}

.weekPoints .listMonth button {
  min-width: 64px;
  margin-right: 5px;
}

.weekPoints .tbstyle3 th:first-child {
  font-size: 18px;
}

.weekPoints .tbstyle3 td:first-child p {
  font-size: 18px;
}

.weekPoints .tbstyle3 td:first-child span {
  color: #5e6d8e;
  font-weight: bold;
  margin-left: 5px;
  font-size: 18px;
}

.weekPoints .tbstyle3 td:first-child small {
  display: block;
  color: #5f5f5f;
  font-size: 16px;
}

.weekPoints .tbstyle3 td .txtDay {
  font-size: 24px;
  font-family: Arial, Helvetica, sans-serif !important;
  line-height: 1.2;
  padding-left: 15px;
}

.weekPoints .tbstyle3 td .txtCase {
  color: #99a3b1;
  line-height: 1;
}

.weekPoints .tbstyle3 td .txtCase small {
  font-size: 13px;
}

.weekPoints .tbstyle3 td .txtNum {
  font-family: Arial, Helvetica, sans-serif !important;
  line-height: 1.2;
}

.weekPoints .tbstyle3 td .txtNum span {
  font-size: 26px;
}

.sortTable-fixth th {
  vertical-align: top !important;
  line-height: 1.2;
  padding-top: 16px;
}

#zoomInfoPerform .chartResults {
  max-width: 1260px;
  margin: 0 auto;
}

#zoomInfoPerform .chartResults .modal-body {
  margin: 0;
  padding: 15px 60px;
}

@media all and (max-width: 1200px) {
  #zoomInfoPerform .chartResults .modal-body {
    padding-left: 20px;
    padding-right: 50px;
  }
}

#zoomInfoPerform .chartResults .modal-body.blockChart {
  padding: 15px 0;
  min-height: 60vh;
  overflow-y: hidden;
  border: none;
}

#zoomInfoPerform .chartResults ul.list {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

#zoomInfoPerform .chartResults ul.list li {
  background: #E7ECF5;
  color: #5E6D8E;
  font-weight: bold;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 15px 25px;
  cursor: pointer;
  -webkit-transition: all .3s;
  transition: all .3s;
  font-size: 18px;
}

#zoomInfoPerform .chartResults ul.list li.isActive, #zoomInfoPerform .chartResults ul.list li:hover {
  background: #5E6D8E;
  color: #fff;
}

#zoomInfoPerform .chartResults .blockChart_head {
  margin-bottom: 0;
}

#zoomInfoPerform .chartResults .blockChart_body .inside {
  border-top: 1px solid #5E6D8E;
  border-bottom: 1px solid #5E6D8E;
}

#zoomInfoPerform .headPop {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

#zoomInfoPerform .headPop .date p {
  line-height: 1.4;
  font-weight: bold;
}

#zoomInfoPerform .headPop .date p.daterange {
  font-weight: normal;
  font-size: 12px;
}

#zoomInfoPerform .headPop .item {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  max-width: 900px;
  margin-left: 3%;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

#zoomInfoPerform .headPop .item .col {
  margin-left: 1%;
}

@media all and (min-width: 1200px) {
  #zoomInfoPerform .headPop .item .col {
    margin-left: 20px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
  }
  #zoomInfoPerform .headPop .item .col.colMonth {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 70px;
        -ms-flex: 0 0 70px;
            flex: 0 0 70px;
  }
}

#zoomInfoPerform .contentPop {
  margin-top: 20px;
  overflow-y: scroll;
  max-height: calc(90vh - 100px);
  min-height: 400px;
}

@media (max-height: 768px) {
  #zoomInfoPerform .contentPop {
    min-height: inherit;
  }
}

#zoomInfoPerform .contentPop dl {
  border-bottom: 1px solid #CDD5E5;
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

#zoomInfoPerform .contentPop dl .alphabet {
  width: 44px;
  height: 44px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 26px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif !important;
}

#zoomInfoPerform .contentPop dl dt {
  position: relative;
}

#zoomInfoPerform .contentPop dl dt label {
  background: #fff;
  padding: 3px 7px;
  color: #5E6D8E;
  display: inline-block;
  border: 2px solid #5E6D8E;
  line-height: 1.2;
  font-size: 12px;
  font-weight: bold;
}

#zoomInfoPerform .contentPop dl dt p {
  font-size: 18px;
  color: #5E6D8E;
  font-weight: bold;
  line-height: 1.2;
  margin-top: 5px;
}

#zoomInfoPerform .contentPop dl dd {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  max-width: 880px;
  margin-left: 2%;
}

@media all and (min-width: 1200px) {
  #zoomInfoPerform .contentPop dl dd {
    margin-left: 30px;
  }
}

#zoomInfoPerform .contentPop dl dd .row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 3px 0;
}

#zoomInfoPerform .contentPop dl dd .colitem {
  margin-left: 10px;
  min-width: 75px;
}

#zoomInfoPerform .contentPop dl dd .colitem:first-child {
  margin-left: 0;
}

#zoomInfoPerform .contentPop dl dd .colitem:last-child {
  min-width: inherit;
}

#zoomInfoPerform .contentPop dl dd .colitem p {
  font-size: 12px;
  font-weight: bold;
}

#zoomInfoPerform .contentPop dl dd .colitem .num {
  font-size: 22px;
  font-weight: normal;
  line-height: 1.2;
  text-align: right;
  font-family: Arial, Helvetica, sans-serif !important;
}

#zoomInfoPerform .contentPop dl dd .colitem .num small {
  font-size: 12px;
  font-family: "Helvetica Neue","Helvetica","Hiragino Sans","Hiragino Kaku Gothic ProN","Arial","Yu Gothic","Meiryo",sans-serif !important;
}

#zoomInfoPerform .contentPop dl dd .colitem .clgray {
  color: #99A3B1;
  margin-left: 20px;
}

.tbstyle3-fixborder{
  border-right: 1px solid rgba(153, 153, 171, 0.8);
}

.tbstyle3-fixborder td,
.tbstyle3-fixborder th{
  border-left: 1px solid rgba(153, 153, 171, 0.8);
}