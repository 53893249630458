@import "./components/mixins";
$maincl2: #007aff;
$cl-red: #E94D4D; 

#paymentPage {
  .redcl {
    color: $cl-red;
  }

  .block_center {
    width: 80%;
    margin: 10px auto;
  }


  .table-setting {
    padding: 20px;
    border: 1px solid #999;
    border-radius: 10px;
    width: 80%;
    margin: 0 auto;
    background-color: #f8f9fa;;
  }
  
  .footer-text {
    font-size: 20px;
    span {
      font-size: 34px;
      padding-right: 10px;
      font-weight: bold;
    }
  }

  .title-blue{
    color: $maincl2;
    font-size: 16px;
    margin-bottom: 10px;
  }

  .page_regist  {
    padding-top: 0;
  }

  .block1 {
    .search-info {
      position: relative;
      .radioform {
        position: absolute;
        top: 20px;
        left: 20px;
      }
      .text-center {
        text-align: center;
        h3 {
          font-size: 18px;
          color: $maincl2;
          margin-bottom: 20px;
        }
        p {
          font-size: 16px;
        }
        .content-text {
          margin-bottom: 20px;
        }
        .footer-text {
          span {
            font-size: 32px;
            font-weight: bold;
            line-height: 1;
            margin-right: 5px;
            display: inline-block;
          }
        }
      }
    }
  }
  .block2 {
    margin-top: 30px;
    .search-info {
      @include flexbox(flex-start, center);
      .title-blue {
        margin-bottom: 0;
        flex: 0 0 25%;
      }
      .method-list {
        @include flexbox(flex-start, center);
        flex: 0 0 70%;
        li {
          @include flexbox(flex-start, center);
          font-size: 16px;
          .radioform {
            margin-right: 5px;
          }
          &:first-child {
            margin-right: 100px;
          }
        }
      }
    }
  }
}

#layoutSetting {
  .setting-manage {
    .disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}