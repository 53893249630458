.absoluteSide {
  .boxlist {
    ul {
      li.disabled {
        background-color: #dcdfe6;
        color: #fff;
        pointer-events: none;
      }
    }
  }
}
