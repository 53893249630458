@include keyframes(fadeInUp) {
  0% {
    opacity: 0;
    @include transform(translateY(100px));}
  100% {
    opacity: 1;
    @include transform(translateY(0));
  }
}

@mixin fadeInUp($count: $countDefault, $duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
  @include animation-name(fadeInUp);
  @include count($count);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}
