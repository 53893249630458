@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }

  .page-break:nth-child(2n) {
    break-after: page;
    page-break-before: always;
  }

}

@page {
  size: auto;
  zoom: 50%;
  margin: 20mm;
}

#process {
  position: relative;
}

#mainPrintReceipt {
  position: absolute;
  left: -430px;

  .printpage_barcode {
    max-width: 430px;
  }

  .main-print {
    border: 1px solid #a7a4a4;
    padding: 20px;
  }
  .printpage_barcode .block_table table td {
    padding-top: 10px;
  }

  .block_table .technics {
    align-items: center;

    .break-label {
      background-color: #000;
      color: #fff;
      padding: 1px 3px;
      margin-right: 3px;
    }
  }

  .block_subinfo {
    .confirmBox {
      margin-right: auto;
    }

    .subinfo {
      margin-right: 0;
    }
  }

}


.printpage  {
  .block_table .technics_list li {
    margin-right: 3px;
  }

  .block_table .technics {
    span.name {
      word-break: break-all;
      word-wrap: break-word;
    }
  }
}


#mainPrintRanking {
  display: none;

  @media print {
      display: block !important;
      table {
        border-collapse: collapse;
      }
      table, th, td {
        border: 1px solid #ccc !important;
        text-align: center;
      }

      .filterSelection span.txtlable {
        padding: 0 15px 0 15px;
      }

      table.tbstyle5 {
        width: auto;
        th, td {
          text-align: center;
          font-size: 14px;
          width: 100.5px;
          font-weight: 600;
        }

        th {
          padding: 14px 6px;  
          &:first-child {
            width: 120px;
          }

          &:last-child{
            width: 120px;
          }
        }

        td {
          padding: 13px 15px;
        }

        td.showRank {
          padding: 0 !important;
          div {
            padding: 20px 15px;
          }
        }
    
        tbody {
          tr:last-child {
            td:first-child, td:last-child {
              padding: 0px;
              div {
                padding: 13px 15px;
                span {
                  font-size: 20px;
                }
              }
            }
          }

          .processStyle {
            padding: 2px 3px;
            p {
              font-size: 13px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }  
          }
        }
      }

      .silverRank {
        background:#E6E5E6;
      }
      .goldRank {
        background:#F6E4AB;
      }
      .copperRank {
        background:#BE873B;
        span.timePeriod {
          color: #FFF;
        }
      }
    
      .noRank {
        background:#8D8D8D;
        span.timePeriod {
          color: #FFF;
        }
      }
  }

  table.tbstyle5 {
    th, td {
      border: 1px solid #ccc;
      text-align: center;
      font-size: 14px;
    }
  }

  .silverRank {
    background:#E6E5E6;
    border: 1px solid #ccc;
  }
  .goldRank {
    background:#F6E4AB;
    border: 1px solid #ccc;
  }
  .copperRank {
    background:#BE873B;
    border: 1px solid #ccc;
    span.timePeriod {
      color: #FFF;
    }
  }

  .noRank {
    background:#8D8D8D;
    border: 1px solid #ccc;
    span.timePeriod {
      color: #FFF;
    }
  }

  .titlePrint {
    margin: 20px 0 10px 0;
  }

  p.title {
    font-size: 20px;
    font-weight: bold;
  }

  .border {
    border: 1px solid #666;
  }

}


