.modal-success .modal-confirm {
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .modal-content {
    background-color: white;
    border-radius: 10px;
    width: 350px;
    max-width: 100%;
    position: relative;
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }

  .close-btn {
    background-color: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }

  .modal-body {
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.modal-info {
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .modal-content {
    background-color: white;
    border-radius: 5px;
    width: 600px;
    height: 300px;
    position: relative;
  }

  .modal-header {
    display: flex;
    align-items: center;
    position: relative;
    padding: 10px;
  }

  .title {
    flex-grow: 1;
    text-align: center;
    margin: 0;
    font-weight: bold !important;
  }

  .close-btn {
    background-color: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .modalAlert .modal-dialog {
    max-width: 600px;
  }

  .modal-body {
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    padding-left: 2rem;
    padding-right: 2rem;

    p {
      padding-left: 5rem;
      padding-right: 5rem;
      text-align: center;
      font-size: 18px;
      line-height: 1.8;
      color: black;
      font-family: 游ゴシック体, Yu Gothic, YuGothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, メイリオ, Meiryo, ＭＳ\ Ｐゴシック, MS PGothic, Osaka, MS UI Gothic, sans-serif !important;
    }
  }

  .close-btn {
    background-color: #7a7a7a;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1.5rem;
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
  }
}

.modal-notice {
  .modalAlert .modal-dialog {
    max-width: 600px;
    transform: translate(0, 0) !important;
  }

  .modal-body {
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    padding-left: 2rem;
    padding-right: 2rem;
    padding: 0px !important;
    border-top-left-radius: 10px;

    .modal-header {
      min-height: 100px;
      width: 100%;
      background-color: blue;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;

      p {
        color: #FFFFFF;
        align-content: center;
        text-align: center;
        font-weight: bold;
        font-size: 22px !important;
      }
    }

    .modal-description {
      overflow-x: auto !important;
      max-height: 600px;

      .text-black {
        color: #000;
        text-align: left;
      }
    }

    p {
      padding-left: 5rem;
      padding-right: 5rem;
      text-align: left;
      font-size: 18px;
      line-height: 1.8;
      color: black;
      font-family: 游ゴシック体, Yu Gothic, YuGothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, メイリオ, Meiryo, ＭＳ\ Ｐゴシック, MS PGothic, Osaka, MS UI Gothic, sans-serif !important;
    }
  }

  .close-btn {
    background-color: #7a7a7a;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1.5rem;
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 5px;
    padding-left: 2px;
    z-index: 10;
  }
}