@media (min-width: 1024px) and (max-width: 1221px) {
  .request {
    margin-top: 10px;
  }
}

@media (min-width: 1024px) and (max-width: 1196px) {
  .blockTeeths {
    margin-top: 50px;
    zoom: 0.8;
  }

  .blockTeeth-teethSelected .teethTop .triangle:after {
    top: -7px;
  }
}

// EVMRE-705
.block-btn-head button {
  min-width: 110px;
}

.block-end {
  display: flex;
  justify-content: end;
  width: auto;
}

@media (max-width: 1024px) {
  .tb-horizontal table {
    min-width: auto;
  }

  .mw300 {
    max-width: 300px;
  }
}

.block-info {
  .tb-horizontal {
    &.divScroll {
      max-height: calc(85vh - 100px);
      overflow-y: auto;
    }

    &.isScrollLoading {
      opacity: 0.5;
    }
  }

  .table-draggable {
    width: 100%;
    table-layout: fixed;

    th,
    td {
      white-space: nowrap;
    }
  }

  .dflex {
    &.dflex_center {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.block-justify-end {
  display: flex;
  justify-content: end;
}

.text-black {
  color: #000000;
}

.error-field {
  border: 2px solid red !important;
}

.block-fixed-bottom {
  position: fixed;
  justify-content: flex-end;
  bottom: 0;
  right: 0;
  width: calc(100% - 200px);
  text-align: center;
  box-shadow: 0 -4px 15px -4px rgba(0, 0, 0, 0.16);
  z-index: 1;
}

.csv-import {
  max-width: none !important;
  border: 1px solid #ccc !important;
  border-radius: 14px;
  padding: 20px;
  margin-right: 12px;

  .form-group {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  input {
    height: 35px;
    border: 1px solid #ccc !important;
    border-top-right-radius: 0%;
    border-bottom-right-radius: 0%;
    border-right: none !important;

    &:hover {
      cursor: pointer;
    }
  }

  .btn-upload-csv {
    height: 35px;
    width: 4rem;
    border: 1px solid #ccc !important;
    border-left: none;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .csv-title {
    font-size: 14px;
    color: #333 !important;
    font-weight: bold;
  }

  .btn-submit {
    background-color: #428bca;
    color: #FFFFFF;
    height: 40px !important;
  }

  .text-note {
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .btn {
    min-height: 40px;
  }

  .pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .count-data {
      font-size: 14px;
    }

    .pagination-controls {
      .previous {
        background-color: white;
        color: #428bca;
        border: 1px solid #ddd;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        width: 34px;
        height: 34px;
      }

      .next {
        background-color: white;
        color: #428bca;
        border: 1px solid #ddd;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        width: 34px;
        height: 34px;
      }

      .active {
        width: 34px;
        height: 34px;
        color: #FFFFFF;
        background-color: #428bca;
        border-color: #428bca;
      }

      .inactive {
        width: 34px;
        height: 34px;
        color: #FFFFFF;
        background-color: #FFFFFF;
        color: #428bca;
        border: 1px solid #ddd;
      }

      .dots {
        width: 34px;
        height: 34px;
        color: #FFFFFF;
        background-color: #FFFFFF;
        color: #428bca;
        border: 1px solid #ddd;

        &:hover {
          cursor: not-allowed;
        }
      }
    }
  }

  .action {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    .row-per-page {
      display: flex;
      align-items: center;

      label {
        margin-bottom: 10px;
        width: 50px;
      }

      select {
        width: 70px;
        height: 30px;
        border: 1px solid #ddd;
        margin-bottom: 10px;
        margin-right: 5px;
      }
    }

    .search {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      padding-left: 10px;

      .title-search {
        width: 70px;
        margin-right: 0px !important;
      }

      .input-search {
        border-right: 1px solid #ddd !important;
        border-top-right-radius: 4px !important;
        border-bottom-right-radius: 4px !important;
      }
    }

  }

  .show-data-csv {
    p {
      font-size: 24px;
      font-weight: 500;
      color: #000000;
      margin-top: 20px;
      margin-bottom: 10px;
    }

    .sorting {
      content: "\f0dc";
      float: right;
      color: rgba(50, 50, 50, .5);
    }

    .table-wrapper {
      width: 100%;
      max-height: 450px;
      overflow: auto;

      tbody {
        color: #5f5f5f !important;
      }

      .table-bordered {
        border: 1px solid #ddd;

        thead {
          tr {
            th {
              border-bottom-width: 2px;
              width: 10rem;
            }
          }
        }
      }

      .sort-default::after {
        content: "\f0dc";
        font-family: "FontAwesome";
        float: right;
        color: rgba(50, 50, 50, .5);
      }

      .sort-asc::after {
        content: "\f0de"; 
        font-family: "FontAwesome";
        float: right;
      }

      .sort-desc::after {
        content: "\f0dd";
        font-family: "FontAwesome";
        float: right;
      }

      .table {
        background-color: transparent;
        border-collapse: collapse;
        border-spacing: 0;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        min-width: 1000px;
        input {
          width: 100%;
          border-right: 1px solid #ddd !important;
          border-radius: 5px;
        }
      }

      thead {
        display: table-header-group;
        vertical-align: middle;
        unicode-bidi: isolate;
        border-color: inherit;
      }

      th {
        font-size: 14px;
        font-weight: 600;
        color: #000000;
        width: auto;
        min-width: 7rem;
      }

      tr {
        display: table-row;
        vertical-align: inherit;
        unicode-bidi: isolate;
        border-color: inherit;
      }

      .border {
        border-bottom: 1px solid #ddd;
        border-right: 1px solid #ddd;
        position: relative;
        word-break: break-all;
        padding-left: 10px !important;
      }

      .cursor {
        cursor: pointer;
      }

      .action-col {
        width: 10%;
        text-align: center;
      }

      .status-registered {
        color: #000;
      }

      .status-manual {
        color: #a94442
      }

      .status-success {
        color: green;
      }

      .status-duplicate {
        background-color: khaki;
      }

      .status-normal {
        cursor: pointer;
        color: #428bca;

        &:hover {
          text-decoration: underline;
        }
      }

      .mr5 {
        margin-right: 5px;
      }
    }
  }
}

.modal-select-register {
  min-width: 1024px;
  width: 90%;
  overflow-y: auto;
  height: 100%;
  .modal-content {
    position: relative;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 6px;
    background-clip: padding-box;
    outline: 0;
  }
  .modal-header {
    padding: 1rem;
    border-bottom: 1px solid #e5e5e5;
    min-height: 1rem;
    display: flex;
    justify-content: space-between;
  }
  .modal-body {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    position: relative;
    padding: 20px;
  }
  .title {
    color: #0d70b7;
    font-size: 20px;
  }
  .mb20 {
    margin-bottom: 20px;
  }
  .pointer {
    cursor: pointer;
  }
  .style {
    display: flex !important;
    justify-content: center;
    align-items: center
  }
  .invalid-feedback {
    color: red;
  }
}

.modal-error {
  @extend .modal-select-register;
  .modal-custom {
    width: 1000px;
    margin: auto !important;
  }
  .table-wrapper .table {
    table-layout: fixed;
    min-width: auto !important;
  }
  .modal-dialog {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .center {
    display: flex;
    justify-self: center;
  }

  .modal-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: none !important;
    height: 25px;
    padding-bottom: 50px;
  }

  .title {
    padding-top: 10px;
    color: black;
  }
}

.input-import:read-only {
  background-color: white !important;
}
