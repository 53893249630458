.react-datepicker__day--highlighted-custom-1 {
    color: #f65a5a !important;
    border-radius: 100%;
    background: #ffd8d8;
    font-size: 22px;
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    width: 40px;
    height: 40px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

#weekPointPage {
    // .react-datepicker-popper .react-datepicker__day.react-datepicker__day--selected {
    //     background: #FDD8D8;
    //     color: #F25F64 !important;
    //     border-radius: 0;
    // }
    .react-datepicker__day--keyboard-selected {
        background-color: transparent;
    }

    .react-datepicker__day--highlighted-custom-1{
        font-weight: normal;
        background: #D7EAFE;
        color: #676869 !important;
        border-radius: 0;
        position: relative;
        z-index: 2;
        &::after {
            position: absolute;
            content: '';
            background: #D7EAFE;
            width: 6px;
            height: 100%;
            right: -6px;
            top: 0;
            z-index: 1;
        }
    }
    .react-datepicker__day--highlighted-custom-start,
    .react-datepicker__day--highlighted-custom-end{
        background: #588CEB;
        color: #fff !important;
        position: relative;
    }
    .react-datepicker__day--highlighted-custom-start{
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        // &::after {
        //     position: absolute;
        //     content: '';
        //     background: #D7EAFE;
        //     width: 100%;
        //     height: 100%;
        //     left: 0;
        //     top: 0;
        //     z-index: 1;
        // }
    }
    .react-datepicker__day--highlighted-custom-end{
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }
}

